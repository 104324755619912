import * as React from "react";
import SectionRow from "./layout/SectionRow";
import { SampleResponse } from "../data/vis_xy";
import { Chart } from "src/components/Reporting/Charts/Chart";
import * as ExampleCharts from "src/components/Reporting/Charts/All/Examples";
import ConditionalWrapper from "src/components/Common/ConditionalWrapper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { ownFunctionKeys } from "src/utils/reflect";
import { SandboxClass } from "./types";

const Visualizations = () => {
  const [chart, setChart] = React.useState("");
  const handleChange = (event: SelectChangeEvent) =>
    setChart(event.target.value as string);
  return (
    <>
      <SectionRow>
        <Select
          id="select-visualization-chart"
          autoWidth={true}
          sx={{ minWidth: "300px" }}
          onChange={handleChange}
          value={chart}
        >
          {ownFunctionKeys(ExampleCharts).map((key: string) => {
            return (
              <MenuItem key={`Charts-${key}`} value={key}>
                {Reflect.get(ExampleCharts, key).displayName}
              </MenuItem>
            );
          })}
        </Select>
      </SectionRow>
      <SectionRow>
        <ConditionalWrapper condition={chart == ""}>
          <Typography width="100%" align="center">
            Please Select a Visualization
          </Typography>
        </ConditionalWrapper>
        <ConditionalWrapper condition={chart != ""}>
          <Chart
            data={SampleResponse}
            style={{ width: "100%", height: "500px" }}
            chart={Reflect.get(ExampleCharts, chart)}
          />
        </ConditionalWrapper>
      </SectionRow>
    </>
  );
};

const VisualizationsSandbox: SandboxClass = class {
  static displayName = "Visualizations";
  static element = Visualizations;
};

export default VisualizationsSandbox;
