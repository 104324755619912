import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface StagesListProps {
  items: [number, number][];
}

const StagesList = ({ items }: StagesListProps): JSX.Element => {
  return (
    <Box component="ul" sx={{ paddingLeft: "16px" }}>
      {items.map(
        ([stageNumber, score]: [stageNumber: number, score: number]) => {
          return (
            <Box component="li" key={stageNumber}>
              <Typography variant="body2">
                Stage {stageNumber} - {score}%
              </Typography>
            </Box>
          );
        }
      )}
    </Box>
  );
};

export default StagesList;
