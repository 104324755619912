import * as React from "react";

import Content from "./Content";
import OptionsHeader from "./OptionsHeader";

export const Report = () => {
  return (
    <>
      <OptionsHeader />
      <Content />
    </>
  );
};
