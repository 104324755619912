import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Answer } from "src/schemas/question";
import Label from "src/components/Common/Input/Label";
import AnswerInput from "./Input";
import AnswersInfoTooltip from "./InfoTooltip";

const LABEL = "Answers";

interface AnswerSectionInterface {
  answers: Answer[];
  setAnswers: (answers: Answer[]) => any;
}
const AnswerSection = ({ answers, setAnswers }: AnswerSectionInterface) => {
  function handleAnswer(scoreValue: number, text: string): void {
    const updatedData: Answer[] = answers.map((item: Answer) =>
      item.ScoreValue === scoreValue
        ? { ...item, ScoreValue: scoreValue, Text: text }
        : item
    );
    setAnswers(updatedData);
  }
  return (
    <Stack>
      <Label htmlFor="answer">
        <Stack direction="row" alignItems="center">
          <Box component="span">{LABEL}</Box>
          <AnswersInfoTooltip answerCount={answers.length} />
        </Stack>
      </Label>
      {answers?.map((answer: Answer) => (
        <AnswerInput
          key={answer.ScoreValue}
          scoreValue={answer.ScoreValue}
          text={answer.Text}
          handleAnswer={handleAnswer}
        />
      ))}
    </Stack>
  );
};

export default AnswerSection;
