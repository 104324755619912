import * as React from "react";
import Box from "@mui/material/Box";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Body from "src/components/Layout/Authenticated/Body";
import Header from "src/components/Layout/Authenticated/Header/Header";
import Sidebar from "src/components/Layout/Authenticated/Sidebar";
import Content from "src/components/Layout/Authenticated/Content";
import useAuth from "src/context/Auth";

const AuthenticatedLayout = (): JSX.Element => {
  const { isUserAuthenticated, reauthenticateUser } = useAuth();
  const location = useLocation();

  /* Reauthenticate user whenever we navigate to new authenticated page.
   * Note: this will happen ASYNCHRONOUSLY, so a user may initially navigate
   * to an authenticated page, but when the re-authentication finishes and if
   * the user is unauthenticated, they will be redirected to the login page.
   */
  React.useEffect(() => {
    reauthenticateUser();
  }, [location.pathname]);

  /*
   * Stay on authenticated route if user is authenticated.
   * Navigate to login page if user is unauthenticated.
   */
  return isUserAuthenticated ? (
    <Box>
      <Header />
      <Body>
        <Sidebar />
        <Content>
          <Outlet />
        </Content>
      </Body>
    </Box>
  ) : (
    <Navigate to="/" />
  );
};

export default AuthenticatedLayout;
