import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BaseAccordionParams } from "./types";

/* NOTE: Setting TransitionProps - `unmountOnExit: true` for better performance per MUI docs
 * https://mui.com/material-ui/react-accordion/#performance */
const ItemAccordion = ({
  id,
  children,
  expanded
}: {
  id: string;
  children: React.ReactNode;
  expanded: boolean;
}) => {
  return (
    <Accordion
      sx={{ paddingBottom: "30px" }}
      expanded={expanded}
      key={id}
      TransitionProps={{ unmountOnExit: true }}
    >
      {children}
    </Accordion>
  );
};

/* Accordion Header */
const ItemAccordionSummary = ({
  id,
  children,
  handleClick
}: { id: string; children: React.ReactNode } & BaseAccordionParams) => {
  return (
    <AccordionSummary
      onClick={() => handleClick(id)}
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`panel-content-${id}`}
      id={`panel-${id}`}
      sx={{ paddingTop: "30px", alignItems: "flex-start" }}
    >
      {children}
    </AccordionSummary>
  );
};

/* Accordion Body */
const ItemAccordionDetails = ({ children }: { children: React.ReactNode }) => {
  return (
    <AccordionDetails sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
      {children}
    </AccordionDetails>
  );
};

ItemAccordion.Summary = ItemAccordionSummary;
ItemAccordion.Details = ItemAccordionDetails;

export default ItemAccordion;
