import { Palette } from "@mui/material";

/**
 * Get a styles object for Report header and sub-header (Report-specific options)
 */
export function getReportHeaderStyles(palette: Palette): Record<string, any> {
  return {
    width: "100%",
    padding: "20px 16px 28px",
    backgroundColor: palette.bg.lightGrey
  };
}

/**
 * A styles object for the core body of a Report
 */
export const reportBodyStyles: Record<string, any> = {
  width: "100%",
  padding: "36px 24px"
};
