import { z } from "zod";
import { StrictDateSchema } from "./common";
import { MetadataSchema } from "./metadata";

export const UserSchema = z.object({
  Id: z.string().email(),
  Name: z.string().nullable().optional(),
  Email: z.string().email(),
  PhoneNumber: z.string().nullable().optional(),
  UserCreateDate: StrictDateSchema.nullable().optional(),
  UserLastModifiedDate: StrictDateSchema.nullable().optional(),
  Metadata: MetadataSchema.nullable().optional(),
  RoleId: z.string().nullable().optional()
});

export const UserListSchema = UserSchema.array();

export const LinkedUsersSchema = UserSchema.shape.Id.array();

// API Request objects
export const ModifyLinkedUsersRequestSchema = z.object({
  item: LinkedUsersSchema
});

// API Response objects
export const UserResponseSchema = z.object({
  result: UserSchema
});

export const UserListResponseSchema = z.object({
  item: UserListSchema
});

export const LinkedUsersResponseSchema = z.object({
  item: LinkedUsersSchema
});

// Types
export type User = z.infer<typeof UserSchema>;
export type UserId = User["Id"];
export type UserResponse = z.infer<typeof UserResponseSchema>;

export type UserList = z.infer<typeof UserListSchema>;
export type UserListResponse = z.infer<typeof UserListResponseSchema>;

export type LinkedUsers = z.infer<typeof LinkedUsersSchema>;
export type ModifyLinkedUsersRequest = z.infer<
  typeof ModifyLinkedUsersRequestSchema
>;
export type LinkedUsersResponse = z.infer<typeof LinkedUsersResponseSchema>;
