export enum PhaseTypesEnum {
  editing = "editing",
  open = "open",
  closed = "closed",
  completed = "completed"
}

export const PHASES = Object.keys(PhaseTypesEnum);

export const PHASE_COLORS_MAP = new Map([
  [PhaseTypesEnum.editing, "#F8961E"],
  [PhaseTypesEnum.open, "#1E27F8"],
  [PhaseTypesEnum.closed, "#577590"],
  [PhaseTypesEnum.completed, "#90BE6D"]
]);

export const PHASE_INFO_MAP = new Map([
  [
    PhaseTypesEnum.editing,
    "Make changes from the original template to fit your client"
  ],
  [
    PhaseTypesEnum.open,
    "Submit responses and track progress against your assessment"
  ],
  [PhaseTypesEnum.closed, "Lock in responses and view reports & analytics"],
  [
    PhaseTypesEnum.completed,
    "After projects have completed, save and archive assessments"
  ]
]);
