import { LegacyScoredResponse } from "src/schemas/scoredResponse";

export const data = [
  {
    category: "Research",
    value1: 1000,
    value2: 588
  },
  {
    category: "Marketing",
    value1: 1200,
    value2: 1800
  },
  {
    category: "Sales",
    value1: 850,
    value2: 1230
  }
];
export interface FakedData {
  year: string;
  europe: number;
  namerica: number;
  asia: number;
  lamerica: number;
  meast: number;
  africa: number;
}

export interface ProgressChartDataI {
  Domain: string;
  "I don't have experience": number;
  Limited: number;
  Implemented: number;
  Evolving: number;
  Integrated: number;
}

export const ProgressChartData: FakedData[] = [
  {
    year: "2021",
    europe: 2.5,
    namerica: 2.5,
    asia: 2.1,
    lamerica: 1,
    meast: 0.8,
    africa: 0.4
  },
  {
    year: "2022",
    europe: 2.6,
    namerica: 2.7,
    asia: 2.2,
    lamerica: 0.5,
    meast: 0.4,
    africa: 0.3
  },
  {
    year: "2023",
    europe: 2.8,
    namerica: 2.9,
    asia: 2.4,
    lamerica: 0.3,
    meast: 0.9,
    africa: 0.5
  }
];

export const SampleResponse: LegacyScoredResponse[] = [
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 4,
    Text: "We have automated provisioning for everything in the cloud",
    Stage: "Evolving"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 3,
    Text: "We have mostly automated provision, but decommissioning is still done manually",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 2,
    Text: "We do a mix of manual and automated provisioning",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 2,
    Text: "We do a mix of manual and automated provisioning",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 2,
    Text: "We do a mix of manual and automated provisioning",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 2,
    Text: "We do a mix of manual and automated provisioning",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 2,
    Text: "We do a mix of manual and automated provisioning",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 2,
    Text: "We do a mix of manual and automated provisioning",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 2,
    Text: "We do a mix of manual and automated provisioning",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 2,
    Text: "We do a mix of manual and automated provisioning",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 2,
    Text: "We do a mix of manual and automated provisioning",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 2,
    Text: "We do a mix of manual and automated provisioning",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 2,
    Text: "We do a mix of manual and automated provisioning",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: 2,
    Text: "We do a mix of manual and automated provisioning",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Resource Provisioning",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 5,
    Text: "We are committed to unit testing and Test Driven Development",
    Stage: "Integrated"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 4,
    Text: "We consistently write tests as a part of the development process",
    Stage: "Evolving"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 4,
    Text: "We consistently write tests as a part of the development process",
    Stage: "Evolving"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 4,
    Text: "We consistently write tests as a part of the development process",
    Stage: "Evolving"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 4,
    Text: "We consistently write tests as a part of the development process",
    Stage: "Evolving"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 4,
    Text: "We consistently write tests as a part of the development process",
    Stage: "Evolving"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 4,
    Text: "We consistently write tests as a part of the development process",
    Stage: "Evolving"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 4,
    Text: "We consistently write tests as a part of the development process",
    Stage: "Evolving"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 4,
    Text: "We consistently write tests as a part of the development process",
    Stage: "Evolving"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 3,
    Text: "3.\tOur test environment and tools make testing of the applications difficult (simulating tachometer/belt etc.), but we can write tests as part of the process",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 3,
    Text: "3.\tOur test environment and tools make testing of the applications difficult (simulating tachometer/belt etc.), but we can write tests as part of the process",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 3,
    Text: "3.\tOur test environment and tools make testing of the applications difficult (simulating tachometer/belt etc.), but we can write tests as part of the process",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 3,
    Text: "3.\tOur test environment and tools make testing of the applications difficult (simulating tachometer/belt etc.), but we can write tests as part of the process",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 3,
    Text: "3.\tOur test environment and tools make testing of the applications difficult (simulating tachometer/belt etc.), but we can write tests as part of the process",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 3,
    Text: "3.\tOur test environment and tools make testing of the applications difficult (simulating tachometer/belt etc.), but we can write tests as part of the process",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 3,
    Text: "3.\tOur test environment and tools make testing of the applications difficult (simulating tachometer/belt etc.), but we can write tests as part of the process",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 2,
    Text: "2.\tOur unit testing or automated test coverage misses some core functionality",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 2,
    Text: "2.\tOur unit testing or automated test coverage misses some core functionality",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 2,
    Text: "2.\tOur unit testing or automated test coverage misses some core functionality",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 2,
    Text: "2.\tOur unit testing or automated test coverage misses some core functionality",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 2,
    Text: "2.\tOur unit testing or automated test coverage misses some core functionality",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 2,
    Text: "2.\tOur unit testing or automated test coverage misses some core functionality",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: 2,
    Text: "2.\tOur unit testing or automated test coverage misses some core functionality",
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Automated Testing",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 5,
    Text: "Everyone on our team has a strong understanding of how we operate, and we have great documentation for sharing with new teammates and other teams.",
    Stage: "Integrated"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 5,
    Text: "Everyone on our team has a strong understanding of how we operate, and we have great documentation for sharing with new teammates and other teams.",
    Stage: "Integrated"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 5,
    Text: "Everyone on our team has a strong understanding of how we operate, and we have great documentation for sharing with new teammates and other teams.",
    Stage: "Integrated"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 5,
    Text: "Everyone on our team has a strong understanding of how we operate, and we have great documentation for sharing with new teammates and other teams.",
    Stage: "Integrated"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 5,
    Text: "Everyone on our team has a strong understanding of how we operate, and we have great documentation for sharing with new teammates and other teams.",
    Stage: "Integrated"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 4,
    Text: "We have very good and complete documentation that we all follow consistently.",
    Stage: "Evolving"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 3,
    Text: "We have some of our common processes documented, but the documentation frequently needs updating or isn't as useful as we'd like.",
    Stage: "Implemented"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 2,
    Text: 'We have "tribal knowledge" that we use for common tasks.',
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 2,
    Text: 'We have "tribal knowledge" that we use for common tasks.',
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 2,
    Text: 'We have "tribal knowledge" that we use for common tasks.',
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: 2,
    Text: 'We have "tribal knowledge" that we use for common tasks.',
    Stage: "Limited"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Automation and Tools",
    Element: "Consistent Processes",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 5,
    Text: "Configuration is set by environment variables that can be dynamically set at deployment time.",
    Stage: "Integrated"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 5,
    Text: "Configuration is set by environment variables that can be dynamically set at deployment time.",
    Stage: "Integrated"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 5,
    Text: "Configuration is set by environment variables that can be dynamically set at deployment time.",
    Stage: "Integrated"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 5,
    Text: "Configuration is set by environment variables that can be dynamically set at deployment time.",
    Stage: "Integrated"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 5,
    Text: "Configuration is set by environment variables that can be dynamically set at deployment time.",
    Stage: "Integrated"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 4,
    Text: "Configuration parameters are injected into the application and can be set via environment variables.",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 4,
    Text: "Configuration parameters are injected into the application and can be set via environment variables.",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 4,
    Text: "Configuration parameters are injected into the application and can be set via environment variables.",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 4,
    Text: "Configuration parameters are injected into the application and can be set via environment variables.",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 4,
    Text: "Configuration parameters are injected into the application and can be set via environment variables.",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 3,
    Text: "Application configuration files are local to each instance installed and can be set at the time of deployment.",
    Stage: "Implemented"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 2,
    Text: "Application configuration is stored in shared local configuration files or in the registry.",
    Stage: "Limited"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 2,
    Text: "Application configuration is stored in shared local configuration files or in the registry.",
    Stage: "Limited"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 2,
    Text: "Application configuration is stored in shared local configuration files or in the registry.",
    Stage: "Limited"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 1,
    Text: "Changing the configuration of an application requires manual edits of local configuration files, or sometimes even the source code itself.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 1,
    Text: "Changing the configuration of an application requires manual edits of local configuration files, or sometimes even the source code itself.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: 1,
    Text: "Changing the configuration of an application requires manual edits of local configuration files, or sometimes even the source code itself.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Configuration stored in environment",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 4,
    Text: "Applications are organized into discrete components and subsystems, so that individual components or subsystems can be worked on without affecting others.",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 4,
    Text: "Applications are organized into discrete components and subsystems, so that individual components or subsystems can be worked on without affecting others.",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 4,
    Text: "Applications are organized into discrete components and subsystems, so that individual components or subsystems can be worked on without affecting others.",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 4,
    Text: "Applications are organized into discrete components and subsystems, so that individual components or subsystems can be worked on without affecting others.",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 4,
    Text: "Applications are organized into discrete components and subsystems, so that individual components or subsystems can be worked on without affecting others.",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 4,
    Text: "Applications are organized into discrete components and subsystems, so that individual components or subsystems can be worked on without affecting others.",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 4,
    Text: "Applications are organized into discrete components and subsystems, so that individual components or subsystems can be worked on without affecting others.",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 3,
    Text: "Code is designed for testability, including use of interfaces, automatable object model, thin UI.",
    Stage: "Implemented"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 3,
    Text: "Code is designed for testability, including use of interfaces, automatable object model, thin UI.",
    Stage: "Implemented"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 3,
    Text: "Code is designed for testability, including use of interfaces, automatable object model, thin UI.",
    Stage: "Implemented"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 3,
    Text: "Code is designed for testability, including use of interfaces, automatable object model, thin UI.",
    Stage: "Implemented"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 2,
    Text: "Our code resides in one large project that all the developers work in.",
    Stage: "Limited"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 2,
    Text: "Our code resides in one large project that all the developers work in.",
    Stage: "Limited"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 2,
    Text: "Our code resides in one large project that all the developers work in.",
    Stage: "Limited"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 2,
    Text: "Our code resides in one large project that all the developers work in.",
    Stage: "Limited"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 2,
    Text: "Our code resides in one large project that all the developers work in.",
    Stage: "Limited"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 1,
    Text: "It is common for developers to get in each other\u2019s way and redundantly work on the same tasks or overlap code updates",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 1,
    Text: "It is common for developers to get in each other\u2019s way and redundantly work on the same tasks or overlap code updates",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 1,
    Text: "It is common for developers to get in each other\u2019s way and redundantly work on the same tasks or overlap code updates",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 1,
    Text: "It is common for developers to get in each other\u2019s way and redundantly work on the same tasks or overlap code updates",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: 1,
    Text: "It is common for developers to get in each other\u2019s way and redundantly work on the same tasks or overlap code updates",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Decoupled development",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 5,
    Text: "Individual components/services in the system can be evolved independently without impacting other components/services.",
    Stage: "Integrated"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 5,
    Text: "Individual components/services in the system can be evolved independently without impacting other components/services.",
    Stage: "Integrated"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 4,
    Text: "Components/service can evolve over time with internal and external consumers unaware of changes on the backend",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 4,
    Text: "Components/service can evolve over time with internal and external consumers unaware of changes on the backend",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 4,
    Text: "Components/service can evolve over time with internal and external consumers unaware of changes on the backend",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 4,
    Text: "Components/service can evolve over time with internal and external consumers unaware of changes on the backend",
    Stage: "Evolving"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 3,
    Text: "Components and APIs implement versioning.",
    Stage: "Implemented"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 3,
    Text: "Components and APIs implement versioning.",
    Stage: "Implemented"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 3,
    Text: "Components and APIs implement versioning.",
    Stage: "Implemented"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 2,
    Text: "Our applications maintain a well-defined manifest of dependencies  on other applications or components.",
    Stage: "Limited"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 2,
    Text: "Our applications maintain a well-defined manifest of dependencies  on other applications or components.",
    Stage: "Limited"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 2,
    Text: "Our applications maintain a well-defined manifest of dependencies  on other applications or components.",
    Stage: "Limited"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 2,
    Text: "Our applications maintain a well-defined manifest of dependencies  on other applications or components.",
    Stage: "Limited"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 1,
    Text: "We are never entirely sure that upgrading the version of one application or service won't break the installation of another.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 1,
    Text: "We are never entirely sure that upgrading the version of one application or service won't break the installation of another.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 1,
    Text: "We are never entirely sure that upgrading the version of one application or service won't break the installation of another.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 1,
    Text: "We are never entirely sure that upgrading the version of one application or service won't break the installation of another.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 1,
    Text: "We are never entirely sure that upgrading the version of one application or service won't break the installation of another.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 1,
    Text: "We are never entirely sure that upgrading the version of one application or service won't break the installation of another.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 1,
    Text: "We are never entirely sure that upgrading the version of one application or service won't break the installation of another.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 1,
    Text: "We are never entirely sure that upgrading the version of one application or service won't break the installation of another.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: 1,
    Text: "We are never entirely sure that upgrading the version of one application or service won't break the installation of another.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Application Architecture",
    Element: "Independent and isolated releases",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 5,
    Text: "I know that security is my shared responsibility and have received some training.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 2,
    Text: "My work is checked for security by someone else.",
    Stage: "Limited"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: 1,
    Text: "I don\u2019t have knowledge of security training or processes and security is not a consideration when working on code.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Collective ownership of security",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 5,
    Text: "My code/work is automatically tested for security and syntax at various points in deployment and then on regular intervals after release.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 5,
    Text: "My code/work is automatically tested for security and syntax at various points in deployment and then on regular intervals after release.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 4,
    Text: "My code/work is tested manually at regular gates during the deployment process and ad hoc when in production.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 4,
    Text: "My code/work is tested manually at regular gates during the deployment process and ad hoc when in production.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 4,
    Text: "My code/work is tested manually at regular gates during the deployment process and ad hoc when in production.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 4,
    Text: "My code/work is tested manually at regular gates during the deployment process and ad hoc when in production.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 4,
    Text: "My code/work is tested manually at regular gates during the deployment process and ad hoc when in production.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 4,
    Text: "My code/work is tested manually at regular gates during the deployment process and ad hoc when in production.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 4,
    Text: "My code/work is tested manually at regular gates during the deployment process and ad hoc when in production.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 4,
    Text: "My code/work is tested manually at regular gates during the deployment process and ad hoc when in production.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 4,
    Text: "My code/work is tested manually at regular gates during the deployment process and ad hoc when in production.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 4,
    Text: "My code/work is tested manually at regular gates during the deployment process and ad hoc when in production.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 4,
    Text: "My code/work is tested manually at regular gates during the deployment process and ad hoc when in production.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 2,
    Text: "My code/work is packaged and manually tested for security or scanned as needed only.",
    Stage: "Limited"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 2,
    Text: "My code/work is packaged and manually tested for security or scanned as needed only.",
    Stage: "Limited"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 2,
    Text: "My code/work is packaged and manually tested for security or scanned as needed only.",
    Stage: "Limited"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 1,
    Text: "My code/work can be deployed without undergoing security checks or peer review.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 1,
    Text: "My code/work can be deployed without undergoing security checks or peer review.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 1,
    Text: "My code/work can be deployed without undergoing security checks or peer review.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: 1,
    Text: "My code/work can be deployed without undergoing security checks or peer review.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "Security gates in SDLC",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 5,
    Text: "I have access to comprehensive reporting dashboards and my team or I can automate actions based off of events.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 5,
    Text: "I have access to comprehensive reporting dashboards and my team or I can automate actions based off of events.",
    Stage: "Integrated"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 4,
    Text: "I have access to dashboards and can generate my own reports on any environment.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 4,
    Text: "I have access to dashboards and can generate my own reports on any environment.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 4,
    Text: "I have access to dashboards and can generate my own reports on any environment.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 4,
    Text: "I have access to dashboards and can generate my own reports on any environment.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 4,
    Text: "I have access to dashboards and can generate my own reports on any environment.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 4,
    Text: "I have access to dashboards and can generate my own reports on any environment.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 4,
    Text: "I have access to dashboards and can generate my own reports on any environment.",
    Stage: "Evolving"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 3,
    Text: "I can request monitoring and reporting data on my application/system to use as needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 3,
    Text: "I can request monitoring and reporting data on my application/system to use as needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 3,
    Text: "I can request monitoring and reporting data on my application/system to use as needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 3,
    Text: "I can request monitoring and reporting data on my application/system to use as needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 3,
    Text: "I can request monitoring and reporting data on my application/system to use as needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 3,
    Text: "I can request monitoring and reporting data on my application/system to use as needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 2,
    Text: "I am notified by monitoring or QA or security teams when there are issues with my project or application.",
    Stage: "Limited"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 2,
    Text: "I am notified by monitoring or QA or security teams when there are issues with my project or application.",
    Stage: "Limited"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 2,
    Text: "I am notified by monitoring or QA or security teams when there are issues with my project or application.",
    Stage: "Limited"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 2,
    Text: "I am notified by monitoring or QA or security teams when there are issues with my project or application.",
    Stage: "Limited"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 2,
    Text: "I am notified by monitoring or QA or security teams when there are issues with my project or application.",
    Stage: "Limited"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 1,
    Text: "I do not have visibility into code, system, or security telemetry.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: 1,
    Text: "I do not have visibility into code, system, or security telemetry.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Security and Monitoring",
    Element: "System of feedback",
    ScoreValue: null,
    Text: "",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 5,
    Text: "My team has complete ownership on our product, and we are empowered to drive product KPIs in an innovative way.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 5,
    Text: "My team has complete ownership on our product, and we are empowered to drive product KPIs in an innovative way.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 5,
    Text: "My team has complete ownership on our product, and we are empowered to drive product KPIs in an innovative way.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 5,
    Text: "My team has complete ownership on our product, and we are empowered to drive product KPIs in an innovative way.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 4,
    Text: "My team has ownership of our work. We are able to solve most product problems without relying on others.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 4,
    Text: "My team has ownership of our work. We are able to solve most product problems without relying on others.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 4,
    Text: "My team has ownership of our work. We are able to solve most product problems without relying on others.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 4,
    Text: "My team has ownership of our work. We are able to solve most product problems without relying on others.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 4,
    Text: "My team has ownership of our work. We are able to solve most product problems without relying on others.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 4,
    Text: "My team has ownership of our work. We are able to solve most product problems without relying on others.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 4,
    Text: "My team has ownership of our work. We are able to solve most product problems without relying on others.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 4,
    Text: "My team has ownership of our work. We are able to solve most product problems without relying on others.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 3,
    Text: "My team has control of most of the work we do, but rely on others to provide direction to address larger product problems.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 3,
    Text: "My team has control of most of the work we do, but rely on others to provide direction to address larger product problems.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 3,
    Text: "My team has control of most of the work we do, but rely on others to provide direction to address larger product problems.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 3,
    Text: "My team has control of most of the work we do, but rely on others to provide direction to address larger product problems.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 3,
    Text: "My team has control of most of the work we do, but rely on others to provide direction to address larger product problems.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 3,
    Text: "My team has control of most of the work we do, but rely on others to provide direction to address larger product problems.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 3,
    Text: "My team has control of most of the work we do, but rely on others to provide direction to address larger product problems.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 3,
    Text: "My team has control of most of the work we do, but rely on others to provide direction to address larger product problems.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 3,
    Text: "My team has control of most of the work we do, but rely on others to provide direction to address larger product problems.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 3,
    Text: "My team has control of most of the work we do, but rely on others to provide direction to address larger product problems.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 3,
    Text: "My team has control of most of the work we do, but rely on others to provide direction to address larger product problems.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 2,
    Text: "We have some control of the work we do. We only work on a subset of product problems.",
    Stage: "Limited"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 2,
    Text: "We have some control of the work we do. We only work on a subset of product problems.",
    Stage: "Limited"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 2,
    Text: "We have some control of the work we do. We only work on a subset of product problems.",
    Stage: "Limited"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 2,
    Text: "We have some control of the work we do. We only work on a subset of product problems.",
    Stage: "Limited"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 1,
    Text: "I'm not sure what others do, and I move to the next project after deployment.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: 1,
    Text: "I'm not sure what others do, and I move to the next project after deployment.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Agile Product Teams",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 5,
    Text: "My team regularly receives important updates from leaders and other teams that are clear and actionable.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 5,
    Text: "My team regularly receives important updates from leaders and other teams that are clear and actionable.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 5,
    Text: "My team regularly receives important updates from leaders and other teams that are clear and actionable.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 5,
    Text: "My team regularly receives important updates from leaders and other teams that are clear and actionable.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 4,
    Text: "My team regularly receives important updates from leaders and other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 3,
    Text: "My team receives some information from leaders and other teams.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 3,
    Text: "My team receives some information from leaders and other teams.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 3,
    Text: "My team receives some information from leaders and other teams.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 3,
    Text: "My team receives some information from leaders and other teams.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 3,
    Text: "My team receives some information from leaders and other teams.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 3,
    Text: "My team receives some information from leaders and other teams.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 3,
    Text: "My team receives some information from leaders and other teams.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 3,
    Text: "My team receives some information from leaders and other teams.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 3,
    Text: "My team receives some information from leaders and other teams.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 3,
    Text: "My team receives some information from leaders and other teams.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 3,
    Text: "My team receives some information from leaders and other teams.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Communications",
    ScoreValue: 3,
    Text: "My team receives some information from leaders and other teams.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 5,
    Text: "My team has a partnership with business and IT teams on important and strategic decisions, adjusting our approaches collectively.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 5,
    Text: "My team has a partnership with business and IT teams on important and strategic decisions, adjusting our approaches collectively.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 4,
    Text: "My team often meets with other teams on important decisions, and we collaborate with business and IT teams so that most parties are aligned.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 4,
    Text: "My team often meets with other teams on important decisions, and we collaborate with business and IT teams so that most parties are aligned.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 4,
    Text: "My team often meets with other teams on important decisions, and we collaborate with business and IT teams so that most parties are aligned.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 4,
    Text: "My team often meets with other teams on important decisions, and we collaborate with business and IT teams so that most parties are aligned.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 4,
    Text: "My team often meets with other teams on important decisions, and we collaborate with business and IT teams so that most parties are aligned.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 4,
    Text: "My team often meets with other teams on important decisions, and we collaborate with business and IT teams so that most parties are aligned.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 4,
    Text: "My team often meets with other teams on important decisions, and we collaborate with business and IT teams so that most parties are aligned.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 4,
    Text: "My team often meets with other teams on important decisions, and we collaborate with business and IT teams so that most parties are aligned.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 4,
    Text: "My team often meets with other teams on important decisions, and we collaborate with business and IT teams so that most parties are aligned.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 3,
    Text: "I understand how decisions are made and my team is sometimes involved with decision-making that impacts us.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 2,
    Text: "My team is rarely involved with decision-making, only hearing about the results after.",
    Stage: "Limited"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 2,
    Text: "My team is rarely involved with decision-making, only hearing about the results after.",
    Stage: "Limited"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 2,
    Text: "My team is rarely involved with decision-making, only hearing about the results after.",
    Stage: "Limited"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 2,
    Text: "My team is rarely involved with decision-making, only hearing about the results after.",
    Stage: "Limited"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 1,
    Text: "I'm not involved with decision-making.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 1,
    Text: "I'm not involved with decision-making.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: 1,
    Text: "I'm not involved with decision-making.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Decision-Making",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 5,
    Text: "Our team has a strong breadth of knowledge, and we share institutional knowledge through formal and informal means across teams. My passion for learning is well supported by my team and leaders.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 5,
    Text: "Our team has a strong breadth of knowledge, and we share institutional knowledge through formal and informal means across teams. My passion for learning is well supported by my team and leaders.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 5,
    Text: "Our team has a strong breadth of knowledge, and we share institutional knowledge through formal and informal means across teams. My passion for learning is well supported by my team and leaders.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 5,
    Text: "Our team has a strong breadth of knowledge, and we share institutional knowledge through formal and informal means across teams. My passion for learning is well supported by my team and leaders.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 5,
    Text: "Our team has a strong breadth of knowledge, and we share institutional knowledge through formal and informal means across teams. My passion for learning is well supported by my team and leaders.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 5,
    Text: "Our team has a strong breadth of knowledge, and we share institutional knowledge through formal and informal means across teams. My passion for learning is well supported by my team and leaders.",
    Stage: "Integrated"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 4,
    Text: "Our team has a good breadth of knowledge but still rely on some specialized knowledge. Knowledge sharing happens across teams. I am motivated and supported to learn new skills.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 4,
    Text: "Our team has a good breadth of knowledge but still rely on some specialized knowledge. Knowledge sharing happens across teams. I am motivated and supported to learn new skills.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 4,
    Text: "Our team has a good breadth of knowledge but still rely on some specialized knowledge. Knowledge sharing happens across teams. I am motivated and supported to learn new skills.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 4,
    Text: "Our team has a good breadth of knowledge but still rely on some specialized knowledge. Knowledge sharing happens across teams. I am motivated and supported to learn new skills.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 4,
    Text: "Our team has a good breadth of knowledge but still rely on some specialized knowledge. Knowledge sharing happens across teams. I am motivated and supported to learn new skills.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 4,
    Text: "Our team has a good breadth of knowledge but still rely on some specialized knowledge. Knowledge sharing happens across teams. I am motivated and supported to learn new skills.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 4,
    Text: "Our team has a good breadth of knowledge but still rely on some specialized knowledge. Knowledge sharing happens across teams. I am motivated and supported to learn new skills.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 4,
    Text: "Our team has a good breadth of knowledge but still rely on some specialized knowledge. Knowledge sharing happens across teams. I am motivated and supported to learn new skills.",
    Stage: "Evolving"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 3,
    Text: "Our team gets work done, but often call on specialized knowledge from other teams. I am encouraged to share with others and to learn new skills, but only when needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 3,
    Text: "Our team gets work done, but often call on specialized knowledge from other teams. I am encouraged to share with others and to learn new skills, but only when needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 3,
    Text: "Our team gets work done, but often call on specialized knowledge from other teams. I am encouraged to share with others and to learn new skills, but only when needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 3,
    Text: "Our team gets work done, but often call on specialized knowledge from other teams. I am encouraged to share with others and to learn new skills, but only when needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 3,
    Text: "Our team gets work done, but often call on specialized knowledge from other teams. I am encouraged to share with others and to learn new skills, but only when needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 3,
    Text: "Our team gets work done, but often call on specialized knowledge from other teams. I am encouraged to share with others and to learn new skills, but only when needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 3,
    Text: "Our team gets work done, but often call on specialized knowledge from other teams. I am encouraged to share with others and to learn new skills, but only when needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 3,
    Text: "Our team gets work done, but often call on specialized knowledge from other teams. I am encouraged to share with others and to learn new skills, but only when needed.",
    Stage: "Implemented"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 2,
    Text: "We know how to do our job, but we have a lot of specialized knowledge outside of our team. I take training classes when my project needs it.",
    Stage: "Limited"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 2,
    Text: "We know how to do our job, but we have a lot of specialized knowledge outside of our team. I take training classes when my project needs it.",
    Stage: "Limited"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 2,
    Text: "We know how to do our job, but we have a lot of specialized knowledge outside of our team. I take training classes when my project needs it.",
    Stage: "Limited"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 2,
    Text: "We know how to do our job, but we have a lot of specialized knowledge outside of our team. I take training classes when my project needs it.",
    Stage: "Limited"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 1,
    Text: "We only know what we need to do our job and that\u2019s fine. I don't have a need to learn new skills.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 1,
    Text: "We only know what we need to do our job and that\u2019s fine. I don't have a need to learn new skills.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: 1,
    Text: "We only know what we need to do our job and that\u2019s fine. I don't have a need to learn new skills.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Org Structure and Governance",
    Element: "Learning and Knowledge Sharing",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 5,
    Text: "The work of our team and other teams is accessible to everyone. We informally compare practices and share insights together across a variety of formats.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 5,
    Text: "The work of our team and other teams is accessible to everyone. We informally compare practices and share insights together across a variety of formats.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 5,
    Text: "The work of our team and other teams is accessible to everyone. We informally compare practices and share insights together across a variety of formats.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 5,
    Text: "The work of our team and other teams is accessible to everyone. We informally compare practices and share insights together across a variety of formats.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 5,
    Text: "The work of our team and other teams is accessible to everyone. We informally compare practices and share insights together across a variety of formats.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 5,
    Text: "The work of our team and other teams is accessible to everyone. We informally compare practices and share insights together across a variety of formats.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 5,
    Text: "The work of our team and other teams is accessible to everyone. We informally compare practices and share insights together across a variety of formats.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 5,
    Text: "The work of our team and other teams is accessible to everyone. We informally compare practices and share insights together across a variety of formats.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 4,
    Text: "We have regular, frequent, opportunities to share and collaborate with other teams, sometimes using collaboration tools.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 4,
    Text: "We have regular, frequent, opportunities to share and collaborate with other teams, sometimes using collaboration tools.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 4,
    Text: "We have regular, frequent, opportunities to share and collaborate with other teams, sometimes using collaboration tools.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 4,
    Text: "We have regular, frequent, opportunities to share and collaborate with other teams, sometimes using collaboration tools.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 4,
    Text: "We have regular, frequent, opportunities to share and collaborate with other teams, sometimes using collaboration tools.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 4,
    Text: "We have regular, frequent, opportunities to share and collaborate with other teams, sometimes using collaboration tools.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 3,
    Text: "We share work and lessons learned with other teams on a regular, but infrequent, basis.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 3,
    Text: "We share work and lessons learned with other teams on a regular, but infrequent, basis.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 3,
    Text: "We share work and lessons learned with other teams on a regular, but infrequent, basis.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 3,
    Text: "We share work and lessons learned with other teams on a regular, but infrequent, basis.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 2,
    Text: "We share work and lessons learned with other teams on an ad hoc basis.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 2,
    Text: "We share work and lessons learned with other teams on an ad hoc basis.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 2,
    Text: "We share work and lessons learned with other teams on an ad hoc basis.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 2,
    Text: "We share work and lessons learned with other teams on an ad hoc basis.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 2,
    Text: "We share work and lessons learned with other teams on an ad hoc basis.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 2,
    Text: "We share work and lessons learned with other teams on an ad hoc basis.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 2,
    Text: "We share work and lessons learned with other teams on an ad hoc basis.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 2,
    Text: "We share work and lessons learned with other teams on an ad hoc basis.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 2,
    Text: "We share work and lessons learned with other teams on an ad hoc basis.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 1,
    Text: "We work around other teams and functions in order to deliver work on our own terms.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 1,
    Text: "We work around other teams and functions in order to deliver work on our own terms.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 1,
    Text: "We work around other teams and functions in order to deliver work on our own terms.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: 1,
    Text: "We work around other teams and functions in order to deliver work on our own terms.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Cross-Team Collaboration",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 4,
    Text: "We experiment whenever we need so that we can learn. Learning from mistakes is encouraged.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 3,
    Text: "Experimentation happens, but it isn\u2019t highly valued or rewarded.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 3,
    Text: "Experimentation happens, but it isn\u2019t highly valued or rewarded.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 3,
    Text: "Experimentation happens, but it isn\u2019t highly valued or rewarded.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 3,
    Text: "Experimentation happens, but it isn\u2019t highly valued or rewarded.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 2,
    Text: "Experimentation is a great idea, but we don\u2019t have time. Failure is seen as a bad word.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 2,
    Text: "Experimentation is a great idea, but we don\u2019t have time. Failure is seen as a bad word.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 2,
    Text: "Experimentation is a great idea, but we don\u2019t have time. Failure is seen as a bad word.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 2,
    Text: "Experimentation is a great idea, but we don\u2019t have time. Failure is seen as a bad word.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 2,
    Text: "Experimentation is a great idea, but we don\u2019t have time. Failure is seen as a bad word.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 2,
    Text: "Experimentation is a great idea, but we don\u2019t have time. Failure is seen as a bad word.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 2,
    Text: "Experimentation is a great idea, but we don\u2019t have time. Failure is seen as a bad word.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 1,
    Text: "Failure is not an option so we stick to proven practices.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: 1,
    Text: "Failure is not an option so we stick to proven practices.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Experiments, Failing Fast, Continuous Learning",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 5,
    Text: "Leaders and teams are able to create new value based on deep understanding of customers and the business.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 5,
    Text: "Leaders and teams are able to create new value based on deep understanding of customers and the business.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 5,
    Text: "Leaders and teams are able to create new value based on deep understanding of customers and the business.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 4,
    Text: "Our leaders are here to support and empower teams and organizations.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 3,
    Text: "Most leaders provide autonomy, but there are still some pockets of command and control.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 3,
    Text: "Most leaders provide autonomy, but there are still some pockets of command and control.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 3,
    Text: "Most leaders provide autonomy, but there are still some pockets of command and control.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 2,
    Text: "Leaders enable some autonomy, but as soon as an issue arises, revert to command and control.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 2,
    Text: "Leaders enable some autonomy, but as soon as an issue arises, revert to command and control.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 2,
    Text: "Leaders enable some autonomy, but as soon as an issue arises, revert to command and control.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 2,
    Text: "Leaders enable some autonomy, but as soon as an issue arises, revert to command and control.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 2,
    Text: "Leaders enable some autonomy, but as soon as an issue arises, revert to command and control.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 2,
    Text: "Leaders enable some autonomy, but as soon as an issue arises, revert to command and control.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 2,
    Text: "Leaders enable some autonomy, but as soon as an issue arises, revert to command and control.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: 1,
    Text: "Leaders command the teams and provide both direction and tell our teams how to get work done.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Leadership / Servant Leadership",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 5,
    Text: "Teams collectively make decisions and own success or failure and recognition occurs at the team level.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 5,
    Text: "Teams collectively make decisions and own success or failure and recognition occurs at the team level.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 5,
    Text: "Teams collectively make decisions and own success or failure and recognition occurs at the team level.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 5,
    Text: "Teams collectively make decisions and own success or failure and recognition occurs at the team level.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 5,
    Text: "Teams collectively make decisions and own success or failure and recognition occurs at the team level.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 5,
    Text: "Teams collectively make decisions and own success or failure and recognition occurs at the team level.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 5,
    Text: "Teams collectively make decisions and own success or failure and recognition occurs at the team level.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 5,
    Text: "Teams collectively make decisions and own success or failure and recognition occurs at the team level.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 5,
    Text: "Teams collectively make decisions and own success or failure and recognition occurs at the team level.",
    Stage: "Integrated"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 4,
    Text: "My team can solve most problems, but the toughest ones are still tackled by the same few people.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 4,
    Text: "My team can solve most problems, but the toughest ones are still tackled by the same few people.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 4,
    Text: "My team can solve most problems, but the toughest ones are still tackled by the same few people.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 4,
    Text: "My team can solve most problems, but the toughest ones are still tackled by the same few people.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 4,
    Text: "My team can solve most problems, but the toughest ones are still tackled by the same few people.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 4,
    Text: "My team can solve most problems, but the toughest ones are still tackled by the same few people.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 4,
    Text: "My team can solve most problems, but the toughest ones are still tackled by the same few people.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 4,
    Text: "My team can solve most problems, but the toughest ones are still tackled by the same few people.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 4,
    Text: "My team can solve most problems, but the toughest ones are still tackled by the same few people.",
    Stage: "Evolving"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 3,
    Text: "Some problems are solved by my team as a whole, but many still require expertise and decision making of single individuals.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 3,
    Text: "Some problems are solved by my team as a whole, but many still require expertise and decision making of single individuals.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 3,
    Text: "Some problems are solved by my team as a whole, but many still require expertise and decision making of single individuals.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 3,
    Text: "Some problems are solved by my team as a whole, but many still require expertise and decision making of single individuals.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 3,
    Text: "Some problems are solved by my team as a whole, but many still require expertise and decision making of single individuals.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 3,
    Text: "Some problems are solved by my team as a whole, but many still require expertise and decision making of single individuals.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 3,
    Text: "Some problems are solved by my team as a whole, but many still require expertise and decision making of single individuals.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 3,
    Text: "Some problems are solved by my team as a whole, but many still require expertise and decision making of single individuals.",
    Stage: "Implemented"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 2,
    Text: "There are groups of people within my team who solve problems, but we rarely solve problems and make decisions as a collective team.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 2,
    Text: "There are groups of people within my team who solve problems, but we rarely solve problems and make decisions as a collective team.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 2,
    Text: "There are groups of people within my team who solve problems, but we rarely solve problems and make decisions as a collective team.",
    Stage: "Limited"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: 1,
    Text: "My team acts as a group of individuals, certain people are \u201cheroes\u201d who carry the rest of the team.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Culture and Growth Mindset",
    Element: "Team Culture",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 5,
    Text: "I operate with a high degree of freedom and trust that everyone on my team is working toward the same goal.",
    Stage: "Integrated"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 5,
    Text: "I operate with a high degree of freedom and trust that everyone on my team is working toward the same goal.",
    Stage: "Integrated"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 5,
    Text: "I operate with a high degree of freedom and trust that everyone on my team is working toward the same goal.",
    Stage: "Integrated"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 5,
    Text: "I operate with a high degree of freedom and trust that everyone on my team is working toward the same goal.",
    Stage: "Integrated"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 5,
    Text: "I operate with a high degree of freedom and trust that everyone on my team is working toward the same goal.",
    Stage: "Integrated"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 5,
    Text: "I operate with a high degree of freedom and trust that everyone on my team is working toward the same goal.",
    Stage: "Integrated"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 5,
    Text: "I operate with a high degree of freedom and trust that everyone on my team is working toward the same goal.",
    Stage: "Integrated"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 4,
    Text: "I have the time and context needed to get my work done, and I'm able to help others with their work.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 4,
    Text: "I have the time and context needed to get my work done, and I'm able to help others with their work.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 4,
    Text: "I have the time and context needed to get my work done, and I'm able to help others with their work.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 4,
    Text: "I have the time and context needed to get my work done, and I'm able to help others with their work.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 4,
    Text: "I have the time and context needed to get my work done, and I'm able to help others with their work.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 4,
    Text: "I have the time and context needed to get my work done, and I'm able to help others with their work.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 4,
    Text: "I have the time and context needed to get my work done, and I'm able to help others with their work.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 4,
    Text: "I have the time and context needed to get my work done, and I'm able to help others with their work.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 4,
    Text: "I have the time and context needed to get my work done, and I'm able to help others with their work.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 4,
    Text: "I have the time and context needed to get my work done, and I'm able to help others with their work.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 3,
    Text: "I know what I'm going to work on next, and how it relates to the work of my peers within my team including dependencies.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 3,
    Text: "I know what I'm going to work on next, and how it relates to the work of my peers within my team including dependencies.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 3,
    Text: "I know what I'm going to work on next, and how it relates to the work of my peers within my team including dependencies.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 3,
    Text: "I know what I'm going to work on next, and how it relates to the work of my peers within my team including dependencies.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 3,
    Text: "I know what I'm going to work on next, and how it relates to the work of my peers within my team including dependencies.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 3,
    Text: "I know what I'm going to work on next, and how it relates to the work of my peers within my team including dependencies.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 3,
    Text: "I know what I'm going to work on next, and how it relates to the work of my peers within my team including dependencies.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 3,
    Text: "I know what I'm going to work on next, and how it relates to the work of my peers within my team including dependencies.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 2,
    Text: "Work is assigned to me, and I need to weigh competing priorities.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 2,
    Text: "Work is assigned to me, and I need to weigh competing priorities.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 2,
    Text: "Work is assigned to me, and I need to weigh competing priorities.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 1,
    Text: "I need to seek out new work and need help prioritizing it.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 1,
    Text: "I need to seek out new work and need help prioritizing it.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: 1,
    Text: "I need to seek out new work and need help prioritizing it.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Ability to work autonomously",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 5,
    Text: "My team has ownership over discrete projects that don't overlap with others. I always know who is depending on my work.",
    Stage: "Integrated"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 5,
    Text: "My team has ownership over discrete projects that don't overlap with others. I always know who is depending on my work.",
    Stage: "Integrated"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 4,
    Text: "My team has a way to resolve conflicts consistently with other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 4,
    Text: "My team has a way to resolve conflicts consistently with other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 4,
    Text: "My team has a way to resolve conflicts consistently with other teams.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 3,
    Text: "My team plans out dependencies with other teams as best we can.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 2,
    Text: "My team knows how to work together. We are often surprised by requests from other teams.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 2,
    Text: "My team knows how to work together. We are often surprised by requests from other teams.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 2,
    Text: "My team knows how to work together. We are often surprised by requests from other teams.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 2,
    Text: "My team knows how to work together. We are often surprised by requests from other teams.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: 1,
    Text: "I know how to be productive. I don't always know what my team expects of me or what is priority.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Team enablement/alignment",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 5,
    Text: "We have everything we need to onboard new teammates quickly. We can easily implement any new capability that would benefit us.",
    Stage: "Integrated"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 5,
    Text: "We have everything we need to onboard new teammates quickly. We can easily implement any new capability that would benefit us.",
    Stage: "Integrated"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 4,
    Text: "We use the best tools and services for the job, and we know how to gain access to anything we need to get our work done.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 4,
    Text: "We use the best tools and services for the job, and we know how to gain access to anything we need to get our work done.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 4,
    Text: "We use the best tools and services for the job, and we know how to gain access to anything we need to get our work done.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 4,
    Text: "We use the best tools and services for the job, and we know how to gain access to anything we need to get our work done.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 4,
    Text: "We use the best tools and services for the job, and we know how to gain access to anything we need to get our work done.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 4,
    Text: "We use the best tools and services for the job, and we know how to gain access to anything we need to get our work done.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 4,
    Text: "We use the best tools and services for the job, and we know how to gain access to anything we need to get our work done.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 4,
    Text: "We use the best tools and services for the job, and we know how to gain access to anything we need to get our work done.",
    Stage: "Evolving"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 3,
    Text: "We have a team that is partially responsible for providing tools and services to help us be more efficient.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 3,
    Text: "We have a team that is partially responsible for providing tools and services to help us be more efficient.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 3,
    Text: "We have a team that is partially responsible for providing tools and services to help us be more efficient.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 3,
    Text: "We have a team that is partially responsible for providing tools and services to help us be more efficient.",
    Stage: "Implemented"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 2,
    Text: "As we need a tool or resource to do our job, we automate or document the process when we can.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 2,
    Text: "As we need a tool or resource to do our job, we automate or document the process when we can.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 2,
    Text: "As we need a tool or resource to do our job, we automate or document the process when we can.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 2,
    Text: "As we need a tool or resource to do our job, we automate or document the process when we can.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 2,
    Text: "As we need a tool or resource to do our job, we automate or document the process when we can.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 2,
    Text: "As we need a tool or resource to do our job, we automate or document the process when we can.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 2,
    Text: "As we need a tool or resource to do our job, we automate or document the process when we can.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 2,
    Text: "As we need a tool or resource to do our job, we automate or document the process when we can.",
    Stage: "Limited"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 1,
    Text: "We don't have consistent tooling to complete our work. Onboarding team members is slow.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 1,
    Text: "We don't have consistent tooling to complete our work. Onboarding team members is slow.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: 1,
    Text: "We don't have consistent tooling to complete our work. Onboarding team members is slow.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "People Enablement",
    Element: "Work enablement",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 5,
    Text: "We gather stakeholder feedback on work in progress, and instrument our production environments to collect information on feature usage.",
    Stage: "Integrated"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 5,
    Text: "We gather stakeholder feedback on work in progress, and instrument our production environments to collect information on feature usage.",
    Stage: "Integrated"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 4,
    Text: "We have frequent, informal contact with stakeholders to gather feedback and insights on work in progress.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 4,
    Text: "We have frequent, informal contact with stakeholders to gather feedback and insights on work in progress.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 4,
    Text: "We have frequent, informal contact with stakeholders to gather feedback and insights on work in progress.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 4,
    Text: "We have frequent, informal contact with stakeholders to gather feedback and insights on work in progress.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 4,
    Text: "We have frequent, informal contact with stakeholders to gather feedback and insights on work in progress.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 4,
    Text: "We have frequent, informal contact with stakeholders to gather feedback and insights on work in progress.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 4,
    Text: "We have frequent, informal contact with stakeholders to gather feedback and insights on work in progress.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 4,
    Text: "We have frequent, informal contact with stakeholders to gather feedback and insights on work in progress.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 4,
    Text: "We have frequent, informal contact with stakeholders to gather feedback and insights on work in progress.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 4,
    Text: "We have frequent, informal contact with stakeholders to gather feedback and insights on work in progress.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 3,
    Text: "We conduct occasional demos with stakeholders to gather feedback on what we've delivered.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 3,
    Text: "We conduct occasional demos with stakeholders to gather feedback on what we've delivered.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 3,
    Text: "We conduct occasional demos with stakeholders to gather feedback on what we've delivered.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 3,
    Text: "We conduct occasional demos with stakeholders to gather feedback on what we've delivered.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 2,
    Text: "We have occasional contact with stakeholders to review work progress.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 2,
    Text: "We have occasional contact with stakeholders to review work progress.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 2,
    Text: "We have occasional contact with stakeholders to review work progress.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 1,
    Text: "We receive incomplete stakeholder requirements at the beginning of a project or epic.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 1,
    Text: "We receive incomplete stakeholder requirements at the beginning of a project or epic.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 1,
    Text: "We receive incomplete stakeholder requirements at the beginning of a project or epic.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: 1,
    Text: "We receive incomplete stakeholder requirements at the beginning of a project or epic.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Customer Understanding and Feedback",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 5,
    Text: "We design processes and procedures that catch and prevent issues that we haven\u2019t even thought of.",
    Stage: "Integrated"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 5,
    Text: "We design processes and procedures that catch and prevent issues that we haven\u2019t even thought of.",
    Stage: "Integrated"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 5,
    Text: "We design processes and procedures that catch and prevent issues that we haven\u2019t even thought of.",
    Stage: "Integrated"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 4,
    Text: "We\u2019re great at solving known problems, so that they never occur again.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 4,
    Text: "We\u2019re great at solving known problems, so that they never occur again.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 4,
    Text: "We\u2019re great at solving known problems, so that they never occur again.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 3,
    Text: "We\u2019re good at finding known problems and prevent them from reoccurring most of the time.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 3,
    Text: "We\u2019re good at finding known problems and prevent them from reoccurring most of the time.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 3,
    Text: "We\u2019re good at finding known problems and prevent them from reoccurring most of the time.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 3,
    Text: "We\u2019re good at finding known problems and prevent them from reoccurring most of the time.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 3,
    Text: "We\u2019re good at finding known problems and prevent them from reoccurring most of the time.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 3,
    Text: "We\u2019re good at finding known problems and prevent them from reoccurring most of the time.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 3,
    Text: "We\u2019re good at finding known problems and prevent them from reoccurring most of the time.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 3,
    Text: "We\u2019re good at finding known problems and prevent them from reoccurring most of the time.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 3,
    Text: "We\u2019re good at finding known problems and prevent them from reoccurring most of the time.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 2,
    Text: "When things break, we do some root cause analysis, but often fail to follow through on long-term solutions.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 2,
    Text: "When things break, we do some root cause analysis, but often fail to follow through on long-term solutions.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 2,
    Text: "When things break, we do some root cause analysis, but often fail to follow through on long-term solutions.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 2,
    Text: "When things break, we do some root cause analysis, but often fail to follow through on long-term solutions.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 2,
    Text: "When things break, we do some root cause analysis, but often fail to follow through on long-term solutions.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 2,
    Text: "When things break, we do some root cause analysis, but often fail to follow through on long-term solutions.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 2,
    Text: "When things break, we do some root cause analysis, but often fail to follow through on long-term solutions.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 2,
    Text: "When things break, we do some root cause analysis, but often fail to follow through on long-term solutions.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 2,
    Text: "When things break, we do some root cause analysis, but often fail to follow through on long-term solutions.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 2,
    Text: "When things break, we do some root cause analysis, but often fail to follow through on long-term solutions.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 2,
    Text: "When things break, we do some root cause analysis, but often fail to follow through on long-term solutions.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 1,
    Text: "When things break, we focus on the immediate solution.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: 1,
    Text: "When things break, we focus on the immediate solution.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Problem Solving",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 5,
    Text: "We understand how our work connects to a larger strategy, and define it ourselves with that strategy in mind.",
    Stage: "Integrated"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 5,
    Text: "We understand how our work connects to a larger strategy, and define it ourselves with that strategy in mind.",
    Stage: "Integrated"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 5,
    Text: "We understand how our work connects to a larger strategy, and define it ourselves with that strategy in mind.",
    Stage: "Integrated"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 4,
    Text: "We define our work in manageable increments that align to deliverables, and we are able to make changes as needed.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 3,
    Text: "We are proficient at defining our work, but struggle to identify interdependencies.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 2,
    Text: "Our work is defined outside of our team, and frequent changes are made.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 2,
    Text: "Our work is defined outside of our team, and frequent changes are made.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 2,
    Text: "Our work is defined outside of our team, and frequent changes are made.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 2,
    Text: "Our work is defined outside of our team, and frequent changes are made.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 2,
    Text: "Our work is defined outside of our team, and frequent changes are made.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 2,
    Text: "Our work is defined outside of our team, and frequent changes are made.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 2,
    Text: "Our work is defined outside of our team, and frequent changes are made.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 1,
    Text: "We often lack a clear understanding of our deliverables or what success looks like.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: 1,
    Text: "We often lack a clear understanding of our deliverables or what success looks like.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Work definition and clarity",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 5,
    Text: "Our team sustains a consistent velocity, and we have work tracking tools are in place to analyze performance.",
    Stage: "Integrated"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 5,
    Text: "Our team sustains a consistent velocity, and we have work tracking tools are in place to analyze performance.",
    Stage: "Integrated"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 5,
    Text: "Our team sustains a consistent velocity, and we have work tracking tools are in place to analyze performance.",
    Stage: "Integrated"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 4,
    Text: "We track work across teams, and we regularly review feedback to ensure high quality delivery. Our retrospectives results in specific ideas for improvement.",
    Stage: "Evolving"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 3,
    Text: "We have some mechanisms to track work, but no clear feedback loop for improvement.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 3,
    Text: "We have some mechanisms to track work, but no clear feedback loop for improvement.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 3,
    Text: "We have some mechanisms to track work, but no clear feedback loop for improvement.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 3,
    Text: "We have some mechanisms to track work, but no clear feedback loop for improvement.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 3,
    Text: "We have some mechanisms to track work, but no clear feedback loop for improvement.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 3,
    Text: "We have some mechanisms to track work, but no clear feedback loop for improvement.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 3,
    Text: "We have some mechanisms to track work, but no clear feedback loop for improvement.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 3,
    Text: "We have some mechanisms to track work, but no clear feedback loop for improvement.",
    Stage: "Implemented"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 2,
    Text: "Bugs/Issues are added to the backlog as they arise. We do not have a clear line of sight to burn down.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 2,
    Text: "Bugs/Issues are added to the backlog as they arise. We do not have a clear line of sight to burn down.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 2,
    Text: "Bugs/Issues are added to the backlog as they arise. We do not have a clear line of sight to burn down.",
    Stage: "Limited"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: 1,
    Text: "We have no clear processes established to identify bottlenecks or risks to delivery.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  },
  {
    Domain: "Lean Value Stream",
    Element: "Workflow velocity and improvement",
    ScoreValue: null,
    Text: "I don't have the experience to make an accurate selection.",
    Stage: "I don't have experience"
  }
];
