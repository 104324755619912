import {
  FilterCategory,
  FilterState
} from "src/pages/ManageEvaluation/QuestionsPanel/Filters/types";
import { ToggleCategoryActionData } from "../types";
import toggleDomain from "./toggleDomain";
import toggleStage from "./toggleStage";

function toggleDomains(newState: FilterState, enabled: boolean): FilterState {
  Object.keys(newState.domains).forEach((domainName: string) => {
    toggleDomain(newState, { domainName, enabled });
  });
  return newState;
}

function toggleStages(newState: FilterState, enabled: boolean): FilterState {
  Object.keys(newState.stages).forEach((stageName: string) => {
    toggleStage(newState, { stageName, enabled });
  });
  return newState;
}

/**
 * Given a new `filterState` and a FilterCategory `category` + `enabled` boolean
 * provided in the action `data` payload, toggles all filter items under that
 * category to the value of `enabled`.
 */
export default function toggleCategory(
  newState: FilterState,
  data: ToggleCategoryActionData
): FilterState {
  const { category, enabled } = data;

  /* Toggling Domains (setting `enabled` to true or false) will automatically
   * toggle all nested Elements, which is why we don't need a separate method
   * for Elements
   */
  if (
    category == FilterCategory.Domains ||
    category == FilterCategory.Elements
  ) {
    return toggleDomains(newState, enabled);
  }

  if (category == FilterCategory.Stages) {
    return toggleStages(newState, enabled);
  }
}
