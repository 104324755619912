import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { LegacyScoredResponse } from "src/schemas/scoredResponse";
import { IXYAxis } from "@amcharts/amcharts5/.internal/charts/xy/series/XYSeries";
import { Aggregate, ChartMaker, ChartInterface } from "../../Chart/types";
import { aggregateBy } from "../../../utils/data/aggregateBy";
import { groupBy } from "../../../utils/data/groupBy";
import { addTitle } from "../../utils/chartUtils/title";
import { addXYChart } from "../../utils/chartUtils/xy";

type Domain = string;
type Stage = string;
type Sum = number;
type ProcessedData = Record<Domain, Stage | Sum>;
// { [k: string]: string | number }
type GroupedResponse = Record<Domain, LegacyScoredResponse[]>;

/* ResponseDistributionByThemeChart()
 * XY Chart representing the grouping of Answers aggregated by their stage but
 * grouped by the Domain
 * Represented as a horizontal 100% stacked bar chart with a series for each of
 * the domains.
 */

export class ResponseDistributionByTheme implements ChartMaker {
  static displayName = "Response Distribution by Domain";
  private root: am5.Root;
  private data: LegacyScoredResponse[];
  private categoryKey = "Domain";
  private chart: am5xy.XYChart;
  private processedData: ProcessedData[];
  private xAxis: IXYAxis;
  private legend: am5.Legend;
  private yAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>;
  private draggableLegend: boolean;
  constructor({ data, root, draggableLegend }: ChartInterface) {
    this.data = data;
    this.root = root;
    this.draggableLegend = draggableLegend ?? false;
  }
  /* processDataResponseDistributionByTheme
   * Processes the raw data of ScoredResponse into the necessary format for the chart.
   * Groups responses by Domain
   * Aggregate response per domain by Stage
   * Translate data back into array of objects with Domain, and a stage key.
   * Ex:
   * {
   *   Domain: "Automation and Tools",
   *   Evolving: 10,
   *   "I don't have experience": 32,
   *   Implemented: 25,
   *   Integrated: 6,
   *   Limited: 23
   * }
   */
  private process(): ProcessedData[] {
    // @return {"Automation and Tools": ScoredResponse[], ...}
    const groupedByDomain: GroupedResponse = groupBy(
      this.data,
      (r) => r.Domain
    );

    // @return {"Automation and Tools": { Key: "Evolving", Sum: sum }[], ...}
    const aggregatedStageGroupedByDomain: [Domain, Aggregate[]][] =
      Object.entries(groupedByDomain).map(
        ([key, rows]: [Domain, LegacyScoredResponse[]]): [
          Domain,
          Aggregate[]
        ] => [key, aggregateBy(rows, (r) => r.Stage)]
      );

    // @return [{Domain: "Automation and Tools", Evolving: sum, ...}, ...]
    const progressChartData: ProcessedData[] =
      aggregatedStageGroupedByDomain.map(
        ([key, rows]: [Domain, Aggregate[]]): ProcessedData => {
          const results: [Stage, Sum][] = rows.map(
            (stageSummary: Aggregate): [Stage, Sum] => {
              return [stageSummary.Key, stageSummary.Sum];
            }
          );
          const data: ProcessedData = Object.fromEntries(results);
          data[this.categoryKey] = key;
          return data;
        }
      );
    return progressChartData;
  }

  private makeSeries(name, fieldName): am5xy.ColumnSeries {
    const series: am5xy.ColumnSeries = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: name,
        stacked: true,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueXField: fieldName,
        categoryYField: this.categoryKey,
        valueXShow: "valueXTotalPercent",
        tooltip: am5.Tooltip.new(this.root, {
          pointerOrientation: "vertical",
          labelText:
            "{valueX} responses ({valueXTotalPercent.formatNumber('#.#')}% of total)"
        })
      })
    );

    series.columns.template.setAll({
      tooltipText:
        "{name}, {categoryY}:{valueXTotalPercent.formatNumber('#.#')}%",
      tooltipX: am5.percent(10)
    });

    series.data.setAll(this.processedData);
    series.appear();

    series.bullets.push(() => {
      return am5.Bullet.new(this.root, {
        sprite: am5.Label.new(this.root, {
          text: "{valueXTotalPercent.formatNumber('#.#')}%",
          fill: this.root.interfaceColors.get("alternativeText"),
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true
        })
      });
    });

    this.legend.data.push(series);

    return series;
  }

  make(container?: am5.Container): am5xy.XYChart {
    this.processedData = this.process();

    this.chart = addXYChart(
      this.root,
      {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: this.root.verticalLayout
      },
      container
    );

    addTitle(this.root, this.chart, ResponseDistributionByTheme.displayName);

    this.chart.bottomAxesContainer.set("layout", this.root.horizontalLayout);

    const yRenderer: am5xy.AxisRendererY = am5xy.AxisRendererY.new(this.root, {
      minGridDistance: 25
    });

    yRenderer.labels.template.setAll({
      multiLocation: 0.5,
      location: 0.5,
      paddingRight: 15
    });

    yRenderer.grid.template.set("location", 0.5);

    this.yAxis = this.chart.yAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: this.categoryKey,
        tooltip: am5.Tooltip.new(this.root, {}),
        renderer: yRenderer
      })
    );
    this.yAxis.data.setAll(this.processedData);

    const xRenderer: am5xy.AxisRendererX = am5xy.AxisRendererX.new(this.root, {
      minGridDistance: 40
    });

    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50
    });

    this.xAxis = this.chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        min: 0,
        max: 100,
        numberFormat: "#'%'",
        strictMinMax: true,
        calculateTotals: true,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {
          animationDuration: 0
        })
      })
    );
    // TODO: Move legend to it's own container https://www.amcharts.com/docs/v5/concepts/legend/#external-container
    this.legend = this.chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
        draggable: this.draggableLegend
        // layout: am5.GridLayout.new(root, {
        //   maxColumns: 1
        // })
      })
    );

    Object.keys(this.processedData[0])
      .filter((e) => e != this.categoryKey)
      .forEach((e) => {
        this.makeSeries(e, e);
      });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    this.chart.appear(1000, 100);
    return this.chart;
  }
}
