import { FilterState } from "src/pages/ManageEvaluation/QuestionsPanel/Filters/types";
import { Question } from "src/schemas/question";
import { formatQuestionStageText } from "src/utils/format/questionStage";
import { InitializeActionData } from "../types";

function setDomain(newState: FilterState, domain: string): void {
  if (!Reflect.has(newState.domains, domain)) {
    Reflect.set(newState.domains, domain, {
      elements: {},
      enabled: false,
      indeterminate: false
    });
  }
}

function setElement(
  newState: FilterState,
  domain: string,
  element: string
): void {
  if (!Reflect.has(newState.domains[`${domain}`].elements, element)) {
    Reflect.set(newState.domains[`${domain}`].elements, element, {
      enabled: false
    });
  }
}

function setStage(
  newState: FilterState,
  stages: Record<string, string>,
  stage: number
): void {
  if (!Reflect.has(newState.stages, stage)) {
    Reflect.set(newState.stages, stage, {
      enabled: false,
      formatted: formatQuestionStageText(stage, Reflect.get(stages, stage))
    });
  }
}

/**
 * Iterate over an evaluation's questions to build out our FilterState object
 * with the following category fields:
 * - domains
 * - elements (nested under each domain)
 * - stages
 */
export default function initialize(
  newState: FilterState,
  data: InitializeActionData
): FilterState {
  data.evaluation.Questions.forEach((question: Question) => {
    setDomain(newState, question.Domain);
    setElement(newState, question.Domain, question.Element);
    // Set Stage - only for Assessment
    if ("Stage" in question) {
      setStage(newState, data.evaluation.Stages, question.Stage);
    }
  });
  return newState;
}
