import * as React from "react";
import { useTheme } from "@mui/material";
import Modal from "src/components/Common/Modal";
import { HeaderProps } from "./types";

const Header = ({ children }: HeaderProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Modal.Header
      sx={{
        backgroundColor: palette.error.main,
        color: palette.common.white
      }}
    >
      {children}
    </Modal.Header>
  );
};

export default Header;
