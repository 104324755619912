import * as React from "react";
import { useTheme } from "@mui/material/styles";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import Prompt from ".";

const ExportChartPrompt = (): JSX.Element => {
  const { palette } = useTheme();
  return (
    <Prompt sx={{ textAlign: "right" }}>
      Click on the{" "}
      <MoreHorizRoundedIcon
        sx={{ verticalAlign: "middle", color: palette.grey["500"] }}
      />{" "}
      button to export the chart
    </Prompt>
  );
};

export default ExportChartPrompt;
