import { z } from "zod";
import { RecommendationListSchema, RecommendationSchema } from ".";
import { RecommendationExpandOptions } from "../expands/recommendation";
import { QuestionSchema } from "../question";

export const RecommendationResponseSchema = z.object({
  item: RecommendationSchema
});

export const RecommendationResponseExpandSchema = z.object({
  [RecommendationExpandOptions.enum.Questions]:
    QuestionSchema.array().optional()
});

export const RecommendationListResponseSchema = z.object({
  result: RecommendationListSchema,
  _expand: RecommendationResponseExpandSchema.optional()
});

// Types
export type RecommendationResponse = z.infer<
  typeof RecommendationResponseSchema
>;

export type RecommendationResponseExpand = z.infer<
  typeof RecommendationResponseExpandSchema
>;

export type RecommendationListResponse = z.infer<
  typeof RecommendationListResponseSchema
>;
