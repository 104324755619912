import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Answer } from "src/schemas/question";
import { ANSWERS, filterAnswers } from "../data/answers";
import { AnswerListProps, AnswerListFilterType } from "../types";
import Calculation from "./Calculation";

/**
 * A component which displays a list of question answers.
 * @param props.filterType an enum that filters the list
 * - `AnswerListFilterType.none` - shows full list
 *
 * - `AnswerListFilterType.includeRecommendation` - filters answer list to show
 * answers that have a score value low enough to warrant a recommendation
 *
 * - `AnswerListFilterType.excludeRecommendation` - filters answer list to show
 * answers that have a score value high enough to NOT warrant a recommendation
 *
 * @param props.showCalculation a boolean to indicate if each answer in the list
 * should show an inline calculation of the score value
 */
const AnswerList = ({
  filterType = AnswerListFilterType.none,
  showCalculation = false
}: AnswerListProps): JSX.Element => {
  const answers: Answer[] = filterAnswers(ANSWERS, filterType);

  return (
    <Box component="ul" sx={{ paddingLeft: "24px" }}>
      {answers.map((answer: Answer) => (
        <Box component="li" key={answer.ScoreValue}>
          <Typography variant="body2">
            {answer.Text} {showCalculation && <Calculation answer={answer} />}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default AnswerList;
