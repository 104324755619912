import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

export const ParentContainer = styled(Box)(({ theme }) => ({
  height: "500px",
  overflow: "hidden",
  width: "100%",
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: "24px"
}));

export const Container = styled(Box)(() => ({
  height: "100%",
  overflowX: "hidden",
  overflowY: "auto",
  padding: "36px"
}));

const ScrollableContainer = ({
  header = null,
  headerHeight = "0px",
  children,
  sxParent = null,
  sx = null
}) => {
  return (
    <ParentContainer sx={sxParent}>
      <Stack sx={{ height: "100%" }}>
        {header}
        <Box
          component="div"
          sx={{
            height: `calc(100% - ${headerHeight})`,
            flex: 1
          }}
        >
          <Container sx={sx}>{children}</Container>
        </Box>
      </Stack>
    </ParentContainer>
  );
};

export default ScrollableContainer;
