import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender
} from "@mui/x-data-grid";
import { RecommendationRow } from "src/schemas/reporting/recommendations";
import { ValueProps } from "./types";

/**
 * Uses MUI Data Grid column definition to dynamically render row value
 * element if `column.renderCell` method is specified. Otherwise, renders row
 * value as normal body text.
 */
function renderCell(
  column: GridColDef,
  row: RecommendationRow
): React.ReactNode {
  const value: any = Reflect.get(row, column.field);
  const renderCellParams: Pick<
    GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
    "value" | "row"
  > = { value, row };

  return column.renderCell ? (
    /* Use type `any` to bypass having to pass in full MUI Data Grid
     * specific object, which is only needed when linking column to
     * Data Grid instance
     */
    column.renderCell(renderCellParams as any)
  ) : (
    <Typography>{value}</Typography>
  );
}

const Value = ({ column, row }: ValueProps): JSX.Element => {
  return (
    <Box sx={{ display: "inline-block", marginBottom: "8px" }}>
      {renderCell(column, row)}
    </Box>
  );
};

export default Value;
