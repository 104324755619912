import * as React from "react";
import { GridColDef } from "@mui/x-data-grid";

/**
 * Helper function for
 * 1. taking a MUI Data Grid column `field` (key for a column)
 * 1. splitting that `field` on any uppercase letters to create a formatted
 * column header string

 * Returns a bolded component of that formatted column header string.
 *
 * **Note**
 * This will be passed in to the `renderHeader` method of an MUI Data Grid
 * column definition for all columns in our Data Grid
 */
export function makeColumnRenderHeader(
  field: GridColDef["field"]
): JSX.Element {
  /* Splits field on uppercase letters and joins with space */
  const displayField: string = field.split(/(?=[A-Z])/).join(" ");
  return <b>{displayField}</b>;
}

/**
 * Wrapper for creating a column definition to be passed into the MUI Data Grid.
 * This function takes a passed-in column definition and appends a property to
 * ensure that all column headers are
 *
 * - split on uppercase letters of `field` name and joined with whitespace
 * - bolded
 */
export function makeColumn(column: GridColDef): GridColDef {
  return {
    renderHeader: () => makeColumnRenderHeader(column.field),
    ...column
  };
}

/** Wrapper around `makeColumn` helper function to provide a convenient
 * way to pass in
 * - field
 * - flex
 * - additional column options
 */
export function makeFlexColumn(
  field: GridColDef["field"],
  flex: GridColDef["flex"],
  options: Omit<GridColDef, "field" | "flex"> = {}
): GridColDef {
  return makeColumn({ field, flex, ...options });
}
