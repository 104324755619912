import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Modal from "src/components/Common/Modal";
import { ActionsProps } from "../../types";
import SubmitButton from "./SubmitButton";
import DeleteButton from "./DeleteButton";

const Actions = ({
  setDeleteModalOpen,
  ...props
}: ActionsProps): JSX.Element => {
  const [disabled, setDisabled] = React.useState<boolean>(false);
  return (
    <Modal.Actions
      sx={{
        padding: "16px"
      }}
    >
      <Stack direction="row" justifyContent="space-between" width="100%">
        {props.isEditing && (
          <DeleteButton
            handleClose={props.handleClose}
            setDeleteModalOpen={setDeleteModalOpen}
          />
        )}
        <Stack
          spacing="16px"
          direction="row"
          justifyContent="flex-end"
          flex="1"
        >
          <Button
            variant="text"
            size="large"
            onClick={props.handleClose}
            disabled={disabled}
          >
            Cancel
          </Button>
          <SubmitButton
            {...props}
            setDisabled={setDisabled}
            disabled={disabled}
          />
        </Stack>
      </Stack>
    </Modal.Actions>
  );
};

export default Actions;
