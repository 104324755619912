import { CustomEvaluationResponse } from "src/schemas/evaluation";
import { Metadata } from "src/schemas/metadata";
import { IndexedQuestion, Question } from "src/schemas/question";
import { createIndexedQuestion } from "./utils/question";
import { updateStateItem } from "./utils/state";

/**
 * Given an array of IndexedQuestions `questions` and new `question`,
 * returns new questions array with indexed version of `question` at the end.
 */
function updateQuestions(
  questions: IndexedQuestion[],
  question: Question
): IndexedQuestion[] {
  const newQuestion: IndexedQuestion = createIndexedQuestion(
    question,
    questions.length // because new question will be added at end of array
  );
  return [...questions, newQuestion];
}

/**
 * A reducer handler function for adding a new question to an evaluation.
 * - Updates the evaluation state `item.Questions` field.
 * - Updates the evaluation state `item.Metadata` object with a new
 * `DateModified` field, used for conflict checking.
 * @param state current reducer state
 * @param question new question to add to end of `state.item.Questions` array
 * @param meta updated Evaluation Metadata from database - for conflict checking
 * @returns new reducer state
 */
export default function addQuestion(
  state: CustomEvaluationResponse,
  question: Question,
  meta: Metadata
): CustomEvaluationResponse {
  return updateStateItem(state, {
    Metadata: meta,
    Questions: updateQuestions(state.item.Questions, question)
  });
}
