import * as React from "react";
import Stack from "@mui/material/Stack";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import { TextFieldProps } from "@mui/material/TextField";
import {
  DisabledHelperText,
  DisabledHelperTextProps,
  DisabledTooltip
} from "../Tooltip/DisabledTooltip";
import Label from "../Input/Label";
import Input from "../Input";

interface DynamicAutocompleteProps<T>
  extends Omit<AutocompleteProps<T, false, false, false>, "renderInput"> {
  title: string;
  disabledToolTipProps?: {
    helperTextProps?: DisabledHelperTextProps;
  };
  inputProps?: TextFieldProps;
}

/**
 * A reusable, **autocomplete** component which features
 * - a label above it
 * - a tooltip whenever the autocomplete is disabled
 */
const DynamicAutocomplete = <T,>({
  title,
  disabledToolTipProps,
  inputProps,
  ...props
}: DynamicAutocompleteProps<T>) => {
  /* Only render tooltip if in disabled state.
   * - "" (empty string) hides tooltip
   */
  const disabledTooltipTitle = props.disabled ? (
    <DisabledHelperText
      item={title}
      {...disabledToolTipProps?.helperTextProps}
    />
  ) : (
    ""
  );

  return (
    <Stack>
      <Label htmlFor="id">{title}</Label>
      <DisabledTooltip title={disabledTooltipTitle} placement="bottom-end">
        <Autocomplete
          {...props}
          renderInput={(params: object) => (
            <Input {...{ ...params, ...inputProps }} />
          )}
        />
      </DisabledTooltip>
    </Stack>
  );
};
export default DynamicAutocomplete;
