import * as React from "react";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ExpandAllButton from "src/components/Common/Accordion/Expand/ExpandAllButton";
import ConditionalWrapper from "src/components/Common/ConditionalWrapper";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import { PhaseTypesEnum } from "src/utils/constants/phase";
import QuestionModal from "../../QuestionModal";
import { QuestionAction } from "../../QuestionModal/types";

const Header = ({ height }: { height: string }) => {
  const { palette } = useTheme();
  const {
    evaluation,
    questionRowsState,
    setQuestionRowsState,
    filteredQuestionsCount
  } = useManageEvaluationContext();
  const phase: PhaseTypesEnum =
    PhaseTypesEnum[`${evaluation.item.Phase.toLowerCase()}`]; // TODO: use lowercase until BE changes
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const questionsCount: number = evaluation?.item.Questions.length;
  return (
    <>
      <QuestionModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        action={QuestionAction.add}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          height,
          backgroundColor: palette.grey["100"],
          padding: "0px 12px",
          borderBottom: `1px solid ${palette.primary.light}`
        }}
      >
        <Stack direction="row" alignItems="center" spacing="12px">
          <ExpandAllButton
            rowsState={questionRowsState}
            updateRowsState={setQuestionRowsState}
          />
          <ConditionalWrapper condition={questionsCount > 0}>
            <Typography sx={{ color: palette.grey["700"], fontSize: "14px" }}>
              {filteredQuestionsCount} / {questionsCount} questions
            </Typography>
          </ConditionalWrapper>
        </Stack>

        <ConditionalWrapper condition={phase == PhaseTypesEnum.editing}>
          <Button
            variant="contained"
            size="medium"
            sx={{ padding: "4px 16px" }}
            onClick={() => setModalOpen(true)}
          >
            Add Question
          </Button>
        </ConditionalWrapper>
      </Stack>
    </>
  );
};

export default Header;
