import * as React from "react";
import Modal from "src/components/Common/Modal";
import { DeleteModalProps } from "./types";
import Header from "./Header";
import Content from "./Content";
import Actions from "./Actions";

/**
 * Common, reusable component for a Delete modal with the following sections:
 * - [optional] **Header**
 *
 * - **Content**, which features a "Delete" confirmation input that a user must
 * fill out in order to be able to click on the "Delete" button in the Actions
 * section
 *
 * - **Actions**, which contains "Cancel" and "Delete" buttons. Clicking on
 * the "Delete" button will fire off a provided DELETE API call.
 *
 * @param props.open boolean that specifies if modal is open
 * @param props.handleClose function that closes modal
 * @param props.HeaderChildren optional React children to populate Header
 * section. If not provided, Header will not be shown.
 * @param props.ContentChildren optional React children to populate area
 * above "Delete" confirmation input in the Content section.
 * @param props.ActionsProps.onCancelButtonClick the action that should happen
 * when the "Cancel" button in the Actions section is clicked
 * @param props.ActionsProps.onDeleteButtonClick a DELETE API call method that
 * should happen when the "Delete" button in the Actions section is clicked
 * @param props.ActionsProps.successCallback function that should be called
 * if the DELETE API call succeeds
 * @param props.ActionsProps.errorCallback function that should be called
 * if the DELETE API call fails
 */
const DeleteModal = <T,>({
  open,
  handleClose,
  HeaderChildren,
  ContentChildren,
  ActionsProps
}: DeleteModalProps<T>): JSX.Element => {
  /* State that tracks that the user has entered "Delete" in Input within
   * DeleteModalContent
   */
  const [deleteConfirmed, setDeleteConfirmed] = React.useState<boolean>(false);

  return (
    <Modal open={open} handleClose={handleClose}>
      {Boolean(HeaderChildren) && <Header>{HeaderChildren}</Header>}

      <Content setDeleteConfirmed={setDeleteConfirmed}>
        {ContentChildren}
      </Content>

      <Actions deleteConfirmed={deleteConfirmed} {...ActionsProps} />
    </Modal>
  );
};

export default DeleteModal;
