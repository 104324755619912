/*
 * This file supports Amplify which handles our Cognito
 * authentication which is used to gain access to our
 * auth required backend API end points.
 *
 * Since the same applicaiton is promoted between multiple
 * environments we must obtain the appropriate cognito
 * configuretion during runtime. This is based on the hostname
 */

import lodash from "lodash";
import {
  Environment,
  EnvironmentSettingsContextType
} from "src/context/EnvironmentSettings/types";
import { AmplifyConfigType, AmplifyEnvSettingsType } from "./types";

export const AMPLIFY_INITIAL_SETTINGS: AmplifyEnvSettingsType = {
  Auth: {
    region: "",
    userPoolId: "",
    userPoolWebClientId: "",
    identityPoolId: "",
    oauth: {
      domain: "",
      provider: ""
    }
  }
};

/**
 * This function is used to determine the API endpoint based on the
 * environment. This is used by Amplify to determine the API endpoint
 * to use for API calls.
 * @param environment
 * @returns string
 **/
function getAPIEndpoint(environment: Environment): string {
  // This is the route after the hostname which cloudfront
  // uses to point to our API. Each environment has a
  // non-feature branch related route. In dev we support
  // feature branch routes under the same hostname by
  // appending the branch name to the end of this. such as
  // "dart-apiservices-dtt29"
  const nonFeaturePath = "dart-apiservices";

  // Return the production API endpoint
  if (environment == Environment.production) {
    return `https://${window.location.hostname}/${nonFeaturePath}`;
  }
  // Check to see if REACT_APP_API_ENDPOINT is set in the previous step.
  // If not, we will proceed with determining the appropriate dev environment endpoint
  if (process.env.REACT_APP_API_ENDPOINT) {
    return `https://${window.location.hostname}/${process.env.REACT_APP_API_ENDPOINT}`;
  } else if (process.env.NODE_ENV == "development") {
    return "http://localhost:3000";
  }
  return `https://${window.location.hostname}/${nonFeaturePath}`;
}

/**
 * This function is used to merge the static config with the environment
 * specific settings
 * @param environmentSettings
 * @returns AmplifyConfigType
 **/
function makeAmplifyConfig(
  environmentSettings: EnvironmentSettingsContextType
): AmplifyConfigType {
  const staticConfig: AmplifyConfigType = {
    Auth: {
      mandatorySignIn: true,
      oauth: {
        domain: "unknown until runtime",
        scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
        redirectSignIn:
          process.env.NODE_ENV == "development"
            ? `http://localhost:8080/home`
            : `https://${window.location.hostname}/home`,
        redirectSignOut:
          "https://login.microsoftonline.com/common/oauth2/logout",
        responseType: "code"
      }
    },
    API: {
      endpoints: []
    }
  };

  return lodash.merge(staticConfig, {
    Auth: environmentSettings.amplify.Auth,
    API: {
      endpoints: [
        {
          name: "Backend",
          endpoint: getAPIEndpoint(environmentSettings.environment)
        }
      ]
    }
  });
}

export default makeAmplifyConfig;
