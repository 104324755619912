/**
 * An enum that decides how a list of question answers should be filtered:
 * - `AnswerListFilterType.none` - no filter applied; shows full list
 *
 * - `AnswerListFilterType.includeRecommendation` - filters list to show
 * answers that have a score value low enough to warrant a recommendation
 *
 * - `AnswerListFilterType.excludeRecommendation` - filters list to show
 * answers that have a score value high enough to NOT warrant a recommendation
 *
 */
export enum AnswerListFilterType {
  none = "none",
  includeRecommendation = "includeRecommendation",
  excludeRecommendation = "excludeRecommendation"
}

export interface AnswerListProps {
  filterType?: AnswerListFilterType;
  showCalculation?: boolean;
}
