import * as React from "react";
import QuestionAccordion from "src/components/Common/Accordion/Question";
import {
  RowsState,
  RowStateValue
} from "src/components/Common/Accordion/types";
import { expandRow } from "src/components/Common/Accordion/utils";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import {
  AppDispatch,
  AppState,
  useAppSelector,
  useAppDispatch
} from "src/store";
import { updateResponse } from "src/store/slices/manageEvaluation";
import { Question, IndexedQuestion } from "src/schemas/question";
import { Response } from "src/schemas/response";
import { PhaseTypesEnum } from "src/utils/constants/phase";
import QuestionModal from "../../QuestionModal";
import { QuestionAction } from "../../QuestionModal/types";
import QuestionSummary from "./QuestionSummary";
import QuestionDetails from "./QuestionDetails";
import DeleteQuestionModal from "../../DeleteQuestionModal";
import ApplyFilters from "../Filters/data/applyFilters";

export const Body = () => {
  const {
    evaluation,
    evaluationType,
    filterState,
    questionRowsState,
    setQuestionRowsState,
    setFilteredQuestionsCount
  } = useManageEvaluationContext();
  const dispatch: AppDispatch = useAppDispatch();
  const phase: PhaseTypesEnum =
    PhaseTypesEnum[`${evaluation.item.Phase.toLowerCase()}`]; // TODO: use lowercase until BE changes
  // This state is for `Add/Edit` question modal
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  // This state is for `Delete` question modal
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const [questions, setQuestions] = React.useState<IndexedQuestion[]>([]);
  const [editingQuestion, setEditingQuestion] = React.useState<Question>();
  const responses: Record<Question["Id"], Response> = useAppSelector(
    (state: AppState) => state.manageEvaluation.responses
  );

  /* Accordion */
  function handleAccordionClick(id: string): void {
    expandRow(id, questionRowsState, setQuestionRowsState);
  }

  /* Filtering + listening for Edit Question changes */
  // Apply filters to questions whenever filter state changes
  React.useEffect(() => {
    const newQuestions: IndexedQuestion[] = ApplyFilters.questions(
      filterState,
      evaluation.item.Questions
    );
    setQuestions(newQuestions);
    setFilteredQuestionsCount(newQuestions.length);

    // Set questionRowsState to be specific to filtered questions so Expand/Collapse All works correctly
    const rowsState: RowsState<RowStateValue> = {};
    newQuestions.forEach((question: Question) => {
      Reflect.set(rowsState, question.Id, {
        expanded: false
      });
    });
    setQuestionRowsState(rowsState);
  }, [filterState, evaluation?.item?.Questions]);

  /* Modal */
  function showModal(
    e: React.SyntheticEvent,
    open: boolean,
    question: IndexedQuestion
  ) {
    e.stopPropagation(); // stop accordion expand/collapse default action
    setEditingQuestion(question);
    setModalOpen(open);
  }

  return (
    <>
      <QuestionModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        setDeleteModalOpen={setDeleteModalOpen}
        action={QuestionAction.edit}
        editingQuestion={editingQuestion}
      />
      <DeleteQuestionModal
        open={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        setEditModalOpen={setModalOpen}
        questionId={editingQuestion?.Id}
      />
      {questions.map((question: IndexedQuestion) => {
        return (
          <QuestionAccordion
            key={question.Id}
            item={question}
            expanded={Reflect.get(questionRowsState, question.Id).expanded}
          >
            <QuestionSummary
              phase={phase}
              item={question}
              handleClick={handleAccordionClick}
              showModal={(e: React.SyntheticEvent) =>
                showModal(e, true, question)
              }
            />
            <QuestionDetails
              phase={phase}
              item={question}
              evaluationType={evaluationType}
              stageName={evaluation.item?.Stages?.[`${question?.Stage}`]}
              response={Reflect.get(responses, question.Id)}
              setResponse={(response: Response) =>
                dispatch(updateResponse(response))
              }
            />
          </QuestionAccordion>
        );
      })}
    </>
  );
};

export default Body;
