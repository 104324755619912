import { toBoolean } from "src/utils/object";
import { Nullable } from "src/utils/types";

/**
 * foundDataItem
 * @param value value of the key you are trying to find.
 * @param items Array of items to find
 * @param getKey small function to get the key you are trying to find by
 * @returns array containing the single item you want/null and the boolean of
 *          the item found
 */
export function foundDataItem<T>(
  value: Nullable<string> | undefined,
  items: T[],
  getKey: (item: T) => string
): [Nullable<T>, boolean] {
  const foundItem: T | null =
    items.find((item: T) => getKey(item) === value) ?? null;
  return [foundItem, toBoolean(foundItem)];
}
