import * as React from "react";
import BasicQuestionDetails from "src/components/Common/Accordion/Question/Basic/Details";
import { BaseQuestionAccordionDetailsParams } from "src/components/Common/Accordion/types";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { PhaseTypesEnum } from "src/utils/constants/phase";
import OpenAssessmentQuestionDetails from "./Assessment/Open";
import PastOpenAssessmentQuestionDetails from "./Assessment/PastOpen";
import { QuestionDetailsParams } from "./types";

const AssessmentQuestionDetails = ({
  phase,
  item,
  evaluationType,
  stageName,
  response,
  setResponse
}: QuestionDetailsParams) => {
  const baseProps: BaseQuestionAccordionDetailsParams = {
    item,
    index: item.index,
    evaluationType,
    stageName
  };
  switch (phase) {
    case PhaseTypesEnum.editing:
      return <BasicQuestionDetails {...baseProps} />;
    case PhaseTypesEnum.open:
      return (
        <OpenAssessmentQuestionDetails
          {...baseProps}
          response={response}
          setResponse={setResponse}
        />
      );
    default:
      return (
        <PastOpenAssessmentQuestionDetails {...baseProps} response={response} />
      );
  }
};

const SurveyQuestionDetails = ({
  item,
  evaluationType
}: QuestionDetailsParams) => {
  return (
    <BasicQuestionDetails
      item={item}
      index={item.index}
      evaluationType={evaluationType}
    />
  );
};

const QuestionDetails = (props: QuestionDetailsParams) => {
  return props.evaluationType == EvaluationTypes.assessment ? (
    <AssessmentQuestionDetails {...props} />
  ) : (
    <SurveyQuestionDetails {...props} />
  );
};

export default QuestionDetails;
