import * as React from "react";
import { useNavigate } from "react-router-dom";
import { DeleteEvaluationModalProps } from "./types";
import ErrorMessage from "src/components/Common/ErrorMessage";
import DeleteModal from "src/components/Common/DeleteModal";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError, showSuccess } from "src/store/slices/notification";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import EvaluationsService from "src/services/evaluations";
import Header from "./Header";
import Content from "./Content";
import { EmptyResponse } from "src/schemas/http";

const DeleteEvaluationModal = ({
  open,
  handleClose
}: DeleteEvaluationModalProps): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();

  const { evaluation, evaluationType } = useManageEvaluationContext();

  function successCallback(): void {
    handleClose();

    dispatch(
      showSuccess({
        message: `Your ${evaluationType} was successfully deleted.`
      })
    );

    navigate("/home");
  }

  function errorCallback(error: Error): void {
    handleClose();

    dispatch(
      showError({
        message: (
          <ErrorMessage
            error={error}
            action={`deleting your ${evaluationType}`}
          />
        )
      })
    );
  }

  async function onDeleteButtonClick(): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      EvaluationsService.deleteEvaluation({
        evaluationId: evaluation.item.Id,
        evaluationType,
        callback: (response: EmptyResponse) => resolve(response),
        errorCallback: (error: Error) => reject(error)
      });
    });
  }

  return (
    <>
      <DeleteModal
        open={open}
        handleClose={handleClose}
        HeaderChildren={<Header evaluationType={evaluationType} />}
        ContentChildren={<Content evaluationType={evaluationType} />}
        ActionsProps={{
          onCancelButtonClick: handleClose,
          onDeleteButtonClick,
          successCallback,
          errorCallback
        }}
      />
    </>
  );
};

export default DeleteEvaluationModal;
