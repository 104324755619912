import * as React from "react";
import Stack from "@mui/material/Stack";
import ItemAccordion from "..";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { AccordionParams } from "../types";

interface DomainAccordionParams extends AccordionParams {
  item: [string, Set<string>];
}

const DomainAccordion = ({
  item,
  expanded,
  handleClick
}: DomainAccordionParams) => {
  const [domain, elements] = item;
  const { palette } = useTheme();

  return (
    <ItemAccordion id={domain} expanded={expanded}>
      <ItemAccordion.Summary id={domain} handleClick={handleClick}>
        <Stack direction="row" spacing="12px" sx={{ marginRight: "16px" }}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            {domain}
          </Typography>
        </Stack>
      </ItemAccordion.Summary>
      <ItemAccordion.Details>
        <List sx={{ listStyleType: "disc", marginLeft: "30px" }}>
          {[...elements].sort().map((element, index) => (
            <ListItem
              key={index}
              sx={{
                color: palette.grey["300"],
                display: "list-item",
                marginTop: "8px"
              }}
            >
              <Box
                component="span"
                sx={{ color: palette.grey["700"], lineHeight: "1.5" }}
              >
                {element}
              </Box>
            </ListItem>
          ))}
        </List>
      </ItemAccordion.Details>
    </ItemAccordion>
  );
};

export default DomainAccordion;
