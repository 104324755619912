// eslint-disable-next-line @typescript-eslint/ban-types
export function ownFunctions(myClass: object): any[] {
  return ownFunctionKeys(myClass).map((prop: string | symbol) =>
    Reflect.get(myClass, prop)
  );
}

export function ownFunctionKeys(myClass: object): any[] {
  return Reflect.ownKeys(myClass).filter(
    (prop: string | symbol) => typeof Reflect.get(myClass, prop) == "function"
  );
}
