import { QuestionTypesSchema } from "src/schemas/common";
import { CreateQuestion } from "src/schemas/question";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { conditionalObject } from "src/utils/object";

/**
 * Creates an initial `CreateQuestion` object used to populate the Question
 * fields in the **Add** Question modal.
 * @param evaluationType the type of evaluation (assessment | survey) used to
 * dynamically add in certain fields (e.g., `Stage` for assessment)
 * @param answerCount # of answers - used to determine the length of the
 * Answers array
 * @returns a new CreateQuestion object
 */
export function createNewQuestion(
  evaluationType: EvaluationTypes,
  answerCount: number
): CreateQuestion {
  return {
    Domain: null,
    Element: null,
    // Conditionally add in `Stage` field if evaluation is assessment
    ...conditionalObject(evaluationType === EvaluationTypes.assessment, {
      Stage: null
    }),
    Text: "",
    Type: QuestionTypesSchema.enum.SingleChoice,
    Answers: Array(answerCount)
      .fill(null)
      .map((_, i) => ({ ScoreValue: i, Text: "" }))
  };
}
