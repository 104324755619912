import * as React from "react";
import Stack from "@mui/material/Stack";
import Label from "src/components/Common/Input/Label";
import {
  ActionButtonVariant,
  ButtonSize,
  BUTTON_SIZES,
  BUTTON_VARIANTS
} from "src/utils/constants/button";
import SectionRow from "../layout/SectionRow";
import { SandboxClass } from "../types";
import ExampleActionButton from "./ExampleActionButton";

const ActionButtons = (): JSX.Element => {
  return (
    <>
      {BUTTON_VARIANTS.map((variant: ActionButtonVariant) => {
        return (
          <SectionRow key={variant}>
            <Stack>
              <Label htmlFor={variant}>{variant}</Label>
              <Stack direction="row" spacing="24px" alignItems="flex-end">
                {BUTTON_SIZES.map((size: ButtonSize) => {
                  return (
                    <ExampleActionButton
                      key={`${variant}-${size}`}
                      variant={variant}
                      size={size}
                    />
                  );
                })}
              </Stack>
            </Stack>
          </SectionRow>
        );
      })}
    </>
  );
};

const ActionButtonsSandbox: SandboxClass = class {
  static displayName = "Action Buttons";
  static element = ActionButtons;
};

export default ActionButtonsSandbox;
