import * as React from "react";
import { SxProps } from "@mui/material";
import { EMAIL } from "src/utils/email";
import {
  StyledNavLink,
  StyledNavLinkOnDark,
  StyledNavLinkOnDarkTooltip
} from "../StyledNavLink";

interface MailToLinkProps {
  onDark?: boolean;
  onDarkTooltip?: boolean;
  sx?: SxProps;
}

const MailtoLink = ({
  onDark,
  onDarkTooltip,
  sx = {}
}: MailToLinkProps): JSX.Element => {
  function getStyledLink() {
    if (onDark) {
      return StyledNavLinkOnDark;
    } else if (onDarkTooltip) {
      return StyledNavLinkOnDarkTooltip;
    } else {
      return StyledNavLink;
    }
  }
  const StyledLink = getStyledLink();
  return (
    <StyledLink sx={sx} to={`mailto:${EMAIL}`}>
      {EMAIL}
    </StyledLink>
  );
};

export default MailtoLink;
