import * as React from "react";
import Stack from "@mui/material/Stack";
import UsersList from "./List";
import UsersInput from "./Input";
import { UsersProps } from "./types";

const Users = ({ currentUsers, users, setUsers }: UsersProps): JSX.Element => {
  return (
    <Stack spacing="18px">
      <UsersInput
        currentUsers={currentUsers}
        users={users}
        setUsers={setUsers}
      />
      <UsersList currentUsers={currentUsers} users={users} />
    </Stack>
  );
};

export default Users;
