import { Palette, SxProps } from "@mui/material";

export function getToolbarStyles(palette: Palette): SxProps {
  return {
    ".MuiButton-root": {
      marginRight: "16px"
    },
    padding: "8px",
    borderBottom: `1px dashed ${palette.grey["200"]}`
  };
}
