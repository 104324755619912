import { User, UserId } from "src/schemas/user";
import { sortObjectArrayASC } from "src/utils/sort";

/**
 * Given a list of users, sorts users by name or id (if name doesn't exist) in
 * ascending order.
 */
function sortUsersByNameOrId(users: User[]) {
  // Filter out any users that are missing an Id field
  const validUsers: User[] = users.filter((user: User) => user?.Id);
  return sortObjectArrayASC(validUsers, (user: User) => user.Name ?? user.Id);
}

/**
 * Given a list of users, sorts users by name or id (if name doesn't exist) in
 * ascending order. If `myUserId` is provided, moves user corresponding to
 * `myUserId` to front of list.
 */
export function sortUsers(users: User[], myUserId?: string): User[] {
  if (myUserId) {
    const me: User = users.find((user: User) => user.Id == myUserId);
    const otherUsers: User[] = users.filter(
      (user: User) => user.Id != myUserId
    );
    return [me, ...sortUsersByNameOrId(otherUsers)];
  }
  return sortUsersByNameOrId(users);
}

/**
 * Given a list of Users, creates a set of User Ids
 */
export function createUserIdSet(users: User[]): Set<UserId> {
  return new Set(users.map((user: User) => user.Id));
}

/**
 * Given a User object, returns the Id.
 * Helper for using shorthand notation when mapping over a Users array and
 * extracting out all Ids.
 */
export function getUserId(user: User): UserId {
  return user.Id;
}
