import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { SxProps } from "@mui/material";

interface LabeledBoxProps {
  children: React.ReactNode;
  label: string | JSX.Element;
  labelColor?: string;
  sx?: SxProps;
}

/**
 * A reusable Sandbox layout component that adds a label above whichever component
 * it's wrapped around.
 */
const LabeledBox = ({
  children,
  label,
  labelColor,
  sx = {}
}: LabeledBoxProps) => {
  const { palette } = useTheme();
  return (
    <Stack
      spacing="16px"
      direction="column"
      justifyContent="flex-start"
      sx={{ marginBottom: "16px", ...sx }}
    >
      <Typography
        variant="body1"
        sx={{ color: labelColor ?? palette.common.black }}
      >
        {label}
      </Typography>
      {children}
    </Stack>
  );
};

export default LabeledBox;
