import * as React from "react";
import Stack from "@mui/material/Stack";
import BasicQuestion from ".";
import { QuestionAccordionGroupParams } from "../../types";

// ItemAccordion for Question objects. The parameter items is a list of Questions
const BasicQuestions = ({
  items,
  evaluationType,
  evaluationStagesMap,
  rowsState,
  handleClick
}: QuestionAccordionGroupParams) => {
  return (
    <Stack>
      {(items ?? []).map((item, index) => (
        <BasicQuestion
          key={item.Id}
          item={item}
          index={index}
          evaluationType={evaluationType}
          stageName={evaluationStagesMap[item?.Stage]}
          expanded={Reflect.get(rowsState, item.Id).expanded}
          handleClick={handleClick}
        />
      ))}
    </Stack>
  );
};

export default BasicQuestions;
