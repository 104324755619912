import * as am5 from "@amcharts/amcharts5";
import theme from "src/theme";

/**
 * Sets up a custom color theme for charts where all data items should
 * have the same color (primary blue)
 */
class BlueTheme extends am5.Theme {
  setupDefaultRules() {
    this.rule("ColorSet").setAll({
      colors: [am5.Color.fromString(theme.palette.primary.main)]
    });
  }
}

export default BlueTheme;
