import { v4 as uuidv4 } from "uuid";
import { Question } from "src/schemas/question";

export const sampleSurveyQuestion: Question = {
  Id: uuidv4(),
  Text: "What is your favorite color?",
  Type: "SingleChoice",
  Answers: [
    { ScoreValue: 0, Text: "Blue" },
    { ScoreValue: 1, Text: "Red" },
    { ScoreValue: 2, Text: "Green" },
    { ScoreValue: 3, Text: "Yellow" }
  ],
  Domain: "Personal Preferences",
  Element: "Color",
  RecommendationId: uuidv4(),
  Recommendation: undefined
};

export const sampleAssessmentQuestion: Question = {
  ...sampleSurveyQuestion,
  Id: uuidv4(),
  Stage: 1
};

// NOTE: Leave in temporarily for testing purposes
function randomNumber(max: number) {
  // gets a random number from 0 -> max
  return Math.floor(Math.random() * max);
}

export function makeSampleAssessmentQuestion(stagesCount: number) {
  return {
    ...sampleSurveyQuestion,
    Id: uuidv4(),
    Stage: randomNumber(stagesCount)
  };
}
