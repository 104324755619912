import * as React from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import ErrorMessage from "src/components/Common/ErrorMessage";
import Input from "src/components/Common/Input";
import { Client } from "src/schemas/client";
import {
  Project as ProjectType,
  ProjectListResponse,
  ProjectListResponseSchema
} from "src/schemas/project";
import { GetAPICaller } from "src/services/APICall";
import {
  AppDispatch,
  AppState,
  useAppDispatch,
  useAppSelector
} from "src/store";
import { setSelectedProject } from "src/store/slices/createEvaluation";
import { showError } from "src/store/slices/notification";
import { Collection } from "src/utils/constants/collection";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { Nullable } from "src/utils/types";
import AddItemLabel from "./AddItemLabel";
import { ProjectProps } from "./types";

const Project = ({ setModalOpen }: ProjectProps): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  const evaluationType: EvaluationTypes = useAppSelector(
    (state: AppState) => state.createEvaluation.evaluationType
  );
  const selectedClient: Nullable<Client> = useAppSelector(
    (state: AppState) => state.createEvaluation.selectedClient
  );
  const selectedProject: Nullable<ProjectType> = useAppSelector(
    (state: AppState) => state.createEvaluation.selectedProject
  );

  const canSelectProjects = Boolean(selectedClient);
  const [projects, setProjects] = React.useState<ProjectType[]>(null);
  const placeholder = `Select project to associate ${evaluationType} with`;

  function updateSelectedProject(project: ProjectType): void {
    dispatch(setSelectedProject(project));
  }

  function successCallback(response: ProjectListResponse): void {
    setProjects(response.result);
  }

  function errorCallback(error: Error): void {
    setProjects([]);
    dispatch(
      showError({
        message: <ErrorMessage error={error} collection={Collection.project} />
      })
    );
  }

  /* Get Projects By Client (only after a Client has been selected / changed) */
  React.useEffect(() => {
    /**
     * Local variable within useEffect to handle race conditions
     * with async calls to make sure only data from final call gets set.
     *
     * If a user first clicks Client 1 and then Client 2 quickly after,
     * we may have an edge case where 2 concurrent
     * `GET /clients/{clientId}/projects` calls are occurring.
     *
     * In this case, the User should only ever see the projects for Client 2
     * so they never accidentally select a project that belongs to Client 1.
     *
     * We can't cleanly abort the Project API call for Client 1, but we can
     * choose to NOT set the local `projects` React state (which populates the
     * dropdown) when the API call successfully finishes. We do this using a
     * local variable `shouldSetData`.
     *
     * Below, the local variable `shouldSetData` is set to `false` anytime
     * we clean up the useEffect cycle. Clean-up can happen if a new
     * `useEffect` cycle gets triggered while the previous one is still
     * processing.
     *
     * In our example, when the user selects Client 2, this triggers a clean-up
     * of the Client 1 useEffect. This means that `shouldSetData` for the
     * Client 1 useEffect gets set to `false`, so the API response data for
     * Client 1 **projects** never gets set in local state.
     */
    let shouldSetData = true;
    // Reset selected Project and Project Options any time we change the Client
    updateSelectedProject(null);
    setProjects(null);

    if (selectedClient !== null) {
      GetAPICaller({
        path: `/clients/${selectedClient.Id}/projects`,
        callback: (response: ProjectListResponse) => {
          if (shouldSetData) {
            successCallback(response);
          }
        },
        errorCallback,
        withReactHook: false,
        typeValidator: ProjectListResponseSchema
      });
    }

    // useEffect clean-up
    return () => {
      shouldSetData = false;
    };
  }, [selectedClient]);

  function onChange(
    _event: React.SyntheticEvent,
    newSelectedProject: Nullable<ProjectType>
  ): void {
    updateSelectedProject(newSelectedProject);
  }

  return (
    <Stack>
      <AddItemLabel id="project" label="Project" setModalOpen={setModalOpen} />

      <Tooltip
        placement="bottom-end"
        title={canSelectProjects ? "" : "Please select a client first"}
      >
        <Autocomplete
          id="project"
          disabled={!canSelectProjects}
          loading={canSelectProjects && projects === null}
          options={projects ? projects : []}
          getOptionLabel={(value: ProjectType) => value.Name}
          isOptionEqualToValue={(option: ProjectType) =>
            option.Id === selectedProject.Id
          }
          value={selectedProject}
          onChange={onChange}
          renderInput={(params: object) => (
            <Input {...params} placeholder={placeholder} />
          )}
        />
      </Tooltip>
    </Stack>
  );
};

export default Project;
