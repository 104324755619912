import * as React from "react";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

interface ConditionLabelInterface {
  required: boolean;
}
/**
 * This is a common, reusable Typography component for Label
 *
 * **Usage**: This component get `required` props to indicate when the field is required or optional
 *
 */
const ConditionLabel = ({ required }: ConditionLabelInterface) => {
  const { palette } = useTheme();
  return (
    <Typography
      sx={{
        fontSize: "14px",
        color: palette.grey["500"],
        margin: "0px 0px 12px 12px !important"
      }}
    >
      {required ? <b>REQUIRED</b> : "OPTIONAL"}
    </Typography>
  );
};
export default ConditionLabel;
