import {
  RowsState,
  RowStateValue
} from "src/components/Common/Accordion/types";

export enum PreviewMode {
  Questions = "questions",
  Domains = "domains"
}

export interface PreviewRowStateValue extends RowStateValue {
  type: PreviewMode;
}

export type PreviewRowsState = RowsState<PreviewRowStateValue>;
