import * as React from "react";
import { styled } from "@mui/material/styles";

const Container = styled("div")({
  padding: "36px"
});

const Content = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Content;
