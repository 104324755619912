import * as React from "react";
import Modal from "src/components/Common/Modal";
import Sections from "./Sections";

interface EditModalProps {
  open: boolean;
  handleClose: () => any;
}

const EditModal = ({ open, handleClose }: EditModalProps) => {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Sections handleClose={handleClose} />
    </Modal>
  );
};
export default EditModal;
