import { z } from "zod";
import { DateSchema, zodUUID } from "../common";
import { UserSchema } from "../user";

export const CreateRecommendationSchema = z.object({
  Name: z.string().min(1),
  Description: z.string().min(1),
  Custom: z.boolean().default(false)
});

export const EmptyCreateRecommendationSchema =
  CreateRecommendationSchema.extend({
    Name: z.string().length(0),
    Description: z.string().length(0)
  });

export const RecommendationSchema = CreateRecommendationSchema.extend({
  Id: zodUUID,
  Name: CreateRecommendationSchema.shape.Name.nullable().optional(),
  Description:
    CreateRecommendationSchema.shape.Description.nullable().optional(),
  Custom: CreateRecommendationSchema.shape.Custom.nullable().optional(),
  CreatedBy: UserSchema.shape.Id.nullable().optional(),
  ModifiedBy: UserSchema.shape.Id.nullable().optional(),
  CreatedDate: DateSchema.nullable().optional(),
  ModifiedDate: DateSchema.nullable().optional()
});

export const RecommendationListSchema = RecommendationSchema.array();

export const RecordOfRecommendationsSchema = z.record(
  RecommendationSchema.shape.Id,
  RecommendationSchema
);

// Types
export type CreateRecommendation = z.infer<typeof CreateRecommendationSchema>;
export type EmptyCreateRecommendation = z.infer<
  typeof EmptyCreateRecommendationSchema
>;
export type Recommendation = z.infer<typeof RecommendationSchema>;
export type RecommendationList = z.infer<typeof RecommendationListSchema>;

export type RecordOfRecommendations = z.infer<
  typeof RecordOfRecommendationsSchema
>;
