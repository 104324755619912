import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import theme from "src/theme";
import PhaseChip from "src/components/Common/Chip/Phase";
import TruncatedText from "src/components/Common/Text/Truncated";
import { EvaluationCardProps } from "../types";
import { PhaseTypesEnum } from "src/utils/constants/phase";
// TODO - Re-enable item actions button past MVP
// import ItemActionsButton from "./ItemActionsButton";

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: "24px",
  backgroundColor: theme.palette.bg.lightGrey,
  marginBottom: "24px",
  cursor: "pointer",
  "&:hover": {
    outline: `2px solid ${theme.palette.primary.dark}`
  }
}));

// Card creation
export const EvaluationCard = ({
  evaluation,
  evaluationType
}: EvaluationCardProps): JSX.Element => {
  /* Conditional rendering for tooltip:
   * Should disappear entirely if actions menu is opened
   */
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <Tooltip
      open={showTooltip}
      disableHoverListener={showTooltip}
      // Use onMouseEnter vs. onMouseOver so that event propagation doesn't occur
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onClick={() => navigate(`/${evaluationType}s/${evaluation.Id}`)}
      title="Click to view item"
      placement="bottom-end"
    >
      <StyledCard elevation={0}>
        <CardContent
          sx={{
            padding: "18px 24px",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between"
          }}
        >
          <Box sx={{ flex: 1, overflow: "hidden" }}>
            <TruncatedText
              variant="body1"
              sx={{
                marginBottom: "4px"
              }}
            >
              {evaluation.Name}
            </TruncatedText>
            <TruncatedText
              variant="body2"
              color={theme.palette.grey["700"]}
              sx={{
                marginBottom: "20px"
              }}
            >
              {evaluation.Description}
            </TruncatedText>
            <PhaseChip phase={PhaseTypesEnum[`${evaluation.Phase}`]} />
          </Box>
          {/* TODO - Re-enable item actions button past MVP */}
          {/* <ItemActionsButton onOpen={() => setShowTooltip(false)} /> */}
        </CardContent>
      </StyledCard>
    </Tooltip>
  );
};

export default EvaluationCard;
