import * as React from "react";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import QuestionAccordion from "src/components/Common/Accordion/Question";
import { Answer } from "src/schemas/question";
import { DisabledStyledFormControlLabel, StyledRadio } from "./Styled";
import { PastOpenQuestionDetailsParams } from "../types";

/* NOTE: This component is a placeholder until we finalize our design.
 * It is for the Assessment Closed + Completed phases and follows the same
 * style as the Assessment Open phase, but the responses are now unclickable */
const PastOpenAssessmentQuestionDetails = ({
  item,
  index,
  evaluationType,
  stageName,
  response
}: PastOpenQuestionDetailsParams) => {
  const [value] = React.useState(response?.ScoreValue ?? null); // NOTE: MUI RadioGroup won't accept an `undefined` here

  return (
    <QuestionAccordion.Details
      item={item}
      stageName={stageName}
      index={index}
      evaluationType={evaluationType}
    >
      <FormControl>
        <RadioGroup
          name="past-open-assessment-questions-radio-group"
          value={value}
        >
          {item.Answers.map((answer: Answer) => (
            <DisabledStyledFormControlLabel
              key={answer.ScoreValue}
              value={answer.ScoreValue}
              control={<StyledRadio />}
              label={answer.Text}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </QuestionAccordion.Details>
  );
};

export default PastOpenAssessmentQuestionDetails;
