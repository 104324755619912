import { z } from "zod";
import { pick } from "src/utils/object";
import { ExpandOptionsBase, ExpandOptionsInterface } from "./index";

/**
 * Key definition for **evaluation** expand options - specifies which properties
 * to pick from
 * - `ExpandOptionsInterface` - for typing
 * - `ExpandOptionsBase` object - for creation of the zod enum object
 *
 * **Notes**
 * - Do not specify return type since we need key union type below to infer it
 *
 * - Specify as **readonly** array - needed for Typescript 3.4 for key union
 * type. See https://stackoverflow.com/a/45257357
 */
const EvaluationExpandKeys = [
  "Client",
  "Project",
  "Recommendation",
  "Template",
  "Users"
] as const;

/**
 * Union type of all keys for **evaluation** expand zod enum object.
 */
type EvaluationExpandKeysUnion = (typeof EvaluationExpandKeys)[number];

/**
 * Interface for object used to create expand zod enum object. Ensures object
 * has specified expand keys.
 */
type EvaluationExpandOptionsInterface = Pick<
  ExpandOptionsInterface,
  EvaluationExpandKeysUnion
>;

/**
 * Object with subset of `ExpandOptionsBase` fields used to create specific
 * expand zod enum object.
 */
const EvaluationExpandOptionsBase: EvaluationExpandOptionsInterface = pick(
  ExpandOptionsBase,
  EvaluationExpandKeys
);

/**
 * Zod enum object - used in place of native Typescript enum due to our
 * ability to pass in an object that implements or narrows down our base
 * expand options object. Native enums cannot be based on another enum.
 *
 * **Example Usage**
 * - EvaluationExpandOptions.enum.Client => "client"
 * - EvaluationExpandOptions.enum.Project => "project"
 */
export const EvaluationExpandOptions = z.nativeEnum(
  EvaluationExpandOptionsBase
);

/**
 * Union of expand enum **values** - e.g.,
 * "client" | "project" | etc.
 */
export type EvaluationExpandOption = z.infer<typeof EvaluationExpandOptions>;
