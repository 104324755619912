import * as React from "react";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import Stack from "@mui/material/Stack";
import ConfigurationContainer, {
  HeaderOptions
} from "src/components/Common/ConfigurationContainer";
import Name from "./Name";
import Template from "./Template";
import Description from "./Description";
import Client from "./Client";
import Project from "./Project";
import AddItemModal from "./AddItemModal";

const headerOptions: HeaderOptions = {
  icon: <SettingsRoundedIcon />,
  text: "Configuration Options"
};

const ConfigurationOptions = (): JSX.Element => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  return (
    <ConfigurationContainer
      sxParent={{ flex: 1 }}
      sx={{ padding: "24px 24px 48px" }}
      headerOptions={headerOptions}
    >
      <AddItemModal open={modalOpen} handleClose={() => setModalOpen(false)} />
      <Stack spacing="32px">
        <Name />
        <Template setModalOpen={setModalOpen} />
        <Description />
        <Client setModalOpen={setModalOpen} />
        <Project setModalOpen={setModalOpen} />
      </Stack>
    </ConfigurationContainer>
  );
};
export default ConfigurationOptions;
