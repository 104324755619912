import * as React from "react";
import Box from "@mui/material/Box";

import NoSelectedReport from "./NoSelectedReport";
import SelectedReport from "./SelectedReport";
import useReportsContext from "src/context/Reports";

const Content = () => {
  const { report } = useReportsContext();
  return (
    <Box>
      {report ? <SelectedReport reportName={report} /> : <NoSelectedReport />}
    </Box>
  );
};

export default Content;
