import update from "./update";
import { UpdateEvaluationUsersInterface } from "./types";

/**
 * API Service class for performing actions on an evaluation's linked Users
 */
class EvaluationUsersService {
  static put(args: UpdateEvaluationUsersInterface): void {
    return update(args);
  }
}

export default EvaluationUsersService;
