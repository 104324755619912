/**
 * Given a condition, if condition is true, return passed-in desired object.
 * Otherwise, return empty object
 */
export function conditionalObject(
  condition: boolean,
  desiredObject: Record<string, any>
) {
  return condition ? desiredObject : {};
}

const PRIMITIVES: readonly string[] = Object.freeze([
  "number",
  "string",
  "bigint",
  "symbol",
  "undefined",
  "boolean",
  "function"
]);

export function toBoolean(obj: any): boolean {
  const oType = typeof obj;
  if (oType == "object") {
    if (Array.isArray(obj)) {
      return obj.length > 0;
    } else if (obj === null) {
      return false;
    }
    return Object.keys(obj).length > 0;
  } else if (PRIMITIVES.includes(oType)) {
    return Boolean(obj);
  }
  throw Error("Unable to check if argument is falsy/truthy");
}

/**
 * Given object `item` and passed-in object argument(s),
 * returns a **shallow** copy of `item` with merged-in fields
 * from passed-in object(s)
 *
 * **Example Usage**
 * - `merge(item, updatedItem)`
 * - `merge(item, updatedItem1, updatedItem2)`
 *
 * **Note**
 * `Object.assign` was chosen over
 * - spread operator
 * - lodash merge
 *
 * due to the results on Chrome, Safari, and Firefox for this benchmark test:
 * https://www.measurethat.net/Benchmarks/Show/2978/0/lodash-merge-vs-objectassign-vs-spread
 */
export function merge<S extends object, T extends S>(
  item: S,
  ...items: object[]
): T {
  const itemCopy: S = Object.assign({}, item);
  return Object.assign(itemCopy, ...items);
}

/**
 * Picks the specified keys from the object and returns an object with only
 * those keys.
 * @param keys a subset array of keys belonging to the passed-in object `item`
 * @param item any object
 */
export function pick<T extends object, K extends keyof T>(
  item: T,
  keys: readonly K[] | K[]
): Pick<T, (typeof keys)[number]> {
  /* Note - need to cast using `as` since Object.fromEntries returns generic
   * `[k: string]: any;` type
   */
  return Object.fromEntries(
    keys.map((key: K) => {
      return [key, Reflect.get(item, key)];
    })
  ) as Pick<T, (typeof keys)[number]>;
}
