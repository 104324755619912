import * as React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ActionButton from "src/components/Common/ActionButton";
import ErrorMessage from "src/components/Common/ErrorMessage";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError, showSuccess } from "src/store/slices/notification";
import { EvaluationMapper } from "src/mappers/evaluation";
import { PostAPICaller } from "src/services/APICall";
import {
  CreateEvaluation,
  CreateEvaluationForm,
  EvaluationPostResponse,
  EvaluationPostResponseSchema
} from "src/schemas/evaluation";
import { AppState, useAppSelector } from "src/store";
import { pick } from "src/utils/object";

const createNewEvaluation = async (
  form: CreateEvaluationForm,
  nav: NavigateFunction,
  dispatch: AppDispatch,
  setLoading: (loading: boolean) => void
) => {
  setLoading(true);
  const requestBody: CreateEvaluation =
    EvaluationMapper.parseCreateEvaluation(form);
  PostAPICaller({
    path: `/${form.evaluationType}s`,
    callback: (response: EvaluationPostResponse) => {
      setLoading(false);
      dispatch(
        showSuccess({
          message: `Your ${form.evaluationType} was successfully created.`
        })
      );
      nav(`/${form.evaluationType}s/${response.result.Id}`);
    },
    withReactHook: false,
    body: requestBody,
    errorCallback: (error: Error) => {
      setLoading(false);
      console.log(error);
      dispatch(
        showError({
          message: (
            <ErrorMessage
              error={error}
              action={`creating your ${form.evaluationType}`}
            />
          )
        })
      );
    },
    typeValidator: EvaluationPostResponseSchema
  });
};

const CreateEvaluationButton = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const form: CreateEvaluationForm = useAppSelector((state: AppState) => {
    return pick(state.createEvaluation, [
      "evaluationType",
      "name",
      "description",
      "selectedTemplate",
      "selectedClient",
      "selectedProject"
    ]);
  });
  const { evaluationType } = form;

  const [loading, setLoading] = React.useState<boolean>(false);
  const validated: boolean =
    EvaluationMapper.parseCreateEvaluationSilent(form) != false;

  return (
    <ActionButton
      tooltipLoadingText={`We're creating your ${evaluationType} - hold on tight`}
      tooltipDisabledText={`Please fill out all fields underneath Configuration Options to create a new ${evaluationType}`}
      onClick={() => {
        createNewEvaluation(form, navigate, dispatch, setLoading);
      }}
      loading={loading}
      disabled={!validated}
    >
      {loading ? "Creating" : `Create ${evaluationType}`}
    </ActionButton>
  );
};

export default CreateEvaluationButton;
