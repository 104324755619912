import * as React from "react";
import Stack from "@mui/material/Stack";
import Label from "src/components/Common/Input/Label";
import Input from "src/components/Common/Input";
import {
  AppDispatch,
  AppState,
  useAppDispatch,
  useAppSelector
} from "src/store";
import { setName } from "src/store/slices/createEvaluation";
import { EvaluationTypes } from "src/utils/constants/evaluation";

const Name = (): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  const name: string = useAppSelector(
    (state: AppState) => state.createEvaluation.name
  );
  const evaluationType: EvaluationTypes = useAppSelector(
    (state: AppState) => state.createEvaluation.evaluationType
  );

  function onChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    dispatch(setName(event.target.value));
  }

  return (
    <Stack>
      <Label htmlFor="name">{evaluationType} Name</Label>
      <Input
        id="name"
        placeholder={`Enter a name for your ${evaluationType}`}
        value={name}
        onChange={onChange}
      />
    </Stack>
  );
};

export default Name;
