import { PayloadAction } from "@reduxjs/toolkit";

/**
 * Reducer function type for any fields under the `reducers` section of an
 * object passed into the React Redux `createSlice` method
 */
export interface CreateSliceReducer<T> {
  (state: T, action: PayloadAction<T[keyof T]>): any;
}

/**
 * Basic reducer function for directly setting a field on a Redux Slice state
 * equal to the data coming from the reducer action `payload`.
 *
 * **Usage**
 * To be used for any of the fields under the `reducers` section of an object
 * passed into the React Redux `createSlice` method.
 */
export function createSliceReducer<T extends object>(
  key: keyof T
): CreateSliceReducer<T> {
  return (state: T, action: PayloadAction<T[typeof key]>) => {
    state[`${key as string}`] = action.payload;
  };
}
