import * as React from "react";
import { DeleteQuestionModalProps } from "./types";
import DeleteModal from "src/components/Common/DeleteModal";
import ErrorMessage from "src/components/Common/ErrorMessage";
import EvaluationQuestionService from "src/services/evaluationQuestion";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError, showSuccess } from "src/store/slices/notification";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import { UpdateEvaluationAction } from "src/context/ManageEvaluation/reducers/evaluation/types";
import { DeleteQuestionResponse } from "src/schemas/question";
import Header from "./Header";
import Content from "./Content";

const DeleteQuestionModal = ({
  open,
  setModalOpen,
  setEditModalOpen,
  questionId
}: DeleteQuestionModalProps): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  const { evaluation, evaluationType, dispatchEvaluation } =
    useManageEvaluationContext();

  // Close the `Delete Question` Modal and show the `Edit Question` Modal
  function onCancelButtonClick(): void {
    setModalOpen(false);
    setEditModalOpen(true);
  }

  async function onDeleteButtonClick(): Promise<DeleteQuestionResponse> {
    return new Promise((resolve, reject) => {
      EvaluationQuestionService.deleteQuestion({
        questionId,
        evaluationId: evaluation.item.Id,
        evaluationDateModified: evaluation.item.Metadata.DateModified,
        evaluationType,
        callback: (response: DeleteQuestionResponse) => resolve(response),
        errorCallback: (error: Error) => reject(error)
      });
    });
  }

  function handleClose() {
    setModalOpen(false);
  }

  function successCallback(response: DeleteQuestionResponse): void {
    handleClose();

    dispatch(
      showSuccess({
        message: "Your question was successfully deleted."
      })
    );

    // Update Manage Evaluation context so that we don't have to fetch evaluation from API
    dispatchEvaluation({
      type: UpdateEvaluationAction.deleteQuestion,
      data: questionId,
      meta: response._meta
    });
  }

  function errorCallback(error: Error): void {
    handleClose();

    dispatch(
      showError({
        message: (
          <ErrorMessage error={error} action={"deleting your question"} />
        )
      })
    );
  }

  return (
    <>
      <DeleteModal
        open={open}
        handleClose={handleClose}
        HeaderChildren={<Header />}
        ContentChildren={<Content />}
        ActionsProps={{
          onCancelButtonClick,
          onDeleteButtonClick,
          successCallback,
          errorCallback
        }}
      />
    </>
  );
};

export default DeleteQuestionModal;
