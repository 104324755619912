import * as React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Input from "src/components/Common/Input";
import Label from "src/components/Common/Input/Label";
import {
  AppDispatch,
  AppState,
  useAppDispatch,
  useAppSelector
} from "src/store";
import { setDescription } from "src/store/slices/createEvaluation";
import {
  EvaluationTypes,
  EvaluationArticles
} from "src/utils/constants/evaluation";

const Description = (): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  const description: string = useAppSelector(
    (state: AppState) => state.createEvaluation.description
  );
  const evaluationType: EvaluationTypes = useAppSelector(
    (state: AppState) => state.createEvaluation.evaluationType
  );

  function onChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    dispatch(setDescription(event.target.value));
  }

  const evaluationArticle =
    EvaluationArticles[evaluationType as EvaluationTypes];

  const descriptionHelperText = `The ${evaluationType} description is an internal description used by consultants to quickly identify templates. This field will be pre-populated when a user selects ${evaluationArticle} ${evaluationType} for the first time.`;

  return (
    <Stack>
      <Label htmlFor="description" sx={{ marginBottom: "4px" }}>
        <Stack direction="row" alignItems="center">
          <Box component="span">{evaluationType} Description</Box>
          <Tooltip
            placement="right"
            title={
              <Box sx={{ maxWidth: "200px", fontSize: "14px", padding: "8px" }}>
                {descriptionHelperText}
              </Box>
            }
          >
            <IconButton>
              <InfoOutlinedIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Label>
      <Input
        id="description"
        multiline
        rows="5"
        placeholder={descriptionHelperText}
        value={description}
        onChange={onChange}
      />
    </Stack>
  );
};

export default Description;
