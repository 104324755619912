import * as am5 from "@amcharts/amcharts5";

const DefaultTitleOptions: am5.ILabelSettings = {
  fontSize: 20,
  fontWeight: "500",
  textAlign: "center",
  x: am5.percent(50),
  centerX: am5.percent(50),
  paddingTop: 0,
  paddingBottom: 10
};

export const addTitle = (
  root: am5.Root,
  chart: am5.Container,
  title: string,
  options?: am5.ILabelSettings
) =>
  chart.children.unshift(
    am5.Label.new(root, {
      ...DefaultTitleOptions,
      ...options,
      text: title
    })
  );
