import * as React from "react";
import { Route, Routes } from "react-router-dom";
import useEnvironmentSettings from "src/context/EnvironmentSettings";
import { Environment } from "src/context/EnvironmentSettings/types";
import { EvaluationTypes } from "src/utils/constants/evaluation";

/* Layouts */
import AuthenticatedLayout from "src/layouts/Authenticated";
import PublicLayout from "src/layouts/Public";

/* Pages */
import Error from "src/pages/Error";

// Public (Unauthenticated)
import Login from "src/pages/Login";
import Contact from "src/pages/Contact";
import Health from "src/pages/Health";

// Authenticated
import Home from "src/pages/Home";
import CreateItem from "src/pages/CreateItem";
import Evaluations from "src/pages/Evaluations";
import ManageEvaluation from "src/pages/ManageEvaluation";
import Reports from "src/pages/Reports";
import Sandbox from "src/pages/Sandbox";

const AppRoutes = (): JSX.Element => {
  const { environment } = useEnvironmentSettings();
  const isLowerEnvironment: boolean = environment !== Environment.production;

  return (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<Login />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/health" element={<Health />} />
        <Route path="*" element={<Error message="Page does not exist." />} />
      </Route>
      <Route path="/" element={<AuthenticatedLayout />}>
        <Route path="/home" element={<Home />} />
        <Route path="/create/:itemType" element={<CreateItem />} />
        <Route
          path="/assessments"
          element={<Evaluations evaluationType={EvaluationTypes.assessment} />}
        />
        <Route
          path="/assessments/:id"
          element={<ManageEvaluation type={EvaluationTypes.assessment} />}
        />
        <Route path="/reports" element={<Reports />} />
        {isLowerEnvironment && <Route path="/sandbox" element={<Sandbox />} />}

        {/* TODO - Re-enable past MVP */}
        {/* <Route
          path="/surveys"
          element={<Evaluations evaluationType={EvaluationTypes.survey} />}
        />
        <Route
          path="/surveys/:id"
          element={<ManageEvaluation type={EvaluationTypes.survey} />}
        /> */}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
