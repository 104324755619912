import * as React from "react";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material";
import { PhaseTypesEnum, PHASE_COLORS_MAP } from "src/utils/constants/phase";

const PhaseChip = ({
  phase,
  disabled = false
}: {
  phase: PhaseTypesEnum;
  disabled?: boolean;
}) => {
  const { palette } = useTheme();

  return (
    <Chip
      label={phase}
      size="small"
      sx={{
        backgroundColor: disabled
          ? palette.grey["200"]
          : PHASE_COLORS_MAP.get(phase),
        textTransform: "capitalize",
        borderRadius: "6px",
        fontWeight: "bold",
        letterSpacing: "0.5px",
        color: disabled ? palette.common.black : palette.common.white,
        padding: "8px 0px"
      }}
    />
  );
};

export default PhaseChip;
