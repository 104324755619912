import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useSearchParams } from "react-router-dom";
import InvertedInput from "src/components/Common/Input/Inverted";
import OptionLabel from "src/components/Reporting/Reports/Common/OptionLabel";
import * as REPORTS from "src/components/Reporting/Reports/All";
import { ReportClass } from "src/components/Reporting/Reports/Report/types";
import useReportsContext from "src/context/Reports";

/**
 * A helper function to render a Category (e.g., "Maturity") prefix
 * (if it exists) in front of the Report name for the Reports dropdown
 * - options
 * - selected option
 */
function renderReportOption(reportName: string): string {
  const reportClass: ReportClass = Reflect.get(REPORTS, reportName);
  const displayCategory: ReportClass["displayCategory"] =
    reportClass?.displayCategory;
  const displayName: ReportClass["displayName"] = reportClass.displayName;
  return displayCategory ? `${displayCategory} - ${displayName}` : displayName;
}

const ReportDropdown = (): JSX.Element => {
  const { setReport, report } = useReportsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const reports: string[] = Object.keys(REPORTS); // names of exports (which map to Report classes)
  function handleReportChange(_event: React.SyntheticEvent, value: string) {
    setReport(value);
    if (value) {
      searchParams.set("report", value);
    } else {
      searchParams.delete("report");
    }
    setSearchParams(searchParams);
  }
  return (
    <>
      <OptionLabel id="report" title="Report" />
      <Autocomplete
        sx={{ width: "450px", display: "inline-flex" }}
        id="report"
        options={reports}
        value={report}
        renderOption={(props: object, option: string) => (
          <li {...props} key={option}>
            {renderReportOption(option)}
          </li>
        )}
        getOptionLabel={(option: string) => renderReportOption(option)}
        onChange={handleReportChange}
        renderInput={(params: object) => (
          <InvertedInput {...params} placeholder="Select a report" />
        )}
      />
    </>
  );
};

export default ReportDropdown;
