import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Input from "src/components/Common/Input";
import Label from "src/components/Common/Input/Label";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError } from "src/store/slices/notification";
import {
  Project,
  ProjectListResponse,
  ProjectListResponseSchema
} from "src/schemas/project";
import { Collection } from "src/utils/constants/collection";
import { GetAPICaller } from "src/services/APICall";
import ErrorMessage from "src/components/Common/ErrorMessage";
import { Client } from "src/schemas/client";

interface ProjectFieldProps {
  project: Project;
  setProject: (project: Project) => any;
  selectedClientId: Client["Id"];
}

const ProjectField = ({
  project,
  setProject,
  selectedClientId
}: ProjectFieldProps): JSX.Element => {
  const [projects, setProjects] = React.useState<Project[] | null>(null);
  const dispatch: AppDispatch = useAppDispatch();

  /* Get projects associated with the selected client */
  GetAPICaller({
    path: `/clients/${selectedClientId}/projects`,
    callback: (response: ProjectListResponse) => setProjects(response.result),
    errorCallback: (error: Error) => {
      setProjects([]);
      dispatch(
        showError({
          message: (
            <ErrorMessage error={error} collection={Collection.project} />
          )
        })
      );
    },
    typeValidator: ProjectListResponseSchema
  });

  return (
    <Stack>
      <Label htmlFor="project">Project</Label>
      <Autocomplete
        id="project"
        loading={projects === null}
        isOptionEqualToValue={(option: Project) => option.Id === project.Id}
        getOptionLabel={(option: Project) => option.Name}
        renderOption={(props: object, option: Project) => (
          <li {...props} key={option.Id}>
            {option.Name}
          </li>
        )}
        options={projects ? projects : []}
        value={project}
        onChange={(
          _event: React.SyntheticEvent,
          newSelectedProject: Project | null
        ) => {
          setProject(newSelectedProject);
        }}
        renderInput={(params: object) => <Input {...params} />}
      />
    </Stack>
  );
};

export default ProjectField;
