import { capitalize } from "@mui/material";
import { UpdateEvaluationForm } from "src/schemas/evaluation";
import { merge } from "src/utils/object";

type T = UpdateEvaluationForm;

export interface ReducerAction {
  type: keyof T["item"] | keyof T["item"]["Metadata"] | keyof T["_expand"];
  data: any;
}

/**
 * A React Reducer helper function for merging data into the `item` field of
 * an UpdateEvaluationForm object.
 * @param state previous UpdateEvaluationForm state
 * @param action reducer action, featuring `type` and `data` fields
 * @param action.type Name of `item` field (e.g., `"Name"`)
 * @param action.data Value corresponding to passed-in `item` field (e.g.,
 * string representing Evaluation name)
 * @returns new updated UpdateEvaluationForm state
 */
function mergeItemFlatFields(state: T, action: ReducerAction): T {
  return merge(state, {
    item: merge(state.item, { [action.type]: action.data })
  }) as T;
}

/**
 * A React Reducer helper function for merging data into the
 * `item.Metadata.Notes` field of an UpdateEvaluationForm object.
 * @param state previous UpdateEvaluationForm state
 * @param action reducer action, featuring `type` and `data` fields
 * @param action.type Name of `item` field (e.g., "Notes")
 * @param action.data Value corresponding to passed-in `item` field (e.g.,
 * string representing a note)
 * @returns new updated UpdateEvaluationForm state
 */
function mergeItemMetadataNotes(state: T, action: ReducerAction): T {
  /* Incoming data is a string, so we override the Notes array
   * with a new array with that 1 updated value (since we only care about the
   * first value in the Notes array anyway)
   */
  return merge(state, {
    item: merge(state.item, {
      Metadata: merge(state.item.Metadata, { [action.type]: [action.data] })
    })
  }) as T;
}

/**
 * A React Reducer helper function for merging data into the
 * `item.Metadata.Tags` field of an UpdateEvaluationForm object.
 * @param state previous UpdateEvaluationForm state
 * @param action reducer action, featuring `type` and `data` fields
 * @param action.type Name of `item` field (e.g., "Tags")
 * @param action.data Value corresponding to passed-in `item` field (e.g.,
 * array with strings representing tags)
 * @returns new updated UpdateEvaluationForm state
 */
function mergeItemMetadataTags(state: T, action: ReducerAction): T {
  return merge(state, {
    item: merge(state.item, {
      Metadata: merge(state.item.Metadata, { [action.type]: action.data })
    })
  }) as T;
}

/**
 * A React Reducer helper function for merging data into both the `item`
 * and `_expand` fields of an UpdateEvaluationForm object.
 *
 * Examples:
 * - Client - `ClientId` in `item` + `client` in `_expand`
 * - Project - `ProjectId` in `item` + `project` in `_expand`
 * - Template - `TemplateId` in `item` + `template` in `_expand`
 *
 * @param state previous UpdateEvaluationForm state
 * @param action reducer action, featuring `type` and `data` fields
 * @param action.type Name of `_expand` field. Will be suffixed with `Id` in
 * `item` field.
 * @param action.data Object corresponding to passed-in `_expand` field.
 * The `Id` field of this object will be merged into the `item` field.
 * @returns new updated UpdateEvaluationForm state
 */
function mergeItemIdAndExpandFields(state: T, action: ReducerAction): T {
  /* Note - data could be null if user clears selected value in
   * corresponding dropdown. Guard using optional chaining.
   */
  return merge(state, {
    item: merge(state.item, {
      [`${capitalize(action.type)}Id`]: action.data?.Id
    }),
    _expand: merge(state._expand, { [action.type]: action.data })
  }) as T;
}

/**
 * A React Reducer helper function for merging data into the `_expand` field of
 * an UpdateEvaluationForm object.
 * @param state previous UpdateEvaluationForm state
 * @param action reducer action, featuring `type` and `data` fields
 * @param action.type Name of `_expand` field (e.g., `"users"`)
 * @param action.data Value corresponding to passed-in `_expand` field (e.g.,
 * a Users array)
 * @returns new updated UpdateEvaluationForm state
 */
function mergeExpandFields(state: T, action: ReducerAction): T {
  return merge(state, {
    _expand: merge(state._expand, { [action.type]: action.data })
  }) as T;
}

/**
 * A React Reducer function for updating a truncated, local working copy of the
 * Evaluation in our ManageEvaluation context. The local working copy here is of
 * type UpdateEvaluationForm.
 *
 * Based on the reducer `action.type` field, calls different helper functions
 * to appropriately update the state.
 *
 * @param state previous UpdateEvaluationForm state
 * @param action reducer action, featuring `type` and `data` fields
 * @returns new updated UpdateEvaluationForm state
 */
export function localEvaluationReducer(state: T, action: ReducerAction): T {
  switch (action.type) {
    case "Name":
    case "Description":
      return mergeItemFlatFields(state, action);
    case "Notes":
      return mergeItemMetadataNotes(state, action);
    case "Tags":
      return mergeItemMetadataTags(state, action);
    case "client":
    case "project":
    case "template":
      return mergeItemIdAndExpandFields(state, action);
    case "users":
      return mergeExpandFields(state, action);
    default:
      return state;
  }
}
