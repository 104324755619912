import * as React from "react";
import { TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import Input from "src/components/Common/Input";

/* Prop overrides */
export function overrideInputProps(props: TextFieldProps): object {
  return {
    ...props.InputProps,
    startAdornment: <SearchIcon />,
    endAdornment: null // remove dropdown icon coming in if Search is wrapped within Autocomplete
  };
}

export const StyledSearch = styled(Input)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "50vh" // hack to ensure border is perfectly rounded regardless of height
  },
  // Search Icon styling
  "& .MuiInputBase-root .MuiSvgIcon-root": {
    color: theme.palette.grey["400"],
    marginRight: "8px",
    transition: "color 0.5s ease"
  },
  "& .MuiInputBase-root.Mui-focused .MuiSvgIcon-root": {
    color: theme.palette.primary.dark
  }
}));

/**
 * This is a common, reusable Search Text Field component.
 *
 * **Usage**: This component should typically be used as the input passed into a MUI Autocomplete component
 */
const Search = (props: TextFieldProps) => {
  return <StyledSearch {...props} InputProps={overrideInputProps(props)} />;
};

export default Search;
