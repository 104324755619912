import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import Report from "../../Report";
import { ReportClass, ReportGroup } from "../../Report/types";
import { RecommendationsReportElementProps } from "../../Report/types/element";

import { EvaluationTypes } from "src/utils/constants/evaluation";
import Description from "../../Common/Recommendations/Description";
import CalculationInfo from "../../Common/Recommendations/CalculationInfo";
import DataTable from "../../Common/Recommendations/DataTable";
import DataTableOverview from "./DataTableOverview";

const report = ({
  name,
  data
}: RecommendationsReportElementProps): JSX.Element => {
  const { evaluation, recommendationsData } = data;

  return (
    <Report>
      <Report.Content name={name}>
        <Stack spacing="24px" sx={{ margin: "12px 0px 24px" }}>
          <Description variant="body2" />
          <CalculationInfo />
          <Divider />
          {Boolean(recommendationsData) && (
            <Box>
              <DataTableOverview count={recommendationsData.length} />
              <DataTable
                evaluationName={evaluation?.Name}
                rows={recommendationsData}
              />
            </Box>
          )}
        </Stack>
      </Report.Content>
    </Report>
  );
};

const RecommendationsReport: ReportClass = class {
  static displayName = "Recommendations";
  static group: ReportGroup = ReportGroup.recommendations;
  static supportedEvaluationTypes = [
    EvaluationTypes.assessment,
    EvaluationTypes.survey
  ];
  static report = report;
};

export default RecommendationsReport;
