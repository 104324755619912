import * as React from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  makeColumn,
  makeFlexColumn
} from "src/components/Common/DataGrid/utils/columns";
import StageTag from "src/components/Common/StageTag";
import { RecommendationRow } from "src/schemas/reporting/recommendations";
import ViewDetailsButton from "../Common/ViewDetailsButton";

/**
 * Interface for passing in options to `makeColumns` method (e.g., if we
 * need to link a column to particular React state, etc.).
 *
 * **Note**
 * For ease of use, key names should try to correspond to column field names
 */
interface MakeColumnsOptions {
  ViewDetails: {
    onClick: (row: RecommendationRow) => void;
  };
}

/**
 * Creates column definitions array to be passed into the MUI Data Grid to
 * - render column headers
 * - render row values in a specified format
 */
export function makeColumns(options: MakeColumnsOptions): GridColDef[] {
  return [
    makeFlexColumn("Question", 2),
    makeFlexColumn("Stage", 1, {
      renderCell: ({ value }) => <StageTag value={value} />
    }),
    makeFlexColumn("Domain", 1),
    makeFlexColumn("Element", 1),
    makeFlexColumn("RecommendationName", 2),
    makeFlexColumn("RecommendationDescription", 2),
    makeColumn({
      field: "ViewDetails",
      sortable: false,
      filterable: false,
      disableExport: true,
      align: "center",
      renderCell: ({ row }) => (
        <ViewDetailsButton onClick={() => options.ViewDetails.onClick(row)} />
      )
    })
  ];
}
