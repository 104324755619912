import { CustomEvaluationResponse } from "src/schemas/evaluation";
import { Metadata } from "src/schemas/metadata";
import { IndexedQuestion } from "src/schemas/question";
import { createIndexedQuestion } from "./utils/question";
import { updateStateItem } from "./utils/state";

function updateQuestions(
  questions: IndexedQuestion[],
  questionId: string
): IndexedQuestion[] {
  const newQuestions: IndexedQuestion[] = questions
    .filter((question: IndexedQuestion) => question.Id != questionId)
    .map((question: IndexedQuestion, index: number) =>
      createIndexedQuestion(question, index)
    );
  return newQuestions;
}

export default function deleteQuestion(
  state: CustomEvaluationResponse,
  questionId: string,
  meta: Metadata
): CustomEvaluationResponse {
  return updateStateItem(state, {
    Metadata: meta,
    Questions: updateQuestions(state.item.Questions, questionId)
  });
}
