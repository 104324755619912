import {
  FilterCategory,
  FilterState
} from "src/pages/ManageEvaluation/QuestionsPanel/Filters/types";
import { ToggleAllActionData } from "../types";
import toggleCategory from "./toggleCategory";

/**
 * Given a new `filterState` and an `enabled` boolean
 * provided in the action `data` payload, toggles all filter items under
 * **all** categories to the value of `enabled`.
 */
export default function toggleAll(
  newState: FilterState,
  data: ToggleAllActionData
): FilterState {
  Object.keys(newState).forEach((category: FilterCategory) => {
    toggleCategory(newState, { category, enabled: data.enabled });
  });
  return newState;
}
