import * as React from "react";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { TooltipProps } from "@mui/material";

const InfoIconTooltip = (props: Omit<TooltipProps, "children">) => {
  return (
    <Tooltip {...props}>
      <IconButton>
        <InfoOutlinedIcon sx={{ fontSize: "16px" }} />
      </IconButton>
    </Tooltip>
  );
};
export default InfoIconTooltip;
