import * as React from "react";
import Button from "@mui/material/Button";
import CustomSnackbar from "src/components/Common/CustomSnackbar";
import { generateFakeText } from "src/components/Common/FakeText";
import SectionRow from "./layout/SectionRow";
import LabeledBox from "./layout/LabeledBox";
import { Severity, Variant } from "src/store/types/notification";
import { SandboxClass } from "./types";

const Snackbars = () => {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [openOutlinedInfo, setOpenOutlinedInfo] = React.useState(false);

  return (
    <>
      <SectionRow>
        <LabeledBox label="Success Snackbar">
          <Button variant="contained" onClick={() => setOpenSuccess(true)}>
            Click me
          </Button>
          <CustomSnackbar
            open={openSuccess}
            handleClose={() => setOpenSuccess(false)}
            severity={Severity.success}
            variant={Variant.filled}
            autoHideDuration={5000}
          >
            {generateFakeText(1)}
          </CustomSnackbar>
        </LabeledBox>
      </SectionRow>
      <SectionRow>
        <LabeledBox label="Info Snackbar with no auto-dismissal">
          <Button variant="contained" onClick={() => setOpenInfo(true)}>
            Click me
          </Button>
          {/* This is missing the autoHideDuration prop because we want to test a snackbar that does not get dismissed automatically */}
          <CustomSnackbar
            open={openInfo}
            handleClose={() => setOpenInfo(false)}
            severity={Severity.info}
            variant={Variant.filled}
          >
            {generateFakeText(1)}
          </CustomSnackbar>
        </LabeledBox>
      </SectionRow>
      <SectionRow>
        <LabeledBox label="Special Snackbar">
          <Button variant="contained" onClick={() => setOpenOutlinedInfo(true)}>
            Click me
          </Button>
          <CustomSnackbar
            open={openOutlinedInfo}
            handleClose={() => setOpenOutlinedInfo(false)}
            severity={Severity.info}
            variant={Variant.outlined}
            autoHideDuration={5000}
          >
            {generateFakeText(1)}
          </CustomSnackbar>
        </LabeledBox>
      </SectionRow>
    </>
  );
};

const SnackbarsSandbox: SandboxClass = class {
  static displayName = "Snackbars";
  static element = Snackbars;
};

export default SnackbarsSandbox;
