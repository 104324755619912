import { plural } from "pluralize";
import {
  DeleteQuestionRequest,
  DeleteQuestionRequestSchema,
  DeleteQuestionResponseSchema
} from "src/schemas/question";
import { DeleteEvaluationQuestionInterface } from "./types";
import { DeleteAPICaller, DeleteAPICallRequest } from "src/services/APICall";

/**
 * Delete an existing question linked to an evaluation.
 * - `DELETE /assessments/{assessmentId}/questions/{questionId}`
 * - `DELETE /surveys/{surveyId}/questions/{questionId}`
 */
export default function deleteQuestion({
  questionId,
  evaluationId,
  evaluationDateModified,
  evaluationType,
  callback,
  errorCallback
}: DeleteEvaluationQuestionInterface): void {
  const url = `/${plural(
    evaluationType
  )}/${evaluationId}/questions/${questionId}`;

  // Use request schema parse to guard against passing additional fields
  const body: DeleteQuestionRequest = DeleteQuestionRequestSchema.parse({
    _meta: {
      DateModified: evaluationDateModified
    }
  });

  const request: DeleteAPICallRequest = {
    path: url,
    body,
    callback,
    errorCallback,
    typeValidator: DeleteQuestionResponseSchema,
    withReactHook: false
  };

  return DeleteAPICaller(request);
}
