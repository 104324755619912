import { capitalize } from "@mui/material";
import RecommendationsDataHandler from "src/components/Reporting/utils/data/recommendations/handler";
import ClassificationDataHandler from "src/components/Reporting/utils/data/scoredResponse/classificationHandler";
import ScoredResponseDataHandler from "src/components/Reporting/utils/data/scoredResponse/handler";
import { ClassificationData } from "src/schemas/reporting/classification";
import { RecommendationRow } from "src/schemas/reporting/recommendations";
import { ScoredResponsesResponse } from "src/schemas/scoredResponse";
import { getEvaluationRecommendations } from "./getEvaluationRecommendations";
import { getScore } from "./getScore";
import { Get, ReportData, SetReportDataMethods } from "./types";

// NOTE: Uncomment for mocking data - look for other places below with this note
// import { mockPreprocess } from "./mock";

/**
 * Static class for fetching data used to populate all reports shown on
 * Reports page.
 */
export default class ReportDataService {
  /**
   * Handles error by
   * - resetting all reports data (make sure to add to this whenever fetching
   * from a new endpoint)
   *
   * - calling our passed-in `errorCallback`
   */
  private static handleError(
    error: Error,
    errorCallback: Get["errorCallback"],
    methods: SetReportDataMethods
  ) {
    methods.setScoredData({});
    methods.setClassificationData(ClassificationDataHandler.initialize());
    methods.setRecommendationsData([]);
    errorCallback(error);
  }

  /**
   * Function that pre-processes data returned from API to fit a format needed
   * by the reports.
   *
   * Note - individual methods within this function filter out any Evaluation
   * questions that correspond to Stage 0 (since that is a baseline stage that
   * should have no questions attached to it).
   */
  private static preprocess(responses: any[]): ReportData {
    // Scored Response
    // NOTE: Swap the commented line below with the uncommented line to mock data
    const [scoredResponse, recommendationsResponse] = responses;
    // const [scoredResponse, recommendationsResponse] = mockPreprocess();

    const scoredData: ScoredResponsesResponse =
      ScoredResponseDataHandler.preprocess(scoredResponse);

    const classificationData: ClassificationData =
      ClassificationDataHandler.make(scoredData.items);

    // Recommendations
    const recommendationsData: RecommendationRow[] =
      RecommendationsDataHandler.preprocess(recommendationsResponse, {
        stages: classificationData.questionStages
      });

    return {
      scoredData,
      classificationData,
      recommendationsData
    };
  }

  /**
   * Function that takes pre-processed `data` and dynamically sets it in
   * Reports context based on the `data` object's keys
   */
  private static set(data: ReportData, methods: SetReportDataMethods): void {
    Object.entries(data).forEach(
      ([key, value]: [key: keyof ReportData, value: any]) => {
        const methodName = `set${capitalize(key)}`;

        if (Reflect.has(methods, methodName)) {
          Reflect.get(methods, methodName)(value); // call context `setX` method
        }
      }
    );
  }

  /**
   * Function that fetches all report data from API, pre-processes it to fit
   * our reports' needs, and sets that processed data in our Reports context.
   *
   * Handles errors if any occur.
   */
  static async get(args: Get): Promise<void> {
    const { evaluation, errorCallback, ...methods } = args;

    if (!evaluation) {
      return;
    }

    methods.setIsReportDataLoading(true);

    return Promise.all([
      getScore({ evaluationIds: evaluation.Id }),
      getEvaluationRecommendations({ evaluationId: evaluation.Id })
    ])
      .then((responses: any[]) =>
        ReportDataService.set(ReportDataService.preprocess(responses), methods)
      )
      .catch((error: Error) =>
        ReportDataService.handleError(error, errorCallback, methods)
      )
      .finally(() => methods.setIsReportDataLoading(false));
  }
}
