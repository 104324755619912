import * as React from "react";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Modal from "src/components/Common/Modal";
import Input from "src/components/Common/Input";
import { ContentProps } from "./types";

const DELETE_CONFIRMATION_TEXT = "Delete";

const Content = ({
  children,
  setDeleteConfirmed
}: ContentProps): JSX.Element => {
  const { palette } = useTheme();

  const [inputText, setInputText] = React.useState<string>("");

  function updateText(text: string): void {
    setInputText(text);
    setDeleteConfirmed(text === DELETE_CONFIRMATION_TEXT);
  }

  return (
    <Modal.Content sx={{ padding: "36px" }}>
      <Stack>
        {children}
        <Typography
          variant="body2"
          sx={{ color: palette.grey["500"], margin: "18px 0px 12px" }}
        >
          To confirm this, type "{DELETE_CONFIRMATION_TEXT}"
        </Typography>
        <Input
          placeholder={DELETE_CONFIRMATION_TEXT}
          value={inputText}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => updateText(event.target.value)}
        />
      </Stack>
    </Modal.Content>
  );
};
export default Content;
