import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { LegacyScoredResponse } from "src/schemas/scoredResponse";
import { ChartMaker, ChartInterface, Aggregate } from "../../Chart/types";
import { aggregateBy } from "../../../utils/data/aggregateBy";
import { addTitle } from "../../utils/chartUtils/title";
import { addLegend } from "../../utils/chartUtils/legend";
import { addPieChart, addPieChartSeries } from "../../utils/chartUtils/pie";

/* FluencyOfRespondentsChart()
 * Pie Chart representing what percentage of respondents were at what fluency level
 * valueField: Stage
 * categoryField: ?
 */

export class FluencyOfRespondents implements ChartMaker {
  static displayName = "Fluency of Respondents";
  private root: am5.Root;
  private data: LegacyScoredResponse[];
  private draggableLegend: boolean;

  constructor({ data, root, draggableLegend }: ChartInterface) {
    this.data = data;
    this.root = root;
    this.draggableLegend = draggableLegend ?? false;
  }
  private process(): Aggregate[] {
    return aggregateBy(this.data, (item: LegacyScoredResponse) => item.Stage);
  }
  make(container?: am5.Container): am5percent.PieChart {
    const chart: am5percent.PieChart = addPieChart(
      this.root,
      {
        layout: this.root.verticalLayout
      },
      container
    );
    const series: am5percent.PieSeries = addPieChartSeries(this.root, chart, {
      categoryField: "Key",
      valueField: "Sum"
    });

    addTitle(this.root, chart, FluencyOfRespondents.displayName);

    series.data.setAll(this.process());
    const legend: am5.Legend = addLegend(this.root, chart, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      draggable: this.draggableLegend
    });

    legend.data.setAll(series.dataItems);
    return chart;
  }
}
