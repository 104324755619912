import create from "./create";
import { CreateUserProjectInterface } from "./types";

/**
 * API Service class for performing actions on an User-Project Links
 */
class UserProjectService {
  static put(args: CreateUserProjectInterface): void {
    return create(args);
  }
}

export default UserProjectService;
