import * as React from "react";
import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ConditionalWrapper from "src/components/Common/ConditionalWrapper";
import ExportChartPrompt from "./Prompt/ExportChart";

interface PromptContainerProps {
  sx?: SxProps;
  children?: React.ReactNode;
}

/**
 * A reusuable container to hold customized text messages to prompt
 * the user to take action. Will always feature an "export chart"
 * prompt on the right since all our charts are exportable
 */
const PromptContainer = ({
  sx,
  children
}: PromptContainerProps): JSX.Element => {
  return (
    <Stack
      direction="row"
      spacing="24px"
      justifyContent="flex-end"
      sx={{ marginBottom: "8px", ...sx }}
    >
      <ConditionalWrapper condition={Boolean(children)}>
        <Box sx={{ flex: 1 }}>{children}</Box>
      </ConditionalWrapper>
      <ExportChartPrompt />
    </Stack>
  );
};

export default PromptContainer;
