import * as React from "react";
import { styled } from "@mui/material/styles";
import { HEADER_HEIGHT } from "src/utils/constants/layout";

const Container = styled("div")(() => ({
  height: `calc(100vh - ${HEADER_HEIGHT})`,
  marginTop: HEADER_HEIGHT,
  display: "flex"
}));

const Body = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Body;
