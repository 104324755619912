import {
  EvaluationPatchResponse,
  UpdateEvaluationForm
} from "src/schemas/evaluation";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { shapeEvaluationItem } from "../../utils/evaluation";
import { IsStateDirty } from "../../types";
import { patchEvaluation } from "./flat/patchEvaluation";
import { putEvaluationNotes } from "./notes/putEvaluationNotes";

export interface UpdateEvaluation {
  evaluationType: EvaluationTypes;
  localEvaluation: UpdateEvaluationForm;
  isStateDirty: IsStateDirty;
}

export interface UpdateEvaluationSuite extends UpdateEvaluation {
  updatedEvaluation: UpdateEvaluationForm;
}

/**
 * If top-level flat `item` evaluation fields are dirty, calls
 * `PATCH /evaluations/{evaluationId}` (replace `evaluation` with
 * provided `evaluationType`).
 *
 * Shapes response to fit our Edit Modal form (e.g. omits Questions, etc.).
 * Mutates passed-in `updatedEvaluation.item` field with shaped response.
 */
async function updateEvaluationItem({
  evaluationType,
  localEvaluation,
  isStateDirty,
  updatedEvaluation
}: UpdateEvaluationSuite): Promise<void> {
  if (isStateDirty.evaluation) {
    const evaluationResponse: EvaluationPatchResponse = await patchEvaluation({
      evaluationType,
      localEvaluation
    });
    updatedEvaluation.item = shapeEvaluationItem(evaluationResponse.result);
  }
}

/**
 * If nested `item.Metadata.Notes` field is dirty, calls
 * `PUT /evaluations/{evaluationId}/notes` (replace `evaluation` with
 * provided `evaluationType`)
 *
 * Mutates passed-in `updatedEvaluation.item.Metadata` field with updated
 * - Notes
 * - DateModified (for conflict checking)
 * values.
 */
async function updateEvaluationNotes({
  evaluationType,
  localEvaluation,
  isStateDirty,
  updatedEvaluation
}: UpdateEvaluationSuite): Promise<void> {
  if (isStateDirty.notes) {
    const notesResponse = await putEvaluationNotes({
      evaluationType,
      localEvaluation,
      dateModified: updatedEvaluation.item.Metadata.DateModified
    });
    updatedEvaluation.item.Metadata.Notes = [notesResponse.item];
    updatedEvaluation.item.Metadata.DateModified =
      notesResponse._meta.DateModified;
  }
}

/**
 * Synchronously makes all Evaluation (flat and nested) field update API calls,
 * depending on whether specified group of fields or field is dirty.
 *
 * Calls must happen synchronously because each call updates Evaluation's
 * `DateModified` Metadata field. We must pass in the updated `DateModified`
 * value every time we make an update evaluation API call, since this allows
 * us to check for conflicts.
 */
export default async function update({
  evaluationType,
  localEvaluation,
  isStateDirty
}: UpdateEvaluation): Promise<UpdateEvaluationForm> {
  const updatedEvaluation: UpdateEvaluationForm =
    structuredClone(localEvaluation);

  /* Each of the calls below mutates `updatedEvaluation` if state is dirty.
   * For example, `item.Metadata.DateModified` is always updated between
   * calls so we don't get conflicts
   */
  await updateEvaluationItem({
    evaluationType,
    localEvaluation,
    isStateDirty,
    updatedEvaluation
  });

  await updateEvaluationNotes({
    evaluationType,
    localEvaluation,
    isStateDirty,
    updatedEvaluation
  });

  return updatedEvaluation;
}
