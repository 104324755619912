import * as React from "react";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import {
  FilterAction,
  ToggleAllActionData
} from "src/context/ManageEvaluation/reducers/filterState/types";
import AllEnabled from "../data/allEnabled";
import ToggleFilterButton from "./Base";

export const ToggleAllCategoriesButton = (): JSX.Element => {
  const { filterState, dispatchFilterState } = useManageEvaluationContext();
  const allEnabled: boolean = AllEnabled.all(filterState);

  function toggle(): void {
    const data: ToggleAllActionData = { enabled: !allEnabled };
    dispatchFilterState({ type: FilterAction.toggleAll, data });
  }

  return <ToggleFilterButton onClick={toggle} allItemsEnabled={allEnabled} />;
};

export default ToggleAllCategoriesButton;
