import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { IXYAxis } from "@amcharts/amcharts5/.internal/charts/xy/series/XYSeries";
import AnimatedTheme from "@amcharts/amcharts5/themes/Animated";
import { MaturityStageScore } from "src/schemas/reporting/maturityScore";
import { ScoredResponse } from "src/schemas/scoredResponse";
import theme from "src/theme";
import {
  MakeYAxis,
  MaturityStageScoresGaugeChartInterface,
  ChartType,
  ChartMetadata,
  LabelsData
} from "./types";
import { ChartMaker } from "../../Chart/types";
import BaseTheme from "../../themes/BaseTheme";
import BlueTheme from "../../themes/BlueTheme";
import { AmChartsDataValidatedSeriesEvent } from "../../utils/chartUtils/events";
import { MUI_CHECK_CIRCLE } from "../../utils/chartUtils/svgPaths";
import { addXYChart, setHeight } from "../../utils/chartUtils/xy";
import { makeRoundedRectangleStyles } from "../../utils/chartUtils/rectangle";
import { addLeftAlignedLabel } from "../../utils/chartUtils/label";
import { getDomainLabel, getOverallStageLabel } from "./data/labels";
import { process } from "./data/process";

/**
 * Horizontal bar chart representing the Maturity Scores of Responses, grouped by Stages
 */
export class MaturityStageScoresGauge implements ChartMaker {
  static displayName = "Maturity Stage Scores";
  private root: am5.Root;
  private data: MaturityStageScore[];
  private metadata: ChartMetadata;
  private chart: am5.Container; // overall chart containing labels, xy chart, and legend
  private xyChart: am5xy.XYChart;
  private xAxis: IXYAxis;
  private yAxisLeft: am5xy.CategoryAxis<am5xy.AxisRenderer>;
  private yAxisRight: am5xy.CategoryAxis<am5xy.AxisRenderer>;
  private backgroundSeries: am5xy.ColumnSeries;
  private series: am5xy.ColumnSeries;
  private labelsData: LabelsData = {
    domains: "",
    overallStage: ""
  };
  /* Data keys */
  private categoryKeyLeft = "QuestionStageNameFormatted";
  private categoryKeyRight = "Percent";
  private gaugeKey = "Total"; // for creating background gauge bars
  private valueKey = "Percent";
  private stageKey = "QuestionStage";
  /* View */
  /**
   * Indicates if an
   * - individual Domain
   * - or overall Domains
   * chart should be shown
   */
  private isIndividualDomainView = false;
  /* Styles */
  private yMinGridDistance = 30;

  constructor({
    data,
    root,
    metadata
  }: MaturityStageScoresGaugeChartInterface) {
    // Set view type first (used for calculate other values, like `this.data`)
    this.isIndividualDomainView =
      metadata?.domain && metadata.chartType == ChartType.Domain;

    this.metadata = metadata;
    this.data = this.process(data);
    this.root = root;

    // Set labels data
    this.labelsData.domains = getDomainLabel(data, metadata);
    this.labelsData.overallStage = getOverallStageLabel(this.data);
  }

  private process(data: ScoredResponse[]): MaturityStageScore[] {
    return process(
      this.isIndividualDomainView
        ? data.filter(
            (item: ScoredResponse) => item.Domain == this.metadata?.domain
          )
        : data
    );
  }

  private overrideThemes(): void {
    /* Don't want default Responsive theme since it breaks our
     * auto-sizing chart height capability
     */
    const customThemes: am5.Theme[] = this.isIndividualDomainView
      ? []
      : [BlueTheme.new(this.root)];
    this.root.setThemes([
      AnimatedTheme.new(this.root),
      BaseTheme.new(this.root),
      ...customThemes
    ]);
  }

  private makeXAxis(): IXYAxis {
    const xRenderer: am5xy.AxisRendererX = am5xy.AxisRendererX.new(
      this.root,
      {}
    );

    // Hide x-axis grid lines
    xRenderer.grid.template.set("forceHidden", true);

    // Hide x-axis labels
    xRenderer.labels.template.set("forceHidden", true);

    return this.xyChart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: xRenderer
      })
    );
  }

  private makeYAxis({
    categoryKey,
    rendererOptions = {},
    axisOptions = {}
  }: MakeYAxis): am5xy.CategoryAxis<am5xy.AxisRenderer> {
    const yRenderer: am5xy.AxisRendererY = am5xy.AxisRendererY.new(this.root, {
      minGridDistance: this.yMinGridDistance,
      ...rendererOptions
    });

    // Hide y-axis grid lines between bars
    yRenderer.grid.template.set("forceHidden", true);

    return this.xyChart.yAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: categoryKey,
        renderer: yRenderer,
        ...axisOptions
      })
    );
  }

  private makeYAxisLeft(): am5xy.CategoryAxis<am5xy.AxisRenderer> {
    const yAxisLeft = this.makeYAxis({ categoryKey: this.categoryKeyLeft });

    // Modify y-axis labels to fit our needs
    yAxisLeft.get("renderer").labels.template.setAll({
      centerX: am5.p0, // left align text
      paddingRight: 12
    });

    function formatLabels(text: string, target: any) {
      const showAsterisk: boolean =
        target?.dataItem?.dataContext &&
        target?.dataItem?.dataContext[this.stageKey] == 0;
      const conditionalAsterisk: string = showAsterisk ? "*" : "";
      return `${text}${conditionalAsterisk}`;
    }

    yAxisLeft
      .get("renderer")
      .labels.template.adapters.add("text", formatLabels.bind(this));

    return yAxisLeft;
  }

  private makeYAxisRightBullet(
    root: am5.Root,
    _axis: am5xy.Axis<am5xy.AxisRenderer>,
    dataItem: am5.DataItem<am5xy.IAxisDataItem>
  ): am5xy.AxisBullet {
    const bullet = am5xy.AxisBullet.new(root, {
      sprite: am5.Graphics.new(root, {
        centerY: am5.p50,
        centerX: am5.p0,
        fill: am5.color(theme.palette.success.main),
        svgPath: MUI_CHECK_CIRCLE,
        x: 66
      })
    });
    return dataItem?.dataContext[this.valueKey] == 100 ? bullet : null;
  }

  private makeYAxisRight(): am5xy.CategoryAxis<am5xy.AxisRenderer> {
    const rendererOptions: Partial<am5xy.IAxisRendererYSettings> = {
      opposite: true
    };

    const axisOptions: Partial<
      am5xy.ICategoryAxisSettings<am5xy.AxisRenderer>
    > = {
      // Add check-circle icons whenever a stage has been sufficiently met
      bullet: this.makeYAxisRightBullet.bind(this)
    };

    const yAxisRight = this.makeYAxis({
      categoryKey: this.categoryKeyRight,
      rendererOptions,
      axisOptions
    });

    // Modify y-axis labels to fit our needs
    yAxisRight.get("renderer").labels.template.setAll({
      paddingLeft: 12,
      paddingRight: 24,
      fill: am5.color(theme.palette.grey["500"]),
      centerX: am5.p100
    });

    // Format labels
    yAxisRight
      .get("renderer")
      .labels.template.adapters.add("text", function (text: string) {
        return `${text}%`;
      });

    return yAxisRight;
  }

  private makeSeriesBase(valueField: string): am5xy.ColumnSeries {
    const series: am5xy.ColumnSeries = this.xyChart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: "Maturity Score Series",
        xAxis: this.xAxis,
        yAxis: this.yAxisLeft,
        valueXField: valueField,
        categoryYField: this.categoryKeyLeft,
        sequencedInterpolation: true,
        maskBullets: false,
        clustered: false
      })
    );

    const columnTemplate: am5.Template<am5.RoundedRectangle> =
      series.columns.template;

    columnTemplate.setAll(makeRoundedRectangleStyles());

    columnTemplate.adapters.add("stroke", () => {
      return am5.color(0x00ffffff);
    });

    return series;
  }

  private isBaselineStage(stageNumber: number): boolean {
    return stageNumber === 0;
  }

  /**
   * Returns
   * - **transparent** color if `stageNumber` is equal to baseline stage (0)
   * - **grey** color if `stageNumber` is NOT equal to baseline stage
   */
  private setBackgroundSeriesDataItemColor(stageNumber: number): am5.Color {
    return this.isBaselineStage(stageNumber)
      ? am5.color(0x00ffffff)
      : am5.Color.fromString(theme.palette.grey["200"]);
  }

  private makeBackgroundSeries(valueField: string): am5xy.ColumnSeries {
    const series: am5xy.ColumnSeries = this.makeSeriesBase(valueField);
    const columnTemplate: am5.Template<am5.RoundedRectangle> =
      series.columns.template;
    columnTemplate.adapters.add(
      "fill",
      (_fill: am5.Color, target: am5.RoundedRectangle) =>
        this.setBackgroundSeriesDataItemColor(
          target?.dataItem?.dataContext[this.stageKey]
        )
    );
    return series;
  }

  /**
   * Returns
   * - **transparent** color if `stageNumber` is equal to baseline stage (0)
   * - **domain-specific** color from default theme if chart is in
   * "individual domain" view
   * - **overall-domain** (primary blue) color from custom theme if chart is
   * NOT in "individual domain" view
   */
  private setSeriesDataItemColor(
    stageNumber: number,
    defaultColor: am5.Color
  ): am5.Color {
    if (this.isBaselineStage(stageNumber)) {
      return am5.color(0x00ffffff);
    } else {
      return this.isIndividualDomainView
        ? this.xyChart.get("colors").getIndex(this.metadata?.domainIndex)
        : defaultColor;
    }
  }

  private makeSeries(valueField: string): am5xy.ColumnSeries {
    const series: am5xy.ColumnSeries = this.makeSeriesBase(valueField);
    const columnTemplate: am5.Template<am5.RoundedRectangle> =
      series.columns.template;
    columnTemplate.adapters.add(
      "fill",
      (fill: am5.Color, target: am5.RoundedRectangle) =>
        this.setSeriesDataItemColor(
          target?.dataItem?.dataContext[this.stageKey],
          fill
        )
    );
    return series;
  }

  private makeLegend(): am5.Container {
    const legend = am5.Container.new(this.root, {
      layout: this.root.horizontalLayout,
      width: am5.percent(100),
      paddingBottom: 16
    });

    legend.children.push(
      am5.Graphics.new(this.root, {
        fill: am5.color(theme.palette.success.main),
        svgPath: MUI_CHECK_CIRCLE,
        marginLeft: 12,
        centerY: am5.p50
      })
    );

    addLeftAlignedLabel(this.root, legend, "Sufficiently-met Stage", {
      fontSize: 14,
      centerY: am5.p50
    });

    return legend;
  }

  private makeLayout(): void {
    addLeftAlignedLabel(this.root, this.chart, "YOUR STAGE", {
      fontSize: 14,
      fontWeight: "bold",
      fill: am5.color(theme.palette.grey["500"])
    });

    addLeftAlignedLabel(this.root, this.chart, this.labelsData.overallStage, {
      fontSize: 20,
      fontWeight: "bold"
    });

    addLeftAlignedLabel(
      this.root,
      this.chart,
      `You've been placed at an overall Maturity Stage of [bold]${this.labelsData.overallStage}[/] ${this.labelsData.domains}.`,
      {
        fontSize: 14
      }
    );

    this.xyChart = addXYChart(
      this.root,
      {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: this.root.verticalLayout
      },
      this.chart
    );

    // Add legend
    this.chart.children.push(this.makeLegend());

    addLeftAlignedLabel(
      this.root,
      this.chart,
      "*Stage 0 is a baseline Maturity Stage that every client begins at and thus meets by default. Because this stage is a baseline, no questions are associated with it.",
      {
        fontSize: 14,
        fontStyle: "italic",
        paddingBottom: 48
      }
    );
  }

  private dynamicallySetHeight(): void {
    // Dynamically set height of chart based on number of bars
    function setHeightOnDataValidated(
      event: AmChartsDataValidatedSeriesEvent<am5xy.ColumnSeries>
    ) {
      const cellSize = this.yMinGridDistance * 2;
      // Padding is a temporary work-around (contains approximate value of labels around chart)
      setHeight(event, { cellSize, padding: 220 });
    }
    this.series.events.on("datavalidated", setHeightOnDataValidated.bind(this));
  }

  make() {
    this.overrideThemes();

    this.chart = this.root.container.children.push(
      am5.Container.new(this.root, {
        layout: this.root.verticalLayout,
        width: am5.percent(100),
        height: am5.percent(100)
      })
    );

    // Create labels, xy chart, and legend
    this.makeLayout();

    // Make axes
    this.xAxis = this.makeXAxis();
    this.yAxisLeft = this.makeYAxisLeft();
    this.yAxisRight = this.makeYAxisRight();

    // Make series (note that xyChart needs to be created first)
    this.backgroundSeries = this.makeBackgroundSeries(this.gaugeKey);
    this.series = this.makeSeries(this.valueKey);

    // Set data
    this.yAxisLeft.data.setAll(this.data);
    this.yAxisRight.data.setAll(this.data);
    this.backgroundSeries.data.setAll(this.data);
    this.series.data.setAll(this.data);

    // Set dimensions
    this.dynamicallySetHeight();

    /* Make stuff animate on load
     * https://www.amcharts.com/docs/v5/concepts/animations/
     */
    this.backgroundSeries.appear(1000);
    this.series.appear(1000);
    this.xyChart.appear(1000, 100);
    return this.chart;
  }
}
