import * as React from "react";
import { useParams } from "react-router-dom";
import Error from "src/pages/Error";
import { EvaluationTypes } from "../../utils/constants/evaluation";
import CreateEvaluationFromTemplate from "./CreateEvaluationFromTemplate";

const createItemPage = (type: string) => {
  switch (type) {
    case EvaluationTypes.assessment:
      // TODO: Surveys should be re-enabled past MVP
      // case EvaluationTypes.survey:
      return <CreateEvaluationFromTemplate evaluationType={type} />;
    /* Add more cases here */
    default:
      return <Error message="Page not found." />;
  }
};

const CreateItem = () => {
  const { itemType } = useParams();
  return createItemPage(itemType);
};

export default CreateItem;
