import * as React from "react";
import { debounce as Debounce } from "lodash";
import { SxProps, Theme, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ConditionalWrapper from "./ConditionalWrapper";
import Modal from "./Modal";

interface ClampModalInterface {
  title: string;
  children: string | React.ReactNode;
  closed: boolean;
  onClose: (event: Event | React.SyntheticEvent) => any;
}

interface ClampedContentInterface {
  children: string;
  lines?: number;
  modalTitle?: string;
  useModal?: boolean;
  allowExpand?: boolean;
}

const getClampingStyle = (lines: number): SxProps<Theme> => {
  return {
    WebkitLineClamp: lines,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    overflowWrap: "break-word"
  };
};

// Wrapped in a ClickAwayListener to close Dialog when clicked outside, escape is pressed
// or the Close button is clicked
const ClampModal = ({
  title,
  children,
  closed,
  onClose
}: ClampModalInterface) => {
  const { palette } = useTheme();

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Modal open={!closed} sx={{ borderRadius: "4px" }} handleClose={onClose}>
        <Modal.Header
          sx={{
            backgroundColor: palette.primary.main,
            width: "600px",
            textTransform: "capitalize"
          }}
        >
          <Box width="100%" color={palette.primary.contrastText}>
            <b>{title.toLowerCase()}</b>
          </Box>
        </Modal.Header>
        <Modal.Content>{children}</Modal.Content>
        <Modal.Actions
          sx={{
            justifyContent: "center",
            padding: "12px"
          }}
        >
          <Button
            variant="contained"
            size="large"
            onClick={onClose}
            color="primary"
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </ClickAwayListener>
  );
};

const ClampedContent = ({
  children,
  lines = 1,
  modalTitle = "",
  useModal = false,
  allowExpand = true
}: ClampedContentInterface) => {
  const [clamped, setClamped] = React.useState(true);
  const [showButton, setShowButton] = React.useState(true);
  const containerRef: React.MutableRefObject<any> = React.useRef(null);
  const clampingStyle: SxProps<Theme> = getClampingStyle(lines);

  React.useEffect(() => {
    const hasClamping = (el): boolean => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = (): void => {
      if (containerRef.current) {
        // Check for clamping and show or hide button accordingly.
        setShowButton(hasClamping(containerRef.current));
      }
    };

    const debouncedCheck = Debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    // Monitor resizing of window every 50ms as to not spam the event stream.
    // If no overflow is determined to exist for containerRef then do not show
    // the clamp toggle text
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [containerRef]);

  return (
    <>
      <ConditionalWrapper condition={useModal}>
        <ClampModal
          title={modalTitle}
          closed={clamped}
          onClose={() => setClamped(!clamped)}
        >
          {children}
        </ClampModal>
      </ConditionalWrapper>
      <Box sx={clamped || useModal ? clampingStyle : {}} ref={containerRef}>
        {children}
      </Box>
      <ConditionalWrapper condition={allowExpand && showButton}>
        <Link
          onClick={() => setClamped(!clamped)}
          underline="none"
          sx={{ cursor: "pointer" }}
        >
          View {clamped || useModal ? "More" : "Less"}
        </Link>
      </ConditionalWrapper>
    </>
  );
};

export default ClampedContent;
