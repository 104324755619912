import * as React from "react";
import { useSearchParams } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Input from "src/components/Common/Input";
import useReportsContext from "src/context/Reports";
import { Client } from "src/schemas/client";
import { Project } from "src/schemas/project";
import { Nullable } from "src/utils/types";
import { ProjectHelperText } from "../HelperTexts";
import OptionLabel from "../OptionLabel";

const ProjectDropdown = (): JSX.Element => {
  /* Context */
  const {
    data,
    selectedClient,
    setSelectedClient,
    selectedProject,
    setSelectedProject,
    selectedEvaluation,
    setSelectedEvaluation
  } = useReportsContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const [projects, setProjects] = React.useState<Nullable<Project[]>>(null);

  // Get initial data to populate dropdown options
  React.useEffect(() => {
    setProjects(data.projects);
  }, [data.projects]);

  // Watch if client changes - change dropdown options
  React.useEffect(() => {
    if (selectedClient) {
      const foundProjects: Project[] = data.projects.filter(
        (item: Project) => item.ClientId === selectedClient?.Id
      );
      setProjects(foundProjects);
    } else {
      setProjects(data.projects);
    }
  }, [selectedClient]);

  // Handle manual user value change - change selected values
  function handleValueChange(newValue: Nullable<Project>): void {
    setSelectedProject(newValue);

    if (newValue) {
      searchParams.set("project", newValue.Id);
    } else {
      searchParams.delete("project");
    }

    // Clear evaluation if it is set
    if (selectedEvaluation) {
      setSelectedEvaluation(null);
      searchParams.delete("evaluation");
    }

    // Backfill selected client
    if (newValue && !selectedClient) {
      const foundClient: Client | undefined = data.clients.find(
        (item: Client) => item.Id == newValue.ClientId
      );
      if (foundClient) {
        setSelectedClient(foundClient);
        searchParams.set("client", newValue.ClientId as string);
      } else {
        searchParams.delete("client");
      }
    }

    setSearchParams(searchParams);
  }

  return (
    <Stack sx={{ marginRight: "24px" }}>
      <OptionLabel
        id="project"
        title="Project"
        required={false}
        TooltipHelperText={<ProjectHelperText />}
      />
      <Autocomplete
        loading={projects === null}
        id="project"
        options={projects ? projects : []}
        renderOption={(props: object, option: Project) => (
          <li {...props} key={option.Id}>
            {option.Name}
          </li>
        )}
        isOptionEqualToValue={(option: Project, value: Project) =>
          option.Name === value.Name
        }
        getOptionLabel={(value: Project) => value.Name as string}
        value={selectedProject}
        onChange={(
          _event: React.SyntheticEvent,
          newSelectedValue: Nullable<Project>
        ) => handleValueChange(newSelectedValue)}
        renderInput={(params: object) => (
          <Input {...params} placeholder="Select a project" />
        )}
      />
    </Stack>
  );
};

export default ProjectDropdown;
