import { z } from "zod";
import { pick } from "src/utils/object";
import { ExpandOptionsInterface, ExpandOptionsBase } from "./index";

/**
 * Key definition for **evaluation template** expand options - specifies which
 * properties to pick from
 * - `ExpandOptionsInterface` - for typing
 * - `ExpandOptionsBase` object - for creation of the zod enum object
 *
 * **Notes**
 * - Do not specify return type since we need key union type below to infer it
 *
 * - Specify as **readonly** array - needed for Typescript 3.4 for key union
 * type. See https://stackoverflow.com/a/45257357
 */
const EvaluationTemplateExpandKeys = ["Recommendation"] as const;

/**
 * Union type of all keys for **evaluation template** expand zod enum object.
 */
type EvaluationTemplateExpandKeysUnion =
  (typeof EvaluationTemplateExpandKeys)[number];

/**
 * Interface for object used to create expand zod enum object. Ensures object
 * has specified expand keys.
 */
type EvaluationTemplateExpandOptionsInterface = Pick<
  ExpandOptionsInterface,
  EvaluationTemplateExpandKeysUnion
>;

/**
 * Object with subset of `ExpandOptionsBase` fields used to create specific
 * expand zod enum object.
 */
const EvaluationTemplateExpandOptionsBase: EvaluationTemplateExpandOptionsInterface =
  pick(ExpandOptionsBase, EvaluationTemplateExpandKeys);

/**
 * Zod enum object - used in place of native Typescript enum due to our
 * ability to pass in an object that implements or narrows down our base
 * expand options object. Native enums cannot be based on another enum.
 *
 * **Example Usage**
 * - EvaluationTemplateExpandOptions.enum.Recommendation => "recommendation"
 */
export const EvaluationTemplateExpandOptions = z.nativeEnum(
  EvaluationTemplateExpandOptionsBase
);

/**
 * Union of expand enum **values** - e.g.,
 * "recommendation" | etc.
 */
export type EvaulationTemplateExpandOption = z.infer<
  typeof EvaluationTemplateExpandOptions
>;
