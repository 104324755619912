import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import FlatTable from "src/components/Common/Table/Flat";
import {
  noLeftPadding,
  noTopLeftPadding
} from "src/components/Common/Table/styles";
import { DomainsProps } from "./types";

const Domains = ({ data }: DomainsProps): JSX.Element => {
  return (
    <FlatTable aria-label="Overall Raw Scores Report - Domains table">
      <TableHead>
        <TableRow>
          <TableCell sx={noTopLeftPadding}>
            <Typography variant="body1">
              <b>Domains</b>
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[...data].sort().map((domain: string) => {
          return (
            <TableRow key={domain}>
              <TableCell sx={noLeftPadding}>{domain}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </FlatTable>
  );
};

export default Domains;
