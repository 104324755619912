import * as am5 from "@amcharts/amcharts5";

/* Column Label Settings */
const TEXT_MAX_WIDTH = 550;
const DEFAULT_LEFT_COLUMN_LABEL_SETTINGS: am5.ILabelSettings = {
  oversizedBehavior: "wrap",
  textAlign: "left",
  x: 0,
  maxWidth: TEXT_MAX_WIDTH
};

export const addLabel = (
  root: am5.Root,
  chart: am5.Container,
  text: string,
  options?: am5.ILabelSettings
) => {
  return chart.children.push(
    am5.Label.new(root, {
      ...options,
      text
    })
  );
};

export const addLeftAlignedLabel = (
  root: am5.Root,
  chart: am5.Container,
  text: string,
  options?: am5.ILabelSettings
) => {
  return addLabel(root, chart, text, {
    ...options,
    centerX: am5.p0
  });
};

export const addColumnLabel = (
  root: am5.Root,
  chart: am5.Container,
  text: string,
  options?: am5.ILabelSettings
) => {
  return addLabel(root, chart, text, {
    ...DEFAULT_LEFT_COLUMN_LABEL_SETTINGS,
    ...options
  });
};
