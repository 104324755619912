import * as React from "react";
import Typography from "@mui/material/Typography";
import Label from "src/components/Common/Input/Label";
import { useTheme } from "@mui/material/styles";

interface OptionLabelProps {
  id: string;
  title: string;
  isSubOption?: boolean;
}

const OptionLabel = ({ id, title, isSubOption = false }: OptionLabelProps) => {
  const { palette } = useTheme();
  const color: string = isSubOption
    ? palette.grey["700"]
    : palette.common.black;
  return (
    <Label htmlFor={id} sx={{ marginBottom: "12px" }}>
      <Typography sx={{ fontSize: "14px", fontWeight: "bold", color }}>
        {title}
      </Typography>
    </Label>
  );
};

export default OptionLabel;
