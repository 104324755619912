import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";

const Header = ({ children }: { children: React.ReactNode }) => {
  const { palette } = useTheme();
  return (
    <ListSubheader
      sx={{
        backgroundColor: palette.bg.lightGrey,
        borderBottom: `1px solid ${palette.grey["200"]}`,
        padding: "6px 8px"
      }}
    >
      <Stack direction="row" flexWrap="wrap" alignItems="center">
        {children}
      </Stack>
    </ListSubheader>
  );
};

export default Header;
