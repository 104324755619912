import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import pluralize from "pluralize";
import Tag from "src/components/Common/Tags";
import { getPercentInteger } from "src/utils/percent";

interface OverviewInterface {
  score: number;
  maxScore: number;
  domainsCount: number;
  questionStagesCount: number;
}

const Overview = ({
  score,
  maxScore,
  domainsCount,
  questionStagesCount
}: OverviewInterface) => {
  const rawScorePercent: number = getPercentInteger(score, maxScore);

  return (
    <Stack direction="row" spacing="24px" alignItems="center" flexWrap="wrap">
      <Typography variant="h4">
        <b>{rawScorePercent}%</b>
      </Typography>
      <Tag
        size="medium"
        label={`${score}/${maxScore} points`}
        sx={{ fontSize: "16px" }}
      />
      <Typography>
        This assessment scored <b>{rawScorePercent}%</b> across{" "}
        <b>{domainsCount}</b> {pluralize("domain", domainsCount)} and{" "}
        <b>{questionStagesCount}</b> {pluralize("stage", questionStagesCount)}.
      </Typography>
    </Stack>
  );
};

export default Overview;
