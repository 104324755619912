import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession
} from "amazon-cognito-identity-js";
// Get proper CognitoUser Typescript interface (with `attributes`) - see: https://github.com/aws-amplify/amplify-js/issues/4927
import { CognitoUserInterface as CognitoUser } from "@aws-amplify/ui-components";

export function isCognitoSession(session: any): session is CognitoUserSession {
  return (
    Boolean(session) &&
    "accessToken" in session &&
    "idToken" in session &&
    "refreshToken" in session &&
    session.accessToken instanceof CognitoAccessToken &&
    session.idToken instanceof CognitoIdToken &&
    session.refreshToken instanceof CognitoRefreshToken
  );
}

export function isCognitoUser(user: any): user is CognitoUser {
  return (
    Boolean(user) &&
    "attributes" in user &&
    user.attributes.name !== undefined &&
    typeof user.attributes.name == "string" &&
    user.attributes.email !== undefined &&
    typeof user.attributes.email == "string" &&
    "signInUserSession" in user &&
    isCognitoSession(user.signInUserSession)
  );
}
