import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface NoRowsMessageProps {
  message: string;
}

/**
 * A component to override the default message of "No rows" for the MUI Data
 * Grid whenever there is **no data** within the component.
 * This component shows whatever empty-state message you pass in instead.
 */
const NoRowsMessage = ({ message }: NoRowsMessageProps): JSX.Element => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100%" }}
    >
      <Typography>{message}</Typography>
    </Stack>
  );
};

export default NoRowsMessage;
