import * as React from "react";
import { capitalize } from "@mui/material";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import {
  AppDispatch,
  AppState,
  useAppSelector,
  useAppDispatch
} from "src/store";
import { setPreviewMode } from "src/store/slices/createEvaluation";
import { sortArrayDESC } from "src/utils/sort";
import { PreviewMode } from "../types";

const PreviewHeaderTop = ({ dataLoaded }) => {
  const dispatch: AppDispatch = useAppDispatch();

  const previewMode: PreviewMode = useAppSelector(
    (state: AppState) => state.createEvaluation.previewMode
  );
  const previewModes: PreviewMode[] = sortArrayDESC(Object.values(PreviewMode));

  function onChange(
    _event: React.MouseEvent<HTMLElement>,
    newTab: string
  ): void {
    if (newTab) {
      dispatch(setPreviewMode(newTab));
    }
  }

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Typography variant="h5">
        <b>Preview</b>
      </Typography>
      {dataLoaded && (
        <ToggleButtonGroup
          size="small"
          value={previewMode}
          exclusive
          onChange={onChange}
        >
          {previewModes.map((mode: PreviewMode) => {
            return (
              <ToggleButton
                key={mode}
                value={mode}
                sx={{ padding: "8px 16px" }}
              >
                {capitalize(mode)}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      )}
    </Stack>
  );
};

export default PreviewHeaderTop;
