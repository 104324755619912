import * as React from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { isUuidValid } from "src/utils/validators";
import Error from "../Error";
import ConditionalWrapper from "src/components/Common/ConditionalWrapper";
import Loading from "src/components/Common/Loading";
import Header from "./Header";
import { PhaseTypesEnum } from "src/utils/constants/phase";
import { DetailLinks, Evaluation } from "src/schemas/evaluation";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import Details from "./Details";
import { mapExpandToLinkables } from "../../mappers/linkables";
import QuestionsPanel from "./QuestionsPanel";
import useManageEvaluationContext, {
  ManageEvaluationContextTypeProvider
} from "src/context/ManageEvaluation";

const ManageEvaluation = ({ type }: { type: EvaluationTypes }) => {
  const { id } = useParams();
  // TODO write an error boundary handler for this component and don't wrap UUID validation with try/catch
  if (isUuidValid(id)) {
    return (
      <ManageEvaluationContextTypeProvider evaluationType={type} id={id}>
        <FetchEvaluationToManage />
      </ManageEvaluationContextTypeProvider>
    );
  }
  return <Error />;
};

const FetchEvaluationToManage = () => {
  const { evaluation, evaluationType } = useManageEvaluationContext();

  return (
    <>
      <ConditionalWrapper condition={!evaluation}>
        <Loading height="100%" />
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={evaluation && (!evaluation.item || !evaluation?._expand)}
      >
        <Error message={`Unable to load ${evaluationType}`} />
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={
          evaluation && evaluation?.item && Reflect.has(evaluation, "_expand")
        }
      >
        <ManagementPageForEvaluation
          evaluation={evaluation?.item}
          linkables={mapExpandToLinkables(evaluation?._expand)}
        />
      </ConditionalWrapper>
    </>
  );
};

const ManagementPageForEvaluation = ({
  evaluation,
  linkables
}: {
  evaluation: Evaluation;
  linkables: DetailLinks;
}) => {
  const { Name, Phase: _phase, Description, Metadata } = evaluation;

  const phase: PhaseTypesEnum = PhaseTypesEnum[`${_phase.toLowerCase()}`];

  return (
    <Stack>
      <Header name={Name} phase={phase} />
      <Stack direction="row" height="100vh" spacing="24px">
        <Details
          description={Description}
          tags={Metadata.Tags}
          notes={Metadata.Notes[0]}
          linkables={linkables}
        />
        <QuestionsPanel />
      </Stack>
    </Stack>
  );
};

export default ManageEvaluation;
