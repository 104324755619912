import * as React from "react";
import ActionButton from "src/components/Common/ActionButton";
import { DeleteButtonProps } from "./types";
import { ButtonVariantEnum } from "src/utils/constants/button";

const DeleteButton = ({
  disabled,
  loading,
  onClick
}: DeleteButtonProps): JSX.Element => {
  return (
    <ActionButton
      variant={ButtonVariantEnum.contained}
      color="error"
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      {loading ? "Deleting" : "Delete"}
    </ActionButton>
  );
};
export default DeleteButton;
