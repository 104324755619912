import { Aggregate } from "../../Charts/Chart/types";

/* aggregateBy()
 * Accepts a list of objects,
 * Retrieves a key from the object or otherwise getKey
 * Summarizes by the values of that key into an array again
 * @return [{Key: "", Sum: 1}, ...]
 */
export const aggregateBy = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
): Aggregate[] => {
  const tempList = list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    // eslint-disable-next-line security/detect-object-injection
    if (!previous[group]) previous[group] = 0;
    // eslint-disable-next-line security/detect-object-injection
    previous[group] += 1;
    return previous;
  }, {} as Record<K, number>);
  return Object.entries(tempList).map(([key, sum]: [string, number]) => ({
    Key: key,
    Sum: sum
  }));
};
