import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material";
import PhaseChip from "src/components/Common/Chip/Phase";
import ConditionalWrapper from "src/components/Common/ConditionalWrapper";
import {
  PHASES,
  PhaseTypesEnum,
  PHASE_INFO_MAP
} from "src/utils/constants/phase";
import TooltipTextBox from "src/components/Common/Tooltip/TooltipTextBox";
import ArrowRightWrapped from "./ArrowRightWrapped";
import PhaseInfoButton from "../PhaseInfoButton";

/**
 * Loops through the phases and returns a list of PhaseChips coloring
 * only the current phase. Arrows between phases with exception of the last phase.
 *
 * The PhaseChipRow component includes the following buttons:
 * - Editing
 * - Open
 * - Closed
 * - Completed
 **/

const PhaseChipRow = ({ currentPhase }: { currentPhase: PhaseTypesEnum }) => {
  const { palette } = useTheme();
  const finalPhase: string = PHASES[PHASES.length - 1];

  return (
    <Stack direction="row" alignItems="center">
      {PHASES.map((phase: PhaseTypesEnum) => (
        <Box component="span" key={phase}>
          <Tooltip
            placement="bottom-start"
            title={<TooltipTextBox>{PHASE_INFO_MAP.get(phase)}</TooltipTextBox>}
          >
            <Box component="span" key={phase}>
              <PhaseChip
                phase={PhaseTypesEnum[`${phase}`]}
                disabled={phase != PhaseTypesEnum[`${currentPhase}`]}
              />
            </Box>
          </Tooltip>
          <ConditionalWrapper condition={phase != finalPhase}>
            <ArrowRightWrapped color={palette.grey["400"]} />
          </ConditionalWrapper>
        </Box>
      ))}
      <PhaseInfoButton currentPhase={currentPhase} />
    </Stack>
  );
};

export default PhaseChipRow;
