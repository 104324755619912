import * as am5 from "@amcharts/amcharts5";

// Types
export interface BucketHeatmapLegendItem {
  color: am5.Color;
  label: string;
}

// Utilties
export const addLegend = (
  root: am5.Root,
  chart: am5.Chart,
  options: am5.ILegendSettings
): am5.Legend => chart.children.push(am5.Legend.new(root, options));

/**
 * Given a **descending** array of point intervals and
 * a corresponding mapped amCharts ColorSet, creates an
 * array of objects that represent legend items.
 *
 * Each legend item has the following fields:
 * - `color` - used to populate a color swatch in the legend
 * - `label` - used to populate text next to color swatch in the legend.
 * Has a format of `${rangeStart}` if it is first point in intervals array (e.g., "100%")
 * or `${rangeStart} - ${rangeEnd}` (e.g., "81 - 99%") otherwise
 */
export function createBucketHeatmapLegendData(
  intervals: number[],
  colors: am5.Color[]
): BucketHeatmapLegendItem[] {
  return intervals.map((rangeStart: number, index: number) => {
    let label = `${rangeStart}`;
    if (index != 0) {
      const rangeEnd = intervals[index - 1] - 1;
      label += `- ${rangeEnd}`;
    }

    return {
      color: Reflect.get(colors, index),
      label: `${label}%`
    };
  });
}
