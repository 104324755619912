import {
  AnyFilterItem,
  AnyFilterItems,
  FilterCategory,
  FilterState
} from "../types";

type SomeEnabledCategoriesInterface = {
  [key in FilterCategory]: (filterState: FilterState) => boolean;
};

export interface SomeEnabledInterface extends SomeEnabledCategoriesInterface {
  category: (filterState: FilterState, category: FilterCategory) => boolean;
  childCategory: (
    filterState: FilterState,
    category: FilterCategory,
    childCategory: FilterCategory
  ) => boolean;
}

/**
 * Static class for checking if **some filter items** in different filter
 * categories (e.g., domains, elements, stages) are **explicitly** enabled.
 *
 * **Usage**
 * This class is meant to be used within the `ApplyFilters` static class to
 * check if different category filters are enabled (switched on).
 *
 */
const SomeEnabled: SomeEnabledInterface = class {
  /** Checks if **some** filters for a particular category (e.g., domains) are
   * enabled.
   */
  static category(filterState: FilterState, category: FilterCategory): boolean {
    return Object.values(Reflect.get(filterState, category)).some(
      (item: AnyFilterItem) => item.enabled
    );
  }

  /**
   * Go through each item in a filter category and check each item's nested
   * filter items. If **some** of the nested filter items are enabled, return
   * true.
   *
   * **Example**
   * - category = "Domains", childCategory = "Elements". This means go through
   * all domains in `filterState.domains` - for each domain, check
   * `filterState.domains[`${domain}`].elements and see if any element filters
   * are enabled.
   */
  static childCategory(
    filterState: FilterState,
    category: FilterCategory,
    childCategory: FilterCategory
  ): boolean {
    // e.g., filterState.domains
    const items: AnyFilterItems = Reflect.get(filterState, category);
    return Object.values(items).reduce((acc: boolean, item: AnyFilterItem) => {
      // e.g., filterState.domains[`${domain}`].elements
      const childItems: AnyFilterItems = Reflect.get(item, childCategory);
      return (
        acc ||
        Object.values(childItems).some(
          (childItem: AnyFilterItem) => childItem.enabled
        )
      );
    }, false);
  }

  static domains(filterState: FilterState): boolean {
    return SomeEnabled.category(filterState, FilterCategory.Domains);
  }

  static elements(filterState: FilterState): boolean {
    return SomeEnabled.childCategory(
      filterState,
      FilterCategory.Domains,
      FilterCategory.Elements
    );
  }

  static stages(filterState: FilterState): boolean {
    return SomeEnabled.category(filterState, FilterCategory.Stages);
  }
};

export default SomeEnabled;
