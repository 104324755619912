import { EvaluationTypes } from "src/utils/constants/evaluation";

interface GetDisabledTooltipTextParams {
  evaluationType: EvaluationTypes;
  isFormInvalid: boolean;
  isFormClean: boolean;
}
export function getDisabledTooltipText({
  evaluationType,
  isFormInvalid,
  isFormClean
}: GetDisabledTooltipTextParams) {
  if (isFormInvalid) {
    return "Please fill out all the fields";
  }

  if (isFormClean) {
    return `Please make changes to update this ${evaluationType}`;
  }
}
