import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import PhaseChip from "src/components/Common/Chip/Phase";
import { makeFlexColumn } from "src/components/Common/DataGrid/utils/columns";
import { Collection } from "src/utils/constants/collection";
import { PhaseTypesEnum } from "src/utils/constants/phase";
import { formatLongDate } from "src/utils/formatDate";
import ViewButton from "../ViewButton";

/**
 * Creates column definitions array to be passed into the MUI Data Grid to
 * - render column headers
 * - render row values in a specified format
 */
export function makeColumns(
  collection: Collection.assessment | Collection.survey
): GridColDef[] {
  return [
    makeFlexColumn("Name", 3),
    makeFlexColumn("Description", 3),
    makeFlexColumn("Phase", 2, {
      renderCell: ({ value }) => (
        <PhaseChip phase={Reflect.get(PhaseTypesEnum, value)} />
      )
    }),
    makeFlexColumn("Client", 3),
    makeFlexColumn("Project", 3),
    makeFlexColumn("Template", 3),
    makeFlexColumn("DateCreated", 3, {
      valueFormatter: ({ value }) => formatLongDate(value)
    }),
    makeFlexColumn("DateModified", 3, {
      valueFormatter: ({ value }) => formatLongDate(value)
    }),
    makeFlexColumn("View", 1, {
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <ViewButton collection={collection} evaluationId={row.id} />
      )
    })
  ];
}
