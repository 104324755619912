import { CreateQuestion, IndexedQuestion } from "src/schemas/question";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import {
  simpleReducer,
  ReducerFunctionParameters,
  ReducerFunction
} from "src/utils/react/reducers/simple";
import { QuestionUnion } from "../types";
import { createNewQuestion } from "../utils/question";

interface SetupQuestionReducer {
  isEditing: boolean;
  evaluationType: EvaluationTypes;
  editingQuestion?: IndexedQuestion;
  answerCount?: number;
}

/**
 * Simple React Reducer function that updates object fields for object of type
 * IndexedQuestion.
 *
 * **Usage**
 * Pass in as the first argument to the `React.useReducer` hook for
 * - **Edit** Question case
 */
function questionReducer(...args: ReducerFunctionParameters<IndexedQuestion>) {
  return simpleReducer(...args);
}

/**
 * Simple React Reducer function that updates object fields for object of type
 * CreateQuestion.
 *
 * **Usage**
 * Pass in as the first argument to the `React.useReducer` hook for
 * - **Add** Question case
 */
function createQuestionReducer(
  ...args: ReducerFunctionParameters<CreateQuestion>
) {
  return simpleReducer(...args);
}

/**
 * Simple React Reducer that updates object fields for object of
 * whichever Question type, dynamically based on passed-in `isEditing` flag.
 *
 * @returns a tuple that can passed into `React.useReducer` hook using the
 * spread `...` operator
 */
export function setupQuestionReducer({
  isEditing,
  editingQuestion,
  evaluationType,
  answerCount
}: SetupQuestionReducer): [ReducerFunction<QuestionUnion>, QuestionUnion] {
  const reducer: ReducerFunction<QuestionUnion> = isEditing
    ? questionReducer
    : createQuestionReducer;
  const defaultValue: QuestionUnion = isEditing
    ? { ...editingQuestion }
    : createNewQuestion(evaluationType, answerCount);

  return [reducer, defaultValue];
}
