import * as React from "react";
import Loading from "src/components/Common/Loading";
import EmptyList from "./EvaluationList/EmptyList";
import EvaluationList from "./EvaluationList";
import { EvaluationListWrapperProps } from "./types";

const EvaluationListWrapper = ({
  evaluations,
  evaluationType
}: EvaluationListWrapperProps): JSX.Element => {
  if (!evaluations) {
    return <Loading height="100%" />;
  }
  if (Array.isArray(evaluations) && evaluations.length == 0) {
    return <EmptyList evaluationType={evaluationType} />;
  }
  return (
    <EvaluationList evaluations={evaluations} evaluationType={evaluationType} />
  );
};
export default EvaluationListWrapper;
