import * as React from "react";
import { capitalize, useTheme } from "@mui/material";
import ScrollableContainer from "src/components/Common/ScrollableContainer";
import { EmptyStateMessage } from "src/components/Common/EmptyStateMessage";
import { getEvaluationsGridItemBodyLgStyles } from "src/pages/Home/utils/styles";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import EvaluationListWrapper from "./EvaluationListWrapper";
import { BodyProps } from "./types";

const Body = ({ evaluations, evaluationType }: BodyProps): JSX.Element => {
  const theme = useTheme();
  return (
    <ScrollableContainer sxParent={getEvaluationsGridItemBodyLgStyles(theme)}>
      {evaluationType == EvaluationTypes.assessment ? (
        <EvaluationListWrapper
          evaluations={evaluations}
          evaluationType={evaluationType}
        />
      ) : (
        <EmptyStateMessage
          header="Coming Soon"
          body={
            <>
              {capitalize(evaluationType)}-related workflows will be supported
              in the future.
            </>
          }
        />
      )}
    </ScrollableContainer>
  );
};
export default Body;
