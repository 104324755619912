import { z } from "zod";
import { QuestionTypesSchema, zodPositiveInteger, zodUUID } from "./common";
import { MetadataSchema, UpdateMetadataRequestSchema } from "./metadata";
import { RecommendationSchema } from "./recommendation";

export const AnswerSchema = z.object({
  ScoreValue: zodPositiveInteger,
  Text: z.string().min(1)
});

/* TODO: DTT-648 - Clean up Question Schemas
 * Replace BaseQuestionSchema with QuestionSchema
 * and rename QuestionSchema as `ExtendedQuestionSchema`, which also
 * merges in IndexedQuestionSchema
 */
export const BaseQuestionSchema = z.object({
  Id: zodUUID,
  Text: z.string().min(1),
  Type: QuestionTypesSchema,
  Answers: z.array(AnswerSchema),
  Domain: z.string(),
  Element: z.string(),
  Stage: zodPositiveInteger.optional(),
  RecommendationId: RecommendationSchema.shape.Id.optional()
});

export const QuestionSchema = BaseQuestionSchema.extend({
  /* `Recommendation` field does not exist on BE version of QuestionSchema
   * TODO: DTT-648 - rename as `ExtendedQuestionSchema` */
  Recommendation: RecommendationSchema.optional()
});

export const IndexedQuestionSchema = QuestionSchema.extend({
  index: zodPositiveInteger
});

export const CreateQuestionSchema = BaseQuestionSchema.omit({
  Id: true
});

export const CreateQuestionRequestSchema = z.object({
  items: CreateQuestionSchema.array(),
  _meta: UpdateMetadataRequestSchema
});

export const CreateQuestionResponseSchema = z.object({
  items: BaseQuestionSchema.array(),
  _meta: MetadataSchema
});

export const UpdateQuestionSchema = CreateQuestionSchema;

export const UpdateQuestionRequestSchema = z.object({
  item: UpdateQuestionSchema,
  _meta: UpdateMetadataRequestSchema
});

// API Response objects
export const UpdateQuestionResponseSchema = z.object({
  item: BaseQuestionSchema,
  _meta: MetadataSchema
});

export const DeleteQuestionRequestSchema = z.object({
  _meta: UpdateMetadataRequestSchema
});

export const DeleteQuestionResponseSchema = z.object({
  _meta: MetadataSchema
});

// Types
export type Answer = z.infer<typeof AnswerSchema>;
export type BaseQuestion = z.infer<typeof BaseQuestionSchema>;
export type Question = z.infer<typeof QuestionSchema>;
export type IndexedQuestion = z.infer<typeof IndexedQuestionSchema>;

export type CreateQuestion = z.infer<typeof CreateQuestionSchema>;
export type CreateQuestionRequest = z.infer<typeof CreateQuestionRequestSchema>;
export type CreateQuestionResponse = z.infer<
  typeof CreateQuestionResponseSchema
>;

export type UpdateQuestion = z.infer<typeof UpdateQuestionSchema>;
export type UpdateQuestionRequest = z.infer<typeof UpdateQuestionRequestSchema>;
export type UpdateQuestionResponse = z.infer<
  typeof UpdateQuestionResponseSchema
>;
export type DeleteQuestionRequest = z.infer<typeof DeleteQuestionRequestSchema>;
export type DeleteQuestionResponse = z.infer<
  typeof DeleteQuestionResponseSchema
>;
