/* Table Cells
 * Styling naming will be based on padding/margin notation (clockwise direction)
 */
export const noLeftPadding: Record<string, any> = {
  paddingLeft: "0"
};

export const noTopLeftPadding: Record<string, any> = {
  paddingTop: "0",
  paddingLeft: "0"
};

export const noRightLeftPadding: Record<string, any> = {
  paddingRight: "0",
  paddingLeft: "0"
};

export const noTopRightLeftPadding: Record<string, any> = {
  paddingTop: "0",
  paddingRight: "0",
  paddingLeft: "0"
};
