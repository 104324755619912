import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import FlatTable from "src/components/Common/Table/Flat";
import { DomainRawScore } from "src/schemas/reporting/rawScore";

const OverviewTable = ({ data }: { data: DomainRawScore[] }): JSX.Element => {
  return (
    <FlatTable aria-label="Individual Domain Raw Scores table">
      <TableHead>
        <TableRow>
          <TableCell>
            <b>Domain</b>
          </TableCell>
          <TableCell align="right">
            <b>Score</b>
          </TableCell>
          <TableCell align="right">
            <b>Points</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item: DomainRawScore) => {
          return (
            <TableRow key={item.Domain}>
              <TableCell>{item.Domain}</TableCell>
              <TableCell align="right">{item.Percent}%</TableCell>
              <TableCell align="right">{`${item.ScoreValue} / ${item.MaxScoreValue}`}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </FlatTable>
  );
};

export default OverviewTable;
