import * as React from "react";
import Modal from "src/components/Common/Modal";
import Actions from "./Actions";
import Content from "./Content";
import Header from "./Header";
import { RowModalProps } from "./types";

/**
 * A modal which allows the user to fully (in a NON-truncated format)
 * view all information for a particular Recommendation (e.g., Question,
 * Domain, Element, etc.)
 */
const RowModal = ({
  open,
  handleClose,
  row,
  columns
}: RowModalProps): JSX.Element => {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Header />
      <Content row={row} columns={columns} />
      <Actions handleClose={handleClose} />
    </Modal>
  );
};

export default RowModal;
