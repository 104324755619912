import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material";
import ConditionalWrapper from "./ConditionalWrapper";
import Tag from "./Tags";
import TooltipTextBox from "./Tooltip/TooltipTextBox";

/**
 * The recommendation has 3 types:
 * - `base` for Editing stage
 * - `excluded` for Open stage and above
 * - `included` for Open stage and above
 *
 * The recommendation is included or excluded is based on the scoring for the response
 */
export enum RecommendationType {
  base = "base",
  included = "included",
  excluded = "excluded"
}

const ExcludedHelperText = () => (
  <TooltipTextBox component="div">
    Based on the response submitted to this question, this recommendation will
    be excluded from your results recommendation list because of sufficient
    client maturity.
  </TooltipTextBox>
);
const IncludedHelperText = () => (
  <TooltipTextBox component="div">
    Based on the response submitted to this question, this recommendation will
    be included in your results recommendation list.
  </TooltipTextBox>
);

const LABEL = "Recommendation";

/* Styling Overrides */
const TAG_STYLES: Record<string, any> = {
  fontSize: "14px",
  letterSpacing: "0px",
  lineHeight: "14px"
};

export const Recommendation = ({
  type = RecommendationType.base,
  description
}: {
  type: RecommendationType;
  description: string;
}) => {
  const { palette } = useTheme();
  return (
    <Box component="div" sx={{ marginTop: "24px" }}>
      <ConditionalWrapper condition={type == RecommendationType.base}>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "bold", marginTop: "12px" }}
        >
          {LABEL}
        </Typography>
      </ConditionalWrapper>

      <ConditionalWrapper condition={type == RecommendationType.included}>
        <Tooltip placement="right-end" title={<IncludedHelperText />}>
          <Tag
            label={LABEL}
            size="small"
            sx={{
              backgroundColor: palette.success.main,
              color: palette.common.white,
              ...TAG_STYLES
            }}
          />
        </Tooltip>
      </ConditionalWrapper>

      <ConditionalWrapper condition={type == RecommendationType.excluded}>
        <Tooltip placement="right-end" title={<ExcludedHelperText />}>
          <Tag label="Recommendation" size="small" sx={TAG_STYLES} />
        </Tooltip>
      </ConditionalWrapper>

      <Typography sx={{ fontSize: "13px", marginTop: "8px" }}>
        {description}
      </Typography>
    </Box>
  );
};
