import { createTheme } from "@mui/material/styles";
import palette from "./palette";
import componentOverrides from "./componentOverrides";

/* REFERENCES
 * - https://mui.com/customization/theming/
 * - https://mui.com/customization/theme-components/
 * - https://mui.com/customization/palette/
 */

/* Constants & Customization */
const bodyFont = `'Slalom Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif`;

/* Theme */
const theme = createTheme({
  palette,
  typography: { fontFamily: bodyFont },
  components: componentOverrides
});

export default theme;
