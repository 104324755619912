import { z } from "zod";
import { EvaluationTemplateExpandOptions } from "./expands/evaluationTemplate";
import { QuestionSchema } from "./question";
import { RecordOfRecommendationsSchema } from "./recommendation";
import { StagesSchema } from "./stages";
import { EvaluationTemplateSummarySchema } from "./evaluationTemplateSummary";

export const EvaluationTemplateSchema = EvaluationTemplateSummarySchema.extend({
  Stages: StagesSchema,
  Questions: QuestionSchema.array()
});

export const EvaluationTemplateExpandSchema = z.object({
  [EvaluationTemplateExpandOptions.enum.Recommendation]:
    RecordOfRecommendationsSchema
});

// API Response items

export const EvaluationTemplateResponseSchema = z.object({
  item: EvaluationTemplateSchema,
  _expand: EvaluationTemplateExpandSchema.optional()
});

// Types

export type EvaluationTemplate = z.infer<typeof EvaluationTemplateSchema>;
export type EvaluationTemplateResponse = z.infer<
  typeof EvaluationTemplateResponseSchema
>;
export type EvaluationTemplateExpand = z.infer<
  typeof EvaluationTemplateExpandSchema
>;
