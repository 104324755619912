import { invertedHasFilter } from "./filter/setHelpers";

/**
 * Returns the difference of items that are in `itemsA` and not in `itemsB`
 * @param itemsA an array of items
 * @param itemsB an array of items
 * @returns an array of items in `itemsA` that are not in `itemsB`
 */
export function difference<T>(itemsA: T[], itemsB: T[]): T[] {
  return [...new Set(itemsA.filter(invertedHasFilter(new Set(itemsB))))];
}
