import * as React from "react";
import AutocompleteItem from "src/components/Common/AutocompleteWithHeader/Item";
import CheckboxGroup, {
  CheckboxMap
} from "src/components/Common/CheckboxGroup";
import {
  OptionsConfig,
  UseAutocompleteHookProps
} from "src/components/Common/AutocompleteWithHeader/types";

const Body = ({
  showFilterScreen,
  useAutocompleteProps,
  optionsConfig,
  tagsMap,
  selectedTags,
  handleSelectAllCheckboxChange,
  handleCheckboxChange
}: {
  showFilterScreen: boolean;
  useAutocompleteProps: UseAutocompleteHookProps<any, false, false, true>;
  optionsConfig: OptionsConfig;
  tagsMap: CheckboxMap;
  selectedTags: Array<string>;
  handleSelectAllCheckboxChange: (oldCheckedValue: boolean) => void;
  handleCheckboxChange: (tag: string) => void;
}) => {
  const { groupedOptions, getOptionProps } = useAutocompleteProps;
  return showFilterScreen ? (
    <>
      <CheckboxGroup
        itemMap={tagsMap}
        selectedItemsCount={selectedTags.length}
        onSelectAllChange={(checked: boolean) =>
          handleSelectAllCheckboxChange(checked)
        }
        onChange={(tag: string) => handleCheckboxChange(tag)}
      />
    </>
  ) : (
    <>
      {groupedOptions.map((option: any, index: number) => (
        <AutocompleteItem
          key={index}
          index={index}
          option={option}
          getOptionProps={getOptionProps}
          optionsConfig={optionsConfig}
        />
      ))}
    </>
  );
};

export default Body;
