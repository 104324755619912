import { PhaseActionInfo } from "../types";

export function populateData({
  name,
  summary = "",
  editing = false,
  open = false,
  closed = false,
  completed = false
}: PhaseActionInfo) {
  return { name, summary, editing, open, closed, completed };
}
