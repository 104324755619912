import * as React from "react";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { capitalize, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Modal from "src/components/Common/Modal";
import useManageEvaluationContext from "src/context/ManageEvaluation";

const Header = (): JSX.Element => {
  const { palette } = useTheme();
  const { evaluationType } = useManageEvaluationContext();
  return (
    <Modal.Header
      sx={{
        backgroundColor: palette.primary.main,
        color: palette.common.white
      }}
    >
      <Stack direction="row" spacing="12px" alignItems="center">
        <EditRoundedIcon />
        <Typography variant="h6">
          <b>Edit {capitalize(evaluationType)}</b>
        </Typography>
      </Stack>
    </Modal.Header>
  );
};

export default Header;
