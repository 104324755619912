import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CalculationInfoModal from "./Modal";
import CalculationDefinition from "./Definition";

const CalculationInfo = (): JSX.Element => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  return (
    <>
      <CalculationInfoModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
      <Stack spacing="8px">
        <Typography>
          <b>How scores are calculated</b>
        </Typography>
        <Box component="span">
          <CalculationDefinition variant="body2" sx={{ display: "inline" }} />
          <IconButton onClick={() => setModalOpen(true)}>
            <InfoOutlinedIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </Box>
      </Stack>
    </>
  );
};

export default CalculationInfo;
