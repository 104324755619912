import * as React from "react";
import Typography from "@mui/material/Typography";
import MailtoLink from "src/components/Common/Contact/MailtoLink";

const Header = (): JSX.Element => {
  return (
    <>
      <Typography variant="h3" sx={{ marginBottom: "28px" }}>
        Contact Us
      </Typography>

      <Typography sx={{ marginBottom: "32px", fontSize: "18px" }}>
        Contact us at <MailtoLink sx={{ fontWeight: "bold" }} /> if you have any
        questions. Click on the following to
      </Typography>
    </>
  );
};

export default Header;
