/**
 * Enum representing the different category sections
 * in the Filters panel
 */
export enum FilterCategory {
  Domains = "domains",
  Elements = "elements",
  Stages = "stages"
}

/**
 * Object interface for housing filter item state.
 *
 * - `enabled` - indicates if filter is on
 *
 * - `formatted` - formatted display name of filter item (e.g., let's assume
 * the key corresponding to this filter item object is "1" for Stage 1. Then the
 * `formatted` field in the filter item object could be "Stage 1 - Reactive".)
 */
export interface FilterItem {
  enabled: boolean;
  formatted?: string;
}

/**
 * Object mapping for multiple filter items, where
 * - key = name of filter item
 * - value = filter item state
 *
 * Used for basic filter cases (Elements, Stages, etc.).
 */
export type FilterItems = Record<string, FilterItem>;

/**
 * Custom object interface for housing Domain filter item state.
 * Extends from `FilterItem` interface.
 *
 * - `elements` - an object where the keys are the Element name and the values
 * are objects of type `FilterItem`. Elements are nested under the Domain they
 * belong to. This design addresses an edge case where Elements could
 * be duplicated across Domains. This is why Elements are not on a flat level
 * with Domains and Stages in our filter state object.
 *
 * - `indeterminate` - indicates if the Domain filter is in an "indeterminate"
 * state whenever **some** (but not all or none) of its Elements are enabled
 */
export interface DomainFilterItem extends FilterItem {
  indeterminate: boolean;
  [FilterCategory.Elements]: FilterItems;
}

/**
 * Object mapping for multiple Domain filter items, where
 * - key = name of Domain
 * - value = Domain filter item state
 */
export type DomainFilterItems = Record<string, DomainFilterItem>;

/**
 * Flexible Extension of base FilterItem to support the base and all custom
 * extensions (e.g., DomainFilterItem)
 */
export type AnyFilterItem = FilterItem & {
  [key: string]: any;
};

/**
 * Object mapping for multiple filter items of **any type**, where
 * - key = name of filter item
 * - value = filter item state
 */
export type AnyFilterItems = Record<string, AnyFilterItem>;

/**
 * Object interface representing our entire filter state. Should contain
 * keys corresponding to the enum `FilterCategory`. However, note that from
 * a state perspective, some of those categories will be nested below others.
 *
 * For instance, "elements" is a nested field under each Domain.
 */
export interface FilterState {
  [FilterCategory.Domains]: DomainFilterItems;
  [FilterCategory.Stages]: FilterItems;
}
