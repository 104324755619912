import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Typography from "@mui/material/Typography";
import FlatTable from "src/components/Common/Table/Flat";
import {
  noLeftPadding,
  noRightLeftPadding,
  noTopRightLeftPadding
} from "src/components/Common/Table/styles";
import { StagesProps } from "./types";

const Stages = ({ data }: StagesProps): JSX.Element => {
  return (
    <FlatTable aria-label="Overall Raw Scores Report - Stages table">
      <TableHead>
        <TableRow>
          <TableCell sx={noTopRightLeftPadding}>
            <Typography variant="body1">
              <b>Stages</b>
            </Typography>
          </TableCell>
          <TableCell></TableCell>
          <TableCell sx={{ width: "100%", ...noLeftPadding }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[...data.keys()].sort().map((key: number) => {
          return (
            <TableRow key={key}>
              <TableCell sx={noRightLeftPadding}>Stage {key}</TableCell>
              <TableCell>&mdash;</TableCell>
              <TableCell sx={noLeftPadding}>{data.get(key)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </FlatTable>
  );
};

export default Stages;
