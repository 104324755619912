import { UpdateEvaluationAction } from "src/context/ManageEvaluation/reducers/evaluation/types";
import {
  BaseQuestion,
  CreateQuestion,
  CreateQuestionResponse,
  Question
} from "src/schemas/question";
import {
  Recommendation,
  RecommendationSchema
} from "src/schemas/recommendation";
import EvaluationQuestionService from "src/services/evaluationQuestion";
import { conditionalObject } from "src/utils/object";
import { isValid } from "src/utils/validators";
import { QuestionAPICallWrapper, SuccessCallbackWrapper } from "./types";

export interface PostQuestionCallback extends SuccessCallbackWrapper {
  response: CreateQuestionResponse;
  recommendation?: Recommendation;
}

export interface PostQuestion extends QuestionAPICallWrapper {
  question: CreateQuestion;
  recommendation?: Recommendation;
}

export function postQuestionSuccessCallback({
  response,
  successCallback,
  recommendation
}: PostQuestionCallback): void {
  const [question]: BaseQuestion[] = response.items;
  /* Add Recommendation information to Question if
   * passed-in `recommendation` exists and is valid
   */
  const newQuestion: Question = {
    ...question,
    ...conditionalObject(isValid(recommendation, RecommendationSchema), {
      Recommendation: recommendation,
      RecommendationId: recommendation?.Id
    })
  };
  return successCallback(
    UpdateEvaluationAction.addQuestion,
    newQuestion,
    response._meta
  );
}

export async function postQuestion({
  question,
  questionCRUDFields,
  successCallback,
  recommendation
}: PostQuestion): Promise<void> {
  return EvaluationQuestionService.post({
    ...questionCRUDFields,
    question,
    callback: (response: CreateQuestionResponse) =>
      postQuestionSuccessCallback({ response, successCallback, recommendation })
  });
}
