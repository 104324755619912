import { z } from "zod";
import { ClientSchema } from "./client";
import { DateSchema, zodUUID } from "./common";
import { CreateMetadataSchema, MetadataSchema } from "./metadata";

export const ProjectSchema = z.object({
  Id: zodUUID,
  Name: z.string().nullable().optional(),
  Description: z.string().nullable().optional(),
  ClientId: ClientSchema.shape.Id.nullable().optional(),
  SFDCId: z.string().nullable().optional(),
  Metadata: MetadataSchema.nullable().optional(),
  StartDate: DateSchema.nullable().optional(),
  EndDate: DateSchema.nullable().optional(),
  IsActive: z.boolean().nullable().optional(),
  Stage: z.string().nullable().optional()
});

export const CreateProjectSchema = ProjectSchema.extend({
  Name: z.string(),
  Metadata: CreateMetadataSchema
});

export const ProjectListSchema = ProjectSchema.array();

export const RecordOfProjectsSchema = z.record(
  ProjectSchema.shape.Id,
  ProjectSchema
);

// API Response objects
export const ProjectResponseSchema = z.object({
  result: ProjectSchema
});

export const ProjectListResponseSchema = z.object({
  result: ProjectListSchema
});

// Types
export type Project = z.infer<typeof ProjectSchema>;
export type ProjectResponse = z.infer<typeof ProjectResponseSchema>;

export type CreateProject = z.infer<typeof CreateProjectSchema>;

export type ProjectList = z.infer<typeof ProjectListSchema>;
export type ProjectListResponse = z.infer<typeof ProjectListResponseSchema>;

export type RecordOfProjects = z.infer<typeof RecordOfProjectsSchema>;
