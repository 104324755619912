import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";

export const addPieChart = (
  root: am5.Root,
  options: am5percent.IPieChartSettings,
  container?: am5.Container
): am5percent.PieChart =>
  (container ?? root.container).children.push(
    am5percent.PieChart.new(root, options)
  );

export const addPieChartSeries = (
  root: am5.Root,
  pc: am5percent.PieChart,
  options: am5percent.IPieSeriesSettings
): am5percent.PieSeries =>
  pc.series.push(am5percent.PieSeries.new(root, options));
