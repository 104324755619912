import * as React from "react";
import { capitalize, useTheme } from "@mui/material";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import NoRowsMessage from "src/components/Common/DataGrid/NoRowsMessage";
import Toolbar from "src/components/Common/DataGrid/Toolbar";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS
} from "src/components/Common/DataGrid/utils/pagination";
import { DataGridEvaluationSummaryList } from "src/schemas/evaluationSummary";
import { Collection } from "src/utils/constants/collection";
import { makeColumns } from "./data/columns";
import { getToolbarStyles } from "./styles";

interface DataTableCoreProps {
  collection: Collection.assessment | Collection.survey;
  rows: DataGridEvaluationSummaryList;
}

const DataTableCore = ({
  rows,
  collection
}: DataTableCoreProps): JSX.Element => {
  const { palette } = useTheme();
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE
    });

  return (
    <DataGrid
      sx={{ marginTop: "24px" }}
      rows={rows}
      columns={makeColumns(collection)}
      hideFooterSelectedRowCount
      initialState={{
        sorting: {
          sortModel: [{ field: "DateModified", sort: "desc" }]
        }
      }}
      slots={{
        noRowsOverlay: () => <NoRowsMessage message={`No ${collection}`} />,
        toolbar: () => (
          <Toolbar
            csvFileName={`${capitalize(collection)}`}
            sx={getToolbarStyles(palette)}
          />
        )
      }}
      pagination={true}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
      autoHeight={true}
    />
  );
};

export default DataTableCore;
