import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import LaunchIcon from "@mui/icons-material/Launch";
import { EvaluationSummaryWithTemplateExpand } from "src/schemas/evaluationSummary";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import TooltipBody from "./TooltipBody";

interface EvaluationDropdownOptionProps {
  props: object;
  option: EvaluationSummaryWithTemplateExpand;
}

const EvaluationDropdownOption = ({
  props,
  option
}: EvaluationDropdownOptionProps): JSX.Element => {
  return (
    <li {...props}>
      <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
        <Tooltip
          placement="left"
          title={
            <TooltipBody
              /* TODO - dynamically set evaluationType from option past MVP
               * to distinguish between assessment + survey
               */
              evaluationType={EvaluationTypes.assessment}
              template={option.Template}
            />
          }
        >
          <Box sx={{ flex: 1 }}>{option.Name}</Box>
        </Tooltip>
        <Tooltip
          placement="bottom-end"
          title={<Box sx={{ fontSize: "14px" }}>View details in new tab</Box>}
        >
          {/* TODO: When survey support added, dynamically get type from evaluation -> onClick={() => navigate(`/${type}s/${option.Id}`)} */}
          <IconButton
            aria-label="launch"
            href={`/assessments/${option.Id}`}
            target="_blank"
            onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
          >
            <LaunchIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </Tooltip>
      </Stack>
    </li>
  );
};

export default EvaluationDropdownOption;
