import * as React from "react";
import { capitalize } from "@mui/material";
import ActionButton from "src/components/Common/ActionButton";
import { AppDispatch, useAppDispatch } from "src/store";
import { showSuccess } from "src/store/slices/notification";
import { ActionButtonProps } from "src/components/Common/ActionButton/types";

const ExampleActionButton = (props: ActionButtonProps): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(
        showSuccess({
          message: "Mock API call successfully"
        })
      );
      setLoading(false);
    }, 5000);
  };

  return (
    <ActionButton
      loading={loading}
      tooltipLoadingText="We're working on your API call - hold on tight"
      onClick={() => handleSubmit()}
      {...props}
    >
      {loading ? "Loading" : `${capitalize(props.size)} Button`}
    </ActionButton>
  );
};
export default ExampleActionButton;
