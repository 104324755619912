import { z } from "zod";
import { DateSchema } from "./common";

export const TagsSchema = z.string().array().nullable();
export const NotesSchema = z.string().array().nullable();

export const CreateMetadataSchema = z
  .object({
    Tags: TagsSchema.optional(),
    Notes: NotesSchema.optional()
  })
  .default({});

export const UpdateMetadataSchema = z.object({
  DateCreated: DateSchema,
  Tags: TagsSchema.optional(),
  Notes: NotesSchema.optional()
});

export const MetadataSchema = UpdateMetadataSchema.required().extend({
  DateModified: DateSchema
});

export const UpdateMetadataRequestSchema = z.object({
  DateModified: DateSchema,
  Tags: TagsSchema.optional(),
  Notes: NotesSchema.optional()
});

// Actions on Notes
export const ModifyNotesRequestSchema = z.object({
  _meta: UpdateMetadataRequestSchema,
  item: z.string()
});

export const ModifyNotesResponseSchema = ModifyNotesRequestSchema;

// Types
export type CreateMetadata = z.infer<typeof CreateMetadataSchema>;
export type UpdateMetadata = z.infer<typeof UpdateMetadataSchema>;
export type Metadata = z.infer<typeof MetadataSchema>;

export type ModifyNotesRequest = z.infer<typeof ModifyNotesRequestSchema>;
export type ModifyNotesResponse = z.infer<typeof ModifyNotesResponseSchema>;
