import * as React from "react";
import Typography from "@mui/material/Typography";
import pluralize from "pluralize";
import { RecommendationCountProps } from "./types";

const RecommendationCount = ({
  count,
  sx
}: RecommendationCountProps): JSX.Element => {
  return (
    <Typography sx={{ fontSize: "20px", ...sx }}>
      <b>
        {count} {pluralize("Recommendation", count)}
      </b>
    </Typography>
  );
};

export default RecommendationCount;
