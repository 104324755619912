import { configureStore } from "@reduxjs/toolkit";
import { useSelector, useDispatch, TypedUseSelectorHook } from "react-redux";
import createEvaluationReducer from "./slices/createEvaluation";
import manageEvaluationReducer from "./slices/manageEvaluation";
import notificationReducer from "./slices/notification";

const store = configureStore({
  reducer: {
    createEvaluation: createEvaluationReducer,
    manageEvaluation: manageEvaluationReducer,
    notification: notificationReducer
  }
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

/* Use the following typed versions of `useDispatch` and `useSelector`
 * throughout the application
 */
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
