import * as React from "react";
import IconButton from "@mui/material/IconButton";
import ZoomInRoundedIcon from "@mui/icons-material/ZoomInRounded";

interface ViewDetailsButtonProps {
  onClick: () => void;
}

/**
 * A button component used in the Data Table component to open a modal
 * containing all information for a particular Recommendation.
 */
const ViewDetailsButton = ({
  onClick
}: ViewDetailsButtonProps): JSX.Element => {
  return (
    <IconButton color="primary" size="small" onClick={onClick}>
      <ZoomInRoundedIcon />
    </IconButton>
  );
};

export default ViewDetailsButton;
