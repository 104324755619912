import * as React from "react";
import { Typography, useTheme } from "@mui/material";
import Modal from "src/components/Common/Modal";

const Header = (): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Modal.Header
      sx={{
        backgroundColor: palette.primary.main,
        color: palette.common.white
      }}
    >
      <Typography variant="h6">
        <b>Add new Client, Project, or Template</b>
      </Typography>
    </Modal.Header>
  );
};
export default Header;
