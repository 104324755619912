import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { ButtonVariantEnum } from "src/utils/constants/button";
import Modal from "src/components/Common/Modal";
import { ActionsProps } from "./types";
import DeleteButton from "./DeleteButton";

const Actions = <T,>({
  deleteConfirmed,
  onCancelButtonClick,
  onDeleteButtonClick,
  successCallback,
  errorCallback
}: ActionsProps<T>): JSX.Element => {
  /*
   * This disabled state is used to enable and disable the Cancel and Delete buttons,
   * whenever we click on the "Delete" button (API call):
   *
   * Cancel button:
   * - Enable by default
   * - Disable when the Delete button is clicked and is loading
   *
   * Delete button:
   * - Disable by default
   * - Enable when the Delete text has been entered successfully and matched "Delete"
   * - Disable when the Delete button is clicked and is loading
   */
  const [disabled, setDisabled] = React.useState<boolean>(false);

  // This loading is for whenever we click on the "Delete" button (API call is going through)
  const [loading, setLoading] = React.useState<boolean>(false);

  function setAllLoadingStates(loading: boolean): void {
    setDisabled(loading);
    setLoading(loading);
  }

  /**
   * Wrapper for successCallback, which sets the local loading states to false
   * before calling the provided successCallback.
   */
  function successCallbackWrapper(response?: T) {
    setAllLoadingStates(false);
    successCallback(response);
  }

  /**
   * Wrapper for errorCallback, which sets the local loading states to false
   * before calling the provided errorCallback.
   */
  function errorCallbackWrapper(error: Error) {
    setAllLoadingStates(false);
    errorCallback(error);
  }

  /**
   * Wrapper for onDeleteButtonClick, which sets the local loading states to
   * true before calling the provided onDeleteButtonClick.
   */
  async function onDeleteButtonClickWrapper(): Promise<void> {
    setAllLoadingStates(true);
    try {
      const response: T = await onDeleteButtonClick();
      successCallbackWrapper(response);
    } catch (error) {
      errorCallbackWrapper(error);
    }
  }

  return (
    <Modal.Actions sx={{ padding: "16px" }}>
      <Stack direction="row" spacing="16px">
        <Button
          size="large"
          color="error"
          variant={ButtonVariantEnum.text}
          disabled={disabled}
          onClick={onCancelButtonClick}
        >
          Cancel
        </Button>
        <DeleteButton
          disabled={!deleteConfirmed || disabled}
          loading={loading}
          onClick={onDeleteButtonClickWrapper}
        />
      </Stack>
    </Modal.Actions>
  );
};

export default Actions;
