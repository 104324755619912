import * as React from "react";
import Button from "@mui/material/Button";
import DeleteRounded from "@mui/icons-material/DeleteRounded";
import MailtoLink from "src/components/Common/Contact/MailtoLink";
import TooltipTextBox from "src/components/Common/Tooltip/TooltipTextBox";
import DisabledActionTooltip from "src/components/Common/Tooltip/DisabledActionTooltip";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import { AppState, useAppSelector } from "src/store";
import { Response } from "src/schemas/response";
import { PhaseTypesEnum } from "src/utils/constants/phase";
import DeleteEvaluationModal from "../../DeleteEvaluationModal";

interface DeleteProps {
  phase: PhaseTypesEnum;
}

const Delete = ({ phase }: DeleteProps) => {
  const { evaluationType } = useManageEvaluationContext();
  const [open, setOpen] = React.useState<boolean>(false);
  const responses: Record<string, Response> = useAppSelector(
    (state: AppState) => state.manageEvaluation.responses
  );
  const evaluationHasResponses: boolean = Object.keys(responses).length > 0;
  const isCompletedPhase: boolean = phase == PhaseTypesEnum.completed;
  const disabled: boolean = evaluationHasResponses || isCompletedPhase;

  function getTooltipText(): string | JSX.Element {
    if (isCompletedPhase) {
      return `An ${evaluationType} cannot be deleted once it has been completed`;
    } else if (evaluationHasResponses) {
      return (
        <>
          <p>An {evaluationType} cannot be deleted if it has responses.</p>
          <p>
            Please contact us at{" "}
            <MailtoLink onDarkTooltip={true} sx={{ fontWeight: "bold" }} /> if
            you must delete it.
          </p>
        </>
      );
    } else {
      return ""; // Hide tooltip
    }
  }

  return (
    <>
      <DisabledActionTooltip
        title={<TooltipTextBox>{getTooltipText()}</TooltipTextBox>}
        disabled={disabled}
      >
        <Button color="error" onClick={() => setOpen(true)} disabled={disabled}>
          <DeleteRounded sx={{ fontSize: "18px", marginRight: "6px" }} />
          Delete
        </Button>
      </DisabledActionTooltip>
      <DeleteEvaluationModal open={open} handleClose={() => setOpen(false)} />
    </>
  );
};

export default Delete;
