import * as React from "react";
import { DomainMap } from "src/components/Common/Accordion/types";
import ErrorMessage from "src/components/Common/ErrorMessage";
import ScrollableContainer from "src/components/Common/ScrollableContainer";
import {
  EvaluationTemplate,
  EvaluationTemplateResponse,
  EvaluationTemplateResponseSchema
} from "src/schemas/evaluationTemplate";
import { EvaluationTemplateSummary } from "src/schemas/evaluationTemplateSummary";
import { EvaluationTemplateExpandOptions } from "src/schemas/expands/evaluationTemplate";
import { Question } from "src/schemas/question";
import { GetAPICaller } from "src/services/APICall";
import {
  AppDispatch,
  AppState,
  useAppDispatch,
  useAppSelector
} from "src/store";
import {
  setPreviewRowsState,
  setPreviewTemplate
} from "src/store/slices/createEvaluation";
import { showError } from "src/store/slices/notification";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { Collection } from "src/utils/constants/collection";
import { Nullable } from "src/utils/types";

import PreviewHeader from "../PreviewHeader";
import { getPreviewHeaderHeight } from "../styles";
import { PreviewMode, PreviewRowsState } from "../types";
import { PreviewDataHandler } from "./data/handler";
import NoSelectedTemplate from "./NoSelectedTemplate";
import TemplateLoading from "./TemplateLoading";
import Template from "./Template";

const Preview = (): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  const evaluationType: EvaluationTypes = useAppSelector(
    (state: AppState) => state.createEvaluation.evaluationType
  );
  const previewMode: PreviewMode = useAppSelector(
    (state: AppState) => state.createEvaluation.previewMode
  );
  const previewTemplate: Nullable<EvaluationTemplate> = useAppSelector(
    (state: AppState) => state.createEvaluation.previewTemplate
  );
  const selectedTemplate: Nullable<EvaluationTemplateSummary> = useAppSelector(
    (state: AppState) => state.createEvaluation.selectedTemplate
  );

  function updatePreviewRowsState(rowsState: PreviewRowsState): void {
    dispatch(setPreviewRowsState(rowsState));
  }

  function updatePreviewTemplate(template: Nullable<EvaluationTemplate>): void {
    dispatch(setPreviewTemplate(template));
  }

  function successCallback(response: EvaluationTemplateResponse): void {
    updatePreviewRowsState(
      PreviewDataHandler.rowsState(response.item.Questions)
    );
    updatePreviewTemplate(PreviewDataHandler.template(response));
  }

  function errorCallback(error: Error): void {
    dispatch(
      showError({
        message: (
          <ErrorMessage
            error={error}
            collection={Collection[`${evaluationType}Template`]}
          />
        )
      })
    );
  }

  /* Get full Evaluation Template object (with Questions, Stages, etc.)
   * after a user has selected the summary version of it from the
   * Templates dropdown
   */
  React.useEffect(() => {
    /**
     * Local variable within useEffect to handle race conditions
     * with async calls to make sure only data from final call gets set (e.g.,
     * if a user selects a new template while the previously selected one is
     * still loading).
     *
     * See another reference in
     * [Project dropdown](../ConfigurationOptions/Project.tsx)
     */
    let shouldSetData = true;

    // Reset the preview template while it's loading
    updatePreviewTemplate(null);

    if (selectedTemplate) {
      GetAPICaller({
        path: `/${evaluationType}-templates/${selectedTemplate.Id}`,
        queryStringParameters: {
          expand: Object.values(EvaluationTemplateExpandOptions.enum).join(",")
        },
        callback: (response: EvaluationTemplateResponse) => {
          if (shouldSetData) {
            successCallback(response);
          }
        },
        errorCallback,
        withReactHook: false,
        typeValidator: EvaluationTemplateResponseSchema
      });
    }

    return () => {
      shouldSetData = false;
    };
  }, [selectedTemplate]);

  /* Different states */
  const empty: boolean = selectedTemplate === null;
  const loading: boolean =
    selectedTemplate !== null && previewTemplate === null;
  const loaded: boolean = selectedTemplate !== null && previewTemplate !== null;

  const questions: Question[] = previewTemplate?.Questions ?? [];
  const domainMap: DomainMap = PreviewDataHandler.domains(questions);

  const isQuestionsMode: boolean = previewMode === PreviewMode.Questions;
  const items: any = isQuestionsMode ? questions : domainMap;

  return (
    <ScrollableContainer
      sxParent={{
        flex: 1,
        minWidth: "300px",
        height: "100%"
      }}
      sx={{
        padding: "0px 36px"
      }}
      header={
        <PreviewHeader
          dataLoaded={loaded}
          count={isQuestionsMode ? questions.length : domainMap.size}
        />
      }
      headerHeight={getPreviewHeaderHeight(loaded)}
    >
      {empty && <NoSelectedTemplate />}
      {loading && <TemplateLoading />}
      {loaded && (
        <Template
          items={items}
          evaluationStagesMap={previewTemplate.Stages}
          isQuestionsMode={isQuestionsMode}
        />
      )}
    </ScrollableContainer>
  );
};

export default Preview;
