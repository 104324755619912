import { EmptyResponse } from "src/schemas/http";
import { UserId } from "src/schemas/user";
import UserClientService from "src/services/userClient";
import { CreateUserClientInterface } from "src/services/userClient/types";

type PutUserClientLink = Pick<CreateUserClientInterface, "userId" | "clientId">;

export interface PutUserClientLinks
  extends Pick<CreateUserClientInterface, "clientId"> {
  userIds: UserId[];
}

/**
 * Simple wrapper around `UserClientService.put` call so that we
 * return the result of the call or throw an error. This is needed due to
 * how our APICaller methods use callbacks and can't actually return results.
 */
async function putUserClientLink({
  userId,
  clientId
}: PutUserClientLink): Promise<EmptyResponse> {
  return new Promise((resolve, reject) => {
    UserClientService.put({
      userId,
      clientId,
      callback: (response: EmptyResponse) => resolve(response),
      errorCallback: (error: Error) => reject(error)
    });
  });
}

/**
 * Promise.all wrapper around `putUserClientLink` above so that we can create
 * User-Client links for each newly added user.
 */
export async function putUserClientLinks({
  userIds,
  clientId
}: PutUserClientLinks): Promise<EmptyResponse[]> {
  const userClientPromises: Promise<EmptyResponse>[] = userIds.map(
    (userId: UserId) => putUserClientLink({ userId, clientId })
  );

  return Promise.all(userClientPromises);
}
