import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

interface EmptyStateMessageInterface {
  header: string | React.ReactNode;
  body: string | React.ReactNode;
}

export const EmptyStateMessage = ({
  header,
  body
}: EmptyStateMessageInterface): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Stack
      spacing="24px"
      sx={{
        maxWidth: "400px",
        margin: "0px auto",
        textAlign: "center",
        marginTop: "60px"
      }}
    >
      <Typography
        variant="h5"
        sx={{ color: palette.grey["700"], fontWeight: "bold" }}
      >
        {header}
      </Typography>
      <Typography variant="body1" sx={{ color: palette.grey["700"] }}>
        {body}
      </Typography>
    </Stack>
  );
};
