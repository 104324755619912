import * as React from "react";
import Button from "@mui/material/Button";
import EditRounded from "@mui/icons-material/EditRounded";
import TooltipTextBox from "src/components/Common/Tooltip/TooltipTextBox";
import DisabledActionTooltip from "src/components/Common/Tooltip/DisabledActionTooltip";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import EditModal from "../../EditModal";

const EditButtonTooltip = () => {
  const { evaluationType } = useManageEvaluationContext();
  return (
    <TooltipTextBox>
      An {evaluationType}'s details cannot be changed once the {evaluationType}{" "}
      has been completed{" "}
    </TooltipTextBox>
  );
};

const Edit = ({ disabled }: { disabled: boolean }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DisabledActionTooltip title={<EditButtonTooltip />} disabled={disabled}>
        <Button onClick={() => setOpen(true)} disabled={disabled}>
          <EditRounded sx={{ fontSize: "18px", marginRight: "6px" }} />
          Edit
        </Button>
      </DisabledActionTooltip>
      <EditModal open={open} handleClose={() => setOpen(false)} />
    </>
  );
};

export default Edit;
