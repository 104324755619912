import * as React from "react";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import Domains from "./Domains";
import Elements from "./Elements";
import Stages from "./Stages";

const Sections = (): JSX.Element => {
  const { evaluationType } = useManageEvaluationContext();
  return (
    <>
      {evaluationType == EvaluationTypes.assessment && <Stages />}
      <Domains />
      <Elements />
    </>
  );
};

export default Sections;
