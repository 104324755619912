import * as React from "react";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import {
  FilterAction,
  ToggleStageActionData
} from "src/context/ManageEvaluation/reducers/filterState/types";
import { FilterCategory } from "../types";
import List from "./Common/List";
import Section from "./Common/Section";

const Stages = (): JSX.Element => {
  const { filterState, dispatchFilterState } = useManageEvaluationContext();

  function onStageClick(item: string): void {
    const data: ToggleStageActionData = { stageName: item };
    dispatchFilterState({ type: FilterAction.toggleStage, data });
  }

  return (
    <Section category={FilterCategory.Stages}>
      <List items={filterState?.stages} onClick={onStageClick} />
    </Section>
  );
};

export default Stages;
