import * as React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Report from "../../Report";
import { ReportClass, ReportGroup } from "../../Report/types";
import { MaturityReportElementProps } from "../../Report/types/element";

import { ChartType } from "src/components/Reporting/Charts/All/MaturityStageScoresGauge/types";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { Chart } from "../../../Charts/Chart";
import { MaturityStageScoresGauge } from "../../../Charts/All";
import CalculationInfo from "./CalculationInfo";
import PromptContainer from "../../Common/PromptContainer";
import Recommendations from "../../Common/Recommendations";

const report = ({ name, data }: MaturityReportElementProps): JSX.Element => {
  const { evaluation, scoredData, recommendationsData } = data;
  return (
    <Report>
      <Report.Content name={name}>
        <Stack spacing="24px" sx={{ margin: "12px 0px 24px" }}>
          <Typography variant="body2">
            This report displays scores for each Maturity Stage and based on
            those score values, assigns an overall Maturity Stage to the Client.
          </Typography>
          <CalculationInfo />
          <Divider />
        </Stack>
        <Stack>
          <PromptContainer />
          <Chart
            data={scoredData.items ?? []}
            style={{ width: "100%", marginBottom: "32px" }}
            chart={MaturityStageScoresGauge}
            metadata={{
              chartType: ChartType.Overall
            }}
          />
        </Stack>
        <Recommendations
          evaluation={evaluation}
          recommendationsData={recommendationsData ?? []}
        />
      </Report.Content>
    </Report>
  );
};

const OverallMaturityStageScoresReport: ReportClass = class {
  static displayName = "Overall Maturity Stage Scores";
  static group: ReportGroup = ReportGroup.maturity;
  static supportedEvaluationTypes = [
    EvaluationTypes.assessment,
    EvaluationTypes.survey
  ];
  static report = report;
};

export default OverallMaturityStageScoresReport;
