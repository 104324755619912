import { plural } from "pluralize";
import { DeleteEvaluationInterface } from "./types";
import { DeleteAPICaller, DeleteAPICallRequest } from "src/services/APICall";

export default function deleteEvaluation({
  evaluationId,
  evaluationType,
  callback,
  errorCallback
}: DeleteEvaluationInterface): void {
  const url = `/${plural(evaluationType)}/${evaluationId}`;

  const request: DeleteAPICallRequest = {
    path: url,
    callback,
    errorCallback,
    withReactHook: false
  };

  return DeleteAPICaller(request);
}
