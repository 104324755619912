import { EvaluationTypes } from "src/utils/constants/evaluation";
import { ReportElement } from "./element";

/**
 * Use display category enum to visually
 * indicate what category a Report falls under
 * (e.g., to display category prefixes in Reports Dropdown)
 */
export enum ReportDisplayCategory {
  Example = "Example",
  Maturity = "Maturity"
}

/**
 * Use internal grouping enum to conditionally
 * determine which pass data into Report
 */
export enum ReportGroup {
  example = "example",
  maturity = "maturity",
  recommendations = "recommendations"
}

/**
 * Interface for exported class from each specific `Report` file
 */
export interface ReportClass {
  displayName: string;
  displayCategory?: ReportDisplayCategory;
  group: ReportGroup;
  report: ReportElement;
  supportedEvaluationTypes: EvaluationTypes[];
}
