import * as React from "react";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { sortArrayDESC } from "src/utils/sort";
import EvaluationsGridItem from "./EvaluationsGridItem";
import { getEvaluationsGridLgStyles } from "../utils/styles";

const EvaluationsGrid = (): JSX.Element => {
  const theme = useTheme();
  const evaluationTypes: EvaluationTypes[] = sortArrayDESC(
    Object.values(EvaluationTypes)
  );

  return (
    <Grid container spacing={5} sx={getEvaluationsGridLgStyles(theme)}>
      {evaluationTypes.map((evaluationType: EvaluationTypes) => (
        <EvaluationsGridItem
          key={evaluationType}
          evaluationType={evaluationType}
        />
      ))}
    </Grid>
  );
};
export default EvaluationsGrid;
