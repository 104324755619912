import * as React from "react";
import Grid from "@mui/material/Grid";
import ClientDropdown from "./Dropdowns/ClientDropdown";
import ProjectDropdown from "./Dropdowns/ProjectDropdown";
import EvaluationDropdown from "./Dropdowns/EvaluationDropdown";

const ConfigurationHeader = (): JSX.Element => {
  return (
    <Grid container>
      <Grid item xs={4}>
        <ClientDropdown />
      </Grid>
      <Grid item xs={4}>
        <ProjectDropdown />
      </Grid>
      <Grid item xs={4}>
        <EvaluationDropdown />
      </Grid>
    </Grid>
  );
};

export default ConfigurationHeader;
