import {
  ScoredResponse,
  ScoredResponsesResponse
} from "src/schemas/scoredResponse";
import { excludeBaseStageQuestion } from "../common";

/**
 * Preprocesses data returned from API to remove any bad data, such
 * as responses with a QuestionStage of 0 - recall that Stage 0 is
 * a baseline stage with no questions/responses attached to it.
 */
export default class ScoredResponseDataHandler {
  static preprocess(
    response: ScoredResponsesResponse
  ): ScoredResponsesResponse {
    return {
      ...response,
      items: response.items.filter((item: ScoredResponse) =>
        excludeBaseStageQuestion(item.QuestionStage)
      )
    };
  }
}
