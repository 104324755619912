import * as React from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Button from "@mui/material/Button";
import { RowsState } from "../types";
import { getRowIds, areAllExpanded } from "../utils";

interface ExpandAllButtonProps {
  rowsState: RowsState<any>;
  updateRowsState: (rowsState: RowsState<any>) => any;
  rowType?: any; // usually an enum
}

const ExpandAllButton = ({
  rowsState,
  updateRowsState,
  rowType = null
}: ExpandAllButtonProps) => {
  const rowIds: string[] = getRowIds(rowsState, rowType);
  const areAllRowsExpanded: boolean = areAllExpanded(rowIds, rowsState);

  function toggle(): void {
    const newRowsState: RowsState<any> = structuredClone(rowsState);
    rowIds.forEach((id: string) => {
      newRowsState[`${id}`].expanded = !areAllRowsExpanded;
    });
    updateRowsState(newRowsState);
  }

  return (
    <Button
      startIcon={
        areAllRowsExpanded ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
      }
      onClick={toggle}
    >
      {areAllRowsExpanded ? "Collapse" : "Expand"} All
    </Button>
  );
};

export default ExpandAllButton;
