import { Question } from "src/schemas/question";
import { Recommendation } from "src/schemas/recommendation";
import { RecommendationListResponse } from "src/schemas/recommendation/response";
import { ClassificationData } from "src/schemas/reporting/classification";
import { RecommendationRow } from "src/schemas/reporting/recommendations";
import { formatQuestionStageText } from "src/utils/format/questionStage";
import { excludeBaseStageQuestion } from "../common";

interface PreprocessOptions {
  stages: ClassificationData["questionStages"];
}

export default class RecommendationsDataHandler {
  private static createRecommendationMap(
    recommendations: Recommendation[]
  ): Map<Recommendation["Id"], Recommendation> {
    return new Map(
      recommendations.map((recommendation: Recommendation) => {
        return [recommendation.Id, recommendation];
      })
    );
  }

  static preprocess(
    response: RecommendationListResponse,
    options: PreprocessOptions
  ): RecommendationRow[] {
    const recommendationMap: Map<Recommendation["Id"], Recommendation> =
      RecommendationsDataHandler.createRecommendationMap(response.result);

    return response._expand.questions
      .filter((question: Question) => excludeBaseStageQuestion(question.Stage))
      .map((question: Question) => {
        const recommendation: Recommendation = recommendationMap.get(
          question.RecommendationId
        );
        const stageName: string = options.stages.get(question.Stage);
        return {
          id: question.Id, // unique id needed for MUI DataGrid component
          Question: question.Text,
          Domain: question.Domain,
          Element: question.Element,
          Stage: formatQuestionStageText(question.Stage, stageName),
          RecommendationName: recommendation.Name,
          RecommendationDescription: recommendation.Description
        };
      });
  }
}
