import * as React from "react";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import { localEvaluationReducer } from "../reducers/localEvaluation";
import { SectionsProps } from "../types";
import { shapeEvaluation } from "../utils/evaluation";
import Actions from "./Actions";
import Content from "./Content";
import Header from "./Header";

const Sections = ({ handleClose }: SectionsProps): JSX.Element => {
  const { evaluation } = useManageEvaluationContext();

  /* Creates a deep copy of `evaluation` for users to locally update as
   * they change fields in the modal form. Attaches a custom reducer
   * `evaluationReducer` to handle field updates.
   *
   * `shapeEvaluation` shapes the evaluation to fit the Edit Modal forms
   * (removes unnecessary fields like Questions, Phase, etc.)
   */
  const [localEvaluation, dispatchLocalEvaluation] = React.useReducer(
    localEvaluationReducer,
    shapeEvaluation(evaluation)
  );

  return (
    <>
      <Header />
      <Content
        evaluation={evaluation}
        localEvaluation={localEvaluation}
        dispatchLocalEvaluation={dispatchLocalEvaluation}
      />
      <Actions
        evaluation={evaluation}
        localEvaluation={localEvaluation}
        handleClose={handleClose}
      />
    </>
  );
};

export default Sections;
