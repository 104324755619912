import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PhaseInfoModal from "../../PhaseInfoModal";
import { PhaseTypesEnum } from "src/utils/constants/phase";
import TooltipTextBox from "src/components/Common/Tooltip/TooltipTextBox";

const PhaseInfoButton = ({
  currentPhase
}: {
  currentPhase: PhaseTypesEnum;
}) => {
  const [open, setOpen] = React.useState(false);
  const openModal = (e: React.SyntheticEvent, open: boolean) => {
    setOpen(open);
  };
  return (
    <>
      <Tooltip
        title={
          <TooltipTextBox>
            Click to learn even more about these <b>4 phases</b>
          </TooltipTextBox>
        }
        placement="bottom-start"
        sx={{ marginLeft: "10px" }}
      >
        <IconButton onClick={(e: React.SyntheticEvent) => openModal(e, true)}>
          <InfoOutlinedIcon sx={{ fontSize: "16px" }} />
        </IconButton>
      </Tooltip>
      <PhaseInfoModal
        open={open}
        currentPhase={currentPhase}
        handleClose={(e: React.SyntheticEvent) => openModal(e, false)}
      />
    </>
  );
};
export default PhaseInfoButton;
