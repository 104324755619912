import { FilterOptionsState } from "@mui/base/AutocompleteUnstyled";
import { CheckboxMap } from "src/components/Common/CheckboxGroup";
import { TagsConfig } from "./types";
import { sortObjectArrayASC } from "src/utils/sort";
import { OptionsConfig } from "src/components/Common/AutocompleteWithHeader/types";

export const HEADER_TAGS_LIMIT = 5;

/* Adding and populating tags to Map */
export const addTag = (tagsMap: CheckboxMap, tag: string): void => {
  if (!tagsMap.has(tag)) {
    tagsMap.set(tag, { selected: false });
  }
};

export const addTags = (tagsMap: CheckboxMap, tags: Array<string>): void => {
  for (const tag of tags) {
    addTag(tagsMap, tag);
  }
};

export const populateTagsMap = (
  tagsMap: CheckboxMap,
  data: Array<any>,
  tagsConfig: TagsConfig
): void => {
  const tagsFieldValue = tagsConfig.getField(data);
  if (tagsFieldValue instanceof Array) {
    addTags(tagsMap, tagsFieldValue);
  } else if (typeof tagsFieldValue == "string") {
    // using (tagsFieldValue instanceof String) throws an error - Argument of type 'String' is not assignable to parameter of type 'string'.
    addTag(tagsMap, tagsFieldValue);
  }
};

/* Filter logic */
const optionIncludesSearchTerm = (optionString: string, searchTerm: string) => {
  const lowerCaseOption = optionString.toLowerCase();
  const lowerCaseSearch = searchTerm.toLowerCase();
  return lowerCaseOption.indexOf(lowerCaseSearch) > -1;
};

const isTagSelected = (tagsMap: CheckboxMap, tag: string) => {
  return tagsMap.get(tag).selected;
};

const isAnyTagSelected = (tagsMap: CheckboxMap, tags: Array<string>) => {
  let anyTagSelected = false;
  for (const tag of tags) {
    anyTagSelected = tagsMap.get(tag).selected || anyTagSelected;
  }
  return anyTagSelected;
};

const checkTagsSelected = (
  tagsMap: CheckboxMap,
  option: any,
  tagsConfig: TagsConfig
) => {
  const tagsFieldValue = tagsConfig.getField(option);
  if (tagsFieldValue instanceof Array) {
    return isAnyTagSelected(tagsMap, tagsFieldValue);
  } else if (typeof tagsFieldValue == "string") {
    return isTagSelected(tagsMap, tagsFieldValue);
  }
  return false; // if any other type
};

export const customFilter = ({
  options,
  state,
  tagsMap,
  selectedTags,
  tagsConfig,
  optionsConfig
}: {
  options: Array<any>;
  state: FilterOptionsState<any>;
  tagsMap: CheckboxMap;
  selectedTags: Array<string>;
  tagsConfig: TagsConfig;
  optionsConfig: OptionsConfig;
}) => {
  const tagsApplied = selectedTags.length !== 0;
  const hasSearchTerm = state.inputValue !== "";

  let filteredOptions = [];

  if (tagsApplied) {
    // Tags Applied
    filteredOptions = options.filter((option) => {
      let showOption = checkTagsSelected(tagsMap, option, tagsConfig);
      // If search term exists, check to see if option contains search term
      if (hasSearchTerm) {
        showOption =
          showOption &&
          optionIncludesSearchTerm(
            option[optionsConfig.label],
            state.inputValue
          );
      }
      return showOption;
    });
  } else if (!tagsApplied && hasSearchTerm) {
    filteredOptions = options.filter((option) =>
      optionIncludesSearchTerm(option[optionsConfig.label], state.inputValue)
    );
  } else {
    filteredOptions = options;
  }

  // Sort filtered options in ascending order by label
  return sortObjectArrayASC(
    filteredOptions,
    (option) => option[optionsConfig.label]
  );
};
