import { plural } from "pluralize";
import {
  CreateQuestion,
  CreateQuestionRequest,
  CreateQuestionRequestSchema,
  CreateQuestionResponseSchema
} from "src/schemas/question";
import { conditionalObject } from "src/utils/object";
import { PostAPICaller, PostAPICallRequest } from "../APICall";
import { CreateEvaluationQuestionInterface } from "./types";

/**
 * Creates a new question tied to an evaluation. If a recommendationId is
 * provided, adds `RecommendationId` field to question.
 * - `POST /assessments/{assessmentId}/questions`
 * - `POST /surveys/{surveyId}/questions`
 */
export default function create({
  question,
  evaluationId,
  evaluationDateModified,
  evaluationType,
  recommendationId,
  callback,
  errorCallback
}: CreateEvaluationQuestionInterface): void {
  const url = `/${plural(evaluationType)}/${evaluationId}/questions`;

  const newQuestion: CreateQuestion = {
    ...question,
    ...conditionalObject(Boolean(recommendationId), {
      RecommendationId: recommendationId
    })
  };

  // Use request schema parse to guard against passing additional fields
  const body: CreateQuestionRequest = CreateQuestionRequestSchema.parse({
    items: [newQuestion],
    _meta: {
      DateModified: evaluationDateModified
    }
  });

  const request: PostAPICallRequest = {
    path: url,
    body: body,
    callback,
    errorCallback,
    typeValidator: CreateQuestionResponseSchema,
    withReactHook: false
  };

  return PostAPICaller(request);
}
