import { RecommendationExpandOptions } from "src/schemas/expands/recommendation";
import { RecommendationListResponse } from "src/schemas/recommendation/response";
import EvaluationRecommendationsService from "src/services/evaluationRecommendations";
import { GetEvaluationRecommendationsInterface } from "src/services/evaluationRecommendations/types";
import { EvaluationTypes } from "src/utils/constants/evaluation";

type GetEvaluationRecommendations = Pick<
  GetEvaluationRecommendationsInterface,
  "evaluationId"
>;

export async function getEvaluationRecommendations({
  evaluationId
}: GetEvaluationRecommendations): Promise<RecommendationListResponse> {
  return new Promise((resolve, reject) => {
    return EvaluationRecommendationsService.get({
      evaluationId,
      evaluationType: EvaluationTypes.assessment,
      expand: [RecommendationExpandOptions.enum.Questions],
      callback: (response: RecommendationListResponse) => resolve(response),
      errorCallback: reject
    });
  });
}
