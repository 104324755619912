import * as React from "react";
import { plural } from "pluralize";
import { capitalize } from "@mui/material";
import TooltipTextBox from "src/components/Common/Tooltip/TooltipTextBox";
import { ActiveEvaluationsTooltipTextProps } from "../types";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import Tag from "src/components/Common/Tags";
import { StyledNavLinkOnDarkTooltip } from "src/components/Common/StyledNavLink";

const ActiveEvaluationsTooltipText = ({
  evaluationType,
  evaluationArticle
}: ActiveEvaluationsTooltipTextProps) => {
  const isSurveyType: boolean = evaluationType == EvaluationTypes.survey;
  const evaluationTypePluralized: string = plural(evaluationType);

  return (
    <TooltipTextBox component="div">
      {isSurveyType && (
        <Tag size="small" label="COMING SOON" sx={{ marginBottom: "12px" }} />
      )}
      <p>
        An <b>Active</b> {evaluationType} is {evaluationArticle}{" "}
        {evaluationType} that is in the <b>Editing</b>, <b>Open</b>, or{" "}
        <b>Closed</b> phase.
      </p>
      {!isSurveyType && (
        <p>
          To view all {evaluationTypePluralized}, including <b>inactive</b>{" "}
          ones, navigate to the{" "}
          <StyledNavLinkOnDarkTooltip
            to={`/${evaluationTypePluralized}`}
            sx={{ fontWeight: "bold" }}
          >
            {capitalize(evaluationTypePluralized)}
          </StyledNavLinkOnDarkTooltip>{" "}
          page.
        </p>
      )}
    </TooltipTextBox>
  );
};
export default ActiveEvaluationsTooltipText;
