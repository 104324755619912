import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

/** Used to populate multi-line text for a Tooltip */
const TooltipTextBox = styled(Box)(() => ({
  maxWidth: "200px",
  fontSize: "14px",
  padding: "12px",
  "& p:first-of-type": {
    marginTop: "0px"
  },
  "& p:last-of-type": {
    marginBottom: "0px"
  },
  "& ul": {
    paddingLeft: "16px"
  },
  "& li": {
    marginBottom: "8px"
  }
}));

export default TooltipTextBox;
