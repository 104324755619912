import * as React from "react";
import { TypographyProps } from "@mui/material";
import Typography from "@mui/material/Typography";

interface DescriptionProps extends TypographyProps {
  forEmbeddedReport?: boolean;
}

const Description = ({
  forEmbeddedReport = false,
  ...typographyProps
}: DescriptionProps): JSX.Element => {
  const reportTypeDescription: string = forEmbeddedReport
    ? "The following table"
    : "This report";

  return (
    <Typography {...typographyProps}>
      {reportTypeDescription} provides recommendations to help the client
      strengthen areas where they lack adequate maturity.
    </Typography>
  );
};

export default Description;
