import { z } from "zod";
import { zodPositiveIntegerString } from "./common";
/* JS casts all object keys as strings, so
 * zod recommends casting key as a string, even if it's a numerical value
 * See - https://github.com/colinhacks/zod#record-key-type
 */
export const StagesSchema = z.record(zodPositiveIntegerString, z.string());

// Types
export type Stages = z.infer<typeof StagesSchema>;
