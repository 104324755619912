import * as React from "react";
import Button from "@mui/material/Button";
import AuthService from "src/services/auth";
import HeaderContainer from "../HeaderContainer";
import useAuth from "src/context/Auth";
import { useNavigate } from "react-router-dom";

const Header = (): JSX.Element => {
  const { isUserAuthenticated } = useAuth();
  const navigate = useNavigate();

  function onClick(): void {
    if (isUserAuthenticated) {
      navigate("/home");
    } else {
      AuthService.signIn();
    }
  }

  return (
    <HeaderContainer>
      <Button variant="contained" color="inverted" onClick={onClick}>
        {isUserAuthenticated ? "Go to Dashboard" : "Login"}
      </Button>
    </HeaderContainer>
  );
};

export default Header;
