import {
  CreateEvaluation,
  CreateEvaluationForm,
  CreateEvaluationSchema
} from "src/schemas/evaluation";

export class EvaluationMapper {
  public static parseCreateEvaluation({
    name,
    description,
    selectedClient,
    selectedProject,
    selectedTemplate
  }: CreateEvaluationForm): CreateEvaluation {
    return CreateEvaluationSchema.parse({
      Name: name,
      Description: description,
      ClientId: selectedClient?.Id,
      ProjectId: selectedProject?.Id,
      TemplateId: selectedTemplate?.Id
    });
  }

  public static parseCreateEvaluationSilent(
    form: CreateEvaluationForm,
    withLog = false
  ): CreateEvaluation | false {
    try {
      this.parseCreateEvaluation(form);
    } catch (error) {
      withLog && console.log(error);
      return false;
    }
  }
}
