import { Auth } from "aws-amplify";
import { LegacyProvider } from "@aws-amplify/auth/lib-esm/types";
import { ICredentials } from "@aws-amplify/core";
// Get proper CognitoUser Typescript interface (with `attributes`) - see: https://github.com/aws-amplify/amplify-js/issues/4927
import { CognitoUserInterface as CognitoUser } from "@aws-amplify/ui-components";
import { Nullable } from "src/utils/types";
import { isCognitoUser } from "./types/predicates";

export default class AuthService {
  static async getUser(): Promise<Nullable<CognitoUser>> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return isCognitoUser(user) ? user : null; // make sure user has all required fields
    } catch (err) {
      console.error("Auth.currentAuthenticatedUser() error:", err);
      return null;
    }
  }

  static async getJWT(): Promise<string> {
    try {
      return (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (err) {
      console.error("Auth.currentSession() error:", err);
      return "";
    }
  }

  static async signIn(): Promise<ICredentials> {
    /* Cannot cast `federationOptions` as `FederatedSignInOptions` b/c
     * then `provider` must be of type `CognitoHostedUIIdentityProvider` (enum).
     * Could have `provider = CognitoHostedUIIdentityProvider.Cognito`, but
     * then we have an extra Cognito screen in between our app and AD login
     */
    const federationOptions: any = {
      provider: "SlalomInternal" as LegacyProvider
    };

    try {
      return await Auth.federatedSignIn(federationOptions);
    } catch (err) {
      console.error("Auth.federatedSignIn() error:", err);
    }
  }
}
