import * as React from "react";
import { AppDispatch, useAppDispatch } from "src/store";
import {
  showError,
  showInfo,
  showSuccess
} from "src/store/slices/notification";
import SectionRow from "./layout/SectionRow";
import LabeledBox from "./layout/LabeledBox";
import Button from "@mui/material/Button";
import { SandboxClass } from "./types";

const Notifications = () => {
  const dispatch: AppDispatch = useAppDispatch();
  return (
    <>
      <SectionRow>
        <LabeledBox label="Success Notification">
          <Button
            variant="contained"
            onClick={() =>
              dispatch(
                showSuccess({
                  message: `Success Message ${new Date().getTime()}`
                })
              )
            }
          >
            Emit Success Notification
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              dispatch(
                showInfo({
                  message: `Info Notification ${new Date().getTime()}`
                })
              )
            }
          >
            Emit Info Notification
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              dispatch(
                showInfo({
                  message: `Info Notification ${new Date().getTime()}`,
                  timeout: null
                })
              )
            }
          >
            Emit Info Notification No Timeout
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              dispatch(
                showError({
                  message: `An error has occurred ${new Date().getTime()}`
                })
              )
            }
          >
            Emit Error Modal
          </Button>
        </LabeledBox>
      </SectionRow>
    </>
  );
};

const NotificationsSandbox: SandboxClass = class {
  static displayName = "Notifications";
  static element = Notifications;
};

export default NotificationsSandbox;
