import * as React from "react";
import Stack from "@mui/material/Stack";
import DynamicInput from "src/components/Common/Input/DynamicInput";
import ConditionLabel from "src/components/Common/Input/ConditionLabel";
import Label from "src/components/Common/Input/Label";

const LABEL = "Recommendation";

interface RecommendationFieldInterface {
  recommendation: any;
  dispatchRecommendation: any;
  disabled: boolean;
}
const RecommendationSection = ({
  recommendation,
  dispatchRecommendation,
  disabled
}: RecommendationFieldInterface) => {
  return (
    <Stack>
      <Stack direction="row" spacing="4px" alignItems="center">
        <Label htmlFor="recommendation">{LABEL}</Label>
        <ConditionLabel required={false} />
      </Stack>
      <Stack spacing="16px">
        <DynamicInput
          id="name"
          title="recommendation name"
          value={recommendation?.Name}
          setValue={(data: string) =>
            dispatchRecommendation({ type: "Name", data })
          }
          isSubInput={true}
          disabled={disabled}
          placeholder="Enter a name for your recommendation"
        />
        <DynamicInput
          id="description"
          title="recommendation description"
          value={recommendation?.Description}
          setValue={(data: string) =>
            dispatchRecommendation({ type: "Description", data })
          }
          multiline
          isSubInput={true}
          disabled={disabled}
          placeholder="Enter a description for your recommendation"
        />
      </Stack>
    </Stack>
  );
};

export default RecommendationSection;
