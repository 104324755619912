import * as React from "react";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import Filters from "./Filters";
import Questions from "./Questions";

export const QuestionsPanel = (): JSX.Element => {
  const { palette } = useTheme();
  const { filterState } = useManageEvaluationContext();
  // Wait for state to be loaded
  return filterState ? (
    <Stack
      direction="row"
      sx={{
        overflow: "hidden",
        width: "100%",
        height: "100%",
        borderRadius: "12px",
        border: `1px solid ${palette.primary.main}`
      }}
    >
      <Filters />
      {/* Placeholder for Questions */}
      <Questions />
    </Stack>
  ) : null;
};

export default QuestionsPanel;
