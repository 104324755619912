import { plural } from "pluralize";
import { EvaluationPatchResponseSchema } from "src/schemas/evaluation";

import { PatchAPICaller, PatchAPICallRequest } from "../APICall";
import { UpdateEvaluationInterface } from "./types";

/**
 * Updates an evaluation's flat fields
 * (e.g., certain fields like `Notes` + `Tags` under the nested `Metadata`
 * object will NOT be updated).
 * - `PATCH /assessments/{assessmentId}`
 * - `PATCH /surveys/{surveyId}`
 */
export default function update({
  evaluationType,
  evaluationId,
  updatedEvaluationFields,
  callback,
  errorCallback
}: UpdateEvaluationInterface): void {
  const path = `/${plural(evaluationType)}/${evaluationId}`;

  const request: PatchAPICallRequest = {
    path,
    body: { item: updatedEvaluationFields },
    callback,
    errorCallback,
    typeValidator: EvaluationPatchResponseSchema,
    withReactHook: false
  };

  return PatchAPICaller(request);
}
