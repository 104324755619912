import * as React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { TooltipProps } from "@mui/material";

interface DisabledActionTooltipProps extends TooltipProps {
  disabled: boolean;
}

const DisabledActionTooltip = ({
  title,
  disabled,
  children,
  ...props
}: DisabledActionTooltipProps) => {
  /* Only render tooltip if in disabled state.
   * - "" (empty string) hides tooltip
   */
  return (
    <Tooltip placement="bottom-start" title={disabled ? title : ""} {...props}>
      <Box component="span">{children}</Box>
    </Tooltip>
  );
};

export default DisabledActionTooltip;
