import * as React from "react";
import Typography from "@mui/material/Typography";
/* FAKE Data - TODO: Remove Later */
const FakeText = () => {
  return (
    <Typography variant="body1">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus egestas
      dui eu pretium ornare. Quisque a nunc varius est tristique lacinia.
      Vivamus sed tellus nulla. Nunc gravida condimentum faucibus. Proin vel
      turpis sem. Nunc lacus tortor, commodo ut fermentum quis, consectetur at
      justo. Sed fringilla blandit dui, lobortis facilisis enim ultricies sit
      amet. Sed vel dolor et lorem convallis ullamcorper. Mauris mi lacus,
      tristique eu condimentum sed, scelerisque a turpis. Proin eget augue
      vehicula, varius metus id, elementum massa. Praesent justo enim, dignissim
      convallis tincidunt quis, pharetra a lacus. Fusce nisl leo, malesuada
      congue placerat ac, condimentum in diam.
    </Typography>
  );
};

export const generateFakeText = (numParagraphs) => {
  return Array(numParagraphs)
    .fill(null)
    .map((item, index) => <FakeText key={index} />);
};

export default FakeText;
