import { getUserId } from "src/pages/ManageEvaluation/utils";
import {
  CustomEvaluationResponse,
  UpdateEvaluationForm
} from "src/schemas/evaluation";
import { LinkedUsersResponse, UserId } from "src/schemas/user";
import { difference } from "src/utils/array";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { putEvaluationUsers } from "../flat/putEvaluationUsers";
import { putUserClientLinks } from "../links/putUserClientLinks";
import { putUserProjectLinks } from "../links/putUserProjectLinks";

interface PutEvaluationUsersAndLinks {
  evaluationType: EvaluationTypes;
  evaluation: CustomEvaluationResponse;
  localEvaluation: UpdateEvaluationForm;
}

/**
 * Makes the following User update API calls:
 *
 * - `PUT /evaluations/{evaluationId}/users` to create User-Evaluation links
 *
 * - `PUT /clients/{clientId}/users/{userId}` for each newly added user to
 * create User-Client link if it doesn't exist
 *
 * - `PUT /projects/{projectId}/users/{userId}` for each newly added user to
 * create User-Project link if it doesn't exist
 */
export async function putEvaluationUsersAndLinks({
  evaluationType,
  evaluation,
  localEvaluation
}: PutEvaluationUsersAndLinks): Promise<LinkedUsersResponse> {
  // Update Users linked to Evaluation
  const updatedUsersResponse: LinkedUsersResponse = await putEvaluationUsers({
    evaluationId: localEvaluation.item.Id,
    evaluationType,
    // current User Ids (from `localEvaluation` - working copy state)
    userIds: localEvaluation._expand.users.map(getUserId)
  });

  const updatedUserIds: UserId[] = updatedUsersResponse.item;
  // User Ids that were newly added
  const addedUserIds: UserId[] = difference(
    updatedUserIds,
    // old User Ids (from `evaluation` - previous saved state)
    evaluation._expand.users.map(getUserId)
  );

  // Add new User-Client links (must happen before User-Project links)
  await putUserClientLinks({
    userIds: addedUserIds,
    clientId: localEvaluation.item.ClientId
  });

  // Add new User-Project links
  await putUserProjectLinks({
    userIds: addedUserIds,
    projectId: localEvaluation.item.ProjectId
  });

  return updatedUsersResponse;
}
