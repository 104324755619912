import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";
import SectionRow from "./layout/SectionRow";
import LabeledBox from "./layout/LabeledBox";
import { SandboxClass } from "./types";

const sizes: any = ["small", "medium", "large", "xlarge"];
const variants: any[] = ["contained", "outlined", "text"];

const Buttons = () => {
  const { palette } = useTheme();
  return (
    <>
      {variants.map((variant) => {
        return (
          <SectionRow key={variant}>
            {sizes.map((size) => {
              return (
                <LabeledBox key={size} label={size}>
                  <Button key={size} variant={variant} size={size}>
                    Variant - {variant}
                  </Button>
                </LabeledBox>
              );
            })}
          </SectionRow>
        );
      })}
      {/* Color - Inverted (e.g., on app header background) */}
      <Stack
        sx={{
          backgroundColor: palette.primary.main,
          padding: "36px",
          borderRadius: "12px"
        }}
      >
        {variants.map((variant) => {
          return (
            <SectionRow key={variant}>
              {sizes.map((size) => {
                return (
                  <LabeledBox
                    key={size}
                    label={size}
                    labelColor={palette.common.white}
                  >
                    <Button
                      color="inverted"
                      key={size}
                      variant={variant}
                      size={size}
                    >
                      Variant - {variant}
                    </Button>
                  </LabeledBox>
                );
              })}
            </SectionRow>
          );
        })}
      </Stack>
    </>
  );
};

const ButtonsSandbox: SandboxClass = class {
  static displayName = "Buttons";
  static element = Buttons;
};

export default ButtonsSandbox;
