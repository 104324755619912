import { z } from "zod";
import { ActiveStatesSchema, PhaseSchema, zodUUID } from "./common";
import { MetadataSchema } from "./metadata";
import { ClientSchema, RecordOfClientsSchema } from "./client";
import { EvaluationTemplateSchema } from "./evaluationTemplate";
import {
  EvaluationTemplateSummarySchema,
  RecordOfEvaluationTemplateSummariesSchema
} from "./evaluationTemplateSummary";
import { ProjectSchema, RecordOfProjectsSchema } from "./project";
import { EvaluationSummariesExpandOptions } from "./expands/evaluationSummaries";

export const EvaluationSummarySchema = z.object({
  Id: zodUUID,
  Name: z.string(),
  Description: z.string(),
  ClientId: ClientSchema.shape.Id,
  ProjectId: ProjectSchema.shape.Id,
  TemplateId: EvaluationTemplateSchema.shape.Id,
  ActiveState: ActiveStatesSchema,
  Phase: PhaseSchema,
  Metadata: MetadataSchema
});

export const EvaluationSummariesExpandSchema = z.object({
  [EvaluationSummariesExpandOptions.enum.Clients]:
    RecordOfClientsSchema.optional(),
  [EvaluationSummariesExpandOptions.enum.Projects]:
    RecordOfProjectsSchema.optional(),
  [EvaluationSummariesExpandOptions.enum.Templates]:
    RecordOfEvaluationTemplateSummariesSchema.optional()
});

export const EvaluationSummaryListSchema = EvaluationSummarySchema.array();

export const EvaluationSummaryListResponseSchema = z.object({
  item: EvaluationSummaryListSchema,
  _expand: EvaluationSummariesExpandSchema.optional()
});

// Data Grid schemas
export const DataGridEvaluationSummaryListSchema =
  EvaluationSummaryListResponseSchema.transform(
    (response: EvaluationSummaryListResponse) => {
      const { clients, projects, templates } = response._expand;
      return response.item.map((evaluation: EvaluationSummary) => {
        return {
          id: `${evaluation.Id}`, // needed field for Data Grid
          Name: evaluation.Name,
          Description: evaluation.Description,
          Phase: evaluation.Phase,
          Client: clients?.[`${evaluation.ClientId}`]?.Name,
          Project: projects?.[`${evaluation.ProjectId}`]?.Name,
          Template: templates?.[`${evaluation.TemplateId}`]?.Name,
          DateCreated: evaluation?.Metadata?.DateCreated,
          DateModified: evaluation?.Metadata?.DateModified
        };
      });
    }
  );

// An evaluation merged with a template expand call
const EvaluationSummaryWithTemplateExpandSchema = EvaluationSummarySchema.merge(
  z.object({
    Template: EvaluationTemplateSummarySchema
  })
);

// Types

export type EvaluationSummary = z.infer<typeof EvaluationSummarySchema>;
export type EvaluationSummaryList = z.infer<typeof EvaluationSummaryListSchema>;
export type EvaluationSummaryListResponse = z.infer<
  typeof EvaluationSummaryListResponseSchema
>;
export type EvaluationSummariesExpand = z.infer<
  typeof EvaluationSummariesExpandSchema
>;
export type EvaluationSummaryWithTemplateExpand = z.infer<
  typeof EvaluationSummaryWithTemplateExpandSchema
>;

export type DataGridEvaluationSummaryList = z.infer<
  typeof DataGridEvaluationSummaryListSchema
>;
