import * as React from "react";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import {
  FilterAction,
  ToggleElementActionData
} from "src/context/ManageEvaluation/reducers/filterState/types";
import { FilterCategory } from "../types";
import List from "./Common/List";
import Section from "./Common/Section";

const Elements = (): JSX.Element => {
  const { palette } = useTheme();
  const { filterState, dispatchFilterState } = useManageEvaluationContext();

  function onElementClick(domainName: string, elementName: string): void {
    const data: ToggleElementActionData = { domainName, elementName };
    dispatchFilterState({ type: FilterAction.toggleElement, data });
  }

  return (
    <Section category={FilterCategory.Elements}>
      {Object.keys(filterState.domains)
        .sort()
        .map((domain: string) => {
          return (
            <Box key={domain}>
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  padding: "6px 12px",
                  margin: "8px 0 4px",
                  backgroundColor: palette.primary.lighter,
                  color: palette.primary.darker,
                  borderBottom: `1px solid ${palette.primary.light}`
                }}
              >
                {domain}
              </Typography>
              <List
                items={filterState?.domains?.[`${domain}`]?.elements}
                onClick={(item: string) => onElementClick(domain, item)}
              />
            </Box>
          );
        })}
    </Section>
  );
};

export default Elements;
