import * as React from "react";
import Typography from "@mui/material/Typography";
import ModalSection from "src/components/Common/Modal/Section";
import BaseModal from "../../../Common/Calculation/InfoModal";
import { CalculationInfoModalProps } from "../../../Common/Calculation/types";
import CalculationDefinition from "./Definition";

const CalculationInfoModal = (
  props: CalculationInfoModalProps
): JSX.Element => {
  return (
    <BaseModal {...props}>
      <CalculationDefinition />
      <ModalSection name="Example">
        <Typography variant="body2">
          If an evaluation has 20 questions, each with a max answer score value
          of 4, then the sum of all most-mature answer score values
          (denominator) would be 80.
        </Typography>
        <Typography variant="body2">
          Let's say the sum of all response score values (numerator) was 60.
          Then the evaluation's{" "}
          <b>"raw" score would be 60 / 80 points, or 75%</b>.
        </Typography>
      </ModalSection>
    </BaseModal>
  );
};

export default CalculationInfoModal;
