import * as am5 from "@amcharts/amcharts5";
import AnimatedTheme from "@amcharts/amcharts5/themes/Animated";
import ResponsiveTheme from "@amcharts/amcharts5/themes/Responsive";
import { DefaultTheme } from "@amcharts/amcharts5/.internal/themes/DefaultTheme";
import BaseTheme from "../../themes/BaseTheme";

interface GetDefaultThemesConfiguration {
  includeProvidedDefault: boolean;
}

/**
 * Get default themes for our charts.
 *
 * **Usage**
 *
 * If `includeProvidedDefault` in options is true, we explicitly add in the
 * internal `DefaultTheme`.
 *
 * - This is NOT needed when setting themes on root
 * (since root already includes DefaultTheme)
 *
 * - This is needed when setting themes on an am5 ColorSet
 * (since am5 ColorSets do NOT already include DefaultTheme)
 */
export function getDefaultThemes(
  root: am5.Root,
  options: GetDefaultThemesConfiguration = { includeProvidedDefault: false }
): am5.Theme[] {
  const defaultThemes: am5.Theme[] = options.includeProvidedDefault
    ? [DefaultTheme.new(root)]
    : [];
  return [
    ...defaultThemes,
    AnimatedTheme.new(root),
    ResponsiveTheme.new(root),
    BaseTheme.new(root)
  ];
}
