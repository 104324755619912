import { capitalize } from "@mui/material";
import {
  CustomEvaluationResponse,
  UpdateEvaluationForm
} from "src/schemas/evaluation";
import { LinkedUsersResponse, User, UserId } from "src/schemas/user";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { IsStateDirty } from "../../types";
import updateEvaluation from "../evaluation/update";
import updateUsers from "../users/update";

/**
 * Key definitions for all the different update API calls we're making
 */
enum UpdateKey {
  evaluation = "evaluation", // Flat + nested fields for evaluation
  users = "users" // User-Evaluation links
}

interface ServiceHandlerParams {
  evaluationType: EvaluationTypes;
  evaluation: CustomEvaluationResponse;
  localEvaluation: UpdateEvaluationForm;
  isStateDirty: IsStateDirty;
}

class ServiceHandler {
  evaluationType: EvaluationTypes;
  evaluation: CustomEvaluationResponse;
  localEvaluation: UpdateEvaluationForm;
  isStateDirty: IsStateDirty;

  /**
   * Mapping of keys (evaluation, users) to promises
   */
  updateKeyToPromiseMap: Map<UpdateKey, Promise<any>>;

  /**
   * Mapping of indexes to keys (evaluation, users) based on `promisesMap`
   * to help us know which promise response from Promise.allSettled to
   * associate with which `process` function
   */
  promiseIndexToUpdateKeyMap: Map<number, UpdateKey>;

  /** Aggregated list of errors from results of Promise.allSettled */
  errors: string[] = [];

  constructor({
    evaluationType,
    evaluation,
    localEvaluation,
    isStateDirty
  }: ServiceHandlerParams) {
    this.evaluationType = evaluationType;
    this.evaluation = evaluation;
    this.localEvaluation = localEvaluation;
    this.isStateDirty = isStateDirty;

    this.updateKeyToPromiseMap = this.createPromiseMap();
    this.promiseIndexToUpdateKeyMap = this.createUpdateKeyMap(
      this.updateKeyToPromiseMap
    );
  }

  /**
   * Calls all asynchronous update API calls:
   * - `updateEvaluation` - updates flat + nested fields for evaluation
   * - `updateUsers` - updates User-Evaluation links + User-Client and
   * User-Project links for newly added users
   *
   * Processes the responses and ulimately
   * - overrides `localEvaluation` based on responses
   * - populates errors if any exist
   */
  async update(): Promise<void> {
    const responses: PromiseSettledResult<any>[] = await Promise.allSettled([
      ...this.updateKeyToPromiseMap.values()
    ]);
    return this.process(responses);
  }

  /** Getter for `errors` array */
  getErrors(): string[] {
    return this.errors;
  }

  /** Getter for updated, overridden local evaluation */
  getUpdatedEvaluation(): UpdateEvaluationForm {
    return this.localEvaluation;
  }

  /**
   * Creates a mapping between update keys (evaluation, users) and
   * the corresponding promises. This helps us understand which returned promise
   * in the Promise.allSettled call corresponds to which update action.
   *
   * Note: this is set up in a way where the number of Promises we create is
   * static, regardless of whether the data has changed for that
   * particular Promise call - this is important for us to get the right index
   * associated with the update action.
   *
   * However, within the actual `update` methods, there is a conditional logic
   * on `isStateDirty` to actually make the underlying API call, otherwise the
   * Promise returns the old state of the data.
   * */
  private createPromiseMap(): Map<UpdateKey, Promise<any>> {
    const promisesMap: Map<UpdateKey, Promise<any>> = new Map();
    promisesMap.set(
      UpdateKey.evaluation,
      updateEvaluation({
        evaluationType: this.evaluationType,
        localEvaluation: this.localEvaluation,
        isStateDirty: this.isStateDirty
      })
    );
    promisesMap.set(
      UpdateKey.users,
      updateUsers({
        evaluationType: this.evaluationType,
        localEvaluation: this.localEvaluation,
        evaluation: this.evaluation,
        isStateDirty: this.isStateDirty
      })
    );
    return promisesMap;
  }

  /**
   * Based on the provided `promisesMap`, creates an index to update key
   * map so we know which Promise response corresponds to which update action.
   */
  private createUpdateKeyMap(
    promisesMap: Map<UpdateKey, Promise<any>>
  ): Map<number, UpdateKey> {
    return new Map(
      [...promisesMap.keys()].map((key: UpdateKey, index: number) => [
        index,
        key
      ])
    );
  }

  /**
   * Helper method for processing the responses from the Promise.allSettled
   * update call. Based on the response index, utilizes the index to update key
   * map to call the proper `processX` method, where X is the entity to be
   * updated (evaluation, users).
   *
   * Ultimately, during response processing,
   * - overrides `localEvaluation`
   * - populates errors if any exist
   */
  private process(responses: PromiseSettledResult<any>[]): void {
    responses.forEach((response: PromiseSettledResult<any>, index: number) => {
      if (response.status === "rejected") {
        this.errors.push(response.reason.toString());
      }

      if (response.status === "fulfilled") {
        const key: UpdateKey = this.promiseIndexToUpdateKeyMap.get(index);
        this[`process${capitalize(key)}`](response.value);
      }
    });
  }

  /**
   * Helper function for processing the response from the `updateEvaluation`
   * call, which updates both flat + nested fields for evaluation.
   *
   * Note - this response contains all of the updates for
   * flat + nested fields for evaluation because for fields attached to
   * evaluation, calls must happen synchronously due to conflict checking logic.
   */
  private processEvaluation(responseValue: UpdateEvaluationForm): void {
    this.localEvaluation = responseValue;
  }

  /**
   * Helper function for processing the response from the `updateUsers`
   * call, which updates the evaluation `item._expand.Users` field
   */
  private processUsers(responseValue: LinkedUsersResponse["item"]) {
    /* We need the full array of Users to add to the
     * `evaluation._expand.Users` field, so we take our local `Users` state
     * and check it against the user Ids returned to us from the BE
     * API call to update Evaluation Users
     */
    const userIdSet: Set<UserId> = new Set(responseValue);
    this.localEvaluation._expand.users =
      this.localEvaluation._expand.users.filter((user: User) =>
        userIdSet.has(user.Id)
      );
  }
}

export default ServiceHandler;
