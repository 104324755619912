import { z } from "zod";
import { zodUUID } from "./common";
import { MetadataSchema } from "./metadata";

export const EvaluationTemplateSummarySchema = z.object({
  Id: zodUUID,
  Name: z.string(),
  Description: z.string(),
  Metadata: MetadataSchema
});

export const EvaluationTemplateSummaryListSchema =
  EvaluationTemplateSummarySchema.array();

export const EvaluationTemplateSummaryListResponseSchema = z.object({
  result: EvaluationTemplateSummaryListSchema
});

export const RecordOfEvaluationTemplateSummariesSchema = z.record(
  EvaluationTemplateSummarySchema.shape.Id,
  EvaluationTemplateSummarySchema
);

// Types

export type EvaluationTemplateSummary = z.infer<
  typeof EvaluationTemplateSummarySchema
>;

export type EvaluationTemplateSummaryList = z.infer<
  typeof EvaluationTemplateSummaryListSchema
>;
export type EvaluationTemplateSummaryListResponse = z.infer<
  typeof EvaluationTemplateSummaryListResponseSchema
>;

export type RecordOfEvaluationTemplateSummaries = z.infer<
  typeof RecordOfEvaluationTemplateSummariesSchema
>;
