import * as React from "react";
import {
  MaturityStageScoresGauge,
  MaturityStageScoresHeatmap
} from "src/components/Reporting/Charts/All";
import {
  ChartType,
  MaturityStageScoresGaugeChartInterface
} from "src/components/Reporting/Charts/All/MaturityStageScoresGauge/types";
import { MaturityStageScoresHeatmapChartInterface } from "src/components/Reporting/Charts/All/MaturityStageScoresHeatmap/types";
import { ScoredResponse } from "src/schemas/scoredResponse";
import { Chart as BaseChart } from "../../../Charts/Chart";
import { SUMMARY_VIEW_KEY } from "./constants";

interface ChartProps {
  data: ScoredResponse[];
  domains: string[];
  questionStages: string[];
  selectedDomain: string;
  selectedDomainIndex: number;
  getRoot: (root: any) => any;
}

/**
 * This component switches between the following charts based on the value of
 * `selectedDomain`:
 * - Summary (MaturityStageScoresHeatmap, which features all Domains)
 * - Detailed (MaturityStageScoresGauge, which features 1 selected Domain)
 */
const Chart = ({
  data,
  domains,
  questionStages,
  selectedDomain,
  selectedDomainIndex,
  getRoot
}: ChartProps): JSX.Element => {
  const isSummaryView: boolean = selectedDomain == SUMMARY_VIEW_KEY;

  const summaryChartMetadata: MaturityStageScoresHeatmapChartInterface["metadata"] =
    {
      domains,
      questionStages
    };
  const detailedChartMetadata: MaturityStageScoresGaugeChartInterface["metadata"] =
    {
      domain: selectedDomain,
      domainIndex: selectedDomainIndex,
      chartType: ChartType.Domain
    };

  return (
    <BaseChart
      style={{ width: "100%", marginBottom: "32px" }}
      data={data}
      chart={
        isSummaryView ? MaturityStageScoresHeatmap : MaturityStageScoresGauge
      }
      metadata={isSummaryView ? summaryChartMetadata : detailedChartMetadata}
      getRoot={getRoot}
    />
  );
};

export default Chart;
