import {
  GridCsvGetRowsToExportParams,
  gridSortedRowIdsSelector,
  GridRowId,
  GridToolbarExportProps
} from "@mui/x-data-grid";

/**
 * Returns all rows for CSV export
 * - Applies **Sorting** rules
 * - Does NOT apply **Filter** rules
 */
export function getSortedUnfilteredRows({
  apiRef
}: GridCsvGetRowsToExportParams): GridRowId[] {
  return gridSortedRowIdsSelector(apiRef);
}

/**
 * Generates the following props for the MUI GridToolbarExport component:
 *
 * - `csvOptions.fileName` - CSV file name
 *
 * - `csvOptions.getRowsToExport` - passes in the `getSortedUnfilteredRows`
 * helper function to always display all rows (even if filtered in UI) in
 * sorted order in the exported CSV file
 */
export function makeToolbarExportProps(
  fileName: string
): GridToolbarExportProps {
  return {
    csvOptions: {
      fileName,
      getRowsToExport: getSortedUnfilteredRows
    }
  };
}
