import * as React from "react";
import { InfoProps } from "../types";
import ActiveEvaluationsTooltip from "./ActiveEvaluationsTooltip";
import Title from "./Title";

const Info = ({ evaluationType }: InfoProps): JSX.Element => {
  return (
    <>
      <Title evaluationType={evaluationType} />
      <ActiveEvaluationsTooltip evaluationType={evaluationType} />
    </>
  );
};
export default Info;
