import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import ErrorMessage from "src/components/Common/ErrorMessage";
import Input from "src/components/Common/Input";
import {
  Client as ClientType,
  ClientListResponse,
  ClientListResponseSchema
} from "src/schemas/client";
import { GetAPICaller } from "src/services/APICall";
import {
  AppDispatch,
  AppState,
  useAppSelector,
  useAppDispatch
} from "src/store";
import { setSelectedClient } from "src/store/slices/createEvaluation";
import { showError } from "src/store/slices/notification";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { Collection } from "src/utils/constants/collection";
import { Nullable } from "src/utils/types";
import AddItemLabel from "./AddItemLabel";
import { ClientProps } from "./types";

const Client = ({ setModalOpen }: ClientProps): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  const evaluationType: EvaluationTypes = useAppSelector(
    (state: AppState) => state.createEvaluation.evaluationType
  );
  const selectedClient: Nullable<ClientType> = useAppSelector(
    (state: AppState) => state.createEvaluation.selectedClient
  );

  const [clients, setClients] = React.useState<ClientType[]>(null);
  const placeholderMessage = `Select client to associate ${evaluationType} with`;

  function successCallback(response: ClientListResponse): void {
    setClients(response.result);
  }

  function errorCallback(error: Error): void {
    setClients([]);
    dispatch(
      showError({
        message: <ErrorMessage error={error} collection={Collection.client} />
      })
    );
  }

  GetAPICaller({
    path: "/clients",
    callback: successCallback,
    errorCallback,
    typeValidator: ClientListResponseSchema
  });

  function onChange(
    _event: React.SyntheticEvent,
    newSelectedClient: Nullable<ClientType>
  ): void {
    dispatch(setSelectedClient(newSelectedClient));
  }

  return (
    <Stack>
      <AddItemLabel id="client" label="Client" setModalOpen={setModalOpen} />

      <Autocomplete
        id="client"
        loading={clients === null}
        options={clients ? clients : []}
        getOptionLabel={(value: ClientType) => value.Name}
        isOptionEqualToValue={(option: ClientType) =>
          option.Id === selectedClient.Id
        }
        value={selectedClient}
        onChange={onChange}
        renderInput={(params: object) => (
          <Input {...params} placeholder={placeholderMessage} />
        )}
      />
    </Stack>
  );
};
export default Client;
