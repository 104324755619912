import { EvaluationTypesParamSchema } from "src/schemas/evaluation";
import { ScoredResponsesResponse } from "src/schemas/scoredResponse";
import ScoreService from "src/services/score";
import { GetScoreInterface } from "src/services/score/types";

type GetScore = Pick<GetScoreInterface, "evaluationIds">;

export async function getScore({
  evaluationIds
}: GetScore): Promise<ScoredResponsesResponse> {
  return new Promise((resolve, reject) => {
    return ScoreService.get({
      evaluationIds,
      evaluationType: EvaluationTypesParamSchema.enum.assessment,
      callback: (response: ScoredResponsesResponse) => resolve(response),
      errorCallback: reject
    });
  });
}
