import * as React from "react";
import { capitalize } from "@mui/material";
import ActionButton from "src/components/Common/ActionButton";
import ErrorMessage from "src/components/Common/ErrorMessage";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError, showSuccess } from "src/store/slices/notification";
import { Question } from "src/schemas/question";
import { UpdateEvaluationAction } from "src/context/ManageEvaluation/reducers/evaluation/types";
import { ActionTenses, SubmitButtonProps } from "../../types";
import { CreateRecommendationSchema } from "src/schemas/recommendation";
import { getActionTenses, getDisabledTooltipText } from "../../utils/text";
import { Metadata } from "src/schemas/metadata";
import { postQuestion } from "../../services/postQuestion";
import { patchQuestion } from "../../services/patchQuestion";
import { postRecommendationAndQuestion } from "../../services/postRecommendationAndQuestion";
import { SuccessCallback } from "../../services/types";
import { CRUDEvaluationQuestionInterface } from "src/services/evaluationQuestion/types";

const SubmitButton = ({
  isEditing,
  handleClose,
  question,
  recommendation,
  isFormDirty,
  isFormValid,
  disabled,
  setDisabled
}: SubmitButtonProps): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  const { evaluation, dispatchEvaluation, evaluationType } =
    useManageEvaluationContext();

  // Disabled case conditions
  const isFormInvalid = !isFormValid;
  const isEditingFormClean: boolean = isEditing && !isFormDirty;

  /* All cases where button is disabled - using `_` prefix to avoid
   * name conflicts with incoming `disabled` prop
   */
  const _disabled: boolean = isFormInvalid || isEditingFormClean || disabled;

  // Text
  const actionTenses: ActionTenses = getActionTenses(isEditing);
  const tooltipText: string = getDisabledTooltipText(
    isFormInvalid,
    isEditingFormClean
  );

  // Loading state
  const [loading, setLoading] = React.useState<boolean>(false);

  function setLoadingStates(loading: boolean) {
    setLoading(loading);
    setDisabled(loading);
  }

  // Need to use arrow function here to properly link up function type
  const successCallback: SuccessCallback = (
    dispatchType: UpdateEvaluationAction,
    data: Question,
    meta: Metadata
  ): void => {
    dispatchEvaluation({ type: dispatchType, data, meta });
    setLoadingStates(false);
    // Close the modal
    handleClose();
    dispatch(
      showSuccess({
        message: `Your question was successfully ${actionTenses.pastSimple}.`
      })
    );
  };

  function errorCallback(error: Error) {
    setLoadingStates(false);
    handleClose();
    dispatch(
      showError({
        message: (
          <ErrorMessage
            error={error}
            action={`${actionTenses.presentContinuous} your question`}
          />
        )
      })
    );
  }

  async function handleSubmit(): Promise<void> {
    setLoadingStates(true);

    // Common fields for CRUD actions on Questions
    const questionCRUDFields: CRUDEvaluationQuestionInterface = {
      evaluationId: evaluation.item.Id,
      evaluationType,
      evaluationDateModified: evaluation.item.Metadata.DateModified,
      errorCallback
    };

    if (isEditing) {
      // Edit question
      return patchQuestion({ question, questionCRUDFields, successCallback });
    } else {
      // Add question
      const validRecommendation: boolean =
        CreateRecommendationSchema.safeParse(recommendation).success;

      return validRecommendation
        ? postRecommendationAndQuestion({
            question,
            questionCRUDFields,
            recommendation,
            successCallback,
            errorCallback
          })
        : postQuestion({ question, questionCRUDFields, successCallback });
    }
  }

  return (
    <ActionButton
      tooltipLoadingText={`We're ${actionTenses.presentContinuous} your question - hold on tight`}
      tooltipDisabledText={tooltipText}
      TooltipProps={{
        placement: "top-end"
      }}
      onClick={handleSubmit}
      loading={loading}
      disabled={_disabled}
    >
      {capitalize(
        loading ? actionTenses.presentContinuous : actionTenses.presentSimple
      )}
    </ActionButton>
  );
};

export default SubmitButton;
