import { z } from "zod";
import { zodUUID } from "./common";
import { MetadataSchema } from "./metadata";

export const ClientSchema = z.object({
  Id: zodUUID,
  Name: z.string().nullable().optional(), // null | undefined
  Description: z.string().nullable().optional(),
  SFDCId: z.string().nullable().optional(),
  Metadata: MetadataSchema.nullable().optional(),
  Market: z.string().nullable().optional()
});

export const ClientListSchema = ClientSchema.array();

export const RecordOfClientsSchema = z.record(
  ClientSchema.shape.Id,
  ClientSchema
);

// API Response objects
export const ClientResponseSchema = z.object({
  result: ClientSchema
});

export const ClientListResponseSchema = z.object({
  result: ClientListSchema
});

// Types
export type Client = z.infer<typeof ClientSchema>;
export type ClientResponse = z.infer<typeof ClientResponseSchema>;

export type ClientList = z.infer<typeof ClientListSchema>;
export type ClientListResponse = z.infer<typeof ClientListResponseSchema>;

export type RecordOfClients = z.infer<typeof RecordOfClientsSchema>;
