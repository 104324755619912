import * as React from "react";
import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { RecommendationsReportData } from "../../Report/types/data";
import RecommendationCount from "./DataTableOverview/RecommendationCount";
import Description from "./Description";
import CalculationInfo from "./CalculationInfo";
import Prompt from "./DataTableOverview/Prompt";
import DataTable from "./DataTable";

interface RecommendationsProps extends RecommendationsReportData {
  showDivider?: boolean;
  sx?: SxProps;
}

/**
 * A drop-in, embeddable Recommendations report component. Meant to be used
 * as a subsection of other reports.
 *
 * Features the following:
 * - # of Recommendations header
 * - Report description & Calculation information
 * - Recommendations Data Table
 */
const Recommendations = ({
  evaluation,
  recommendationsData,
  showDivider = true,
  sx = {}
}: RecommendationsProps): JSX.Element => {
  return (
    <Box sx={sx}>
      {showDivider && <Divider sx={{ marginTop: "24px" }} />}
      <RecommendationCount
        count={recommendationsData.length}
        sx={{ marginTop: "24px" }}
      />
      <Box sx={{ margin: "10px 0px" }}>
        <Description
          forEmbeddedReport={true}
          variant="body2"
          sx={{ display: "inline" }}
        />{" "}
        <CalculationInfo showTitle={false} sx={{ display: "inline" }} />
        <Prompt sx={{ marginTop: "8px" }} />
      </Box>
      <DataTable evaluationName={evaluation?.Name} rows={recommendationsData} />
    </Box>
  );
};

export default Recommendations;
