import Typography from "@mui/material/Typography";
import * as React from "react";
import ConditionalWrapper from "src/components/Common/ConditionalWrapper";

interface IntroProps {
  domain?: string;
  stagesCount: number;
}

const Intro = ({ domain, stagesCount }: IntroProps): JSX.Element => {
  return (
    <Typography variant="body2">
      Let's say an example assessment has {stagesCount} Stages with the
      following values{" "}
      <ConditionalWrapper condition={Boolean(domain)}>
        <>
          for a Domain called <b>{domain}</b>
        </>
      </ConditionalWrapper>
      :
    </Typography>
  );
};

export default Intro;
