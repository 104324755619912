import * as React from "react";
import Button from "@mui/material/Button";
import Modal from "src/components/Common/Modal";
import { ActionsProps } from "./types";

const Actions = ({ handleClose }: ActionsProps): JSX.Element => {
  return (
    <Modal.Actions
      sx={{
        justifyContent: "center",
        padding: "16px"
      }}
    >
      <Button variant="contained" size="large" onClick={handleClose}>
        Close
      </Button>
    </Modal.Actions>
  );
};
export default Actions;
