import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { LinkableContent } from "src/schemas/evaluation";
import { User } from "src/schemas/user";
import useAuth from "src/context/Auth";
import { sortUsers } from "../utils";
import Tag from "src/components/Common/Tags";

const YouTag = styled(Tag)(() => ({
  "& .MuiChip-label": {
    padding: "0px 6px"
  }
}));

const UserList = ({ users }: { users: LinkableContent[] }) => {
  const { user } = useAuth();
  const myUserId: string = user.attributes.email;
  const sortedUsers: User[] = sortUsers(users, myUserId);

  return (
    <>
      {sortedUsers.map((user: LinkableContent) => (
        <Box
          key={user.Id}
          sx={{ position: "relative", marginBottom: "4px", paddingLeft: "8px" }}
        >
          <li>
            {user.Name ?? user.Id}
            {myUserId == user.Id && (
              <YouTag
                label="YOU"
                size="small"
                sx={{
                  position: "absolute",
                  fontSize: "10px",
                  marginLeft: "8px"
                }}
              />
            )}
          </li>
        </Box>
      ))}
    </>
  );
};

export default UserList;
