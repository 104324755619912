import { DOUBLE_NEWLINE, EMAIL } from "src/utils/email";
import { ContactReason } from "../types";

function createMailToBody(body: string): string {
  return `Hi DART team,${DOUBLE_NEWLINE}${body}`;
}

export function createMailToLink(reason: ContactReason): string {
  const body: string = createMailToBody(reason.body);
  return `mailto:${EMAIL}?subject=${reason.subject}&body=${body}`;
}
