import * as React from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AppState, useAppSelector } from "src/store";
import {
  EvaluationArticles,
  EvaluationTypes
} from "src/utils/constants/evaluation";

const Header = (): JSX.Element => {
  const evaluationType = useAppSelector(
    (state: AppState) => state.createEvaluation.evaluationType
  );

  // Typing key as string (returned type of EvaluationTypes) removes Eslint Object Injection Sink error
  const evaluationArticle =
    EvaluationArticles[evaluationType as EvaluationTypes];

  return (
    <Stack spacing="28px">
      <Typography variant="h4" sx={{ marginTop: "12px" }}>
        Create
        <Box component="span" sx={{ textTransform: "capitalize" }}>
          {" "}
          {evaluationType}{" "}
        </Box>
        from Template
      </Typography>
      <Alert severity="info">
        <b>
          All {evaluationType}s need to be created from {evaluationArticle}{" "}
          {evaluationType} template first.
        </b>{" "}
        You will be able to customize your {evaluationType} (edit questions, add
        notes, add tags, etc.) in the next step.
      </Alert>
    </Stack>
  );
};

export default Header;
