import { styled } from "@mui/material/styles";
import Input from ".";

/**
 * This is a common, reusable Text Field component **that should be used on filled (non-white) backgrounds**.
 *
 * **Usage**: This component should typically be used for
 * - basic inputs
 * - inputs passed into a MUI Autocomplete component
 * - etc.
 */
const InvertedInput = styled(Input)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.common.white
  },
  "& .MuiInputBase-root:hover": {
    backgroundColor: theme.palette.common.white
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.common.white
  }
}));

export default InvertedInput;
