import * as React from "react";
import Stack from "@mui/material/Stack";
import Label from "src/components/Common/Input/Label";
import { AddItemLabelProps } from "./types";
import AddItemButton from "./AddItemButton";

const AddItemLabel = ({
  id,
  label,
  setModalOpen
}: AddItemLabelProps): JSX.Element => {
  return (
    <Stack
      direction="row"
      spacing="12px"
      alignItems="center"
      sx={{ marginBottom: "12px" }}
    >
      <Label htmlFor={id} sx={{ marginBottom: "0px" }}>
        {label}
      </Label>
      <AddItemButton setModalOpen={setModalOpen} />
    </Stack>
  );
};
export default AddItemLabel;
