import * as React from "react";
import { useTheme } from "@mui/material";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Tag from "src/components/Common/Tags";
import { formatLongDateAndShortTime } from "src/utils/formatDate";
import { DateMetadataProps } from "./types";
import { AppState, useAppSelector } from "src/store";
import { EvaluationTemplateSummary } from "src/schemas/evaluationTemplateSummary";

// Icon should be MUI Icon Component
const DateMetadata = ({ Icon, children }: DateMetadataProps): JSX.Element => {
  const { palette } = useTheme();
  return (
    <Stack direction="row" spacing="6px" alignItems="center">
      <Icon sx={{ color: palette.primary.main, fontSize: "16px" }} />
      <Typography variant="body1" sx={{ fontSize: "14px" }}>
        {children}
      </Typography>
    </Stack>
  );
};

const TemplateMetadata = (): JSX.Element => {
  const { palette } = useTheme();
  const selectedTemplate: EvaluationTemplateSummary = useAppSelector(
    (state: AppState) => state.createEvaluation.selectedTemplate
  );
  return (
    <Stack
      spacing="4px"
      sx={{ margin: "12px 0px 0px 4px", color: palette.grey["500"] }}
    >
      <Stack
        direction="row"
        spacing="8px"
        sx={{ marginBottom: "6px" }}
        flexWrap="wrap"
      >
        {selectedTemplate.Metadata.Tags.map((tag: string) => (
          <Tag size="small" key={tag} label={tag} />
        ))}
      </Stack>
      <DateMetadata Icon={AccessTimeRoundedIcon}>
        Created on{" "}
        {formatLongDateAndShortTime(
          selectedTemplate.Metadata.DateCreated,
          "at"
        )}
      </DateMetadata>
      <DateMetadata Icon={EditRoundedIcon}>
        <i>Last Modified</i> on{" "}
        {formatLongDateAndShortTime(
          selectedTemplate.Metadata.DateModified,
          "at"
        )}
      </DateMetadata>
    </Stack>
  );
};

export default TemplateMetadata;
