import * as React from "react";
import QuestionAccordion from "..";
import { QuestionAccordionParams } from "../../types";
import BasicQuestionDetails from "./Details";

/**
 * A simple question accordion component. Use in cases where
 * - we have no actions in the accordion header and
 * - we have a static list of question answers in the accordion body
 */
const BasicQuestion = ({
  item,
  index,
  evaluationType,
  stageName,
  expanded,
  handleClick
}: QuestionAccordionParams) => {
  return (
    <QuestionAccordion item={item} expanded={expanded}>
      <QuestionAccordion.Summary
        item={item}
        index={index}
        handleClick={handleClick}
      />
      <BasicQuestionDetails
        item={item}
        index={index}
        evaluationType={evaluationType}
        stageName={stageName}
      />
    </QuestionAccordion>
  );
};
export default BasicQuestion;
