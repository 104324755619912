import theme from "src/theme";
import { ButtonProps } from "@mui/material";

export enum ButtonSizeEnum {
  xlarge = "xlarge",
  large = "large",
  medium = "medium",
  small = "small"
}

export const BUTTON_SIZES: string[] = Object.values(ButtonSizeEnum);

export const BUTTON_SIZING_CONFIG = new Map([
  [
    ButtonSizeEnum.xlarge,
    { fontSize: "20px", iconSize: "24px", spacing: "12px" }
  ],
  [
    ButtonSizeEnum.large,
    { fontSize: "15px", iconSize: "20px", spacing: "10px" }
  ],
  [
    ButtonSizeEnum.medium,
    { fontSize: "14px", iconSize: "18px", spacing: "8px" }
  ],
  [ButtonSizeEnum.small, { fontSize: "13px", iconSize: "16px", spacing: "6px" }]
]);

export enum ButtonVariantEnum {
  contained = "contained",
  text = "text"
}
export const BUTTON_VARIANTS: string[] = Object.values(ButtonVariantEnum);

export const LOADING_ICON_COLOR = new Map([
  [ButtonVariantEnum.contained, theme.palette.grey["500"]],
  [ButtonVariantEnum.text, theme.palette.grey["500"]]
]);

export type ButtonSize = ButtonProps["size"];
export type ButtonVariant = ButtonProps["variant"];

// Action buttons currently don't support all variants, so we need a separate type
export type ActionButtonVariant = Extract<ButtonVariant, "contained" | "text">;
