import * as React from "react";
import HeaderContainer from "../../HeaderContainer";
// TODO: The search bar should be re-enabled past MVP
// import GlobalSearch from "./GlobalSearch";
// TODO: The Profile should be re-enabled past MVP
// import { HEADER_ELEMENT_HEIGHT } from "src/utils/constants/layout";
// import IconButton from "@mui/material/IconButton";
// import PersonIcon from "@mui/icons-material/Person";
// import { styled } from "@mui/material/styles";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";

/* Person Account Button */
// const StyledIconButton = styled(IconButton)(({ theme }) => ({
//   height: HEADER_ELEMENT_HEIGHT,
//   width: HEADER_ELEMENT_HEIGHT, // b/c we want a square container
//   backgroundColor: theme.palette.common.white,
//   "& .MuiSvgIcon-root": {
//     color: theme.palette.grey["300"]
//   },
//   "&:hover": {
//     backgroundColor: theme.palette.common.white,
//     "& .MuiSvgIcon-root": {
//       color: theme.palette.primary.dark
//     }
//   }
// }));

const Header = () => {
  // TODO: The Profile should be re-enabled past MVP
  // For the Profile Icon
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  return (
    <HeaderContainer>
      {/* TODO: The search bar should be re-enabled past MVP */}
      {/* <GlobalSearch /> */}

      {/* TODO: The Profile should be re-enabled past MVP */}
      {/* <div>
        <StyledIconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
        >
          <PersonIcon />
        </StyledIconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          PaperProps={{
            variant: "dark"
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={AuthService.signOut}>Log Out</MenuItem>
        </Menu>
      </div> */}
    </HeaderContainer>
  );
};

export default Header;
