import { plural } from "pluralize";
import { EvaluationSummaryListResponseSchema } from "src/schemas/evaluationSummary";
import { GetAPICaller, GetAPICallRequest } from "../APICall";
import { GetUserEvaluationsInterface } from "./types";

/**
 * Get the user's evaluations
 * - `GET /users/me/assessments`
 * - `GET /users/me/surveys`
 */
export default function get({
  evaluationType,
  queryStringParameters = {},
  expand,
  callback,
  errorCallback
}: GetUserEvaluationsInterface): void {
  const path = `/users/me/${plural(evaluationType)}`;

  if (expand) {
    queryStringParameters["expand"] = expand.join(",");
  }

  const request: GetAPICallRequest = {
    path,
    queryStringParameters,
    callback,
    errorCallback,
    typeValidator: EvaluationSummaryListResponseSchema,
    withReactHook: false
  };

  return GetAPICaller(request);
}
