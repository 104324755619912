import * as React from "react";
import Box from "@mui/material/Box";
import Reason from "./Reason";
import { CONTACT_REASONS } from "./data";
import { ContactReason } from "./types";

const Reasons = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: "grid",
        /* Automatically have grid items repeat based on width of parent
         * container. Will range from a minimum of 250px to 1fr (available
         * width / # of items).
         * This allows grid items to wrap if screen width is reduced.
         */
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        gridGap: "32px" // vertical + horizontal spacing between grid items
      }}
    >
      {CONTACT_REASONS.map((reason: ContactReason) => {
        return <Reason item={reason} />;
      })}
    </Box>
  );
};

export default Reasons;
