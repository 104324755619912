import * as React from "react";
import { useSearchParams } from "react-router-dom";
import ErrorMessage from "src/components/Common/ErrorMessage";
import {
  Client,
  ClientListResponse,
  ClientListResponseSchema
} from "src/schemas/client";
import {
  EvaluationSummariesExpand,
  EvaluationSummary,
  EvaluationSummaryListResponse
} from "src/schemas/evaluationSummary";
import {
  Project,
  ProjectListResponse,
  ProjectListResponseSchema
} from "src/schemas/project";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError } from "src/store/slices/notification";
import { GetAPICaller } from "src/services/APICall";
import { Nullable } from "src/utils/types";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { ScoredResponsesResponse } from "src/schemas/scoredResponse";
import { PhaseSchema } from "src/schemas/common";
import {
  ReportsContextType,
  Data,
  LocalSelect,
  ReportsResponse
} from "./types";
import { foundDataItem } from "./utils";
import { DEFAULT_REPORT } from "src/components/Reporting/Reports/All/constants";
import * as REPORTS from "src/components/Reporting/Reports/All";
import { RecommendationRow } from "src/schemas/reporting/recommendations";
import { ClassificationData } from "src/schemas/reporting/classification";
import ReportDataService from "./services/reportData";
import UserEvaluationsService from "src/services/userEvaluations";

const initialReportsContext: ReportsContextType = {
  isPreloading: false,
  setIsPreloading: () => null,
  data: {},
  selectedClient: null,
  selectedProject: null,
  selectedEvaluation: null,
  setSelectedClient: () => null,
  setSelectedProject: () => null,
  setSelectedEvaluation: () => null,
  isReportDataLoading: false,
  setIsReportDataLoading: () => false,
  scoredData: {},
  setScoredData: () => ({}),
  classificationData: {},
  setClassificationData: () => ({}),
  recommendationsData: [],
  setRecommendationsData: () => [],
  report: null,
  setReport: () => null
};

const ReportsContext = React.createContext<ReportsContextType>(
  initialReportsContext
);

export const ReportsProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  // query string params
  const [searchParams, setSearchParams] = useSearchParams();

  // Data
  const [data, setData] = React.useState<Data>({
    clients: null,
    projects: null,
    evaluations: null,
    evaluationsExpand: null
  });

  // Preloading query string params
  const [isPreloading, setIsPreloading] = React.useState<boolean>(false);

  const [selectedClient, setSelectedClient] =
    React.useState<Nullable<Client>>(null);
  const [selectedProject, setSelectedProject] =
    React.useState<Nullable<Project>>(null);
  const [selectedEvaluation, setSelectedEvaluation] =
    React.useState<Nullable<EvaluationSummary>>(null);

  // Data
  const [isReportDataLoading, setIsReportDataLoading] =
    React.useState<boolean>(false);
  const [scoredData, setScoredData] = React.useState<
    Partial<ScoredResponsesResponse>
  >({});
  const [classificationData, setClassificationData] = React.useState<
    Partial<ClassificationData>
  >({});
  const [recommendationsData, setRecommendationsData] = React.useState<
    Partial<RecommendationRow[]>
  >([]);
  const [report, setReport] = React.useState<string>(DEFAULT_REPORT);

  const dispatch: AppDispatch = useAppDispatch();

  function fetchData(): void {
    // Query Params
    const client: Nullable<string> = searchParams.get("client");
    const project: Nullable<string> = searchParams.get("project");
    const evaluation: Nullable<string> = searchParams.get("evaluation");
    const localReport: Nullable<string> = searchParams.get("report");
    if (localReport) {
      if (Object.keys(REPORTS).includes(localReport)) {
        setReport(localReport);
      } else {
        searchParams.delete("report");
        dispatch(
          showError({ message: `Report ${localReport} does not exist` })
        );
        searchParams.set("report", DEFAULT_REPORT);
        setSearchParams(searchParams);
      }
    }
    const preloading = Boolean(client || project || evaluation);
    setIsPreloading(preloading);

    // TODO: Use commented out code below once we support Surveys
    // const evaluationTypes: string[] = Object.values(EvaluationTypes);
    const evaluationTypes: EvaluationTypes[] = [EvaluationTypes.assessment];

    Promise.all([
      new Promise((resolve, reject) => {
        return GetAPICaller({
          path: "/users/me/clients",
          callback: (response: ClientListResponse) => resolve(response.result),
          errorCallback: (error: Error) => reject(error),
          withReactHook: false,
          typeValidator: ClientListResponseSchema
        });
      }),
      new Promise((resolve, reject) => {
        return GetAPICaller({
          path: "/users/me/projects",
          callback: (response: ProjectListResponse) => resolve(response.result),
          errorCallback: (error: Error) => reject(error),
          withReactHook: false,
          typeValidator: ProjectListResponseSchema
        });
      }),
      ...evaluationTypes.map((evaluationType: EvaluationTypes) => {
        return new Promise((resolve, reject) => {
          return UserEvaluationsService.get({
            evaluationType,
            queryStringParameters: {
              filter: JSON.stringify({
                Phase: { $not: { $eq: PhaseSchema.enum.editing } }
              })
            },
            expand: ["templates"],
            callback: (response: EvaluationSummaryListResponse) =>
              resolve(response),
            errorCallback: (error: Error) => reject(error)
          });
        });
      })
    ])
      .then((response: ReportsResponse) => {
        /* TODO - change logic to support surveys past MVP.
         * - Ensure we separate out assessments + surveys from the response:
         * `const [clients, projects, assessmentSummaryListResponse,
         *  surveySummaryListResponse]: ReportsResponse = response;`
         *
         * - Add type (assessment, survey) to each item in assessmentSummaryList
         * + surveySummaryList
         */
        const [
          clients,
          projects,
          evaluationSummaryListResponse
        ]: ReportsResponse = response;
        const evaluations: EvaluationSummary[] =
          evaluationSummaryListResponse.item;
        const evaluationsExpand: EvaluationSummariesExpand =
          evaluationSummaryListResponse._expand.templates;

        setData({
          clients,
          projects,
          evaluations,
          evaluationsExpand
        });
        if (!preloading) {
          return;
        }

        /* The following logic deals with parsing prefilled query params for
         * client
         * project
         * evaluation
         *
         * and automatically setting those values for configuration dropdowns
         */
        const [foundEvaluation, boolFoundEvaluation] =
          foundDataItem<EvaluationSummary>(
            evaluation,
            evaluations,
            (item: EvaluationSummary) => item.Id
          );
        const [foundProject, boolFoundProject] = foundDataItem<Project>(
          project,
          projects,
          (item: Project) => item.Id
        );
        const [foundClient, boolFoundClient] = foundDataItem<Client>(
          client,
          clients,
          (item: Client) => item.Id
        );
        const [foundProjectClient, boolFoundProjectClient] =
          foundDataItem<Client>(
            foundProject?.ClientId,
            clients,
            (item: Client) => item.Id
          );
        const [foundEvaluationClient, boolFoundEvaluationClient] =
          foundDataItem<Client>(
            foundEvaluation?.ClientId,
            clients,
            (item: Client) => item.Id
          );
        const [foundEvaluationProject, boolFoundEvaluationProject] =
          foundDataItem<Client>(
            foundEvaluation?.ProjectId,
            projects,
            (item: Project) => item.Id
          );

        let localSelectedClient: Nullable<Client> = null;
        let localSelectedProject: Nullable<Project> = null;
        let localSelectedEvaluation: Nullable<EvaluationSummary> = null;

        if (boolFoundEvaluation) {
          localSelectedEvaluation = foundEvaluation;
          if (!boolFoundEvaluationClient && !boolFoundEvaluationProject) {
            throw new Error(
              "Selected Project and Client for Evaluation are missing from dropdowns. You are missing access to this project and client."
            );
          }
          if (!boolFoundEvaluationClient) {
            throw new Error(
              "Selected Client for Evaluation is missing from dropdown. You are missing access to this client."
            );
          }
          if (!boolFoundEvaluationProject) {
            throw new Error(
              "Selected Project for Evaluation is missing from dropdown. You are missing access to this project."
            );
          }
          localSelectedProject = foundEvaluationProject;
          localSelectedClient = foundEvaluationClient;
        }
        if (boolFoundProject) {
          localSelectedProject = foundProject;
          if (!boolFoundProjectClient) {
            throw new Error(
              "Selected Client for Project is missing from dropdown. You are missing access to this client."
            );
          }
          localSelectedClient = foundProjectClient;
        }
        if (boolFoundClient) {
          localSelectedClient = foundClient;
        }
        const localSelect: LocalSelect = {
          client: localSelectedClient,
          project: localSelectedProject,
          evaluation: localSelectedEvaluation
        };

        for (const [key, value] of Object.entries(localSelect)) {
          if (value) {
            searchParams.set(key, value?.Id);
          }
        }

        try {
          const errors: string[] = [];
          if (
            localSelectedClient &&
            localSelectedProject &&
            localSelectedEvaluation &&
            localSelectedClient?.Id != localSelectedEvaluation?.ClientId &&
            localSelectedProject?.Id != localSelectedEvaluation?.ProjectId
          ) {
            const msg =
              "Selected Evaluation and both Client and Project do not match.";
            localSelectedEvaluation = null;
            searchParams.delete("evaluation");
            errors.push(msg);
          } else if (
            localSelectedClient &&
            localSelectedEvaluation &&
            localSelectedClient?.Id != localSelectedEvaluation?.ClientId
          ) {
            const msg = "Selected Evaluation and Client do not match.";
            localSelectedEvaluation = null;
            searchParams.delete("evaluation");
            errors.push(msg);
          } else if (
            localSelectedProject &&
            localSelectedEvaluation &&
            localSelectedProject?.Id != localSelectedEvaluation?.ProjectId
          ) {
            const msg = "Selected Evaluation and Project do not match.";
            localSelectedEvaluation = null;
            searchParams.delete("evaluation");
            errors.push(msg);
          }
          if (
            localSelectedClient &&
            localSelectedProject &&
            localSelectedProject?.ClientId != localSelectedClient?.Id
          ) {
            const msg = "Selected Project and Client do not match.";
            localSelectedProject = null;
            searchParams.delete("project");
            errors.push(msg);
          }
          if (errors.length > 0) {
            throw new Error(errors.join(" "));
          }
        } finally {
          setSelectedClient(localSelectedClient);
          setSelectedProject(localSelectedProject);
          setSelectedEvaluation(localSelectedEvaluation);

          setSearchParams(searchParams);
        }
      })
      .catch((error: Error) => {
        dispatch(
          showError({
            message: (
              <ErrorMessage
                error={error}
                action="loading report collections and queries"
              />
            )
          })
        );
      })
      .finally(() => {
        setIsPreloading(false);
      });
  }

  // Fetch all data for configuration dropdowns on DOM mount
  React.useEffect(() => {
    fetchData();
  }, []);

  /* Watch for selectedEvaluation changes - fetch Reports data here if
   * selectedEvaluation is not null
   */
  React.useEffect(() => {
    ReportDataService.get({
      evaluation: selectedEvaluation,
      setIsReportDataLoading,
      setScoredData,
      setClassificationData,
      setRecommendationsData,
      errorCallback: (error: Error) =>
        dispatch(
          showError({
            message: (
              <ErrorMessage error={error} action="loading your reports data" />
            )
          })
        )
    });
  }, [selectedEvaluation]);

  const contextProviderValue: ReportsContextType = {
    isPreloading,
    setIsPreloading,
    data,
    selectedClient,
    selectedProject,
    selectedEvaluation,
    setSelectedClient,
    setSelectedProject,
    setSelectedEvaluation,
    scoredData,
    setScoredData,
    classificationData,
    setClassificationData,
    recommendationsData,
    setRecommendationsData,
    isReportDataLoading,
    setIsReportDataLoading,
    report,
    setReport
  };

  return (
    <ReportsContext.Provider value={contextProviderValue}>
      {children}
    </ReportsContext.Provider>
  );
};

const useReportsContext = () => React.useContext(ReportsContext);
export default useReportsContext;
