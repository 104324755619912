import * as React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { RecommendationRow } from "src/schemas/reporting/recommendations";
import DataTableCore from "./Core";
import { makeColumns } from "./data/columns";
import RowModal from "./RowModal";
import { DataTableProps } from "./types";

const DataTable = ({ evaluationName, rows }: DataTableProps): JSX.Element => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [selectedRow, setSelectedRow] = React.useState<RecommendationRow>(null);

  function viewRowDetails(row: RecommendationRow): void {
    setSelectedRow(row);
    setModalOpen(true);
  }

  const columns: GridColDef[] = makeColumns({
    ViewDetails: { onClick: viewRowDetails }
  });

  return (
    <>
      <RowModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        row={selectedRow}
        columns={columns}
      />
      <DataTableCore
        evaluationName={evaluationName}
        rows={rows}
        columns={columns}
      />
    </>
  );
};

export default DataTable;
