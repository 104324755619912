import { getPercentInteger } from "src/utils/percent";
import {
  MaturityStageScore,
  MaturityStageScoreCalculations
} from "src/schemas/reporting/maturityScore";
import {
  GroupedScoredResponses,
  ScoredResponse
} from "src/schemas/scoredResponse";
import { formatQuestionStageText } from "src/utils/format/questionStage";
import { sortObjectArrayDESC } from "src/utils/sort";
import { groupBy } from "../../../../utils/data/groupBy";

type Calculations = MaturityStageScoreCalculations &
  Pick<MaturityStageScore, "QuestionStage">;

/**
 * A Stage 0 Maturity Stage Score object that will
 * explicitly be added to the calculated list of Maturity Stage Scores.
 * Stage 0 is a baseline stage that has no responses associated with it.
 */
const BASELINE_STAGE_SCORE: MaturityStageScore = {
  QuestionStageNameFormatted: formatQuestionStageText(0, "Baseline"),
  QuestionStage: 0,
  Total: 100,
  Percent: 100
};

/**
 * Converts an object where
 * - key is a string of format `Stage ${stageNumber} - ${stageName}` and
 * - value is a list of ScoredResponses
 *
 * into an array of MaturityStageScores,
 * which is an array of objects with the following fields:
 * - QuestionStageNameFormatted
 * - QuestionStage
 * - Total
 * - Percent
 */
function convertToMaturityStageScores(
  groupedData: GroupedScoredResponses
): MaturityStageScore[] {
  return Object.entries(groupedData).map(
    ([stageText, items]: [stageText: string, items: ScoredResponse[]]) => {
      const initialValues: Calculations = {
        responsesCount: 0,
        sufficientlyMetResponsesCount: 0,
        QuestionStage: null
      };

      const aggregatedValues: Calculations = items.reduce(
        (acc: Calculations, item: ScoredResponse) => {
          acc.responsesCount += 1;

          // # of Responses that pass maturity threshold (e.g., when ProvideRecommendation is false in ScoredResponse)
          acc.sufficientlyMetResponsesCount += item.ProvideRecommendation
            ? 0
            : 1;

          // Get the stage number associated with this group of items
          acc.QuestionStage ??= item.QuestionStage;

          return acc;
        },
        initialValues
      );

      return {
        QuestionStageNameFormatted: stageText,
        QuestionStage: aggregatedValues.QuestionStage,
        Total: 100,
        Percent: getPercentInteger(
          aggregatedValues.sufficientlyMetResponsesCount,
          aggregatedValues.responsesCount
        )
      };
    }
  );
}

/**
 * Converts an array of ScoredResponses into MaturityStageScores,
 * which is an array of objects with the following fields:
 * - QuestionStageNameFormatted
 * - QuestionStage
 * - Total
 * - Percent
 *
 * Output object array is sorted in descending order by QuestionStage
 */
export function process(data: ScoredResponse[]): MaturityStageScore[] {
  // Group ScoredResponses by formatted QuestionStageText (e.g, "Stage 1 - Reactive")
  const groupedData: GroupedScoredResponses = groupBy(
    data,
    (item: ScoredResponse) =>
      formatQuestionStageText(item.QuestionStage, item.QuestionStageName)
  );

  const maturityStageScores: MaturityStageScore[] =
    convertToMaturityStageScores(groupedData);

  // Explicitly add in our baseline Stage 0 score
  maturityStageScores.push(BASELINE_STAGE_SCORE);

  /* Sort Maturity Stage Scores in descending order based on QuestionStage - since in amCharts,
   * the first item in the array is the last item in the series (last bar on chart)
   */
  return sortObjectArrayDESC(
    maturityStageScores,
    (item: MaturityStageScore) => item.QuestionStage
  );
}
