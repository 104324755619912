import * as React from "react";
import TooltipTextBox from "src/components/Common/Tooltip/TooltipTextBox";

export const ClientHelperText = () => (
  <TooltipTextBox component="div">
    Selecting a Client will automatically filter all <b>Projects</b> and{" "}
    <b>Evaluations</b> that belong to that Client
  </TooltipTextBox>
);
export const ProjectHelperText = () => (
  <TooltipTextBox component="div">
    <p>
      Selecting a Project will automatically filter all <b>Evaluations</b> that
      belong to that Project.
    </p>
    <p>
      If a Client hasn't been selected, selecting a Project will auto-populate
      the linked Client.
    </p>
  </TooltipTextBox>
);
export const EvaluationHelperText = () => (
  <TooltipTextBox component="div">
    <p>
      An Evaluation is an Assessment or Survey that belongs to a Client Project.
    </p>
    <p>
      If a Client and/or Project haven't been selected, selecting an Evaluation
      will auto-populate the linked Client and linked Project.
    </p>
  </TooltipTextBox>
);
