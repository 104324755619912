import * as React from "react";
import {
  CustomEvaluationResponse,
  EvaluationResponse,
  EvaluationResponseSchema
} from "src/schemas/evaluation";
import {
  Response,
  ResponseListResponse,
  ResponseListResponseSchema
} from "src/schemas/response";
import { GetAPICaller } from "src/services/APICall";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { plural } from "pluralize";
import { AppDispatch, useAppDispatch } from "src/store";
import { setResponses } from "src/store/slices/manageEvaluation";
import { showError } from "src/store/slices/notification";
import ErrorMessage from "src/components/Common/ErrorMessage";
import { Collection } from "src/utils/constants/collection";
import {
  RowsState,
  RowStateValue
} from "src/components/Common/Accordion/types";
import { Question } from "src/schemas/question";
import { Recommendation } from "src/schemas/recommendation";
import updateEvaluationReducer from "./reducers/evaluation";
import {
  EvaluationReducerAction,
  UpdateEvaluationAction
} from "./reducers/evaluation/types";
import {
  FilterAction,
  FilterReducerAction,
  InitializeActionData
} from "./reducers/filterState/types";
import { conditionalObject } from "src/utils/object";
import { EvaluationExpandOptions } from "src/schemas/expands/evaluation";
import { FilterState } from "src/pages/ManageEvaluation/QuestionsPanel/Filters/types";
import filterReducer from "./reducers/filterState";
import { INITIAL_FILTER_STATE } from "./reducers/filterState/constants";
// NOTE: Leave in temporarily for performance testing purposes
// import { makeSampleAssessmentQuestion } from "src/pages/Sandbox/data/accordion";

interface ManageEvaluationContextType {
  evaluationType: EvaluationTypes;
  filterState: FilterState;
  dispatchFilterState: React.Dispatch<FilterReducerAction>;
  questionRowsState: RowsState<RowStateValue>;
  setQuestionRowsState: React.Dispatch<
    React.SetStateAction<RowsState<RowStateValue>>
  >;
  filteredQuestionsCount: number;
  setFilteredQuestionsCount: React.Dispatch<React.SetStateAction<number>>;
  evaluation: CustomEvaluationResponse;
  dispatchEvaluation: React.Dispatch<EvaluationReducerAction>;
}

const initialManageEvaluationContext = {
  evaluationType: null,
  filterState: INITIAL_FILTER_STATE,
  dispatchFilterState: () => null,
  questionRowsState: null,
  setQuestionRowsState: () => null,
  filteredQuestionsCount: 0,
  setFilteredQuestionsCount: () => null,
  evaluation: null,
  dispatchEvaluation: () => null
};

const ManageEvaluationContext =
  React.createContext<ManageEvaluationContextType>(
    initialManageEvaluationContext
  );

const initEvaluationDetails = (
  response: EvaluationResponse,
  dispatchEvaluation: ManageEvaluationContextType["dispatchEvaluation"],
  dispatchFilterState: ManageEvaluationContextType["dispatchFilterState"],
  setQuestionRowsState: React.Dispatch<
    React.SetStateAction<RowsState<RowStateValue>>
  >,
  setFilteredQuestionsCount: React.Dispatch<React.SetStateAction<number>>
) => {
  // NOTE: Leave in temporarily for performance testing purposes
  // response.item.Questions = Array(1000)
  //   .fill(null)
  //   .map(() => makeSampleAssessmentQuestion(Object.keys(response.item.Stages).length));

  // Initialize filters based on evaluation data
  const initializeFilterData: InitializeActionData = {
    evaluation: response.item
  };
  dispatchFilterState({
    type: FilterAction.initialize,
    data: initializeFilterData
  });

  setFilteredQuestionsCount(response.item.Questions.length);
  const rowsState: RowsState<RowStateValue> = {};

  response.item.Questions.forEach((question: Question) => {
    Reflect.set(rowsState, question.Id, {
      expanded: false
    });
  });

  setQuestionRowsState(rowsState);

  // Append `index` to every question so that numbering of questions doesn't change when filtering
  // Look up `recommendation` for every question from the _expand of evaluation
  response.item.Questions = response.item.Questions.map(
    (question: Question, index: number) => {
      const recommendation: Recommendation =
        response?._expand?.recommendation?.[question?.RecommendationId];
      return {
        ...question,
        index: index,
        /* Only add a `Recommendation` field if a question has a RecommendationId and
         * if the question's RecommendationId is present in the `expand` response
         */
        ...conditionalObject(Boolean(recommendation), {
          Recommendation: recommendation
        })
      };
    }
  );

  // For testing add/edit question modal
  // response.item.Phase = "editing";

  dispatchEvaluation({ type: UpdateEvaluationAction.set, data: response });
};

export const ManageEvaluationContextTypeProvider = ({
  evaluationType,
  id,
  children
}: {
  evaluationType: EvaluationTypes;
  id: string;
  children: React.ReactNode;
}) => {
  const [evaluation, dispatchEvaluation] = React.useReducer(
    updateEvaluationReducer,
    null
  );

  const [filterState, dispatchFilterState] = React.useReducer(
    filterReducer,
    INITIAL_FILTER_STATE
  );

  const [questionRowsState, setQuestionRowsState] = React.useState<
    RowsState<RowStateValue>
  >({});
  const [filteredQuestionsCount, setFilteredQuestionsCount] =
    React.useState<number>(0);

  const dispatch: AppDispatch = useAppDispatch();

  function fetchData(): void {
    Promise.all([
      new Promise((resolve, reject) => {
        return GetAPICaller({
          path: `/${plural(evaluationType)}/${id}`,
          queryStringParameters: {
            expand: Object.values(EvaluationExpandOptions.enum).join(",")
          },
          callback: (response: EvaluationResponse) => resolve(response),
          errorCallback: (error: Error) => reject(error),
          withReactHook: false,
          typeValidator: EvaluationResponseSchema
        });
      }),
      new Promise((resolve, reject) => {
        return GetAPICaller({
          path: `/${plural(evaluationType)}/${id}/responses`,
          callback: (response: ResponseListResponse) => resolve(response),
          errorCallback: (error: Error) => reject(error),
          withReactHook: false,
          typeValidator: ResponseListResponseSchema
        });
      })
    ])
      .then(
        ([evaluationResponse, evaluationResponsesResponse]: [
          EvaluationResponse,
          ResponseListResponse
        ]) => {
          // Set evaluation + responses
          initEvaluationDetails(
            evaluationResponse,
            dispatchEvaluation,
            dispatchFilterState,
            setQuestionRowsState,
            setFilteredQuestionsCount
          );
          dispatch(
            setResponses(
              Object.fromEntries(
                evaluationResponsesResponse.result.map((item: Response) => [
                  item.QuestionId,
                  item
                ])
              )
            )
          );
        }
      )
      .catch((error: Error) => {
        // Reset data and throw error message
        dispatchEvaluation({
          type: UpdateEvaluationAction.set,
          data: { item: null, _expand: null }
        });
        dispatch(setResponses({}));
        dispatch(
          showError({
            message: (
              <ErrorMessage
                error={error}
                collection={Collection[`${evaluationType}`]}
              />
            )
          })
        );
      });
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const contextProviderValue = {
    evaluationType,
    evaluation,
    dispatchEvaluation,
    filterState,
    dispatchFilterState,
    questionRowsState,
    setQuestionRowsState,
    filteredQuestionsCount,
    setFilteredQuestionsCount
  };
  return (
    <ManageEvaluationContext.Provider value={contextProviderValue}>
      {children}
    </ManageEvaluationContext.Provider>
  );
};

const useManageEvaluationContext = () =>
  React.useContext(ManageEvaluationContext);
export default useManageEvaluationContext;
