import * as React from "react";
import { capitalize } from "@mui/material";
import { Collection } from "src/utils/constants/collection";

const ErrorMessage = ({
  error,
  action,
  collection
}: {
  error: Error;
  action?: string;
  collection?: Collection;
}) => {
  const text: string = action ?? `fetching your ${capitalize(collection)}`;

  return (
    <>
      We're sorry, but something went wrong while {text}.
      <p>{error.toString()}</p>
    </>
  );
};
export default ErrorMessage;
