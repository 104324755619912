import * as React from "react";
import Divider from "@mui/material/Divider";
import BaseModal from "../../../Calculation/InfoModal";
import { CalculationInfoModalProps } from "../../../Calculation/types";
import CalculationDefinition from "../Definition";
import Example from "./Example";

const CalculationInfoModal = (
  props: CalculationInfoModalProps
): JSX.Element => {
  return (
    <BaseModal {...props}>
      <CalculationDefinition />
      <Divider />
      <Example />
    </BaseModal>
  );
};

export default CalculationInfoModal;
