import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import TruncatedText from "src/components/Common/Text/Truncated";

interface SidebarItemProps {
  id: string;
  name: string;
}

function navigateToSection(
  event: React.SyntheticEvent,
  id: SidebarItemProps["id"]
) {
  // Stop sidebar toggle button click from occurring
  event.stopPropagation();
  window.location.href = `#${id}`;
}

const SidebarItem = ({ id, name }: SidebarItemProps) => {
  return (
    <MenuItem
      onClick={(event: React.SyntheticEvent) => navigateToSection(event, id)}
    >
      <TruncatedText sx={{ fontSize: "14px" }}>{name}</TruncatedText>
    </MenuItem>
  );
};

export default SidebarItem;
