/**
 * Returns a boolean indicating whether `initialState` and `currentState`
 * are different.
 *
 * **Note**
 * We use JSON.stringify to perform deeper comparisons for object equality
 * (e.g., objects, arrays, etc.)
 */
export function hasChanged<T>(initialState: T, currentState: T): boolean {
  return JSON.stringify(initialState) !== JSON.stringify(currentState);
}

/**
 * Given 2 objects, checks `currentState`'s fields against those of
 * `initialState` and returns a boolean indicating whether any of
 * `currentState`'s fields are different than `initialState`'s.
 *
 * **Example Usage**
 * - Checking if form data has changed
 */
export function isDirty<T extends object>(
  initialState: T,
  currentState: Partial<T>
): boolean {
  return Object.keys(currentState)
    .map((key: string) =>
      hasChanged(initialState[`${key}`], currentState[`${key}`])
    )
    .some((changed: boolean) => changed);
}
