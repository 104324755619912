import * as React from "react";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import NoRowsMessage from "src/components/Common/DataGrid/NoRowsMessage";
import Toolbar from "src/components/Common/DataGrid/Toolbar";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS
} from "src/components/Common/DataGrid/utils/pagination";
import { trimWhitespace } from "src/utils/string";
import { DATA_GRID_STYLES, TOOLBAR_STYLES } from "./styles";
import { DataTableCoreProps } from "./types";

const DataTableCore = ({
  evaluationName,
  rows,
  columns
}: DataTableCoreProps): JSX.Element => {
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE
    });

  const csvFileName = `${trimWhitespace(evaluationName)}-Recommendations`;

  return (
    <DataGrid
      sx={DATA_GRID_STYLES}
      rows={rows}
      columns={columns}
      hideFooterSelectedRowCount
      slots={{
        noRowsOverlay: () => <NoRowsMessage message="No recommendations" />,
        toolbar: () => <Toolbar csvFileName={csvFileName} sx={TOOLBAR_STYLES} />
      }}
      pagination={true}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
      autoHeight={true}
    />
  );
};

export default DataTableCore;
