import { z } from "zod";
import { pick } from "src/utils/object";
import { ExpandOptionsBase, ExpandOptionsInterface } from "./index";

/**
 * Key definition for **evaluation summaries** expand options - specifies which
 * properties to pick from
 * - `ExpandOptionsInterface` - for typing
 * - `ExpandOptionsBase` object - for creation of the zod enum object
 *
 * **Notes**
 * - Do not specify return type since we need key union type below to infer it
 *
 * - Specify as **readonly** array - needed for Typescript 3.4 for key union
 * type. See https://stackoverflow.com/a/45257357
 */
const EvaluationSummariesExpandKeys = [
  "Clients",
  "Projects",
  "Templates"
] as const;

/**
 * Union type of all keys for **evaluation** expand zod enum object.
 */
type EvaluationSummariesExpandKeysUnion =
  (typeof EvaluationSummariesExpandKeys)[number];

/**
 * Interface for object used to create expand zod enum object. Ensures object
 * has specified expand keys.
 */
type EvaluationSummariesExpandOptionsInterface = Pick<
  ExpandOptionsInterface,
  EvaluationSummariesExpandKeysUnion
>;

/**
 * Object with subset of `ExpandOptionsBase` fields used to create specific
 * expand zod enum object.
 */
const EvaluationSummariesExpandOptionsBase: EvaluationSummariesExpandOptionsInterface =
  pick(ExpandOptionsBase, EvaluationSummariesExpandKeys);

/**
 * Zod enum object - used in place of native Typescript enum due to our
 * ability to pass in an object that implements or narrows down our base
 * expand options object. Native enums cannot be based on another enum.
 *
 * **Example Usage**
 * - EvaluationSummariesExpandOptions.enum.Clients => "clients"
 * - EvaluationSumarriesExpandOptions.enum.Projects => "projects"
 */
export const EvaluationSummariesExpandOptions = z.nativeEnum(
  EvaluationSummariesExpandOptionsBase
);

/**
 * Union of expand enum **values** - e.g.,
 * "clients" | "projects" | etc.
 */
export type EvaluationSummariesExpandOption = z.infer<
  typeof EvaluationSummariesExpandOptions
>;
