import { FilterState } from "src/pages/ManageEvaluation/QuestionsPanel/Filters/types";
import * as Actions from "./actions";
import { FilterReducerAction } from "./types";

export default function filterReducer(
  state: FilterState,
  action: FilterReducerAction
): FilterState {
  if (Reflect.has(Actions, action.type)) {
    const newState: FilterState = structuredClone(state);
    return Reflect.get(Actions, action.type)(newState, action.data);
  }
  return state;
}
