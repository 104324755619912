import * as React from "react";
import TooltipTextBox from "src/components/Common/Tooltip/TooltipTextBox";

const DisabledExportHelperText = (): JSX.Element => (
  <TooltipTextBox component="div">
    You cannot export data until you have selected a report
  </TooltipTextBox>
);

export default DisabledExportHelperText;
