import * as React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Loading from "src/components/Common/Loading";

export const ReportLoading = () => {
  const { palette } = useTheme();
  return (
    <Loading height="auto" sx={{ marginTop: "60px" }}>
      <Typography variant="body1" sx={{ color: palette.grey["700"] }}>
        <b>Retrieving your reports...</b>
      </Typography>
    </Loading>
  );
};
