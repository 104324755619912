import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ActiveEvaluationsTooltipProps } from "../types";
import {
  EvaluationTypes,
  EvaluationArticles
} from "src/utils/constants/evaluation";
import ActiveEvaluationsTooltipText from "./ActiveEvaluationsTooltipText";

const ActiveEvaluationsTooltip = ({
  evaluationType
}: ActiveEvaluationsTooltipProps): JSX.Element => {
  const evaluationArticle =
    EvaluationArticles[evaluationType as EvaluationTypes];
  return (
    <Tooltip
      title={
        <ActiveEvaluationsTooltipText
          evaluationType={evaluationType}
          evaluationArticle={evaluationArticle}
        />
      }
      placement="bottom-start"
      sx={{ marginLeft: "10px" }}
    >
      <IconButton sx={{ padding: "4px" }}>
        <InfoOutlinedIcon sx={{ fontSize: "18px" }} />
      </IconButton>
    </Tooltip>
  );
};

export default ActiveEvaluationsTooltip;
