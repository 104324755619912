import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import NumberChip from "src/components/Common/Chip/Number";
import TooltipText from "src/components/Common/Tooltip/TooltipText";

const ScoreChip = ({ score }: { score: number; disabled?: boolean }) => {
  return (
    <Tooltip
      placement="bottom-start"
      title={
        <TooltipText>This score represents the Maturity level</TooltipText>
      }
    >
      <NumberChip label={score} size="small" sx={{ margin: "10px" }} />
    </Tooltip>
  );
};

export default ScoreChip;
