import * as React from "react";
import Stack from "@mui/material/Stack";

interface SectionRowProps {
  children: React.ReactNode;
}

/**
 * A reusable Sandbox layout component that creates a row
 * within a particular Sandbox Section (e.g., for creating
 * a row of Buttons with different sizes).
 */
export const SectionRow = ({ children }: SectionRowProps) => {
  return (
    <Stack
      spacing="32px"
      direction="row"
      alignItems="flex-start"
      flexWrap="wrap"
      sx={{ marginBottom: "36px" }}
    >
      {children}
    </Stack>
  );
};

export default SectionRow;
