import * as React from "react";
import { useTheme } from "@mui/material";
import ScrollableContainer from "src/components/Common/ScrollableContainer";
import { HEADER_HEIGHT, FILTERS_WIDTH } from "../constants";
import Sections from "./Sections";
import Header from "./Header";

const Filters = (): JSX.Element => {
  const { palette } = useTheme();
  return (
    <ScrollableContainer
      sxParent={{
        borderRadius: 0,
        border: "none",
        borderRight: `1px solid ${palette.primary.main}`,
        height: "100%",
        width: FILTERS_WIDTH,
        flexShrink: 0
      }}
      sx={{ padding: "0px" }}
      header={<Header height={HEADER_HEIGHT} />}
      headerHeight={HEADER_HEIGHT}
    >
      <Sections />
    </ScrollableContainer>
  );
};

export default Filters;
