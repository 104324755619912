import * as React from "react";
import { capitalize } from "@mui/material";
import ActionButton from "src/components/Common/ActionButton";
import ErrorMessage from "src/components/Common/ErrorMessage";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError, showSuccess } from "src/store/slices/notification";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import { UpdateEvaluationAction } from "src/context/ManageEvaluation/reducers/evaluation/types";
import { ButtonVariantEnum } from "src/utils/constants/button";
import { EvaluationPatchResponse } from "src/schemas/evaluation";
import EvaluationService from "src/services/evaluation";
import { Phase, PhaseSchema } from "src/schemas/common";
import { ZodError } from "zod";
import { formatError } from "src/utils/zod/formatError";

interface MoveToPhaseButtonProps {
  phase: string;
  variant: ButtonVariantEnum;
  disabled: boolean;
  setDisabled: (disabled: boolean) => void;
}

const MoveToPhaseButton = ({
  phase,
  variant,
  disabled,
  setDisabled
}: MoveToPhaseButtonProps) => {
  const dispatch: AppDispatch = useAppDispatch();
  const { evaluation, dispatchEvaluation, evaluationType } =
    useManageEvaluationContext();
  const [loading, setLoading] = React.useState<boolean>(false);
  const capitalizedPhase: string = capitalize(phase ?? "");

  function setAllLoadingStates(loading: boolean): void {
    setDisabled(loading);
    setLoading(loading);
  }

  /* Callbacks for Patch */
  function successCallback(response: EvaluationPatchResponse): void {
    // Update Manage Evaluation context so that we don't have to fetch assessment from API
    dispatchEvaluation({
      type: UpdateEvaluationAction.editPhase,
      data: response.result.Phase,
      meta: response.result.Metadata
    });

    setAllLoadingStates(false);

    dispatch(
      showSuccess({
        message: (
          <>
            Your assessment was successfully moved to the{" "}
            <b>{capitalizedPhase}</b> phase.
          </>
        )
      })
    );
  }

  function errorCallback(error: Error): void {
    setAllLoadingStates(false);
    dispatch(
      showError({
        message: (
          <ErrorMessage
            error={error}
            action={`moving your evaluation to the ${capitalizedPhase} phase`}
          />
        )
      })
    );
  }

  /**
   * Convert `phase`, which is of type string to type Phase.
   *
   * Throw error if not possible - e.g., when phase isn't any of
   * "editing"|"open"|"closed"|"completed"
   */
  function getParsedPhase(phase: string): Phase {
    try {
      return PhaseSchema.parse(phase);
    } catch (error) {
      const formattedError: Error =
        error instanceof ZodError ? new Error(formatError(error)) : error;
      errorCallback(formattedError);
      throw formattedError;
    }
  }

  const handleSubmit = async (): Promise<void> => {
    setAllLoadingStates(true);

    EvaluationService.patch({
      evaluationType,
      evaluationId: evaluation.item.Id,
      updatedEvaluationFields: {
        Phase: getParsedPhase(phase),
        Metadata: {
          DateModified: evaluation.item.Metadata.DateModified
        }
      },
      callback: successCallback,
      errorCallback
    });
  };

  return (
    Boolean(phase) && (
      <ActionButton
        disabled={disabled}
        loading={loading}
        onClick={handleSubmit}
        variant={variant}
      >
        {loading ? "Moving" : "Move"} to {capitalizedPhase}
      </ActionButton>
    )
  );
};

export default MoveToPhaseButton;
