import { EmptyResponseSchema } from "src/schemas/http";
import { PutAPICaller, PutAPICallRequest } from "../APICall";
import { CreateUserClientInterface } from "./types";

/**
 * Creates a User-Client link if it doesn't already exist.
 * Does nothing if the specific link already exists.
 *
 * - `PUT /clients/{clientId}/users/{userId}`
 */
export default function create({
  clientId,
  userId,
  callback,
  errorCallback
}: CreateUserClientInterface): void {
  const path = `/clients/${clientId}/users/${userId}`;

  const request: PutAPICallRequest = {
    path,
    callback,
    errorCallback,
    typeValidator: EmptyResponseSchema,
    withReactHook: false
  };

  return PutAPICaller(request);
}
