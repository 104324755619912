import * as React from "react";
import InfoIconTooltip from "src/components/Common/Tooltip/InfoIconTooltip";
import TooltipText from "src/components/Common/Tooltip/TooltipText";

const AnswersInfoTooltip = ({ answerCount }: { answerCount: number }) => {
  return (
    <InfoIconTooltip
      placement="right-end"
      title={
        <TooltipText>
          The number of answers required is <b>{answerCount}</b>
        </TooltipText>
      }
    />
  );
};
export default AnswersInfoTooltip;
