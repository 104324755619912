import * as React from "react";
import Typography from "@mui/material/Typography";
import ModalSection from "src/components/Common/Modal/Section";
import Intro from "./Intro";
import StagesList from "./StagesList";
import Conclusion from "./Conclusion";

const STAGE_TO_MATURITY_SCORES: [number, number][] = [
  [0, 100],
  [1, 100],
  [2, 70],
  [3, 30]
];

const DOMAIN = "Application Architecture";

export interface ExampleProps {
  domainSpecific?: boolean;
}

const Example = ({ domainSpecific = false }: ExampleProps): JSX.Element => {
  const domain: string = domainSpecific ? DOMAIN : null;
  return (
    <ModalSection name="Example">
      <Intro domain={domain} stagesCount={STAGE_TO_MATURITY_SCORES.length} />
      <StagesList items={STAGE_TO_MATURITY_SCORES} />
      <Typography variant="body2">
        Stages 0 and 1 are sufficiently met because they both have score values
        of 100%.
      </Typography>
      <Typography variant="body2">
        Stage 2, however, has a score of 70%, which indicates that 70% of
        attributes are evaluated at an adequate rating and do not need
        improvement recommendations. The remaining 30% of attributes represent
        areas that have opportunity for improvement recommendations. For those
        30% of responses, recommendations will be provided to help the client
        strengthen and enhance those areas.
      </Typography>
      <Conclusion domain={domain} />
    </ModalSection>
  );
};

export default Example;
