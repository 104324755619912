import * as React from "react";
import Box from "@mui/material/Box";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "src/components/Layout/Public/Header";
import Body from "src/components/Layout/Public/Body";
import useAuth from "src/context/Auth";

const PublicLayout = (): JSX.Element => {
  const { isUserAuthenticated } = useAuth();
  const location = useLocation();
  const isLoginRoute = location.pathname === "/";

  /* If user is on login page and is authenticated,
   * automatically redirect to authenticated homepage
   */
  return isLoginRoute && isUserAuthenticated ? (
    <Navigate to="/home" />
  ) : (
    <Box>
      <Header />
      <Body>
        <Outlet />
      </Body>
    </Box>
  );
};

export default PublicLayout;
