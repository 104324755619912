import * as React from "react";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import {
  FilterAction,
  ToggleDomainActionData
} from "src/context/ManageEvaluation/reducers/filterState/types";
import { FilterCategory } from "../types";
import List from "./Common/List";
import Section from "./Common/Section";

const Domains = (): JSX.Element => {
  const { filterState, dispatchFilterState } = useManageEvaluationContext();

  function onDomainClick(item: string): void {
    const data: ToggleDomainActionData = { domainName: item };
    dispatchFilterState({ type: FilterAction.toggleDomain, data });
  }

  return (
    <Section category={FilterCategory.Domains}>
      <List items={filterState?.domains} onClick={onDomainClick} />
    </Section>
  );
};

export default Domains;
