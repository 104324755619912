import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";

/**
 * This is a common, reusable InputLabel component
 *
 * **Usage**: This component should typically be used for
 * - the main label for each field
 * - etc.
 */
const Label = styled(InputLabel)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "bold",
  color: theme.palette.common.black,
  letterSpacing: "1px",
  textTransform: "uppercase",
  marginBottom: "12px"
}));

export default Label;
