import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

interface SectionProps {
  children: React.ReactNode;
  header: string;
  id?: string;
}

/**
 * A reusable Sandbox layout component that creates an
 * official section in the Sandbox (e.g., "Accordions", "Buttons", etc.).
 */
const Section = ({ children, header, id }: SectionProps) => {
  return (
    <Box id={id} sx={{ margin: "36px 0px 100px" }}>
      <Typography variant="overline">
        <b>{header}</b>
      </Typography>
      <Divider sx={{ margin: "8px 0px 36px" }} />
      {children}
    </Box>
  );
};

export default Section;
