import * as React from "react";
import Box from "@mui/material/Box";
import { FieldProps } from "./types";
import Label from "./Label";
import Value from "./Value";

/**
 * A component that displays a section in the View Recommendation modal. Each
 * section has
 * - a particular column label (bolded)
 * - corresponding value for that column
 *
 * **Example**
 * Label = "Domain"
 * Value = "Application Architecture"
 */
const Field = (props: FieldProps): JSX.Element => {
  return (
    <Box sx={{ margin: "16px 0px 8px" }}>
      <Label column={props.column} />
      <Value {...props} />
    </Box>
  );
};

export default Field;
