import * as React from "react";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { User, UserId } from "src/schemas/user";
import AddedUserText from "./AddedUserText";
import YouTag from "./YouTag";

interface ItemProps {
  user: User;
  myUserId: UserId;
  currentUserIdSet: Set<UserId>;
}

const Item = ({ user, myUserId, currentUserIdSet }: ItemProps): JSX.Element => {
  return (
    <ListItem sx={{ padding: "10px 12px" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Stack direction="row" spacing="12px">
          <Typography>{user.Name ?? user.Id}</Typography>
          {myUserId == user.Id && <YouTag />}
        </Stack>
        {!currentUserIdSet.has(user.Id) && <AddedUserText />}
      </Stack>
    </ListItem>
  );
};

export default Item;
