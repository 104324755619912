import * as React from "react";
import Typography from "@mui/material/Typography";
import useAuth from "src/context/Auth";

const Header = (): JSX.Element => {
  const { user } = useAuth();
  return (
    <Typography variant="h4" sx={{ marginTop: "12px", marginBottom: "36px" }}>
      Welcome{user ? `, ${user.attributes.name}` : ""}!
    </Typography>
  );
};
export default Header;
