import * as React from "react";
import { TypographyProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import { MATURITY_THRESHOLD_PASSED_TEXT } from "src/components/Reporting/utils/maturity";

const CalculationDefinition = (props: TypographyProps): JSX.Element => {
  return (
    <Typography {...props}>
      A Domain-Specific Maturity Stage score calculates the % of responses for
      questions linked to a particular Domain <b>and</b> Stage that have{" "}
      <b>an answer value of {MATURITY_THRESHOLD_PASSED_TEXT}</b>. A
      Domain-Specific Maturity Stage has been{" "}
      <b>
        <i>sufficiently met</i>
      </b>{" "}
      when it has a score of 100%. Your <b>overall</b> Maturity Stage is the{" "}
      <b>highest consecutive</b> Stage that has been sufficiently met for that
      Domain.
    </Typography>
  );
};

export default CalculationDefinition;
