import * as React from "react";

export enum Variant {
  standard = "standard",
  filled = "filled",
  outlined = "outlined"
}

export enum Severity {
  success = "success",
  info = "info",
  warning = "warning",
  error = "error"
}

export interface ErrorNotification {
  message: string | React.ReactNode;
}

/** Options to pass in when calling reducer actions */
export interface NotificationOptions extends ErrorNotification {
  timeout?: number;
  createdAt?: number;
}

export interface MakeNotification extends NotificationOptions {
  variant?: Variant;
  severity?: Severity;
}

export interface Notification extends Required<MakeNotification> {
  id: number;
}

export interface NotificationState {
  notifications: Notification[];
}
