import * as React from "react";
import ScrollableContainer from "src/components/Common/ScrollableContainer";
import Header from "./Header";
import Body from "./Body";
import { HEADER_HEIGHT } from "../constants";

export const Questions = () => {
  return (
    <ScrollableContainer
      sxParent={{ flex: 1, borderRadius: 0, border: "none", height: "100%" }}
      sx={{ padding: "0 20px" }}
      header={<Header height={HEADER_HEIGHT} />}
      headerHeight={HEADER_HEIGHT}
    >
      <Body />
    </ScrollableContainer>
  );
};

export default Questions;
