import * as React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import Box from "@mui/material/Box";
import { IChartMaker } from "./types";
import { getDefaultThemes } from "../utils/chartUtils/themes";

const ROOT_ID = "dartAmChartsRoot";

export interface ChartInterface {
  data: any[];
  metadata?: Record<string, any>;
  style: any;
  chart: IChartMaker;
  draggableLegend?: boolean;
  /**
   * Optional prop to grab root and pass it up to parent.
   * **Usage** - we need the root for things like getting colors from
   * am5 Themes and ColorSets. But initialization of Themes + ColorSets
   * relies on root being passed into constructors
   */
  getRoot?: (root: am5.Root) => any;
}

/* Chart
 * Create new amCharts Root
 * Automatically adds an export object to the root
 */
export const Chart = ({
  data,
  metadata,
  style,
  chart,
  draggableLegend,
  getRoot
}: ChartInterface): JSX.Element => {
  const chartRef = React.useRef(null);
  let root: am5.Root;
  const [rootGotten, setRootGotten] = React.useState<boolean>(false);

  React.useLayoutEffect(() => {
    root ??= am5.Root.new(ROOT_ID);

    // If `getRoot` present, pass root up to parent once
    if (getRoot !== undefined && !rootGotten) {
      getRoot(root);
      setRootGotten(true);
    }

    root.setThemes(getDefaultThemes(root));

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: data,
      filePrefix: chart.name
    });
    chartRef.current = new chart({
      data,
      root,
      draggableLegend,
      metadata
    }).make();
    return () => {
      root.dispose();
    };
  }, [chart, data, metadata]);

  return <Box ref={chartRef} id={ROOT_ID} sx={style} />;
};
