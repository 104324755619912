import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";
import InfoIconTooltip from "src/components/Common/Tooltip/InfoIconTooltip";
import Label from "src/components/Common/Input/Label";
import * as React from "react";

const OptionLabel = ({ id, title, required, TooltipHelperText }) => {
  const { palette } = useTheme();
  return (
    <Label htmlFor={id} sx={{ marginBottom: "4px" }}>
      <Stack direction="row" spacing="4px" alignItems="center">
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            color: palette.grey["500"],
            marginLeft: "12px !important"
          }}
        >
          {required ? <b>REQUIRED</b> : "OPTIONAL"}
        </Typography>
        <InfoIconTooltip placement="bottom-start" title={TooltipHelperText} />
      </Stack>
    </Label>
  );
};
export default OptionLabel;
