import * as React from "react";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// Global sidebar width
import { SIDEBAR_WIDTH } from "src/utils/constants/layout";
import { SANDBOX_TOGGLE_BUTTON_SIZE } from "./constants";

interface ToggleSidebarButtonProps {
  sidebarWidth: string; // sandbox sidebar width
  sidebarOpen: boolean;
  toggle: () => any;
}

const ToggleSidebarButton = ({
  sidebarWidth,
  sidebarOpen,
  toggle
}: ToggleSidebarButtonProps) => {
  const { palette } = useTheme();
  const toggleActionText: string = sidebarOpen ? "Close" : "Open";

  // Left offset + positioning for icon button
  const buttonLeftOffset: string = sidebarOpen
    ? `calc(${SIDEBAR_WIDTH} + ${sidebarWidth})`
    : SIDEBAR_WIDTH;
  const buttonLeftPosition = `calc(${buttonLeftOffset} - calc(${SANDBOX_TOGGLE_BUTTON_SIZE} / 2))`; // center

  const ArrowIcon = sidebarOpen
    ? KeyboardArrowLeftRoundedIcon
    : KeyboardArrowRightRoundedIcon;

  return (
    <Tooltip title={`${toggleActionText} sidebar`}>
      <IconButton
        size="small"
        onClick={toggle}
        sx={{
          width: SANDBOX_TOGGLE_BUTTON_SIZE,
          height: SANDBOX_TOGGLE_BUTTON_SIZE,
          position: "fixed",
          left: buttonLeftPosition,
          background: `${palette.primary.main} !important`
        }}
      >
        <ArrowIcon
          sx={{
            color: `${palette.common.white} !important`
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default ToggleSidebarButton;
