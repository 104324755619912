import { LinkedUsersResponse } from "src/schemas/user";
import EvaluationUsersService from "src/services/evaluationUsers";
import { UpdateEvaluationUsersInterface } from "src/services/evaluationUsers/types";

export type PutEvaluationUsers = Omit<
  UpdateEvaluationUsersInterface,
  "callback" | "errorCallback"
>;

/**
 * Simple wrapper around `EvaluationUsersService.put` call so that we
 * return the result of the call or throw an error. This is needed due to
 * how our APICaller methods use callbacks and can't actually return results.
 */
export async function putEvaluationUsers({
  evaluationId,
  evaluationType,
  userIds
}: PutEvaluationUsers): Promise<LinkedUsersResponse> {
  return new Promise((resolve, reject) => {
    EvaluationUsersService.put({
      evaluationId,
      evaluationType,
      userIds,
      callback: (response: LinkedUsersResponse) => resolve(response),
      errorCallback: (error: Error) => reject(error)
    });
  });
}
