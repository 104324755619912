import * as React from "react";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

const AddedUserText = (): JSX.Element => {
  const { palette } = useTheme();
  return (
    <Typography sx={{ fontSize: "13px", color: palette.grey["400"] }}>
      <i>Added user</i>
    </Typography>
  );
};

export default AddedUserText;
