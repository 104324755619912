import { AnyFilterItems, FilterCategory, FilterState } from "../types";

type AllEnabledCategoriesInterface = {
  [key in FilterCategory]: (filterState: FilterState) => boolean;
};

export interface AllEnabledInterface extends AllEnabledCategoriesInterface {
  all: (filterState: FilterState) => boolean;
  category: (filterState: FilterState, category: FilterCategory) => boolean;
}

/**
 * Static class for checking if **each filter item** in different filter
 * categories (e.g., domains, elements, stages) is **explicitly** enabled.
 *
 * **Usage**
 * This class is meant to be used within the following components
 * - ToggleCategoryButton
 * - ToggleAllCategoriesButton
 *
 * to check whenever we should display the following buttons:
 * - "Clear" - to clear all enabled items (within category or entirely)
 * - "All" - to enable (select) all filter items (within category or entirely)
 */
const AllEnabled: AllEnabledInterface = class {
  static category(filterState: FilterState, category: FilterCategory): boolean {
    const filterItems: AnyFilterItems = Reflect.get(filterState, category);
    return Object.keys(filterItems).every((key: string) => {
      return filterItems[`${key}`].enabled;
    });
  }

  static domains(filterState: FilterState): boolean {
    return AllEnabled.category(filterState, FilterCategory.Domains);
  }

  /**
   * Domains and Elements are tied (elements are nested under Domains), so
   * we know that all elements are enabled whenever all domains are enabled.
   * We don't need extra logic to check if every element is enabled.
   */
  static elements(filterState: FilterState): boolean {
    return AllEnabled.domains(filterState);
  }

  static stages(filterState: FilterState): boolean {
    return AllEnabled.category(filterState, FilterCategory.Stages);
  }

  static all(filterState: FilterState): boolean {
    return AllEnabled.domains(filterState) && AllEnabled.stages(filterState);
  }
};

export default AllEnabled;
