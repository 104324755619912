import { z } from "zod";
import { ActiveStatesSchema, PhaseSchema } from "./common";
import { CreateMetadataSchema, UpdateMetadataRequestSchema } from "./metadata";
import { IndexedQuestionSchema, QuestionSchema } from "./question";
import { UserSchema } from "./user";
import { ClientSchema } from "./client";
import { ProjectSchema } from "./project";
import { EvaluationTemplateSchema } from "./evaluationTemplate";
import { RecordOfRecommendationsSchema } from "./recommendation";
import { StagesSchema } from "./stages";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { EvaluationExpandOptions } from "./expands/evaluation";
import { EvaluationSummarySchema } from "./evaluationSummary";

export const EvaluationTypesParamSchema = z.enum([
  EvaluationTypes.assessment,
  EvaluationTypes.survey
]);

export const EvaluationSchema = EvaluationSummarySchema.extend({
  Anonymous: z.boolean(),
  Stages: StagesSchema,
  Questions: QuestionSchema.array()
});

export const EvaluationExpandSchema = z.object({
  [EvaluationExpandOptions.enum.Client]: ClientSchema,
  [EvaluationExpandOptions.enum.Project]: ProjectSchema,
  [EvaluationExpandOptions.enum.Template]: EvaluationTemplateSchema,
  [EvaluationExpandOptions.enum.Users]: UserSchema.array(),
  [EvaluationExpandOptions.enum.Recommendation]: RecordOfRecommendationsSchema
});

/** Extends base Evaluation schema with fields custom to frontend logic */
export const CustomEvaluationSchema = EvaluationSchema.extend({
  Questions: IndexedQuestionSchema.array()
});

export const CreateEvaluationSchema = EvaluationSummarySchema.extend({
  TemplateId: EvaluationTemplateSchema.shape.Id,
  Metadata: CreateMetadataSchema
}).omit({ Id: true, ActiveState: true, Phase: true, Stages: true });

/**
 * Used for configuring evaluation in Create Evaluation from Template page.
 * Fields are based off of `CreateEvaluationState` in Redux
 */
export const CreateEvaluationFormSchema = z.object({
  evaluationType: EvaluationTypesParamSchema,
  name: CreateEvaluationSchema.shape.Name,
  description: CreateEvaluationSchema.shape.Description,
  selectedClient: ClientSchema,
  selectedProject: ProjectSchema,
  selectedTemplate: EvaluationTemplateSchema
});

export const UpdateEvaluationSchema = z.object({
  Name: z.string().optional(),
  Description: z.string().optional(),
  ProjectId: ProjectSchema.shape.Id.optional(),
  ActiveState: ActiveStatesSchema.optional(),
  Phase: PhaseSchema.optional(),
  Metadata: UpdateMetadataRequestSchema
});

/**
 * Edit Evaluation modal -
 * Top-level Evaluation `item` fields (not `expand`) that populate form.
 *
 * Notes:
 * - `Id` and `Metadata` are needed for making update API call
 * - All other fields are optional (in case they're falsy from the BE)
 */
export const UpdateEvaluationItemSchema = EvaluationSchema.pick({
  Id: true,
  Metadata: true
})
  .required()
  .merge(
    EvaluationSchema.pick({
      Name: true,
      Description: true,
      ClientId: true,
      ProjectId: true,
      TemplateId: true
    }).partial()
  );

/**
 * Edit Evaluation modal -
 * Shape of evaluation object that populates form
 */
export const UpdateEvaluationFormSchema = z.object({
  item: UpdateEvaluationItemSchema,
  _expand: EvaluationExpandSchema.optional()
});

/**
 * Edit Evaluation modal -
 * Fields required (cannot be empty) for submitting the form
 *
 * Note: Not adding UI unchangeable fields (ClientId, TemplateId)
 * in case we ever have an edge case where those are empty. User would have
 * no way to update those fields because they're disabled/unchangeable
 */
export const UpdateEvaluationRequiredFieldsSchema =
  UpdateEvaluationSchema.extend({
    Name: z.string().min(1),
    Description: z.string().min(1),
    ProjectId: ProjectSchema.shape.Id.min(1)
  });

// API Request items
export const UpdateEvaluationRequestSchema = z.object({
  item: UpdateEvaluationSchema
});

export const EvaluationIdsParamSchema = z.string(); // comma-separated string

// API Response items
export const EvaluationResponseSchema = z.object({
  item: EvaluationSchema,
  _expand: EvaluationExpandSchema.optional()
});

export const CustomEvaluationResponseSchema = EvaluationSchema.extend({
  item: CustomEvaluationSchema,
  _expand: EvaluationExpandSchema.optional()
});

// TODO: BE should match GET format so we can get rid of this schema eventually
export const EvaluationPostResponseSchema = z.object({
  result: EvaluationSchema
});

export const EvaluationPatchResponseSchema = EvaluationPostResponseSchema;

// Types
export type CreateEvaluation = z.infer<typeof CreateEvaluationSchema>;
export type CreateEvaluationForm = z.infer<typeof CreateEvaluationFormSchema>;
export type UpdateEvaluation = z.infer<typeof UpdateEvaluationSchema>;
export type UpdateEvaluationItem = z.infer<typeof UpdateEvaluationItemSchema>;
export type UpdateEvaluationForm = z.infer<typeof UpdateEvaluationFormSchema>;
export type UpdateEvaluationRequest = z.infer<
  typeof UpdateEvaluationRequestSchema
>;

export type Evaluation = z.infer<typeof EvaluationSchema>;
export type EvaluationResponse = z.infer<typeof EvaluationResponseSchema>;

export type CustomEvaluation = z.infer<typeof CustomEvaluationSchema>;
export type CustomEvaluationResponse = z.infer<
  typeof CustomEvaluationResponseSchema
>;

export type EvaluationExpand = z.infer<typeof EvaluationExpandSchema>;

export type EvaluationPostResponse = z.infer<
  typeof EvaluationPostResponseSchema
>;
export type EvaluationPatchResponse = z.infer<
  typeof EvaluationPatchResponseSchema
>;

export type EvaluationIdsParam = z.infer<typeof EvaluationIdsParamSchema>;
export type EvaluationTypesParam = z.infer<typeof EvaluationTypesParamSchema>;

export type LinkableContent = {
  Id: string;
  Name: string;
};

export interface DetailLinks {
  [EvaluationExpandOptions.enum.Client]: LinkableContent;
  [EvaluationExpandOptions.enum.Project]: LinkableContent;
  [EvaluationExpandOptions.enum.Template]: LinkableContent;
  [EvaluationExpandOptions.enum.Users]: LinkableContent[];
}
