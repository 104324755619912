import { z } from "zod";
import { pick } from "src/utils/object";
import { ExpandOptionsInterface, ExpandOptionsBase } from "./index";

/**
 * Key definition for **recommendation** expand options - specifies which
 * properties to pick from
 * - `ExpandOptionsInterface` - for typing
 * - `ExpandOptionsBase` object - for creation of the zod enum object
 *
 * **Notes**
 * - Do not specify return type since we need key union type below to infer it
 *
 * - Specify as **readonly** array - needed for Typescript 3.4 for key union
 * type. See https://stackoverflow.com/a/45257357
 */
const RecommendationExpandKeys = ["Questions"] as const;

/**
 * Union type of all keys for **recommendation** expand zod enum object.
 */
type RecommendationExpandKeysUnion = (typeof RecommendationExpandKeys)[number];

/**
 * Interface for object used to create expand zod enum object. Ensures object
 * has specified expand keys.
 */
type RecommendationExpandOptionsInterface = Pick<
  ExpandOptionsInterface,
  RecommendationExpandKeysUnion
>;

/**
 * Object with subset of `ExpandOptionsBase` fields used to create specific
 * expand zod enum object.
 */
const RecommendationExpandOptionsBase: RecommendationExpandOptionsInterface =
  pick(ExpandOptionsBase, RecommendationExpandKeys);

/**
 * Zod enum object - used in place of native Typescript enum due to our
 * ability to pass in an object that implements or narrows down our base
 * expand options object. Native enums cannot be based on another enum.
 *
 * **Example Usage**
 * - RecommendationExpandOptions.enum.Questions => "questions"
 */
export const RecommendationExpandOptions = z.nativeEnum(
  RecommendationExpandOptionsBase
);

/**
 * Union of expand enum **values** - e.g.,
 * "questions" | etc.
 */
export type RecommendationExpandOption = z.infer<
  typeof RecommendationExpandOptions
>;
