import { UpdateEvaluationAction } from "src/context/ManageEvaluation/reducers/evaluation/types";
import { IndexedQuestion, UpdateQuestionResponse } from "src/schemas/question";
import EvaluationQuestionService from "src/services/evaluationQuestion";
import { QuestionAPICallWrapper, SuccessCallbackWrapper } from "./types";

export interface PatchQuestionCallback extends SuccessCallbackWrapper {
  response: UpdateQuestionResponse;
}

export interface PatchQuestion extends QuestionAPICallWrapper {
  question: IndexedQuestion;
}

export function patchQuestionSuccessCallback({
  response,
  successCallback
}: PatchQuestionCallback): void {
  return successCallback(
    UpdateEvaluationAction.editQuestion,
    response.item,
    response._meta
  );
}

export async function patchQuestion({
  question,
  questionCRUDFields,
  successCallback
}: PatchQuestion): Promise<void> {
  return EvaluationQuestionService.patch({
    ...questionCRUDFields,
    question,
    callback: (response: UpdateQuestionResponse) =>
      patchQuestionSuccessCallback({ response, successCallback })
  });
}
