import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import ScrollableContainer from "./ScrollableContainer";

export interface HeaderOptions {
  icon: React.ReactNode;
  text: string;
}

const Header = ({ palette, headerOptions, headerHeight }) => {
  return (
    <Stack
      sx={{
        backgroundColor: palette.primary.main,
        color: palette.common.white,
        padding: "12px 24px",
        height: headerHeight
      }}
      direction="row"
      alignItems="center"
      spacing="12px"
    >
      {headerOptions.icon}
      <Typography variant="subtitle1">{headerOptions.text}</Typography>
    </Stack>
  );
};

const ConfigurationContainer = ({
  headerOptions,
  headerHeight = "54px",
  children,
  sxParent,
  sx
}) => {
  const { palette } = useTheme();
  return (
    <ScrollableContainer
      sxParent={{
        borderRadius: "12px",
        border: `1px solid ${palette.primary.main}`,
        ...sxParent
      }}
      sx={sx}
      header={
        <Header
          palette={palette}
          headerOptions={headerOptions}
          headerHeight={headerHeight}
        />
      }
      headerHeight={headerHeight}
    >
      {children}
    </ScrollableContainer>
  );
};

export default ConfigurationContainer;
