import { CustomEvaluationResponse } from "src/schemas/evaluation";
import { EvaluationReducerAction, UpdateEvaluationAction } from "./types";
import addQuestion from "./addQuestion";
import editQuestion from "./editQuestion";
import deleteQuestion from "./deleteQuestion";
import editPhase from "./editPhase";
import edit from "./edit";

export default function updateEvaluationReducer(
  state: CustomEvaluationResponse,
  action: EvaluationReducerAction
) {
  switch (action.type) {
    case UpdateEvaluationAction.set:
      return action.data;
    case UpdateEvaluationAction.addQuestion:
      return addQuestion(state, action.data, action.meta);
    case UpdateEvaluationAction.editQuestion:
      return editQuestion(state, action.data, action.meta);
    case UpdateEvaluationAction.deleteQuestion:
      return deleteQuestion(state, action.data, action.meta);
    case UpdateEvaluationAction.editPhase:
      return editPhase(state, action.data, action.meta);
    case UpdateEvaluationAction.edit:
      return edit(state, action.data);
    default:
      break;
  }
}
