import Button from "@mui/material/Button";
import React from "react";
import useXRay from "src/context/XRay";
import { Segment } from "src/context/XRay/types";
import LabeledBox from "./layout/LabeledBox";
import SectionRow from "./layout/SectionRow";
import { SandboxClass } from "./types";

const XRay = () => {
  const xray = useXRay();
  let segment: Segment;
  return (
    <>
      <SectionRow>
        <LabeledBox label="Segments">
          <Button
            variant="contained"
            onClick={async () => (segment = await xray.beginSegment())}
          >
            Begin Segment
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              segment.error = true;
              segment.cause = "Sandbox test segment";
              await xray.sendSegment(segment);
            }}
          >
            Send Segment
          </Button>
          <Button
            variant="contained"
            onClick={async () => await xray.endSegment(segment)}
          >
            End Segment
          </Button>
        </LabeledBox>
      </SectionRow>
    </>
  );
};
const XRaySandbox: SandboxClass = class {
  static displayName = "X-Ray";
  static element = XRay;
};

export default XRaySandbox;
