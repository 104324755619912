import { MIMEType } from "src/schemas/common";
import { conditionalObject } from "../object";

interface Download {
  data: any;
  fileName: string;
  fileType?: MIMEType;
}

/**
 * Given `data` in any MIME type format,
 * 1. creates a URL containing our `data` in Blob ("file-like") format
 * 1. creates a temporary link in the DOM, with the newly created URL attached
 * to it and the `fileName` associated with it
 * 1. automates a click on the link, which downloads the file
 * 1. removes the temporary link from the DOM
 * @param args.data data in any MIME type format (e.g., could be response data
 * from an API call in "text/csv" format)
 * @param args.fileName name for the downloaded file
 * @param args.fileType optional MIME type to specify the type of Blob we're
 * creating
 *
 * Reference: https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js
 */
export function download({ data, fileName, fileType }: Download): void {
  const blobOptions: BlobPropertyBag = conditionalObject(Boolean(fileType), {
    type: fileType
  });
  const url: string = window.URL.createObjectURL(new Blob([data], blobOptions));

  // Create temporary link in document body
  const link: HTMLAnchorElement = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);

  // Start download
  link.click();

  // Remove temporary link from document body
  link.parentNode.removeChild(link);
}
