import * as React from "react";
import Box from "@mui/material/Box";
import InputContainer from "src/components/Common/Input/InputContainer";
import Search from "src/components/Common/Search";
import { BoxedTags } from "src/components/Common/Tags";
import {
  DisabledTooltip,
  DisabledHelperText
} from "src/components/Common/Tooltip/DisabledTooltip";

interface TagsFieldProps {
  tags: string[];
}

const TagsField = ({ tags }: TagsFieldProps): JSX.Element => {
  return (
    <InputContainer id="tag" title="tags" isSubInput={false}>
      <DisabledTooltip
        title={
          <DisabledHelperText message="Support for editing tags will be coming in the future" />
        }
        placement="bottom-end"
      >
        <Box>
          <Search
            fullWidth
            placeholder="Search for and add / remove tags"
            title="tags"
            disabled
          />
        </Box>
      </DisabledTooltip>

      <BoxedTags
        tags={tags}
        marginLeft="12px"
        marginRight="0px"
        marginTop="12px"
        marginBottom="0px"
        label="No tags currently selected"
      />
    </InputContainer>
  );
};
export default TagsField;
