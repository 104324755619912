import * as React from "react";
import { useSearchParams } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Input from "src/components/Common/Input";
import useReportsContext from "src/context/Reports";
import { Client } from "src/schemas/client";
import { Project } from "src/schemas/project";
import { EvaluationSummary } from "src/schemas/evaluationSummary";
import { Nullable } from "src/utils/types";
import { EvaluationHelperText } from "../../HelperTexts";
import OptionLabel from "../../OptionLabel";
import EvaluationDropdownOption from "./Option";
import { DEFAULT_REPORT } from "src/components/Reporting/Reports/All/constants";

const EvaluationDropdown = (): JSX.Element => {
  /* Context */
  const {
    data,
    selectedClient,
    selectedProject,
    selectedEvaluation,
    setSelectedClient,
    setSelectedProject,
    setSelectedEvaluation,
    setReport
  } = useReportsContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const [evaluations, setEvaluations] =
    React.useState<Nullable<EvaluationSummary[]>>(null);

  // Get initial data to populate dropdown options
  React.useEffect(() => {
    setEvaluations(data.evaluations);
  }, [data.evaluations]);

  // Watch if client and project change - change dropdown options
  React.useEffect(() => {
    if (selectedClient && selectedProject) {
      setEvaluations(
        data.evaluations.filter(
          (item: EvaluationSummary) =>
            item.ClientId == selectedClient.Id &&
            item.ProjectId === selectedProject.Id
        )
      );
    } else if (selectedClient && !selectedProject) {
      setEvaluations(
        data.evaluations.filter(
          (item: EvaluationSummary) => item.ClientId === selectedClient.Id
        )
      );
    } else if (selectedProject && !selectedClient) {
      setEvaluations(
        data.evaluations.filter(
          (item: EvaluationSummary) => item.ProjectId === selectedProject.Id
        )
      );
    } else {
      setEvaluations(data.evaluations);
    }
  }, [selectedClient, selectedProject]);

  // Handle manual user value change - change selected values
  function handleValueChange(newValue: Nullable<EvaluationSummary>): void {
    setSelectedEvaluation(newValue);
    if (newValue) {
      searchParams.set("evaluation", newValue.Id);
      setReport(DEFAULT_REPORT);
      searchParams.set("report", DEFAULT_REPORT);
    } else {
      searchParams.delete("evaluation");
      searchParams.delete("report");
    }

    // Backfill selected project
    if (newValue && !selectedProject) {
      const foundProject: Project | undefined = data.projects.find(
        (item: Project) => item.Id == newValue.ProjectId
      );
      if (foundProject) {
        setSelectedProject(foundProject);
        searchParams.set("project", newValue.ProjectId);
      }
    }

    // Backfill selected client
    if (newValue && !selectedClient) {
      const foundClient: Client | undefined = data.clients.find(
        (item: Client) => item.Id == newValue.ClientId
      );
      if (foundClient) {
        setSelectedClient(foundClient);
        searchParams.set("client", newValue.ClientId);
      }
    }

    setSearchParams(searchParams);
  }

  return (
    <Stack>
      <OptionLabel
        id="evaluations"
        title="Evaluation"
        required={true}
        TooltipHelperText={<EvaluationHelperText />}
      />
      <Autocomplete
        loading={evaluations === null}
        id="evaluation"
        options={evaluations ? evaluations : []}
        renderOption={(props: object, option: EvaluationSummary) => (
          <EvaluationDropdownOption
            key={option.Id}
            props={props}
            option={{
              ...option,
              Template: data.evaluationsExpand[option.TemplateId]
            }}
          />
        )}
        getOptionLabel={(evaluation: EvaluationSummary) => evaluation.Name}
        value={selectedEvaluation}
        onChange={(
          _event: React.SyntheticEvent,
          newSelectedEvaluation: Nullable<EvaluationSummary>
        ) => {
          handleValueChange(newSelectedEvaluation);
        }}
        renderInput={(params: object) => (
          <Input {...params} placeholder="Select an evaluation" />
        )}
      />
    </Stack>
  );
};

export default EvaluationDropdown;
