import { UpdateEvaluationForm } from "src/schemas/evaluation";
import { UpdateEvaluation } from "../evaluation/update";
import { UpdateUsers } from "../users/update";
import ServiceHandler from "./handler";

interface UpdateEvaluationAndLinks extends UpdateUsers, UpdateEvaluation {
  callback: (response: UpdateEvaluationForm) => any;
  errorCallback: (error: Error) => any;
}

/**
 * Entry point into making all API calls related to updating an Evaluation
 * and its links.
 *
 * - If evaluation fields are dirty, call the following suite (see 2 bullets):
 *
 *  - If top-level flat `item` evaluation fields are dirty, calls
 *  `PATCH /evaluations/{evaluationId}` (replace `evaluation` with
 *  provided `evaluationType`)
 *
 *  - If nested `item.Metadata.Notes` field is dirty, calls
 *  `PUT /evaluations/{evaluationId}/notes` (replace `evaluation` with
 *  provided `evaluationType`)
 *
 * - If `_expand.Users` field is dirty, calls the following suite
 * (see 3 bullets):
 *  - `PUT /evaluations/{evaluationId}/users` to create User-Evaluation links
 *  - `PUT /clients/{clientId}/users/{userId}` for each newly added user to
 *  create User-Client link if it doesn't exist
 *  - `PUT /projects/{projectId}/users/{userId}` for each newly added user to
 *  create User-Project link if it doesn't exist
 *
 * Each suite is called asynchronously since they don't depend on each other.
 */
export async function updateEvaluationAndLinks({
  evaluationType,
  evaluation,
  localEvaluation,
  isStateDirty,
  callback,
  errorCallback
}: UpdateEvaluationAndLinks) {
  const serviceHandler: ServiceHandler = new ServiceHandler({
    evaluationType,
    evaluation,
    localEvaluation,
    isStateDirty
  });

  await serviceHandler.update();
  const errors: string[] = serviceHandler.getErrors();

  if (errors.length) {
    errorCallback(new Error(errors.join(". ")));
  } else {
    const updatedEvaluation: UpdateEvaluationAndLinks["localEvaluation"] =
      serviceHandler.getUpdatedEvaluation();
    callback(updatedEvaluation);
  }
}
