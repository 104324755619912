import { CustomEvaluationResponse } from "src/schemas/evaluation";
import { merge } from "src/utils/object";

/**
 * A reducer handler function for updating an Evaluation.
 * @param state current reducer state
 * @param data updated evaluation
 * @returns new reducer state
 */
export default function edit(
  state: CustomEvaluationResponse,
  data: Partial<CustomEvaluationResponse>
): CustomEvaluationResponse {
  return merge(state, {
    item: merge(state.item, data.item),
    _expand: merge(state._expand, data._expand)
  });
}
