import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import * as REPORTS from "src/components/Reporting/Reports/All";
import { getReportHeaderStyles } from "src/components/Reporting/Reports/Report/styles";
import { ReportGroup } from "src/components/Reporting/Reports/Report/types";
import useReportsContext from "src/context/Reports";
import ReportDropdown from "./ReportDropdown";
import ExportDataButton from "./ExportDataButton";

// TODO: Re-enable past MVP when we support a detailed view of reports
// import MoreReportsButton from "./MoreReportsButton";

/**
 * Set of ReportGroup enums that have a top-level export.
 * Use to conditionally render ExportDataButton
 */
export const REPORT_GROUPS_WITH_TOP_EXPORT: Set<ReportGroup> = new Set([
  ReportGroup.maturity
]);

const OptionsHeader = () => {
  const { palette } = useTheme();
  const { report } = useReportsContext();
  const reportGroup: ReportGroup = Reflect.get(REPORTS, report)?.group;
  const showExportDataButton: boolean =
    REPORT_GROUPS_WITH_TOP_EXPORT.has(reportGroup);

  return (
    <Box id="OptionsHeader" sx={getReportHeaderStyles(palette)}>
      <ReportDropdown />
      {/* TODO: Re-enable past MVP when we support a detailed view of reports */}
      {/* <MoreReportsButton /> */}
      {showExportDataButton && (
        <ExportDataButton report={report} reportGroup={reportGroup} />
      )}
    </Box>
  );
};

export default OptionsHeader;
