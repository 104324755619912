import * as React from "react";
import ActionButton from "src/components/Common/ActionButton";
import ErrorMessage from "src/components/Common/ErrorMessage";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import { UpdateEvaluationAction } from "src/context/ManageEvaluation/reducers/evaluation/types";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError, showSuccess } from "src/store/slices/notification";
import {
  UpdateEvaluationForm,
  UpdateEvaluationRequiredFieldsSchema
} from "src/schemas/evaluation";
import { User } from "src/schemas/user";
import { hasChanged, isDirty } from "src/utils/dirtyData";
import { isValid } from "src/utils/validators";
import { updateEvaluationAndLinks } from "../../services/all";
import { IsStateDirty, SubmitButtonProps } from "../../types";
import { getDisabledTooltipText } from "../../utils/text";

const SubmitButton = ({
  disabled,
  setDisabled,
  evaluation,
  localEvaluation,
  handleClose
}: SubmitButtonProps): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();
  const { evaluationType, dispatchEvaluation } = useManageEvaluationContext();
  /* Keep track of dirty state
   * Note - based on each field, we call a different API endpoint
   */
  const isStateDirty: IsStateDirty = {
    evaluation: false,
    notes: false,
    users: false
  };

  /* Top-Level Flat Evaluation state */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { Metadata: evaluationMetadata, ...flatEvaluation } = evaluation.item;
  const { Metadata: localEvaluationMetadata, ...flatLocalEvaluation } =
    localEvaluation.item;
  /* eslint-enable @typescript-eslint/no-unused-vars */
  isStateDirty.evaluation = isDirty(flatEvaluation, flatLocalEvaluation);

  /* Notes state */
  const currentNotes: string = evaluation.item.Metadata.Notes[0];
  const notes: string = localEvaluation.item.Metadata.Notes[0];
  isStateDirty.notes = hasChanged(currentNotes, notes);

  /* Users state */
  const currentUsers: User[] = evaluation._expand.users;
  const users: User[] = localEvaluation._expand.users;
  isStateDirty.users = hasChanged(currentUsers, users);

  /* Form is invalid if required form fields are empty
   * (only Top-level Evaluation state for now)
   */
  const isFormInvalid = !isValid(
    localEvaluation.item,
    UpdateEvaluationRequiredFieldsSchema
  );

  /** Boolean indicating if form data has changed or not */
  const isFormClean: boolean = Object.keys(isStateDirty).every(
    (key: string) => !Reflect.get(isStateDirty, key)
  );
  /* All cases where button is disabled - using `_` prefix to avoid
   * name conflicts with incoming `disabled` prop
   */
  const _disabled: boolean = isFormInvalid || isFormClean || disabled;

  // Text
  const tooltipText: string = getDisabledTooltipText({
    evaluationType,
    isFormClean,
    isFormInvalid
  });

  // Loading state
  const [loading, setLoading] = React.useState<boolean>(false);

  function setLoadingStates(loading: boolean): void {
    setLoading(loading);
    setDisabled(loading);
  }

  function successCallback(response: UpdateEvaluationForm): void {
    dispatchEvaluation({
      type: UpdateEvaluationAction.edit,
      data: response
    });
    setLoadingStates(false);
    handleClose(); // Close the modal
    dispatch(
      showSuccess({
        message: `Your ${evaluationType} was successfully updated.`
      })
    );
  }

  function errorCallback(error: Error) {
    setLoadingStates(false);
    handleClose(); // Close the modal
    dispatch(
      showError({
        message: (
          <ErrorMessage
            error={error}
            action={`updating your ${evaluationType}`}
          />
        )
      })
    );
  }

  async function handleSubmit(): Promise<void> {
    setLoadingStates(true);

    /* Update
     * - Top-level Evaluation fields
     * - Evaluation Notes
     * - Evaluation Users, User-Client links, and User-Project links
     */
    return updateEvaluationAndLinks({
      evaluationType,
      evaluation,
      localEvaluation,
      callback: successCallback,
      errorCallback,
      isStateDirty
    });
  }

  return (
    <ActionButton
      tooltipLoadingText={`We're updating your ${evaluationType} - hold on tight`}
      tooltipDisabledText={tooltipText}
      TooltipProps={{
        placement: "top-end"
      }}
      onClick={handleSubmit}
      loading={loading}
      disabled={_disabled}
    >
      {loading ? "Updating" : "Update"}
    </ActionButton>
  );
};

export default SubmitButton;
