import * as React from "react";
import Stack from "@mui/material/Stack";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import DynamicAutocomplete from "../../../../../components/Common/Autocomplete/DynamicAutocomplete";
import DynamicInput from "src/components/Common/Input/DynamicInput";
import AnswerSection from "./AnswerSection";
import RecommendationSection from "./RecommendationSection";
import { Answer } from "src/schemas/question";
import Modal from "src/components/Common/Modal";
import { ContentProps } from "../../types";

const Content = ({
  isEditing,
  question,
  dispatchQuestion,
  recommendation,
  dispatchRecommendation
}: ContentProps): JSX.Element => {
  const { filterState } = useManageEvaluationContext();

  /**
   * Value for Domain dropdown
   */
  const domains: string[] = Object.keys(filterState.domains).sort();

  /**
   * State value for Element dropdown - this value changes if a domain is
   * selected or not
   */
  const [elements, setElements] = React.useState<string[]>([]);

  function getElements(domain: string): string[] {
    return Object.keys(
      Reflect.get(filterState.domains, domain).elements
    ).sort();
  }

  function changeDomain(domain: string): void {
    // Clear selected Element
    if (question?.Element) {
      dispatchQuestion({ type: "Element", data: null });
    }
    // Populate Element dropdown based on domain selection
    setElements(domain ? getElements(domain) : []);

    dispatchQuestion({ type: "Domain", data: domain });
  }

  /**
   * Value for Stage dropdown
   */
  const stages: number[] = Object.keys(filterState.stages)
    .sort()
    .map((item: string) => parseInt(item, 10));

  return (
    <Modal.Content sx={{ padding: "36px 24px" }}>
      <Stack spacing="32px">
        <DynamicAutocomplete
          id="domain"
          title="Domain"
          options={domains ?? []}
          value={question?.Domain}
          onChange={(_event: React.SyntheticEvent, data: string) =>
            changeDomain(data)
          }
          inputProps={{
            placeholder: "Select a domain",
            type: "text"
          }}
          disabled={isEditing}
        />
        <DynamicAutocomplete
          id="element"
          title="Element"
          options={elements ?? []}
          value={question?.Element}
          onChange={(_event: React.SyntheticEvent, data: string) =>
            dispatchQuestion({ type: "Element", data })
          }
          inputProps={{
            placeholder: "Select an element",
            type: "text"
          }}
          disabled={isEditing || !question?.Domain}
          /* If in "Add Question" modal,
           * - disable Element dropdown until a Domain is selected
           * - prompt user to select a Domain first
           */
          {...(!isEditing && !question?.Domain
            ? {
                disabledToolTipProps: {
                  helperTextProps: { message: "Please select a Domain first" }
                }
              }
            : {})}
        />
        <DynamicAutocomplete
          id="stage"
          title="Stage"
          options={stages ?? []}
          value={question?.Stage}
          onChange={(_event: React.SyntheticEvent, data: string) =>
            dispatchQuestion({ type: "Stage", data })
          }
          getOptionLabel={(option: any) =>
            Reflect.get(filterState.stages, option).formatted
          }
          inputProps={{
            placeholder: "Select a maturity stage",
            type: "text"
          }}
          disabled={isEditing}
        />
        <DynamicInput
          id="question"
          title="question"
          value={question?.Text}
          setValue={(data: string) => dispatchQuestion({ type: "Text", data })}
          multiline
          placeholder="Enter a new question"
        />
        <AnswerSection
          answers={question?.Answers}
          setAnswers={(data: Answer[]) =>
            dispatchQuestion({ type: "Answers", data })
          }
        />
        <RecommendationSection
          recommendation={recommendation}
          dispatchRecommendation={dispatchRecommendation}
          disabled={isEditing}
        />
      </Stack>
    </Modal.Content>
  );
};
export default Content;
