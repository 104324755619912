import * as React from "react";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { User, UserId } from "src/schemas/user";
import { createUserIdSet, sortUsers } from "../../../../../utils";
import useAuth from "src/context/Auth";
import Item from "./Item";
import { UsersListProps } from "../types";

const UsersList = ({ currentUsers, users }: UsersListProps): JSX.Element => {
  const { palette } = useTheme();
  const { user } = useAuth();

  const myUserId: string = user.attributes.email;
  const sortedUsers: User[] = sortUsers(users, myUserId);

  const currentUserIdSet: Set<UserId> = createUserIdSet(currentUsers);

  return (
    <Stack>
      <Typography sx={{ fontSize: "14px", color: palette.grey["700"] }}>
        <b>Linked Users</b>
      </Typography>
      <List sx={{ paddingTop: "0px" }}>
        {sortedUsers.map((user: User, index: number) => (
          <>
            <Item
              key={user.Id}
              user={user}
              myUserId={myUserId}
              currentUserIdSet={currentUserIdSet}
            />
            {index != sortedUsers.length - 1 && <Divider />}
          </>
        ))}
      </List>
    </Stack>
  );
};

export default UsersList;
