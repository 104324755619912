import { ZodError, ZodIssue } from "zod";

/**
 * Given an object of type ZodIssue,
 * returns a string of the following format:
 *
 * - `{schemaField} - {message}`
 *
 * **Example**: `item.Metadata.DateModified - Expected number, received string.`
 *
 */
function formatIssue(issue: ZodIssue): string {
  return `${issue.path.join(".")} - ${issue.message}.`;
}

/**
 * Given an error of type ZodError,
 * returns an empty string if no error issues exist.
 * Otherwise, returns a concatenated string of error issues, where each
 * issue string has this format:
 *
 * - `{schemaField} - {message}`
 *
 * and each error string is separated by `" "` delimiter.
 *
 * **Example**:
 *
 * `item.Phase - Invalid enum value. Expected 'editing' | 'open' | 'closed' |
 * 'completed', received 'hello'. item.Metadata.DateModified - Expected number,
 *  received string.`
 *
 */
export function formatError<T>(error: ZodError<T>): string {
  if (!error.issues.length) {
    return "";
  }
  return error.issues.map(formatIssue).join(" ");
}
