import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Header = (): JSX.Element => {
  return (
    <Box>
      <Typography variant="h6">
        <b>Delete Question</b>
      </Typography>
    </Box>
  );
};

export default Header;
