import * as React from "react";
import { ButtonProps } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
  BUTTON_SIZING_CONFIG,
  LOADING_ICON_COLOR
} from "src/utils/constants/button";
import ConditionalWrapper from "../ConditionalWrapper";
import { CoreActionButtonProps } from "./types";

/**
 * A reusable loading button component (extended from MUI button)
 * since MUI's LoadingButton component is a testing stage (lab).
 *
 * **Note**:
 * This is a PRIVATE component that is used to create `ActionButton`.
 * This component should NOT be used across the application -
 * `ActionButton` should.
 */
const CoreActionButton = ({
  children,
  loading,
  size = ButtonSizeEnum.large,
  variant = ButtonVariantEnum.contained,
  ...rest
}: CoreActionButtonProps): JSX.Element => {
  const sizeStyles: Record<string, any> = BUTTON_SIZING_CONFIG.get(
    ButtonSizeEnum[`${size}`]
  );
  const loadingIconColorStyle: string = LOADING_ICON_COLOR.get(
    ButtonVariantEnum[`${variant}`]
  );

  const buttonProps: ButtonProps = {
    ...rest,
    size,
    variant,
    disabled: rest.disabled || loading
  };

  return (
    <Button {...buttonProps}>
      <Box
        component="span"
        sx={{
          fontSize: sizeStyles.fontSize
        }}
      >
        {children}
      </Box>
      <ConditionalWrapper condition={loading}>
        <CircularProgress
          size={sizeStyles.iconSize}
          sx={{
            color: loadingIconColorStyle,
            marginLeft: sizeStyles.spacing
          }}
        />
      </ConditionalWrapper>
    </Button>
  );
};

export default CoreActionButton;
