import {
  DetailLinks,
  EvaluationExpand,
  LinkableContent
} from "src/schemas/evaluation";
import { EvaluationExpandOptions } from "src/schemas/expands/evaluation";

export const mapExpandToLinkables = (
  expandedRecords: EvaluationExpand
): DetailLinks | null => {
  if (!expandedRecords) {
    return null;
  }

  return {
    [EvaluationExpandOptions.enum.Project]: mapExpandToLinkable(
      expandedRecords[EvaluationExpandOptions.enum.Project]
    ),
    [EvaluationExpandOptions.enum.Client]: mapExpandToLinkable(
      expandedRecords[EvaluationExpandOptions.enum.Client]
    ),
    [EvaluationExpandOptions.enum.Template]: mapExpandToLinkable(
      expandedRecords[EvaluationExpandOptions.enum.Template]
    ),
    [EvaluationExpandOptions.enum.Users]: expandedRecords[
      EvaluationExpandOptions.enum.Users
    ].map((user): LinkableContent => mapExpandToLinkable(user))
  };
};

const mapExpandToLinkable = ({
  Id,
  Name
}: Record<string, any>): LinkableContent => ({ Id, Name });
