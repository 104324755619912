import * as React from "react";
import { CognitoUserInterface as CognitoUser } from "@aws-amplify/ui-components";
import { ThemeProvider } from "@mui/material/styles";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "src/store";
import Loading from "../components/Common/Loading";
import Notifications from "../components/Common/Notifications";
import { AuthProvider } from "../context/Auth";
import { EnvironmentSettingsProvider } from "../context/EnvironmentSettings";
import { EnvironmentSettingsContextType } from "../context/EnvironmentSettings/types";
import { XRayProvider } from "../context/XRay";
import AppRoutes from "../routes";
import theme from "../theme";
import { Nullable } from "../utils/types";
import Preloader from "./services/preloader";

const App = (): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [user, setUser] = React.useState<Nullable<CognitoUser>>(null);
  const [envSettings, setEnvSettings] =
    React.useState<EnvironmentSettingsContextType>(null);

  async function preload(): Promise<void> {
    const { settings, authenticatedUser } = await Preloader.get();
    setEnvSettings(settings);
    setUser(authenticatedUser);
    setIsLoading(false);
  }

  React.useEffect(() => {
    preload();
  }, []);

  return (
    <ReduxProvider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          {isLoading ? (
            <Loading height="100vh" />
          ) : (
            <EnvironmentSettingsProvider envSettings={envSettings}>
              <AuthProvider initialUserValue={user}>
                <XRayProvider>
                  <Notifications />
                  <AppRoutes />
                </XRayProvider>
              </AuthProvider>
            </EnvironmentSettingsProvider>
          )}
        </ThemeProvider>
      </Router>
    </ReduxProvider>
  );
};

export default App;
