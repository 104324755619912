import * as React from "react";
import { styled } from "@mui/material/styles";
import { Stack, useTheme } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { ActionsData } from "./ActionsData";
import { PHASES, PhaseTypesEnum } from "src/utils/constants/phase";
import PhaseChip from "src/components/Common/Chip/Phase";
import { PhaseActionInfo } from "./types";
import ConditionalWrapper from "src/components/Common/ConditionalWrapper";
import { conditionalObject } from "src/utils/object";

const firstCellStyles: Record<string, any> = {
  padding: "8px 12px 8px 0px"
};
const rowStyles: Record<string, any> = {
  "&:last-child td, &:last-child th": { border: 0 }
};

function getPhaseCellStyles(
  currentPhase: PhaseTypesEnum,
  phase: PhaseTypesEnum,
  highlightColor: string
): Record<string, any> {
  return {
    ...conditionalObject(currentPhase == phase, {
      backgroundColor: highlightColor
    }),
    padding: "8px 12px"
  };
}

const SubText = styled(Typography)(({ theme }) => ({
  marginTop: "4px",
  fontSize: "12px",
  color: theme.palette.grey["700"]
}));

const PhaseActionTable = ({
  currentPhase
}: {
  currentPhase: PhaseTypesEnum;
}) => {
  const { palette } = useTheme();
  return (
    <Table aria-label="phase-action-table" sx={{ border: 0 }}>
      <TableHead>
        <TableRow>
          <TableCell sx={firstCellStyles}>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Actions
            </Typography>
          </TableCell>
          {PHASES.map((phase: PhaseTypesEnum) => (
            <TableCell
              align="center"
              width="130px"
              sx={getPhaseCellStyles(
                currentPhase,
                phase,
                palette.bg.lightYellow
              )}
            >
              <PhaseChip phase={PhaseTypesEnum[`${phase}`]} />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {ActionsData.map((action: PhaseActionInfo, index: number) => (
          <TableRow key={index} sx={rowStyles}>
            <TableCell component="th" scope="row" sx={firstCellStyles}>
              <Typography sx={{ fontSize: "14px" }}>{action.name}</Typography>
              <ConditionalWrapper condition={Boolean(action.summary)}>
                <SubText>{action.summary}</SubText>
              </ConditionalWrapper>
            </TableCell>
            {PHASES.map((phase: PhaseTypesEnum) => (
              <TableCell
                align="center"
                sx={getPhaseCellStyles(
                  currentPhase,
                  phase,
                  palette.bg.lightYellow
                )}
              >
                <ConditionalWrapper condition={action[`${phase}`]}>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", height: "100%" }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{ color: palette.success.main }}
                    />
                  </Stack>
                </ConditionalWrapper>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default PhaseActionTable;
