import { CreateQuestion } from "src/schemas/question";
import { Recommendation } from "src/schemas/recommendation";
import { RecommendationResponse } from "src/schemas/recommendation/response";
import RecommendationService from "src/services/recommendation";
import { conditionalObject } from "src/utils/object";
import { postQuestion } from "./postQuestion";
import { QuestionAPICallWrapper } from "./types";

export interface PostRecommendation {
  recommendation: Recommendation;
  errorCallback: (error: Error) => any;
}

export interface PostRecommendationAndQuestion
  extends PostRecommendation,
    QuestionAPICallWrapper {
  question: CreateQuestion;
}

export async function postRecommendation({
  recommendation,
  errorCallback
}: PostRecommendation): Promise<Recommendation> {
  return await new Promise((resolve, reject) => {
    RecommendationService.post({
      recommendation,
      callback: (response: RecommendationResponse) => resolve(response.item),
      errorCallback: (error: Error) => {
        errorCallback(error);
        reject(error);
      }
    });
  });
}

export async function postRecommendationAndQuestion({
  question,
  questionCRUDFields,
  recommendation,
  successCallback,
  errorCallback
}: PostRecommendationAndQuestion): Promise<void> {
  // Create new recommendation
  const newRecommendation: Recommendation = await postRecommendation({
    recommendation,
    errorCallback
  });

  // Create new question with new recommendation's id
  const newQuestion: CreateQuestion = {
    ...question,
    ...conditionalObject(Boolean(newRecommendation?.Id), {
      RecommendationId: newRecommendation.Id
    })
  };

  return postQuestion({
    question: newQuestion,
    questionCRUDFields,
    successCallback,
    recommendation: newRecommendation
  });
}
