import { EmptyResponse } from "src/schemas/http";
import { UserId } from "src/schemas/user";
import UserProjectService from "src/services/userProject";
import { CreateUserProjectInterface } from "src/services/userProject/types";

type PutUserProjectLink = Pick<
  CreateUserProjectInterface,
  "userId" | "projectId"
>;

export interface PutUserProjectLinks
  extends Pick<CreateUserProjectInterface, "projectId"> {
  userIds: UserId[];
}

/**
 * Simple wrapper around `UserProjectService.put` call so that we
 * return the result of the call or throw an error. This is needed due to
 * how our APICaller methods use callbacks and can't actually return results.
 */
async function putUserProjectLink({
  userId,
  projectId
}: PutUserProjectLink): Promise<EmptyResponse> {
  return new Promise((resolve, reject) => {
    UserProjectService.put({
      userId,
      projectId,
      callback: (response: EmptyResponse) => resolve(response),
      errorCallback: (error: Error) => reject(error)
    });
  });
}

/**
 * Promise.all wrapper around `putUserProjectLink` above so that we can create
 * User-Project links for each newly added user.
 */
export async function putUserProjectLinks({
  userIds,
  projectId
}: PutUserProjectLinks): Promise<EmptyResponse[]> {
  const userProjectPromises: Promise<EmptyResponse>[] = userIds.map(
    (userId: UserId) => putUserProjectLink({ userId, projectId })
  );

  return Promise.all(userProjectPromises);
}
