import { styled } from "@mui/material/styles";
import { ReactComponent as DARTLogoSVG } from "src/assets/dartLogo.svg";
import { CustomSVGProps } from "./types";

const DARTLogo = styled(DARTLogoSVG)<CustomSVGProps>(({ theme, ...props }) => ({
  height: props.height || props.width ? "auto" : "24px",
  width: props.width || props.height ? "auto" : "24px",
  path: {
    fill: props.fill || theme.palette.common.black
  }
}));

export default DARTLogo;
