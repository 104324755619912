import * as React from "react";
import { styled } from "@mui/material/styles";
import Content from "./Content";
import Footer from "./Footer";
import { HEADER_HEIGHT } from "src/utils/constants/layout";

const Container = styled("div")({
  width: "100%",
  height: `calc(100vh - ${HEADER_HEIGHT})`,
  marginTop: HEADER_HEIGHT,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
});

const Body = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>
      <Footer />
    </Container>
  );
};

export default Body;
