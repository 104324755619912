import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { CircularProgress } from "@mui/material";

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  alignItems: "flex-start",
  marginTop: "16px",
  "& .MuiFormControlLabel-label": {
    lineHeight: "1.4" // to better align the top of the text with the radio buttons
  },
  "&:hover .MuiRadio-root": {
    color: theme.palette.primary.main
  },
  "&.Mui-disabled": {
    cursor: "not-allowed" // show not-allowed pointer when response is being submitted
  }
}));

export const DisabledStyledFormControlLabel = styled(StyledFormControlLabel)(
  () => ({
    pointerEvents: "none"
  })
);

/* Radio buttons + submission loading */
export const RADIO_SIZE = "20px"; // comes in as default 24px - 2px of padding on every side
export const RADIO_PADDING = "2px";
const RADIO_MARGIN = "0 8px";

export const StyledRadio = styled(Radio)(() => ({
  padding: "0px",
  margin: RADIO_MARGIN
}));

export const StyledCircularProgress = styled(CircularProgress)(() => ({
  margin: RADIO_MARGIN
}));
