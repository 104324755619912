import { DomainMap } from "src/components/Common/Accordion/types";
import {
  EvaluationTemplate,
  EvaluationTemplateResponse
} from "src/schemas/evaluationTemplate";
import { Question } from "src/schemas/question";
import { PreviewMode, PreviewRowsState } from "../../types";

/**
 * Static class for transforming and creating different data within the
 * Preview component. Methods are named according to the data they transform
 * or create.
 *
 * - rowsState - for managing the accordion state for the Questions & Domains
 * components
 *
 * - template - for consolidating an evaluation template's questions and the
 * questions' corresponding recommendations
 *
 * - domains - for deriving a map of all Domains and their Elements based on the
 * evaluation template's questions
 */
export class PreviewDataHandler {
  static template(response: EvaluationTemplateResponse): EvaluationTemplate {
    const { item, _expand } = response;

    // Add Recommendation key to Questions
    item.Questions = item.Questions.map((question: Question) => ({
      ...question,
      Recommendation: _expand.recommendation[question.RecommendationId]
    }));

    return item;
  }

  static domains(questions: Question[]): DomainMap {
    const domainMap: DomainMap = new Map();

    questions.forEach((question: Question) => {
      if (domainMap.has(question.Domain)) {
        domainMap.get(question.Domain).add(question.Element);
      } else {
        domainMap.set(question.Domain, new Set([question.Element]));
      }
    });

    return domainMap;
  }

  static rowsState(questions: Question[]): PreviewRowsState {
    // Populate a key'd object of Question Ids and Domains for expansion management
    const state: PreviewRowsState = {};
    questions.forEach((question: Question) => {
      Reflect.set(state, question.Id, {
        expanded: false,
        type: PreviewMode.Questions
      });

      if (!Reflect.get(state, question.Domain)) {
        Reflect.set(state, question.Domain, {
          expanded: false,
          type: PreviewMode.Domains
        });
      }
    });

    return state;
  }
}
