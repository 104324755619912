import * as React from "react";
import Box from "@mui/material/Box";
import RecommendationCount from "../../Common/Recommendations/DataTableOverview/RecommendationCount";
import Prompt from "../../Common/Recommendations/DataTableOverview/Prompt";
import { baselineVerticalAlign } from "src/utils/styles/text/verticalAlign";
import { RecommendationCountProps } from "../../Common/Recommendations/DataTableOverview/types";

type DataTableOverviewProps = Pick<RecommendationCountProps, "count">;

/**
 * A component shown above the Data Grid. This component shows the following:
 * - # of Recommendations subheader
 * - Prompt to encourage the user to interact with the Data Table component
 */
const DataTableOverview = ({ count }: DataTableOverviewProps): JSX.Element => {
  return (
    <Box sx={{ marginBottom: "16px" }}>
      <RecommendationCount
        count={count}
        sx={{ ...baselineVerticalAlign, marginRight: "12px" }}
      />
      <Prompt sx={baselineVerticalAlign} />
    </Box>
  );
};

export default DataTableOverview;
