import create from "./create";
import { CreateUserClientInterface } from "./types";

/**
 * API Service class for performing actions on an User-Client Links
 */
class UserClientService {
  static put(args: CreateUserClientInterface): void {
    return create(args);
  }
}

export default UserClientService;
