import * as React from "react";
import { styled } from "@mui/material/styles";
import { TooltipProps } from "@mui/material";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export interface DisabledHelperTextProps {
  item?: string;
  message?: string;
}

export const DisabledHelperText = ({
  item = "item",
  message
}: DisabledHelperTextProps) => {
  const text =
    message ?? `Once the ${item} has been selected, it cannot be changed`;
  return <Box sx={{ fontSize: "14px" }}>{text}</Box>;
};

export const DisabledTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  { shouldForwardProp: (prop) => prop !== "sx" }
)(({ sx = {} }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: sx["maxWidth"] ?? 550
  }
}));
