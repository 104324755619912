import * as am5 from "@amcharts/amcharts5";
import blue from "@mui/material/colors/blue";
import teal from "@mui/material/colors/teal";
import lightGreen from "@mui/material/colors/lightGreen";
import amber from "@mui/material/colors/amber";
import orange from "@mui/material/colors/orange";
import red from "@mui/material/colors/red";

/**
 * Sets up custom color theme for heatmap with 6 buckets.
 * Grabs colors from MUI: https://mui.com/material-ui/customization/color/
 */
export class SixBucketsTheme extends am5.Theme {
  setupDefaultRules() {
    this.rule("ColorSet").setAll({
      colors: [
        am5.Color.fromString(blue.A100), // 100%
        am5.Color.fromString(teal["200"]), // 81 - 99%
        am5.Color.fromString(lightGreen["300"]), // 61 - 80%
        am5.Color.fromString(amber["300"]), // 41 - 60%
        am5.Color.fromString(orange["400"]), // 21 - 40%
        am5.Color.fromString(red.A100) // 0 - 20%
      ],
      userData: {
        colorPointIntervals: [100, 81, 61, 41, 21, 0]
      }
    });
  }
}
