import update from "./update";
import { UpdateEvaluationNotesInterface } from "./types";

/**
 * API Service class for performing actions on an evaluation's Notes
 */
class EvaluationNotesService {
  static put(args: UpdateEvaluationNotesInterface): void {
    return update(args);
  }
}

export default EvaluationNotesService;
