import { plural } from "pluralize";
import {
  LinkedUsersResponseSchema,
  ModifyLinkedUsersRequest,
  ModifyLinkedUsersRequestSchema
} from "src/schemas/user";
import { PutAPICaller, PutAPICallRequest } from "../APICall";
import { UpdateEvaluationUsersInterface } from "./types";

/**
 * Updates users linked to an evaluation.
 * - `PUT /assessments/{assessmentId}/users`
 * - `PUT /surveys/{surveyId}/users`
 */
export default function update({
  evaluationId,
  evaluationType,
  userIds,
  callback,
  errorCallback
}: UpdateEvaluationUsersInterface): void {
  const path = `/${plural(evaluationType)}/${evaluationId}/users`;

  const body: ModifyLinkedUsersRequest = ModifyLinkedUsersRequestSchema.parse({
    item: userIds
  });

  const request: PutAPICallRequest = {
    path,
    body,
    callback,
    errorCallback,
    typeValidator: LinkedUsersResponseSchema,
    withReactHook: false
  };

  return PutAPICaller(request);
}
