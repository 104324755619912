import * as React from "react";
import { capitalize, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "src/components/Common/Modal";
import { HeaderProps } from "../types";

const Header = ({ action }: HeaderProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Modal.Header
      sx={{
        backgroundColor: palette.primary.main,
        color: palette.common.white
      }}
    >
      <Box>
        <Typography variant="h6">
          <b>{capitalize(action)} Question</b>
        </Typography>
      </Box>
    </Modal.Header>
  );
};

export default Header;
