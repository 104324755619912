import * as React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ActionRow from "./ActionRow";
import PhaseProgressionRow from "./PhaseProgressionRow";
import { PhaseTypesEnum } from "src/utils/constants/phase";

export const Header = ({
  name,
  phase
}: {
  name: string;
  phase: PhaseTypesEnum;
}) => (
  <Stack spacing="12px" sx={{ marginBottom: "24px" }}>
    <Typography variant="h4">{name}</Typography>
    <PhaseProgressionRow currentPhase={phase} />
    <ActionRow phase={phase} />
  </Stack>
);

export default Header;
