import * as React from "react";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SlalomLogo from "src/components/Common/svg/SlalomLogo";

const Footer = () => {
  const { palette } = useTheme();
  /* Get year dynamically */
  const currentYear = new Date().getFullYear();
  return (
    <Stack alignItems="center" sx={{ marginTop: "60px" }}>
      <SlalomLogo
        fill={palette.common.black}
        height="24px"
        sx={{ margin: "0 0 16px 0" }}
      />
      <Typography variant="body1">
        <span className="copyright">&copy;</span> {currentYear} Slalom, LLC
      </Typography>
    </Stack>
  );
};

export default Footer;
