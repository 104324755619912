import { Metadata } from "src/schemas/metadata";

/**
 * Enum indicating all possible actions for updating the
 * `evaluation` object in the ManageEvaluation context.
 */
export enum UpdateEvaluationAction {
  set = "set",
  edit = "edit",
  addQuestion = "addQuestion",
  editQuestion = "editQuestion",
  deleteQuestion = "deleteQuestion",
  editPhase = "editPhase",
  editUsers = "editUsers"
}

/**
 * Object interface for the payload passed into the `dispatchEvaluation`
 * method in the ManageEvaluation context.
 */
export interface EvaluationReducerAction {
  type: UpdateEvaluationAction;
  data: any; // can be evaluation, evaluation question, evaluation stage, etc. depending on action
  meta?: Metadata;
}
