import { plural } from "pluralize";
import { RecommendationsQueryParams } from "src/schemas/recommendation/request";
import { RecommendationListResponseSchema } from "src/schemas/recommendation/response";
import { conditionalObject } from "src/utils/object";
import { GetAPICaller, GetAPICallRequest } from "../APICall";
import { GetEvaluationRecommendationsInterface } from "./types";

/**
 * Gets all recommendations for a particular evaluation.
 * - `GET /assessments/{assessmentId}/recommendations`
 * - `GET /surveys/{surveyId}/recommendations`
 */
export default function get({
  evaluationId,
  evaluationType,
  expand,
  callback,
  errorCallback
}: GetEvaluationRecommendationsInterface): void {
  const path = `/${plural(evaluationType)}/${evaluationId}/recommendations`;

  const queryStringParameters: RecommendationsQueryParams = {
    ...conditionalObject(Boolean(expand), {
      expand: expand.join(",")
    })
  };

  const request: GetAPICallRequest = {
    path,
    queryStringParameters,
    callback,
    errorCallback,
    typeValidator: RecommendationListResponseSchema,
    withReactHook: false
  };

  return GetAPICaller(request);
}
