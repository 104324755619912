import * as React from "react";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError } from "src/store/slices/notification";
import { PhaseSchema } from "src/schemas/common";
import {
  EvaluationSummary,
  EvaluationSummaryListResponse
} from "src/schemas/evaluationSummary";

import ErrorMessage from "src/components/Common/ErrorMessage";
import { Collection } from "src/utils/constants/collection";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import UserEvaluationsService from "src/services/userEvaluations";
import { getEvaluationsGridItemLgStyles } from "../../utils/styles";
import Header from "./Header";
import Body from "./Body";
import { EvaluationsGridItemProps } from "./types";

const EvaluationsGridItem = ({
  evaluationType
}: EvaluationsGridItemProps): JSX.Element => {
  const theme = useTheme();
  /* TODO: The loading state for survey should be `null` past MVP
   * - `null` is for the initial loading state
   */
  const isSurveyType: boolean = evaluationType == EvaluationTypes.survey;
  const evaluationsInitialState: EvaluationSummary[] = isSurveyType ? [] : null;
  const [evaluations, setEvaluations] = React.useState<EvaluationSummary[]>(
    evaluationsInitialState
  );

  const dispatch: AppDispatch = useAppDispatch();

  function successCallback(response: EvaluationSummaryListResponse) {
    setEvaluations(response.item);
  }

  function errorCallback(error: Error) {
    setEvaluations([]);
    dispatch(
      showError({
        message: (
          <ErrorMessage error={error} collection={Collection.evaluation} />
        )
      })
    );
  }

  // TODO: The `!isSurveyType` should be removed past MVP to get the list of surveys
  React.useEffect(() => {
    if (!isSurveyType) {
      const queryStringParameters: Record<string, string> = {
        filter: JSON.stringify({
          Phase: { $not: { $eq: PhaseSchema.enum.completed } }
        })
      };
      UserEvaluationsService.get({
        evaluationType,
        queryStringParameters,
        callback: successCallback,
        errorCallback
      });
    }
  }, []);

  return (
    <Grid item xs={12} lg={6} sx={getEvaluationsGridItemLgStyles(theme)}>
      <Header evaluationType={evaluationType} />
      <Body evaluations={evaluations} evaluationType={evaluationType} />
    </Grid>
  );
};

export default EvaluationsGridItem;
