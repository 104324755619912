import * as React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Report from "../../Report";
import { ReportClass, ReportGroup } from "../../Report/types";
import { MaturityReportElementProps } from "../../Report/types/element";

import { Chart } from "../../../Charts/Chart";
import { IndividualDomainRawScores } from "../../../Charts/All";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import CalculationInfo from "./CalculationInfo";
import PromptContainer from "../../Common/PromptContainer";
import Prompt from "../../Common/Prompt";
import OverviewTable from "./OverviewTable";
import { DomainRawScore } from "src/schemas/reporting/rawScore";
import { process } from "./data/process";

const report = ({ name, data }: MaturityReportElementProps): JSX.Element => {
  const { scoredData } = data;
  // Alphabetically sorted data by Domain
  const processedData: DomainRawScore[] = process(scoredData.items ?? []);

  return (
    <Report>
      <Report.Content name={name}>
        <Stack spacing="24px" sx={{ margin: "12px 0px 24px" }}>
          <Typography variant="body2">
            This report breaks down individual domain "raw" scores in terms of
            percentages and point ratios.
          </Typography>
          <CalculationInfo />
          <Divider />
        </Stack>
        <Stack>
          <PromptContainer>
            <Prompt>
              Hover over the chart to visualize percentages and point scores
            </Prompt>
          </PromptContainer>
          <Chart
            data={processedData}
            style={{ width: "100%", marginBottom: "32px" }}
            chart={IndividualDomainRawScores}
          />
          <OverviewTable data={processedData} />
        </Stack>
      </Report.Content>
    </Report>
  );
};

const IndividualDomainRawScoresReport: ReportClass = class {
  static displayName = "Individual Domain Raw Scores";
  static group: ReportGroup = ReportGroup.maturity;
  static supportedEvaluationTypes = [
    EvaluationTypes.assessment,
    EvaluationTypes.survey
  ];
  static report = report;
};

export default IndividualDomainRawScoresReport;
