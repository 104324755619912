import _get from "./get";
import { GetUserEvaluationsInterface } from "./types";

/**
 * API Service class for performing actions on user's evaluations
 */
class UserEvaluationsService {
  static get(args: GetUserEvaluationsInterface): void {
    return _get(args);
  }
}
export default UserEvaluationsService;
