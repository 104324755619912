import { FilterCategory } from "src/pages/ManageEvaluation/QuestionsPanel/Filters/types";
import { Evaluation } from "src/schemas/evaluation";

/**
 * Enum indicating all possible actions for updating the
 * `filterState` object in the ManageEvaluation context.
 */
export enum FilterAction {
  initialize = "initialize",
  toggleDomain = "toggleDomain",
  toggleElement = "toggleElement",
  toggleStage = "toggleStage",
  toggleCategory = "toggleCategory",
  toggleAll = "toggleAll"
}

/**
 * Object interface for the payload passed into the `dispatchFilterState`
 * method in the ManageEvaluation context.
 */
export interface FilterReducerAction {
  type: FilterAction;
  data: any;
}

/* Specific Reducer Action data overrides */

export interface InitializeActionData {
  evaluation: Evaluation;
}

// For all Categories
export interface ToggleAllActionData {
  enabled: boolean;
}

// For entire Category
export interface ToggleCategoryActionData extends ToggleAllActionData {
  category: FilterCategory;
}

// For individual Category filter items
interface ToggleCategoryItemActionData {
  enabled?: boolean;
}

export interface ToggleDomainActionData extends ToggleCategoryItemActionData {
  domainName: string;
}

export interface ToggleElementActionData extends ToggleDomainActionData {
  elementName: string;
}

export interface ToggleStageActionData extends ToggleCategoryItemActionData {
  stageName: string;
}
