import * as React from "react";
import { capitalize } from "@mui/material";
import Stack from "@mui/material/Stack";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Typography from "@mui/material/Typography";
import { HeaderProps } from "./types";

const Header = ({ evaluationType }: HeaderProps): JSX.Element => {
  return (
    <Stack direction="row" spacing="12px" alignItems="center">
      <DeleteRoundedIcon />
      <Typography variant="h6">
        <b>Delete {capitalize(evaluationType)}</b>
      </Typography>
    </Stack>
  );
};

export default Header;
