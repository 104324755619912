import * as React from "react";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { GetAPICaller } from "src/services/APICall";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import { HealthResponse, HealthResponseSchema } from "src/schemas/health";

const Health = () => {
  const [healthStatus, setHealthStatus] = React.useState("");
  const [Rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: 25
    });

  const successCallback = (response: HealthResponse) => {
    setHealthStatus(response.result.status);
    const rows = [];
    let count = 0;
    for (const [key, value] of Object.entries(response.result)) {
      count += 1;
      rows.push({ id: count, key: key, value: JSON.stringify(value) });
    }
    setLoading(false);
    setRows(rows);
  };

  const errorCallback = (err) => {
    console.log(err);
    setHealthStatus(null);
  };

  GetAPICaller({
    path: "/health",
    callback: successCallback,
    errorCallback: errorCallback,
    typeValidator: HealthResponseSchema,
    withAuthorizer: false
  });

  const columns = [
    {
      field: "key",
      headerName: "Key",
      width: 150
    },
    {
      field: "value",
      headerName: "Value",
      width: 400
    }
  ];

  // before the data is returned, we're saying.. Value is set to "Unknown"
  return (
    <>
      {healthStatus == "healthy" ? (
        <CheckCircleIcon />
      ) : loading ? (
        <CircularProgress />
      ) : (
        <ErrorIcon />
      )}
      <div style={{ height: 400, width: "50%" }}>
        <DataGrid
          rows={Rows}
          columns={columns}
          pageSizeOptions={[25]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          loading={loading}
        />
      </div>
    </>
  );
};

export default Health;
