import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/material";
import { HEADER_HEIGHT } from "src/utils/constants/layout";
import SlalomLogo from "../Common/svg/SlalomLogo";
import DARTLogo from "../Common/svg/DARTLogo";
import { StyledNavLink } from "../Common/StyledNavLink";

const HeaderContainer = ({ children }): JSX.Element => {
  const { palette } = useTheme();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        height: HEADER_HEIGHT
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          padding: "8px 36px",
          flexGrow: 1,
          alignItems: "center",
          minHeight: `${HEADER_HEIGHT} !important`
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <StyledNavLink
            to="/"
            sx={{
              display: "flex",
              alignItems: "center",
              color: palette.common.white
            }}
          >
            <SlalomLogo
              height="30px"
              fill={palette.common.white}
              sx={{ flexShrink: 0 }}
            />
            <DARTLogo
              height="24px"
              fill={palette.common.white}
              sx={{ flexShrink: 0, margin: "0 12px" }}
            />
            <Typography variant="h4">D.A.R.T</Typography>
          </StyledNavLink>
        </Box>

        {children}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderContainer;
