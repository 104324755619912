import * as React from "react";
import Autocomplete, {
  AutocompleteRenderOptionState
} from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ErrorMessage from "src/components/Common/ErrorMessage";
import Search from "src/components/Common/Search";
import InfoIconTooltip from "src/components/Common/Tooltip/InfoIconTooltip";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError } from "src/store/slices/notification";
import {
  User,
  UserId,
  UserListResponse,
  UserListResponseSchema
} from "src/schemas/user";
import { GetAPICaller } from "src/services/APICall";
import { Collection } from "src/utils/constants/collection";
import { createUserIdSet, sortUsers } from "../../../../../utils";
import Label from "src/components/Common/Input/Label";
import Option from "./Option";
import HelperText from "./HelperText";
import { Nullable } from "src/utils/types";
import { UsersInputProps } from "../types";

const UsersInput = ({ currentUsers, users, setUsers }: UsersInputProps) => {
  const dispatch: AppDispatch = useAppDispatch();

  /* Create set of currentUsers' Ids - to disable removing any Users currently
   * attached to evaluation (this is a desired MVP feature)
   */
  const currentUserIdSet: Set<UserId> = createUserIdSet(currentUsers);

  // Dropdown options
  const [options, setOptions] = React.useState<Nullable<User[]>>(null);

  /* Get all users - to populate dropdown options */
  GetAPICaller({
    path: "/users",
    callback: (response: UserListResponse) =>
      setOptions(sortUsers(response.item)),
    errorCallback: (error: Error) => {
      setOptions([]);
      dispatch(
        showError({
          message: <ErrorMessage error={error} collection={Collection.user} />
        })
      );
    },
    typeValidator: UserListResponseSchema
  });

  return (
    <Stack>
      <Label htmlFor="users" sx={{ marginBottom: "6px" }}>
        <Stack direction="row" alignItems="center">
          <Box component="span">Users</Box>
          <InfoIconTooltip placement="bottom-start" title={<HelperText />} />
        </Stack>
      </Label>
      <Autocomplete
        multiple
        renderTags={() => null}
        disableCloseOnSelect
        loading={options === null}
        id="users"
        options={options ?? []}
        isOptionEqualToValue={(option: User, value: User) => {
          return option.Id == value.Id;
        }}
        getOptionDisabled={(option: User) => currentUserIdSet.has(option.Id)}
        renderOption={(
          props: object,
          option: User,
          { selected }: AutocompleteRenderOptionState
        ) => (
          <Option
            key={option.Id}
            props={props}
            option={option}
            selected={selected}
            disabled={currentUserIdSet.has(option.Id)}
          />
        )}
        getOptionLabel={(value: User) => value.Name ?? value.Id}
        value={users}
        onChange={(_event: React.SyntheticEvent, newValue: User[]) =>
          setUsers(newValue)
        }
        renderInput={(params: object) => (
          <Search {...params} placeholder="Search for and add users" />
        )}
      />
    </Stack>
  );
};

export default UsersInput;
