import * as React from "react";
import Box from "@mui/material/Box";
import { ZodError } from "zod";
import ErrorMessage from "src/components/Common/ErrorMessage";
import Loading from "src/components/Common/Loading";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError } from "src/store/slices/notification";
import {
  DataGridEvaluationSummaryList,
  DataGridEvaluationSummaryListSchema,
  EvaluationSummaryListResponse
} from "src/schemas/evaluationSummary";
import UserEvaluationsService from "src/services/userEvaluations";
import { Collection } from "src/utils/constants/collection";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { remainingVerticalSpace } from "src/utils/styles/flex";
import { Nullable } from "src/utils/types";
import { formatError } from "src/utils/zod/formatError";
import DataTableCore from "./Core";

interface DataTableProps {
  evaluationType: EvaluationTypes;
}

/**
 * Wrapper around DataTableCore component.
 * This component fetches user evaluations data and
 * - renders a loading screen during the data fetch
 * - renders the DataTableCore component after the data fetch has finished
 */
const DataTable = ({ evaluationType }: DataTableProps): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();
  const [rows, setRows] =
    React.useState<Nullable<DataGridEvaluationSummaryList>>(null);

  const collection: Collection = Reflect.get(Collection, evaluationType);

  function successCallback(
    response: EvaluationSummaryListResponse,
    shouldSetData: boolean
  ): void {
    if (shouldSetData) {
      try {
        setRows(DataGridEvaluationSummaryListSchema.parse(response));
      } catch (error) {
        throw error instanceof ZodError ? new Error(formatError(error)) : error;
      }
    }
  }

  function errorCallback(error: Error): void {
    dispatch(
      showError({
        message: <ErrorMessage error={error} collection={collection} />
      })
    );
    setRows([]);
  }

  React.useEffect(() => {
    let shouldSetData = true;
    UserEvaluationsService.get({
      evaluationType,
      expand: ["clients", "projects", "templates"],
      callback: (response: EvaluationSummaryListResponse) =>
        successCallback(response, shouldSetData),
      errorCallback
    });

    /* Clean up useEffect so we don't try to set data on unmounted component
     * E.g., if we navigate away from page while the data fetch is occurring
     */
    return () => {
      shouldSetData = false;
    };
  }, []);

  return (
    <Box sx={remainingVerticalSpace}>
      {!rows && <Loading height="100%" />}
      {rows && <DataTableCore rows={rows} collection={collection} />}
    </Box>
  );
};

export default DataTable;
