import * as React from "react";
import Modal from "src/components/Common/Modal";
import { AddItemModalProps } from "./types";
import Header from "./Header";
import Content from "./Content";
import Actions from "./Actions";

const AddItemModal = ({
  open,
  handleClose
}: AddItemModalProps): JSX.Element => {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Header />
      <Content />
      <Actions handleClose={handleClose} />
    </Modal>
  );
};
export default AddItemModal;
