import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

/**
 * This is a common, reusable Text Field component **that should be used on white backgrounds**.
 *
 * **Usage**: This component should typically be used for
 * - basic inputs
 * - inputs passed into a MUI Autocomplete component
 * - etc.
 */
const Input = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    padding: "12px 16px !important",
    backgroundColor: theme.palette.grey["100"],
    borderRadius: "8px",
    boxShadow: "none",
    margin: "0px"
  },
  "& .MuiInputBase-root:hover": {
    backgroundColor: theme.palette.grey["100"]
  },
  "& .MuiInputBase-input": {
    padding: "0px !important"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "2px",
    borderColor: theme.palette.grey["100"],
    transition: theme.transitions.create(["border-color"])
  },
  "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.dark
  },
  /* Disabled Case */
  "& .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderStyle: "dashed"
  }
}));

export default Input;
