import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Severity, Variant } from "src/store/types/notification";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

interface CustomSnackbarInterface {
  sx?: { [key: string]: any };
  children: React.ReactNode;
  open: boolean;
  handleClose: () => any;
  severity: Severity;
  variant: Variant;
  autoHideDuration?: number;
}

const CustomSnackbar = ({
  sx = {},
  children,
  open,
  handleClose,
  severity,
  variant,
  autoHideDuration = null
}: CustomSnackbarInterface) => (
  <Snackbar
    ClickAwayListenerProps={{ mouseEvent: false }}
    open={open}
    onClose={handleClose}
    autoHideDuration={autoHideDuration}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
  >
    <Alert onClose={handleClose} severity={severity} sx={sx} variant={variant}>
      {children}
    </Alert>
  </Snackbar>
);

export default CustomSnackbar;
