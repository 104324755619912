import { groupBy } from "src/components/Reporting/utils/data/groupBy";
import { DomainRawScore } from "src/schemas/reporting/rawScore";
import {
  GroupedScoredResponses,
  ScoredResponse
} from "src/schemas/scoredResponse";
import { sortObjectArrayASC } from "src/utils/sort";
import { getPercentInteger } from "src/utils/percent";

/**
 * Converts an object where
 * - key is a Domain and
 * - value is a list of ScoredResponses
 *
 * into an array of DomainRawScores,
 * which is an array of objects with the following fields:
 * - Domain
 * - ScoreValue
 * - MaxScoreValue
 * - Percent
 */
function convertScoredResponsesToRawScores(
  groupedData: GroupedScoredResponses
): DomainRawScore[] {
  return Object.entries(groupedData).map(
    ([domain, items]: [domain: string, items: ScoredResponse[]]) => {
      const initialSums: Partial<DomainRawScore> = {
        ScoreValue: 0,
        MaxScoreValue: 0
      };

      const sums: Partial<DomainRawScore> = items.reduce(
        (acc: Partial<DomainRawScore>, currentItem: ScoredResponse) => {
          acc.ScoreValue += currentItem.ScoreValue;
          acc.MaxScoreValue += currentItem.MaxScoreValue;
          return acc;
        },
        initialSums
      );

      return {
        Domain: domain,
        ...sums,
        Percent: getPercentInteger(sums.ScoreValue, sums.MaxScoreValue)
      };
    }
  );
}

/**
 * Converts an array of ScoredResponses into DomainRawScores,
 * which is an array of objects with the following fields:
 * - Domain
 * - ScoreValue
 * - MaxScoreValue
 * - Percent
 *
 * Output object array is sorted alphabetically by Domain
 */
export function process(items: ScoredResponse[]): DomainRawScore[] {
  // Group ScoredResponses by Domain
  const groupedScoredResponses: GroupedScoredResponses = groupBy(
    items,
    (item: ScoredResponse) => item.Domain
  );

  // Parse through each Domain's ScoredResponses and reduce ScoredResponses into a list of DomainRawScores
  const domainRawScores: DomainRawScore[] = convertScoredResponsesToRawScores(
    groupedScoredResponses
  );

  // Return raw scores sorted by Domain
  return sortObjectArrayASC(
    domainRawScores,
    (item: DomainRawScore) => item.Domain
  );
}
