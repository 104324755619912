import * as React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import TooltipTextBox from "../Tooltip/TooltipTextBox";
import CoreActionButton from "./Core";
import { ActionButtonProps } from "./types";

/**
 * A reusable loading button component (extended from MUI button)
 * with tooltip support for the following states:
 * - disabled
 * - loading
 *
 * **Usage**:
 * Use in cases where clicking on a button will trigger an action (e.g., API call).
 *
 * - `loading` - prop that indicates the action is being performed. Button
 * will be disabled when `loading` is true and
 * will show a loading icon to the right of the passed-in `children`
 *
 * - `tooltipDisabledText` - use in conjuction with `disabled` prop to
 * show a tooltip when the button is disabled
 *
 * - `tooltipLoadingText` - use in conjunction with `loading` prop to
 * show a tooltip when the button is in the loading state
 *
 * - `TooltipProps` - an object prop to override any MUI Tooltip props (e.g., `placement`)
 * since this component is a button wrapped in a Tooltip
 */
const ActionButton = ({
  TooltipProps,
  tooltipDisabledText,
  tooltipLoadingText,
  ...buttonProps
}: ActionButtonProps): JSX.Element => {
  function getTooltipText(
    disabled: boolean,
    loading: boolean
  ): string | JSX.Element {
    const showDisabledTooltip: boolean = tooltipDisabledText && disabled;
    const showLoadingTooltip: boolean = tooltipLoadingText && loading;
    if (showDisabledTooltip) {
      return <TooltipTextBox>{tooltipDisabledText}</TooltipTextBox>;
    } else if (showLoadingTooltip) {
      return <TooltipTextBox>{tooltipLoadingText}</TooltipTextBox>;
    } else {
      return ""; // returning an empty string doesn't render a tooltip in MUI
    }
  }

  return (
    <Tooltip
      placement="bottom-start"
      title={getTooltipText(buttonProps.disabled, buttonProps.loading)}
      {...TooltipProps}
    >
      {/* Span is required to allow the Tooltip to accept actions on a disabled button */}
      <Box component="span">
        <CoreActionButton {...buttonProps}>
          {buttonProps.children}
        </CoreActionButton>
      </Box>
    </Tooltip>
  );
};

export default ActionButton;
