import { Phase } from "src/schemas/common";
import { CustomEvaluationResponse } from "src/schemas/evaluation";
import { Metadata } from "src/schemas/metadata";
import { updateStateItem } from "./utils/state";

/**
 * A reducer handler function for moving an evaluation to a different Phase.
 * - Updates the evaluation state `item.Phase` field.
 * - Updates the evaluation state `item.Metadata` object with a new
 * `DateModified` field, used for conflict checking.
 * @param state current reducer state
 * @param updatedPhase new evaluation phase to set for `state.item.Phase` field
 * @param meta updated Evaluation Metadata from database - for conflict checking
 * @returns new reducer state
 */
export default function editPhase(
  state: CustomEvaluationResponse,
  updatedPhase: Phase,
  meta: Metadata
): CustomEvaluationResponse {
  return updateStateItem(state, {
    Metadata: meta,
    Phase: updatedPhase
  });
}
