import { ReportGroup } from "src/components/Reporting/Reports/Report/types";
// Pretend like this is coming in from the Reports context
import { SampleResponse as sampleData } from "src/pages/Sandbox/data/vis_xy";
import { ReportsContextType } from "src/context/Reports/types";
import { ReportElementProps } from "src/components/Reporting/Reports/Report/types/element";
import {
  ExampleReportData,
  MaturityReportData,
  RecommendationsReportData
} from "src/components/Reporting/Reports/Report/types/data";
import { merge, pick } from "src/utils/object";

// Note: doesn't cover `Example` Report Group
type AllReportData = MaturityReportData & RecommendationsReportData;

type PassDataInterface = {
  [key in ReportGroup]: (
    context?: ReportsContextType
  ) => ReportElementProps["data"];
};

/**
 * Class to dynamically pass all necessary data to the selected report.
 *
 * Data will be passed into selected report based on selected report's `group`
 * name.
 *
 * Thus, all the function names for this class must correspond to all
 * available Report Group names (e.g., "example", "maturity", "recommendations",
 * etc.).
 */
class PassData implements PassDataInterface {
  allReportData: AllReportData;

  constructor(context: ReportsContextType) {
    this.allReportData = merge(
      {},
      // Objects to merge into base {} above
      { evaluation: context.selectedEvaluation },
      pick(context, ["classificationData", "recommendationsData", "scoredData"])
    );
  }

  example(): ExampleReportData {
    return sampleData;
  }

  maturity(): MaturityReportData {
    return pick(this.allReportData, [
      "evaluation",
      "classificationData",
      "recommendationsData",
      "scoredData"
    ]);
  }

  recommendations(): RecommendationsReportData {
    return pick(this.allReportData, ["evaluation", "recommendationsData"]);
  }
}

export default PassData;
