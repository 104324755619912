import { CustomEvaluationResponse } from "src/schemas/evaluation";
import { merge } from "src/utils/object";

/**
 * Given the current reducer `state` and an object `updatedFields` that
 * corresponds to a subset of fields for `state.item`, returns a new reducer
 * `state` with merged-in `updatedFields`.
 */
export function updateStateItem(
  state: CustomEvaluationResponse,
  updatedFields: Partial<CustomEvaluationResponse["item"]>
): CustomEvaluationResponse {
  return merge(state, {
    item: merge(state.item, updatedFields)
  });
}

/**
 * Given the current reducer `state` and an object `updatedFields` that
 * corresponds to a subset of fields for `state._expand`, returns a new reducer
 * `state` with merged-in `updatedFields`.
 */
export function updateStateExpand(
  state: CustomEvaluationResponse,
  updatedFields: Partial<CustomEvaluationResponse["_expand"]>
): CustomEvaluationResponse {
  return merge(state, {
    _expand: merge(state._expand, updatedFields)
  });
}
