import { plural } from "pluralize";
import { ModifyNotesResponseSchema } from "src/schemas/metadata";
import { PutAPICaller, PutAPICallRequest } from "../APICall";
import { UpdateEvaluationNotesInterface } from "./types";

/**
 * Updates an evaluation's Notes field, which lives under the nested
 * `Metadata` object.
 * - `PUT /assessments/{assessmentId}/notes`
 * - `PUT /surveys/{surveyId}/notes`
 */
export default function update({
  evaluationType,
  evaluationId,
  body,
  callback,
  errorCallback
}: UpdateEvaluationNotesInterface): void {
  const path = `/${plural(evaluationType)}/${evaluationId}/notes`;

  const request: PutAPICallRequest = {
    path,
    body,
    callback,
    errorCallback,
    typeValidator: ModifyNotesResponseSchema,
    withReactHook: false
  };

  return PutAPICaller(request);
}
