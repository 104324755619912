import * as React from "react";
import Typography from "@mui/material/Typography";
import ModalSection from "src/components/Common/Modal/Section";
import LessThanOrEqualToThreshold from "../../../../Maturity/Threshold/LessThanOrEqualTo";
import GreaterThanThreshold from "../../../../Maturity/Threshold/GreaterThan";
import AnswerList from "./AnswerList";
import { ANSWER_MAX_VALUE } from "./data/answers";
import { AnswerListFilterType } from "./types";

const Example = (): JSX.Element => {
  return (
    <ModalSection name="Example">
      <Typography variant="body2">
        Let's say we have an example question with the following answer choices:
      </Typography>
      <Typography variant="body2">
        <b>
          Are applications explicit in their declaration and use of
          dependencies?
        </b>
      </Typography>
      <AnswerList />
      <Typography variant="body2">
        Answer choices are represented in terms of increasing maturity score
        values (0 - {ANSWER_MAX_VALUE} in this case). A response score is
        calculated as{" "}
        <b>
          answer score value / max answer score value. A recommendation is
          provided whenever the response score is <LessThanOrEqualToThreshold />
          .
        </b>
      </Typography>
      <Typography variant="body2">
        In this example, a recommendation is provided if the response is any of
        the following (since they have a response score{" "}
        <LessThanOrEqualToThreshold />
        ).
      </Typography>
      <AnswerList
        filterType={AnswerListFilterType.includeRecommendation}
        showCalculation={true}
      />
      <Typography variant="body2">
        In this example, a recommendation is <b>NOT</b> provided if the response
        is any of the following (since they have a response score{" "}
        <GreaterThanThreshold />
        ).
      </Typography>
      <AnswerList
        filterType={AnswerListFilterType.excludeRecommendation}
        showCalculation={true}
      />
    </ModalSection>
  );
};

export default Example;
