import * as React from "react";
import { TypographyProps } from "@mui/material";
import Typography from "@mui/material/Typography";

const CalculationDefinition = (props: TypographyProps): JSX.Element => {
  return (
    <Typography {...props}>
      A domain <b>"raw"</b> score is a simple ratio of{" "}
      <b>
        sum of all response score values / sum of all most-mature answer score
        values
      </b>{" "}
      for a specific domain.
    </Typography>
  );
};

export default CalculationDefinition;
