import * as React from "react";
import Grid from "@mui/material/Grid";
import Domains from "./Domains";
import Stages from "./Stages";
import { ClassificationsProps } from "./types";

const Classifications = ({
  domains,
  questionStages
}: ClassificationsProps): JSX.Element => {
  return (
    <Grid container columnGap="48px">
      <Grid item sx={{ flex: "1" }}>
        <Domains data={domains} />
      </Grid>
      <Grid item sx={{ flex: "1" }}>
        <Stages data={questionStages} />
      </Grid>
    </Grid>
  );
};

export default Classifications;
