import { z } from "zod";
import { AnswerSchema, QuestionSchema } from "./question";
import {
  EvaluationIdsParamSchema,
  EvaluationSchema,
  EvaluationTypesParamSchema
} from "./evaluation";
import { ResponseSchema } from "./response";

export const ScoredResponseSchema = QuestionSchema.pick({
  Domain: true,
  Element: true
})
  .merge(ResponseSchema.pick({ EvaluationId: true, QuestionId: true }))
  .merge(AnswerSchema.pick({ ScoreValue: true }))
  .extend({
    EvaluationName: EvaluationSchema.shape.Name,
    QuestionText: QuestionSchema.shape.Text,
    QuestionStage: QuestionSchema.shape.Stage,
    QuestionStageName: z.string().optional(),
    AnswerText: AnswerSchema.shape.Text,
    MaxScoreValue: AnswerSchema.shape.ScoreValue,
    ProvideRecommendation: z.boolean().optional()
  });

/* API Request objects */
export const ScoredResponsesQueryParamsSchema = z.object({
  evaluationIds: EvaluationIdsParamSchema, // comma-separated string
  type: EvaluationTypesParamSchema
});

/* API Response objects */
export const ScoredResponsesResponseSchema = z.object({
  score: z.number().min(0), // sum of response scores for all Evaluations
  maxScore: z.number().min(0), // sum of max answer scores for all Evaluations
  items: ScoredResponseSchema.array() // scored responses from all Evaluations
});

/* Types */
export type ScoredResponsesQueryParams = z.infer<
  typeof ScoredResponsesQueryParamsSchema
>;
export type ScoredResponse = z.infer<typeof ScoredResponseSchema>;
export type ScoredResponsesResponse = z.infer<
  typeof ScoredResponsesResponseSchema
>;

// Use as an intermediate stage for processing ScoredResponses data
export type GroupedScoredResponses = Record<string, ScoredResponse[]>;

// Use for testing purposes
export interface LegacyScoredResponse {
  Domain: string;
  Element: string;
  ScoreValue: number;
  Text: string;
  Stage: string;
}
