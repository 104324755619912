import * as React from "react";
import Input from ".";
import {
  DisabledHelperText,
  DisabledTooltip
} from "src/components/Common/Tooltip/DisabledTooltip";
import InputContainer from "./InputContainer";
import { TextFieldProps } from "@mui/material";
import { conditionalObject } from "src/utils/object";

/* Need to use a type alias here instead of an interface due the following error, which
 * comes because TextFieldProps is a union type:
 * "An interface can only extend an object type or intersection of object types with statically known members"
 */
type DynamicInputInterface = TextFieldProps & {
  title?: string; // disabled tooltip text
  value: any;
  setValue?: (value: any) => any;
  isSubInput?: boolean;
};

const DynamicInput = ({
  title,
  value,
  setValue,
  isSubInput = false,
  ...textFieldProps
}: DynamicInputInterface) => {
  const derivedTextFieldProps: TextFieldProps = {
    ...textFieldProps,
    ...conditionalObject(textFieldProps.multiline, { rows: 5 })
  };
  return (
    <InputContainer
      id={textFieldProps.id}
      title={title}
      isSubInput={isSubInput}
    >
      <DisabledTooltip
        title={<DisabledHelperText item={title} />}
        placement="bottom-end"
        disableHoverListener={!textFieldProps.disabled}
        disableFocusListener={!textFieldProps.disabled}
        disableTouchListener={!textFieldProps.disabled}
      >
        <Input
          value={value}
          onChange={(event: any) => setValue(event.target.value)}
          {...derivedTextFieldProps}
        />
      </DisabledTooltip>
    </InputContainer>
  );
};
export default DynamicInput;
