import * as React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { ReportsProvider } from "src/context/Reports";
import Body from "./Body";

const Reports = (): JSX.Element => {
  return (
    <ReportsProvider>
      <Stack spacing="12px" sx={{ flex: 1 }}>
        <Typography variant="h4">Reports</Typography>
        <Body />
      </Stack>
    </ReportsProvider>
  );
};
export default Reports;
