import * as React from "react";
import Report from "../../../Report";
import DomainDropdown from "./DomainDropdown";
import { ReportOptionsProps } from "./types";

const ReportOptions = ({
  domainOptions,
  domain,
  setDomain
}: ReportOptionsProps): JSX.Element => {
  return (
    <Report.Options>
      <DomainDropdown
        domainOptions={domainOptions}
        domain={domain}
        setDomain={setDomain}
      />
    </Report.Options>
  );
};

export default ReportOptions;
