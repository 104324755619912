import * as am5 from "@amcharts/amcharts5";

export const addVerticalDivider = (
  root: am5.Root,
  container: am5.Container,
  color: number | string = 0x000000
) =>
  container.children.push(
    am5.Container.new(root, {
      background: am5.Rectangle.new(root, {
        fill: am5.color(color)
      }),
      height: am5.p100,
      width: 1
    })
  );
