import * as React from "react";
import EnvironmentSettingsFile from "../../settings.json.txt";
import { AMPLIFY_INITIAL_SETTINGS } from "src/config/amplify";
import {
  EnvironmentSettingsContextType,
  Environment,
  EnvironmentSettingsProviderProps
} from "./types";

export const EnvironmentSettingsContext =
  React.createContext<EnvironmentSettingsContextType>({
    environment: Environment.development,
    amplify: AMPLIFY_INITIAL_SETTINGS
  });

export async function loadEnvironmentSettings(): Promise<EnvironmentSettingsContextType> {
  const response: Response = await fetch(EnvironmentSettingsFile);
  return await response.json();
}

export const EnvironmentSettingsProvider = ({
  children,
  envSettings
}: EnvironmentSettingsProviderProps) => {
  return (
    <EnvironmentSettingsContext.Provider value={envSettings}>
      {children}
    </EnvironmentSettingsContext.Provider>
  );
};

const useEnvironmentSettings = () =>
  React.useContext(EnvironmentSettingsContext);
export default useEnvironmentSettings;
