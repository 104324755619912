import * as React from "react";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import Modal from "src/components/Common/Modal";
import { PhaseTypesEnum } from "src/utils/constants/phase";
import Content from "./Content";

const PhaseInfoModal = ({
  open,
  currentPhase,
  handleClose
}: {
  open: boolean;
  currentPhase: PhaseTypesEnum;
  handleClose: (e: React.SyntheticEvent) => any;
}) => {
  const { palette } = useTheme();

  return (
    <Modal open={open} handleClose={handleClose} sx={{ width: "900px" }}>
      <Modal.Header
        sx={{
          backgroundColor: palette.primary.main,
          color: palette.common.white
        }}
      >
        <Stack direction="row" spacing="12px" alignItems="center">
          <InfoRoundedIcon />
          <Typography variant="h6">
            <b>Phases</b>
          </Typography>
        </Stack>
      </Modal.Header>
      <Modal.Content sx={{ padding: "24px" }}>
        <Content currentPhase={currentPhase} />
      </Modal.Content>
      <Modal.Actions
        sx={{
          justifyContent: "center",
          padding: "16px"
        }}
      >
        <Button variant="contained" size="large" onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default PhaseInfoModal;
