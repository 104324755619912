/* Layout Constants */
export const LEFT_COLUMN_WIDTH = "500px";

/**
 * Dynamically sets certain fixed height of Preview component header
 * based on whether an Evaluation Template has been loaded in or not
 */
export function getPreviewHeaderHeight(templateLoaded: boolean) {
  return templateLoaded ? "124px" : "80px";
}
