import * as am5 from "@amcharts/amcharts5";
import { LegacyScoredResponse } from "src/schemas/scoredResponse";
import { ChartMaker, ChartInterface } from "../../Chart/types";
import { ResponseDistributionByTheme } from "./ResponseDistributionByTheme";
import { FluencyOfRespondents } from "./FluencyOfRespondents";
import { addVerticalDivider } from "../../utils/chartUtils/divider";

export class Multiple implements ChartMaker {
  static displayName = "Example of Multiple Charts in One";
  private root: am5.Root;
  private data: LegacyScoredResponse[];
  private draggableLegend: boolean;
  constructor({ data, root, draggableLegend }: ChartInterface) {
    this.data = data;
    this.root = root;
    this.draggableLegend = draggableLegend;
  }
  make(): am5.Container {
    const container = this.root.container.children.push(
      am5.Container.new(this.root, {
        layout: this.root.horizontalLayout,
        width: am5.p100,
        height: am5.p100
      })
    );

    const args: ChartInterface = {
      data: this.data,
      root: this.root,
      draggableLegend: this.draggableLegend
    };

    new FluencyOfRespondents(args).make(container);

    addVerticalDivider(this.root, container);

    new ResponseDistributionByTheme(args).make(container);

    return container;
  }
}
