import * as React from "react";
import Modal from "src/components/Common/Modal";
import Stack from "@mui/material/Stack";
import DynamicInput from "src/components/Common/Input/DynamicInput";
import { Project } from "src/schemas/project";
import { User } from "src/schemas/user";
import ProjectField from "./ProjectField";
import UsersSection from "./Users";
import TagsField from "./TagsField";
import { ContentProps } from "../../types";

const Content = ({
  evaluation,
  localEvaluation,
  dispatchLocalEvaluation
}: ContentProps): JSX.Element => {
  return (
    <Modal.Content sx={{ padding: "36px 24px" }}>
      <Stack spacing="32px">
        <DynamicInput
          id="name"
          title="name"
          value={localEvaluation.item.Name}
          setValue={(item: string) =>
            dispatchLocalEvaluation({ type: "Name", data: item })
          }
        />
        <DynamicInput
          id="client"
          title="client"
          value={localEvaluation._expand.client.Name}
          disabled
        />
        <ProjectField
          project={localEvaluation._expand.project}
          setProject={(item: Project) =>
            dispatchLocalEvaluation({ type: "project", data: item })
          }
          selectedClientId={localEvaluation._expand.client.Id}
        />
        <DynamicInput
          id="description"
          title="description"
          value={localEvaluation.item.Description}
          setValue={(item: string) =>
            dispatchLocalEvaluation({ type: "Description", data: item })
          }
          multiline
        />
        <TagsField tags={localEvaluation.item.Metadata.Tags} />
        <DynamicInput
          id="note"
          title="notes"
          value={localEvaluation.item.Metadata.Notes[0]}
          setValue={(item: string) =>
            dispatchLocalEvaluation({ type: "Notes", data: item })
          }
          multiline
        />
        <DynamicInput
          id="template"
          title="template"
          value={localEvaluation._expand.template.Name}
          disabled
        />
        <UsersSection
          currentUsers={evaluation._expand.users}
          users={localEvaluation._expand.users}
          setUsers={(items: User[]) =>
            dispatchLocalEvaluation({ type: "users", data: items })
          }
        />
      </Stack>
    </Modal.Content>
  );
};

export default Content;
