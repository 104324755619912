import { ZodSchema } from "zod";
import { zodUUID } from "src/schemas/common";

export function isValid<T>(item: T, schema: ZodSchema): boolean {
  return schema.safeParse(item).success;
}

export function isUuidValid(uuid: string): boolean {
  return isValid(uuid, zodUUID);
}
