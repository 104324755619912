import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { AmChartsDataValidatedSeriesEvent } from "./events";

interface SetHeightOptions {
  cellSize: number;
  /**
   * Padding is a temporary work-around to manually add in height of other elements around chart
   * until we can find a better approach
   */
  padding?: number;
}

export const addXYChart = (
  root: am5.Root,
  options: am5xy.IXYChartSettings,
  container?: am5.Container
): am5xy.XYChart =>
  (container ?? root.container).children.push(am5xy.XYChart.new(root, options));

/* Dynamically set height of chart
 * https://www.amcharts.com/docs/v5/tutorials/auto-adjusting-chart-height-based-on-a-number-of-data-items/
 */
export function setHeight(
  event: AmChartsDataValidatedSeriesEvent<am5xy.ColumnSeries>,
  options: SetHeightOptions
): void {
  const series = event.target;
  const chart = series.chart;
  const xAxis = chart.xAxes.getIndex(0);
  const { cellSize, padding } = options;

  // Calculate how we need to adjust chart height
  const chartHeight =
    series.data.length * cellSize +
    xAxis.height() +
    chart.get("paddingTop", 0) +
    chart.get("paddingBottom", 0);

  // Set it on chart's container
  chart.root.dom.style.height = chartHeight + (padding ?? 0) + "px";
}
