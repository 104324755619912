import * as React from "react";
import { z } from "zod";
import { API, Hub } from "aws-amplify";
import { merge } from "src/utils/object";
import AuthService from "./auth";
import { DART_AUTH_CHANNEL } from "./auth/constants";

/* Interfaces */
enum Methods {
  GET = "get",
  POST = "post",
  PATCH = "patch",
  PUT = "put",
  DELETE = "del"
}

enum Backends {
  DART = "Backend"
}

interface Callback {
  (response: any): any;
}

interface APICallRequestPartial {
  path: string;
  headers?: object;
  callback: Callback;
  errorCallback?: any;
  typeValidator?: z.ZodSchema;
  watchedProperties?: Array<any>;
  withAuthorizer?: boolean;
  withReactHook?: boolean;
}

export interface APICallRequest
  extends APICallRequestPartial,
    GetAPICallRequest,
    PostAPICallRequest {
  method: Methods;
}

export interface GetAPICallRequest extends APICallRequestPartial {
  queryStringParameters?: Record<string, string>;
}

export interface PostAPICallRequest extends GetAPICallRequest {
  body?: any;
}

export type PatchAPICallRequest = PostAPICallRequest;
export type PutAPICallRequest = PostAPICallRequest;
export type DeleteAPICallRequest = PostAPICallRequest;

/* Helper Methods */
function generateCorrelationHeader(): object {
  return {
    "Correlation-Object": JSON.stringify({ correlationId: "12345" })
  };
}

function defaultErrorCallback(error: Error): void {
  console.log(error);
}

export async function getToken(): Promise<string> {
  const jwt = await AuthService.getJWT();
  /* Can't use `reauthenticateUser` from useAuth hook in here since hooks only
   * work in React Components, so using Amplify's Hub utility instead
   */
  Hub.dispatch(DART_AUTH_CHANNEL, {
    event: "fetchJWT",
    data: null,
    message: "Getting JWT from user session"
  });
  return jwt;
}

/* API Callers */
const APICaller = (request: APICallRequest): void => {
  request.errorCallback = request.errorCallback ?? defaultErrorCallback;
  request.typeValidator = request.typeValidator ?? z.any();
  request.watchedProperties = request.watchedProperties ?? [];
  request.withAuthorizer = request.withAuthorizer ?? true;
  request.withReactHook = request.withReactHook ?? true;
  request.headers = request.headers ?? {};

  const handleRequest = async () => {
    // Set up headers
    const headers: object = merge(generateCorrelationHeader(), request.headers);
    if (request.withAuthorizer) {
      headers["Authorization"] = `Bearer ${await getToken()}`;
    }

    const myInit = {
      queryStringParameters: request.queryStringParameters,
      body: request.body,
      headers
    };

    if (!Object.values(Methods).includes(request.method)) {
      throw new Error("NotImplemented");
    }

    return API[request.method](Backends.DART, request.path, myInit)
      .then((response: Record<string, any>) => {
        try {
          request.typeValidator.parse(response) == response;
        } catch (err) {
          console.log({ error: err, response, path: request.path });
          request.errorCallback(
            `Data validation error on request route ${request.path}`
          );
          // Do we want to throw an error here or pass the returned data down to request.callback()?
          // As of now we need to work on the validation before throwing.
        }
        request.callback(response);
      })
      .catch((error) => {
        request.errorCallback(error);
      });
  };

  if (request.withReactHook) {
    /* useCallbackHandleRequest has to be defined here otherwise it breaks for withReactHook = false case */
    const useCallbackHandleRequest = React.useCallback(handleRequest, []);
    React.useEffect(() => {
      useCallbackHandleRequest();
    }, request.watchedProperties);
  } else {
    handleRequest();
  }
};

function makeAPICaller<T extends APICallRequestPartial>(
  request: T,
  method: Methods
): void {
  return APICaller({
    ...request,
    method
  } as APICallRequest);
}

export function GetAPICaller(request: GetAPICallRequest): void {
  return makeAPICaller(request, Methods.GET);
}

export function PostAPICaller(request: PostAPICallRequest): void {
  return makeAPICaller(request, Methods.POST);
}

export function PatchAPICaller(request: PatchAPICallRequest): void {
  return makeAPICaller(request, Methods.PATCH);
}

export function PutAPICaller(request: PutAPICallRequest): void {
  return makeAPICaller(request, Methods.PUT);
}

export function DeleteAPICaller(request: DeleteAPICallRequest): void {
  return makeAPICaller(request, Methods.DELETE);
}
