import {
  AnyFilterItems,
  DomainFilterItem,
  FilterItem,
  FilterState
} from "src/pages/ManageEvaluation/QuestionsPanel/Filters/types";
import { ToggleElementActionData } from "../types";

interface EnabledInformation {
  all: boolean;
  some: boolean;
}

/**
 * Given a list of filter items, returns an object containing information about
 * whether
 * - all
 * - some
 * items are enabled.
 */
function getEnabledInformation(items: AnyFilterItems): EnabledInformation {
  const names: string[] = Object.keys(items);
  const count: number = names.length;

  const enabledCount: number = names.reduce((acc: number, name: string) => {
    if (Reflect.get(items, name).enabled) {
      acc += 1;
    }
    return acc;
  }, 0);

  return {
    all: enabledCount === count,
    some: enabledCount > 0 && enabledCount < count
  };
}
/**
 * Given a new `filterState` and `domainName`, `elementName`, and
 * [optional] `enabled` provided in the action `data` payload,
 *
 * - toggles the element filter on / off OR
 *
 * - if `enabled` is explicitly passed in, sets the element filter to that value
 */
export default function toggleElement(
  newState: FilterState,
  data: ToggleElementActionData
): FilterState {
  const { domainName, elementName, enabled } = data;

  const domain: DomainFilterItem = Reflect.get(newState.domains, domainName);
  const element: FilterItem = Reflect.get(domain.elements, elementName);

  // Set or toggle element state
  element.enabled = enabled ?? !element.enabled;

  // Modify parent domain's state based on all elements' state
  const elementsEnabled = getEnabledInformation(domain.elements);
  domain.enabled = elementsEnabled.all;
  domain.indeterminate = elementsEnabled.some;

  return newState;
}
