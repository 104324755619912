import { IndexedQuestion, Question } from "src/schemas/question";
import { merge } from "src/utils/object";

/**
 * Given a `question` and an `index`, returns a new object of type
 * `IndexedQuestion`
 */
export function createIndexedQuestion(
  question: Question,
  index: number
): IndexedQuestion {
  return merge(question, { index });
}
