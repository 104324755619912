import { styled } from "@mui/material/styles";
import Label from "./Label";

/**
 * This is a common, reusable InputLabel component
 *
 * **Usage**: This component should typically be used for
 * - nested label inside the main fields
 * - etc.
 */
const SubLabel = styled(Label)(({ theme }) => ({
  color: theme.palette.grey["700"],
  textTransform: "capitalize"
}));

export default SubLabel;
