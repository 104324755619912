import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import AnimatedTheme from "@amcharts/amcharts5/themes/Animated";
import { DomainMaturityStageScore } from "src/schemas/reporting/maturityScore";
import { ChartMaker } from "../../Chart/types";
import BaseTheme from "../../themes/BaseTheme";
import { SixBucketsTheme } from "../../themes/heatmap/SixBucketsTheme";
import { AmChartsDataValidatedSeriesEvent } from "../../utils/chartUtils/events";
import { HeatmapCategoryAxisData } from "../../utils/chartUtils/heatmap";
import { makeRoundedRectangleStyles } from "../../utils/chartUtils/rectangle";
import {
  BucketHeatmapLegendItem,
  createBucketHeatmapLegendData
} from "../../utils/chartUtils/legend";
import { addXYChart, setHeight } from "../../utils/chartUtils/xy";
import { process } from "./data/process";
import { MaturityStageScoresHeatmapChartInterface } from "./types";

/**
 * Heatmap showing the cross-section of Stages and Domains
 */
export class MaturityStageScoresHeatmap implements ChartMaker {
  static displayName = "Domain Maturity Stage Scores";
  private root: am5.Root;
  private data: DomainMaturityStageScore[];
  private stagesData: HeatmapCategoryAxisData;
  private domainsData: HeatmapCategoryAxisData;
  private chart: am5.Container; // overall chart containing xy chart and legend
  private xyChart: am5xy.XYChart;
  private xAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>;
  private yAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>;
  private series: am5xy.ColumnSeries;
  /* Data keys */
  private categoryAxesKey = "category" as const;
  private xKey = "QuestionStageNameFormatted";
  private yKey = "Domain";
  private valueKey = "Percent";
  /* Styles */
  private minGridDistance = 30;
  private heatmapTheme: am5.Theme;
  private colorSet: am5.Template<am5.ColorSet>;

  constructor({
    root,
    data,
    metadata
  }: MaturityStageScoresHeatmapChartInterface) {
    this.data = process(data);
    this.root = root;

    // Set category axis information based on passed-in metadata
    this.domainsData = metadata.domains.map((item: string) => ({
      [this.categoryAxesKey]: item
    }));
    this.stagesData = metadata.questionStages.map((item: string) => ({
      [this.categoryAxesKey]: item
    }));

    // Set theme to be used for grabbing colors + intervals data
    this.heatmapTheme = SixBucketsTheme.new(this.root);
    this.colorSet = this.heatmapTheme.rule("ColorSet");
  }

  private overrideThemes(): void {
    /* Don't want default Responsive theme since it breaks our
     * auto-sizing chart height capability
     */
    this.root.setThemes([
      AnimatedTheme.new(this.root),
      BaseTheme.new(this.root),
      this.heatmapTheme
    ]);
  }

  private makeXAxis(): am5xy.CategoryAxis<am5xy.AxisRenderer> {
    const xRenderer: am5xy.AxisRendererX = am5xy.AxisRendererX.new(this.root, {
      visible: false,
      minGridDistance: this.minGridDistance
    });

    xRenderer.grid.template.set("visible", false);

    return this.xyChart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        renderer: xRenderer,
        categoryField: this.categoryAxesKey
      })
    );
  }

  private makeYAxis(): am5xy.CategoryAxis<am5xy.AxisRenderer> {
    const yRenderer: am5xy.AxisRendererY = am5xy.AxisRendererY.new(this.root, {
      visible: false,
      minGridDistance: this.minGridDistance,
      inversed: true
    });

    yRenderer.grid.template.set("visible", false);

    return this.xyChart.yAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        renderer: yRenderer,
        categoryField: this.categoryAxesKey
      })
    );
  }

  private makeLegend(): am5.Legend {
    const legend = am5.Legend.new(this.root, {
      reverseChildren: true, // mapped legend info is in DESC order, but we need ASC, so this is true
      nameField: "label",
      fillField: "color",
      strokeField: "color",
      marginBottom: 24,
      centerX: am5.percent(100),
      x: am5.percent(100)
    });

    const legendData: BucketHeatmapLegendItem[] = createBucketHeatmapLegendData(
      this.colorSet.get("userData").colorPointIntervals,
      this.colorSet.get("colors")
    );

    legend.data.setAll(legendData);

    return legend;
  }

  /**
   * Given a numerical `value`, this function iterates through
   * the chart theme's **colorPointIntervals**. Because the colorPointIntervals
   * are in **descending** order, if the `value` exceeds or equals the
   * current interval's start value, then we know `value` falls within that
   * interval.
   *
   * Using the index of the interval's start value, we map `value` to
   * its appropriate **color** according to the theme and return that color.
   */
  private mapValueToColor(value: number): am5.Color {
    const { colorPointIntervals }: { colorPointIntervals: number[] } =
      this.colorSet.get("userData");
    for (const [index, intervalStartValue] of colorPointIntervals.entries()) {
      if (value >= intervalStartValue) {
        return Reflect.get(this.colorSet.get("colors"), index);
      }
    }
  }

  private setSeriesColor(
    _fill: am5.Color,
    target: am5.RoundedRectangle
  ): am5.Color {
    return this.mapValueToColor(target?.dataItem?.dataContext["Percent"]);
  }

  private makeSeriesBullet(root: am5.Root): am5.Bullet {
    return am5.Bullet.new(root, {
      sprite: am5.Label.new(root, {
        populateText: true,
        centerX: am5.p50,
        centerY: am5.p50,
        fontSize: 14,
        fontWeight: "bold",
        text: `{${this.valueKey}}%`
      })
    });
  }

  private makeSeries(): am5xy.ColumnSeries {
    const series: am5xy.ColumnSeries = this.xyChart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        calculateAggregates: true,
        stroke: am5.color(0xffffff),
        clustered: false,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        categoryXField: this.xKey,
        categoryYField: this.yKey,
        valueField: this.valueKey
      })
    );

    series.columns.template.setAll({
      ...makeRoundedRectangleStyles(5),
      strokeWidth: 2,
      width: am5.percent(100),
      height: am5.percent(100)
    });

    // Dynamically set colors based on values
    series.columns.template.adapters.add(
      "fill",
      this.setSeriesColor.bind(this)
    );

    // Create labels positioned over each data item
    series.bullets.push(this.makeSeriesBullet.bind(this));

    return series;
  }

  private dynamicallySetHeight(): void {
    /* Dynamically set height of chart based on number of domains (y-axis)
     * `setHeight` multiplies `cellSize` by data.length, so we need to set a multiplier of
     * `domainsData.length` / `data.length` to ensure we get
     * `cellSize` * `domainsData.length`
     */
    const cellSize: number =
      this.minGridDistance * 2 * (this.domainsData.length / this.data.length);
    this.series.events.on(
      "datavalidated",
      function (event: AmChartsDataValidatedSeriesEvent<am5xy.ColumnSeries>) {
        setHeight(event, { cellSize, padding: 60 });
      }
    );
  }

  private makeLayout(): void {
    this.xyChart = addXYChart(
      this.root,
      {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: this.root.verticalLayout
      },
      this.chart
    );

    /* Need xyChart to be instantiated before we called `makeLegend`
     * since we grab colors from the xyChart
     */
    this.chart.children.unshift(this.makeLegend());
  }

  make(): am5.Container {
    this.overrideThemes();

    this.chart = this.root.container.children.push(
      am5.Container.new(this.root, {
        layout: this.root.verticalLayout,
        width: am5.percent(100),
        height: am5.percent(100)
      })
    );

    // Create xy chart and legend
    this.makeLayout();

    this.xAxis = this.makeXAxis();
    this.yAxis = this.makeYAxis();
    this.series = this.makeSeries();

    // Set data
    this.xAxis.data.setAll(this.stagesData);
    this.yAxis.data.setAll(this.domainsData);
    this.series.data.setAll(this.data);

    // Set dimensions
    this.dynamicallySetHeight();

    /* Make stuff animate on load
     * https://www.amcharts.com/docs/v5/concepts/animations/
     */
    this.series.appear(1000);
    this.xyChart.appear(1000, 100);
    return this.chart;
  }
}
