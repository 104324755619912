import * as React from "react";
import Stack from "@mui/material/Stack";
import PhaseChipRow from "./PhaseChipRow";
import ChangePhase from "./ChangePhase";
import { PhaseTypesEnum } from "src/utils/constants/phase";

const PhaseProgressionRow = ({
  currentPhase
}: {
  currentPhase: PhaseTypesEnum;
}) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <PhaseChipRow currentPhase={currentPhase} />
    <ChangePhase currentPhase={currentPhase} />
  </Stack>
);

export default PhaseProgressionRow;
