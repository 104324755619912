/* General Filter Helper Functions for Sets */

type FilterFunction = (item: any) => boolean;

/**
 * Given a set, return a filter function that checks if an item is in the set
 * @param data a set of items
 * @returns a filter function (most commonly used with Array.filter())
 */
export function hasFilter<T>(data: Set<T>): FilterFunction {
  return (item: T) => data.has(item);
}

/**
 * Given a set, return a filter function that checks if an item is NOT in the set
 * @param data a set of items
 * @returns a filter function (most commonly used with Array.filter())
 */
export function invertedHasFilter<T>(data: Set<T>): FilterFunction {
  return (item: T) => !data.has(item);
}
