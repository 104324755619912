import * as React from "react";
import { AddItemButtonProps } from "./types";
import { StyledNavLink } from "src/components/Common/StyledNavLink";

const AddItemButton = ({ setModalOpen }: AddItemButtonProps): JSX.Element => {
  return (
    <StyledNavLink
      sx={{ fontSize: "14px" }}
      to="#"
      onClick={() => setModalOpen(true)}
    >
      Add
    </StyledNavLink>
  );
};

export default AddItemButton;
