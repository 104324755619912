import { getPercentInteger } from "src/utils/percent";
import {
  DomainMaturityStageScore,
  MaturityStageScoreCalculations
} from "src/schemas/reporting/maturityScore";
import {
  GroupedScoredResponses,
  ScoredResponse
} from "src/schemas/scoredResponse";
import { formatQuestionStageText } from "src/utils/format/questionStage";
import { groupBy } from "../../../../utils/data/groupBy";

type Calculations = MaturityStageScoreCalculations &
  Omit<DomainMaturityStageScore, "Percent">;

/**
 * Converts an object where
 * - key is a string of format `${domain} - Stage ${stageNumber}` and
 * - value is a list of ScoredResponses
 *
 * into an array of DomainMaturityStageScores,
 * which is an array of objects with the following fields:
 * - QuestionStageNameFormatted
 * - Domain
 * - Percent
 */
function convertToDomainMaturityStageScores(
  groupedData: GroupedScoredResponses
): DomainMaturityStageScore[] {
  return Object.entries(groupedData).map(
    ([, items]: [group: string, items: ScoredResponse[]]) => {
      const initialValues: Calculations = {
        responsesCount: 0,
        sufficientlyMetResponsesCount: 0,
        QuestionStageNameFormatted: null,
        Domain: null
      };

      const aggregatedValues: Calculations = items.reduce(
        (acc: Calculations, item: ScoredResponse) => {
          acc.responsesCount += 1;

          // # of Responses that pass maturity threshold (e.g., when ProvideRecommendation is false in ScoredResponse)
          acc.sufficientlyMetResponsesCount += item.ProvideRecommendation
            ? 0
            : 1;

          // Get the formatted stage text associated with this group of items
          acc.QuestionStageNameFormatted ??= formatQuestionStageText(
            item.QuestionStage,
            item.QuestionStageName
          );

          // Get the domain associated with this group of items
          acc.Domain ??= item.Domain;

          return acc;
        },
        initialValues
      );

      return {
        QuestionStageNameFormatted: aggregatedValues.QuestionStageNameFormatted,
        Domain: aggregatedValues.Domain,
        Percent: getPercentInteger(
          aggregatedValues.sufficientlyMetResponsesCount,
          aggregatedValues.responsesCount
        )
      };
    }
  );
}

/**
 * Converts an array of ScoredResponses into DomainMaturityStageScores,
 * which is an array of objects with the following fields:
 * - QuestionStageNameFormatted
 * - Domain
 * - Percent
 */
export function process(data: ScoredResponse[]): DomainMaturityStageScore[] {
  // Group ScoredResponses by Domain and Stage: `${domain} - Stage ${stageNumber}`
  const groupedData: GroupedScoredResponses = groupBy(
    data,
    (item: ScoredResponse) => `${item.Domain} - Stage ${item.QuestionStage}`
  );

  return convertToDomainMaturityStageScores(groupedData);
}
