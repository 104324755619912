import * as am5 from "@amcharts/amcharts5";
import theme from "src/theme";

class BaseTheme extends am5.Theme {
  setupDefaultRules() {
    // this.rule("ColorSet").setAll({
    //   colors: [am5.Color.fromString("#376cc8")],
    //   passOptions: {
    //     hue: 0.05
    //   }
    // });

    this.rule("Label").set("fontFamily", theme.typography.fontFamily);
  }
}

export default BaseTheme;
