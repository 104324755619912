import * as React from "react";
import * as REPORTS from "src/components/Reporting/Reports/All";
import { ReportClass } from "src/components/Reporting/Reports/Report/types";
import { ReportElement } from "src/components/Reporting/Reports/Report/types/element";
import useReportsContext from "src/context/Reports";
import { ReportsContextType } from "src/context/Reports/types";
import PassData from "./data/passData";

interface SelectedReportProps {
  reportName: string;
}

const SelectedReport = ({ reportName }: SelectedReportProps): JSX.Element => {
  const context: ReportsContextType = useReportsContext();

  const reportClass: ReportClass = Reflect.get(REPORTS, reportName);
  const Report: ReportElement = reportClass.report;
  const passData: PassData = new PassData(context);
  const data: any = passData[`${reportClass.group}`]();

  return <Report name={reportClass.displayName} data={data} />;
};

export default SelectedReport;
