import * as React from "react";
import Typography from "@mui/material/Typography";

const Content = (): JSX.Element => {
  return (
    <Typography>
      Are you sure you want to delete your question? All of your question data
      will be <b>permanently removed.</b>
    </Typography>
  );
};

export default Content;
