import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FilterCategory } from "../../../types";
import SectionHeader from "./Header";

interface SectionProps {
  category: FilterCategory;
  children: React.ReactNode;
}

const Section = ({ category, children }: SectionProps): JSX.Element => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
    <Accordion expanded={expanded} sx={{ paddingBottom: "16px" }}>
      <SectionHeader
        category={category}
        toggleExpanded={() => setExpanded(!expanded)}
      />
      <AccordionDetails sx={{ padding: "0px", marginTop: "6px" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default Section;
