import * as React from "react";
import Divider from "@mui/material/Divider";
import BaseModal from "../../../Common/Calculation/InfoModal";
import { CalculationInfoModalProps } from "../../../Common/Calculation/types";
import Example from "../../../Common/Calculation/MaturityStageScores/Example";
import Notes from "../../../Common/Calculation/MaturityStageScores/Notes";
import CalculationDefinition from "./Definition";

const CalculationInfoModal = (
  props: CalculationInfoModalProps
): JSX.Element => {
  return (
    <BaseModal {...props}>
      <CalculationDefinition />
      <Notes />
      <Divider />
      <Example />
    </BaseModal>
  );
};

export default CalculationInfoModal;
