import palette from "./palette";
import { SIDEBAR_WIDTH } from "src/utils/constants/layout";
/* Adding New Component Variants - Interfaces
 * - Reference: https://mui.com/customization/theme-components/#adding-new-component-variants
 */
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    inverted: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsSizeOverrides {
    xlarge: true;
  }
}

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    dark: true;
  }
}

/* Constants & Customization */
const xLargeButtonStyles = {
  minHeight: "48px",
  fontSize: "20px",
  paddingLeft: "24px",
  paddingRight: "24px"
};

const overrideAlertIcon = (severity: string, color: string) => {
  return {
    props: { variant: "standard", severity },
    style: {
      ".MuiAlert-icon": {
        color
      }
    }
  };
};

/* Component Overrides */
const componentOverrides: any = {
  /* Accordion */
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        borderTop: `1px solid ${palette.grey["200"]}`,
        margin: "0px", // gets rid of extra margin + moving content down when expanded
        "&.Mui-expanded": {
          margin: "0px"
        },
        "&:first-of-type": {
          borderTop: "none" // remove border for first item in accordion stack
        },
        "&::before": {
          display: "none !important" // remove default top divider (being replaced with borderTop)
        }
      }
    }
  },
  /* Accordion Summary */
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        minHeight: "0px !important"
      },
      content: {
        margin: "0px", // gets rid of extra margin + moving content down when expanded
        "&.Mui-expanded": {
          margin: "0px"
        }
      }
    }
  },
  /* Alert */
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: "12px",
        padding: "6px 24px"
      }
    },
    variants: [
      /* variant="standard" - for cases where the alert is fixed (not part of a Snackbar) */
      {
        props: { variant: "standard" },
        style: {
          backgroundColor: palette.grey["100"],
          color: palette.common.black, // set text color
          icon: {
            alignItems: "center",
            marginRight: "24px"
          }
        }
      },
      {
        props: { variant: "filled" },
        style: {
          color: palette.common.white // set text color
        }
      },
      overrideAlertIcon("info", palette.primary.main),
      overrideAlertIcon("success", palette.success.main),
      overrideAlertIcon("error", palette.error.light),
      /* variant="filled"|"outlined" - for cases where the Alert is part of a Snackbar */
      {
        props: { variant: "filled", severity: "info" },
        style: {
          backgroundColor: palette.primary.darker,
          // Override Close Icon
          "& .MuiAlert-action .MuiIconButton-root .MuiSvgIcon-root": {
            color: palette.common.white
          }
        }
      },
      {
        props: { variant: "filled", severity: "success" },
        style: {
          // Override Close Icon
          "& .MuiAlert-action .MuiIconButton-root .MuiSvgIcon-root": {
            color: palette.common.white
          }
        }
      },
      //Special Notification
      {
        props: { variant: "outlined", severity: "info" },
        style: {
          backgroundColor: palette.common.white,
          color: palette.grey["700"],
          border: `2px solid ${palette.primary.dark}`,
          ".MuiAlert-icon": {
            color: `${palette.primary.dark}`
          }
        }
      }
    ]
  },
  /* Autocomplete */
  MuiAutocomplete: {
    styleOverrides: {
      listbox: {
        "& .MuiAutocomplete-option[aria-disabled='true']": {
          opacity: 1,
          ".MuiCheckbox-root": {
            color: palette.grey["300"]
          },
          ".MuiTypography-root": {
            color: palette.grey["500"]
          },
          /* Remove highlighted primary color background when option is
           * both disabled and selected
           */
          "&[aria-selected='true']": {
            background: "none"
          }
        }
      },
      input: {
        // Disable number Input step arrows when Input wrapped in Autocomplete
        "&[type='number']": {
          // For Chrome, Safari, Edge, Opera
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0
          },
          // For Firefox
          MozAppearance: "textfield"
        }
      }
    }
  },
  /* Button */
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "96px", // 2 * largestButtonHeight
        textTransform: "none",
        fontWeight: "bold",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none"
        },
        "&.Mui-disabled": {
          color: palette.grey["500"]
        }
      }
    },
    variants: [
      {
        props: { size: "xlarge" },
        style: xLargeButtonStyles
      },
      {
        props: { variant: "contained", color: "inverted" },
        style: {
          "&:hover": {
            backgroundColor: palette.common.white,
            color: palette.primary.main
          }
        }
      },
      {
        props: { variant: "outlined", color: "inverted" },
        style: {
          borderColor: palette.common.white,
          color: palette.common.white,
          "&:hover": {
            borderColor: palette.common.white
          }
        }
      },
      {
        props: { variant: "text", color: "inverted" },
        style: {
          color: palette.common.white
        }
      }
    ]
  },
  /* Dialog */
  MuiDialog: {
    styleOverrides: {
      root: {
        "& .MuiDialog-paper": {
          borderRadius: "16px"
        }
      }
    }
  },
  /* Dialog Content Text*/
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        color: palette.common.black
      }
    }
  },
  /* Icon Button */
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: palette.grey["300"],
        "&:hover": {
          color: palette.primary.dark
        }
      }
    }
  },
  /* Paper
   * - Add "dark" variant for MuiMenu
   * - MuiMenu uses Paper as a child
   */
  MuiPaper: {
    variants: [
      {
        props: { variant: "dark" },
        style: {
          backgroundColor: palette.bg.dark,
          color: palette.common.white,
          "& .MuiSvgIcon-root": {
            color: palette.common.white
          },
          "& .MuiMenuItem-root:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.15)"
          }
        }
      }
    ]
  },
  /* Radio Button */
  MuiRadio: {
    styleOverrides: {
      root: {
        color: palette.grey["300"]
      }
    }
  },
  /* Snackbar */
  MuiSnackbar: {
    styleOverrides: {
      root: {
        //Centering the Snackbar by offsetting the sidebar width
        marginLeft: `calc(${SIDEBAR_WIDTH}/2) !important`
      }
    }
  },
  /* Toggle Button */
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderRadius: "96px", // 2 * largestButtonHeight
        color: palette.primary.main,
        fontWeight: "bold",
        textTransform: "none",
        backgroundColor: palette.common.white,
        "&.Mui-selected": {
          color: palette.common.white,
          backgroundColor: palette.primary.main
        },
        "&.Mui-selected:hover": {
          color: palette.common.white,
          backgroundColor: palette.primary.dark
        }
      }
    }
  },
  /* Tooltip */
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: palette.bg.dark,
        fontSize: "16px"
      }
    }
  },
  /* Typography Components
   * - Reference: https://material.io/design/typography/the-type-system.html#type-scale
   */
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontSize: "96px"
      },
      h2: {
        fontSize: "60px"
      },
      h3: {
        fontSize: "48px"
      },
      h4: {
        fontSize: "36px"
      },
      h5: {
        fontSize: "24px"
      },
      h6: {
        fontSize: "20px"
      },
      body1: {
        fontSize: "16px"
      },
      body2: {
        fontSize: "14px"
      },
      overline: {
        letterSpacing: "1px",
        fontSize: "16px",
        lineHeight: "1.4",
        color: palette.grey["700"]
      },
      subtitle1: {
        letterSpacing: "0.5px",
        fontSize: "18px",
        lineHeight: "1.4",
        fontWeight: "bold"
      }
    }
  }
};

export default componentOverrides;
