import * as React from "react";
import Stack from "@mui/material/Stack";
import ErrorMessage from "src/components/Common/ErrorMessage";
import FilterTagsAutocomplete from "src/components/Common/FilterTagsAutocomplete";
import {
  EvaluationTemplateSummary,
  EvaluationTemplateSummaryListResponse,
  EvaluationTemplateSummaryListResponseSchema
} from "src/schemas/evaluationTemplateSummary";
import { GetAPICaller } from "src/services/APICall";
import {
  AppDispatch,
  AppState,
  useAppDispatch,
  useAppSelector
} from "src/store";
import {
  setDescription,
  setSelectedTemplate
} from "src/store/slices/createEvaluation";
import { showError } from "src/store/slices/notification";
import { Collection } from "src/utils/constants/collection";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { Nullable } from "src/utils/types";
import AddItemLabel from "./AddItemLabel";
import TemplateMetadata from "./TemplateMetadata";
import { TemplateProps } from "./types";

const Template = ({ setModalOpen }: TemplateProps): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  const evaluationType: EvaluationTypes = useAppSelector(
    (state: AppState) => state.createEvaluation.evaluationType
  );
  const selectedTemplate: Nullable<EvaluationTemplateSummary> = useAppSelector(
    (state: AppState) => state.createEvaluation.selectedTemplate
  );

  const [templates, setTemplates] =
    React.useState<EvaluationTemplateSummary[]>(null);

  function successCallback(
    response: EvaluationTemplateSummaryListResponse
  ): void {
    setTemplates(response.result);
  }

  function errorCallback(error: Error): void {
    setTemplates([]);
    dispatch(
      showError({
        message: (
          <ErrorMessage
            error={error}
            collection={Collection[`${evaluationType}Template`]}
          />
        )
      })
    );
  }

  /* Get Survey or Assessment Templates
   * - evaluationType = assessment | survey
   */
  GetAPICaller({
    path: `/${evaluationType}-templates`,
    callback: successCallback,
    errorCallback,
    typeValidator: EvaluationTemplateSummaryListResponseSchema
  });

  function onChange(
    _event: React.SyntheticEvent,
    template: Nullable<EvaluationTemplateSummary>
  ): void {
    dispatch(setSelectedTemplate(template));
    /* If the selected template exists AND the selected template has a
     * Description, set the description to the template's provided description
     */
    if (template?.Description) {
      dispatch(setDescription(template.Description));
    }
  }

  return (
    <Stack>
      <AddItemLabel
        id="template"
        label={`${evaluationType} Template`}
        setModalOpen={setModalOpen}
      />

      <FilterTagsAutocomplete
        id="template"
        tagsConfig={{
          getField: (item) => item.Metadata.Tags
        }}
        optionsConfig={{
          id: "Id",
          label: "Name",
          tooltip: "Description"
        }}
        inputConfig={{
          placeholder: `Select a pre-existing ${evaluationType} template`
        }}
        value={selectedTemplate}
        loading={templates === null}
        options={templates ? templates : []}
        onChange={onChange}
      />
      {selectedTemplate && <TemplateMetadata />}
    </Stack>
  );
};
export default Template;
