import * as React from "react";
import {
  AppDispatch,
  AppState,
  useAppSelector,
  useAppDispatch
} from "src/store";
import { clearNotification } from "src/store/slices/notification";
import { Notification, Severity } from "src/store/types/notification";
import CustomSnackbar from "./CustomSnackbar";
import ErrorModal from "./ErrorModal";

class RenderNotifications {
  private static renderSnackBar({
    id,
    message,
    variant,
    severity,
    timeout
  }: Notification) {
    const dispatch: AppDispatch = useAppDispatch();
    return (
      <CustomSnackbar
        key={id}
        variant={variant}
        handleClose={() => dispatch(clearNotification(id))}
        open={true}
        severity={severity}
        autoHideDuration={timeout}
      >
        {message}
      </CustomSnackbar>
    );
  }
  static info = RenderNotifications.renderSnackBar;
  static success = RenderNotifications.renderSnackBar;
  static warning = RenderNotifications.renderSnackBar;

  static error({ id, message }: Notification) {
    const dispatch: AppDispatch = useAppDispatch();
    return (
      <ErrorModal
        key={id}
        open={true}
        handleClose={() => dispatch(clearNotification(id))}
      >
        {message}
      </ErrorModal>
    );
  }
}

const Notifications = () => {
  const notifications: Notification[] = useAppSelector(
    (state: AppState) => state.notification.notifications
  );
  const errorNotifications: Notification[] = notifications.filter(
    (value: Notification) => value.severity == Severity.error
  );
  if (errorNotifications.length > 0) {
    // Only render the most recent error notification
    const args = errorNotifications[errorNotifications.length - 1];
    return RenderNotifications[args.severity](args);
  } else if (notifications.length > 0) {
    // Only render the most recent notification
    const args: Notification = notifications[notifications.length - 1];
    return RenderNotifications[args.severity](args);
  }
  return <></>;
};

export default Notifications;
