import {
  DomainFilterItem,
  FilterState
} from "src/pages/ManageEvaluation/QuestionsPanel/Filters/types";
import { ToggleDomainActionData } from "../types";

/**
 * Given a new `filterState` and `domainName` + [optional] `enabled` provided
 * in the action `data` payload,
 *
 * - toggles the domain filter on / off OR
 *
 * - if `enabled` is explicitly passed in, sets the domain filter to that value
 */
export default function toggleDomain(
  newState: FilterState,
  data: ToggleDomainActionData
): FilterState {
  const { domainName, enabled } = data;
  const domain: DomainFilterItem = Reflect.get(newState.domains, domainName);

  // Set or toggle element state
  domain.enabled = enabled ?? !domain.enabled;

  /* Set `indeterminate` to false since we're setting/toggling Domain `enabled`
   * state, which means that all nested elements are either enabled or not.
   */
  domain.indeterminate = false;

  // Set all elements to same `enabled` state as parent Domain
  Object.keys(domain.elements).forEach((elementName: string) => {
    domain.elements[`${elementName}`].enabled = domain.enabled;
  });

  return newState;
}
