import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import SectionRow from "./layout/SectionRow";
import LabeledBox from "./layout/LabeledBox";
import { SandboxClass } from "./types";

const Colors = () => {
  const { palette } = useTheme();
  const paletteItems = {
    common: Object.entries(palette.common),
    primary: Object.entries(palette.primary),
    bg: Object.entries(palette.bg),
    grey: Object.entries(palette.grey),
    success: Object.entries(palette.success),
    error: Object.entries(palette.error)
  };

  return (
    <>
      {Object.entries(paletteItems).map((item) => {
        return (
          <SectionRow key={item[0]}>
            {item[1].map((color) => {
              return (
                <LabeledBox
                  key={`${item[0]}.${color[0]}`}
                  label={
                    <span>
                      {item[0]}.{color[0]} <br /> {color[1]}
                    </span>
                  }
                >
                  <Box
                    sx={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "4px",
                      backgroundColor: color[1]
                    }}
                  />
                </LabeledBox>
              );
            })}
          </SectionRow>
        );
      })}
    </>
  );
};

const ColorsSandbox: SandboxClass = class {
  static displayName = "Colors";
  static element = Colors;
};

export default ColorsSandbox;
