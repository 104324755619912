import * as React from "react";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import {
  FilterAction,
  ToggleCategoryActionData
} from "src/context/ManageEvaluation/reducers/filterState/types";
import AllEnabled from "../data/allEnabled";
import { FilterCategory } from "../types";
import ToggleFilterButton from "./Base";

interface ToggleCategoryButtonProps {
  category: FilterCategory;
}

export const ToggleCategoryButton = ({
  category
}: ToggleCategoryButtonProps): JSX.Element => {
  const { filterState, dispatchFilterState } = useManageEvaluationContext();
  const allEnabled: boolean = AllEnabled[`${category}`](filterState);

  function toggle(): void {
    const data: ToggleCategoryActionData = { category, enabled: !allEnabled };
    dispatchFilterState({ type: FilterAction.toggleCategory, data });
  }

  return <ToggleFilterButton onClick={toggle} allItemsEnabled={allEnabled} />;
};

export default ToggleCategoryButton;
