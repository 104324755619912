import { AmplifyEnvSettingsType } from "src/config/amplify/types";

export enum Environment {
  development = "dev",
  production = "prod"
}

export interface EnvironmentSettingsContextType {
  environment: Environment;
  amplify: AmplifyEnvSettingsType;
}

export interface EnvironmentSettingsProviderProps {
  children: React.ReactNode;
  envSettings: EnvironmentSettingsContextType;
}
