import * as React from "react";
import { Hub } from "aws-amplify";
// Get proper CognitoUser Typescript interface (with `attributes`) - see: https://github.com/aws-amplify/amplify-js/issues/4927
import { CognitoUserInterface as CognitoUser } from "@aws-amplify/ui-components";
import { useNavigate } from "react-router-dom";
import AuthService from "src/services/auth";
import { Nullable } from "src/utils/types";
import { DART_AUTH_CHANNEL } from "src/services/auth/constants";
import { isCognitoUser } from "src/services/auth/types/predicates";
import { AuthContextType, AuthProviderProps } from "./types";

export const AuthContext = React.createContext<AuthContextType>({
  isUserAuthenticated: false,
  user: null,
  reauthenticateUser: async () => null
});

/* References on setting up AuthContext & useAuth() hook:
 * https://reactrouter.com/docs/en/v6/examples/auth
 * https://github.com/aws-amplify/amplify-js/issues/3640#issuecomment-760935908
 */
export const AuthProvider = ({
  children,
  initialUserValue
}: AuthProviderProps): JSX.Element => {
  const navigate = useNavigate();
  const [user, setUser] =
    React.useState<Nullable<CognitoUser>>(initialUserValue);
  const [isUserAuthenticated, setIsUserAuthenticated] = React.useState<boolean>(
    isCognitoUser(initialUserValue)
  );

  async function reauthenticateUser(): Promise<Nullable<CognitoUser>> {
    const user: Nullable<CognitoUser> = await AuthService.getUser();
    setUser(user);
    setIsUserAuthenticated(isCognitoUser(user));
    return user;
  }

  /* Reauthenticate user whenever we make an authenticated API call.
   * Note: this will happen ASYNCHRONOUSLY, so it may take a little time to
   * determine if the user is authenticated.
   * If the user is unauthenticated, they will be redirected to the login page.
   */
  async function reauthenticateUserAfterAPICall(): Promise<void> {
    await reauthenticateUser();
    if (!isUserAuthenticated) {
      navigate("/");
    }
  }

  // Clean-up useEffect
  function cleanUp(): void {
    Hub.remove(DART_AUTH_CHANNEL, () => null);
  }

  // When AuthProvider is mounted to DOM
  React.useEffect(() => {
    /* Note - we were previously doing a `Hub.listen("auth")...` to listen
     * for sign-in + sign-out events, but after doing some testing, it looks
     * like we
     *
     * - never get a sign-in event since we're redirecting from an OAuth screen
     * to DART (Besides, the user gets authenticated/set when the user is
     * redirected to the DART site - when app is mounted)
     *
     * - we get a sign-out event but then we redirect to an OAuth logged out
     * screen (The user gets unauthenticated/set when the user comes back to
     * the DART site - when the app is mounted)
     */

    /* Refetch user whenever we make an API call
     * - E.g., If user stays on a page for 1+ hours, session will expire.
     * But if they are interacting with the backend API during that time,
     * every AUTHENTICATED call will attempt to update the global useAuth
     * "user" object with an updated user session.
     *
     * If user becomes unauthenticated during that time, this will redirect
     * them to the login page
     */
    Hub.listen(DART_AUTH_CHANNEL, async ({ payload: { event } }) => {
      switch (event) {
        case "fetchJWT": // Event after we've fetched JWT for backend API call
          reauthenticateUserAfterAPICall();
          break;
        default:
          break;
      }
    });

    // Specify how to clean up after effect (when AuthProvider is unmounted)
    return () => cleanUp();
  }, []);

  const value: AuthContextType = {
    isUserAuthenticated,
    user,
    reauthenticateUser
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => React.useContext(AuthContext);
export default useAuth;
