import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const Loading = ({ height, sx = {}, children = null }) => {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height, ...sx }}>
      <CircularProgress />
      {children ? (
        <Box component="div" sx={{ marginTop: "24px" }}>
          {children}
        </Box>
      ) : null}
    </Stack>
  );
};

export default Loading;
