/**
 * Custom checkbox style overrides
 * Aligns checkbox with first line of text (via `flex-start`) and adds padding
 * around overall group
 */
export const CHECKBOX_STYLES = {
  "& .MuiListItemButton-root": {
    padding: "8px 12px",
    alignItems: "flex-start"
  },
  "& .MuiListItemIcon-root": {
    marginRight: "6px",
    // Following is needed to make sure checkbox is in line with text
    // Custom code for when checkbox is of `small` size
    width: "20px",
    height: "20px"
  },
  "& .MuiListItemText-root": { margin: "0px" },
  "& .MuiTypography-root": { fontSize: "13px" }
};
