import { z } from "zod";
import { DateSchema, zodUUID } from "./common";
import { EvaluationSchema } from "./evaluation";
import { AnswerSchema, QuestionSchema } from "./question";
import { UserSchema } from "./user";

export const ResponseSchema = AnswerSchema.extend({
  Id: zodUUID,
  RespondentId: UserSchema.shape.Id.optional(),
  EvaluationId: EvaluationSchema.shape.Id,
  QuestionId: QuestionSchema.shape.Id,
  DateCreated: DateSchema,
  DateModified: DateSchema
});

export const ModifyResponseSchema = ResponseSchema.omit({
  Id: true,
  DateCreated: true,
  DateModified: true
}).extend({
  EvaluationId: EvaluationSchema.shape.Id.optional(),
  QuestionId: QuestionSchema.shape.Id.optional()
});

export const ResponseListSchema = ResponseSchema.array();

/* API Response objects */
export const ResponseResponseSchema = z.object({
  result: ResponseSchema
});

export const ResponseListResponseSchema = z.object({
  result: ResponseListSchema
});

/* Types */
export type Response = z.infer<typeof ResponseSchema>;
export type ResponseResponse = z.infer<typeof ResponseResponseSchema>;

export type ModifyResponse = z.infer<typeof ModifyResponseSchema>;

export type ResponseList = z.infer<typeof ResponseListSchema>;
export type ResponseListResponse = z.infer<typeof ResponseListResponseSchema>;
