import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.main
}));

/**
 * A reusable navigation link to be used on dark backgrounds (grey, black, etc.)
 */
export const StyledNavLinkOnDark = styled(StyledNavLink)(({ theme }) => ({
  color: theme.palette.grey["200"],
  // Add transparent border so hover-state border doesn't move item up
  borderBottom: "2px solid rgba(0, 0, 0, 0)",
  paddingBottom: "4px",
  "&:hover": {
    color: theme.palette.common.white,
    borderBottom: `2px solid ${theme.palette.common.white}`
  }
}));

/**
 * A reusable navigation link to be used on a dark tooltip
 */
export const StyledNavLinkOnDarkTooltip = styled(StyledNavLink)(
  ({ theme }) => ({
    color: theme.palette.grey["200"],
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.common.white
    }
  })
);
