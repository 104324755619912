import * as React from "react";
import TooltipTextBox from "src/components/Common/Tooltip/TooltipTextBox";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import { EvaluationArticles } from "src/utils/constants/evaluation";

const HelperText = (): JSX.Element => {
  const { evaluationType } = useManageEvaluationContext();
  const evaluationArticle: string = EvaluationArticles[`${evaluationType}`];
  return (
    <TooltipTextBox component="div">
      <p>
        Currently, users can only be added to {evaluationArticle}{" "}
        {evaluationType} and <b>cannot</b> be removed.
      </p>
      <p>
        <i>This is subject to change in the future.</i>
      </p>
    </TooltipTextBox>
  );
};

export default HelperText;
