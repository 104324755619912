import * as am5 from "@amcharts/amcharts5";

/**
 * Given a corner radius value, returns an object
 * which sets all amCharts rectangle corner radius
 * settings to the specified corner radius
 */
export function makeRoundedRectangleStyles(
  radius = 100
): am5.IRoundedRectangleSettings {
  return {
    cornerRadiusBR: radius,
    cornerRadiusTR: radius,
    cornerRadiusBL: radius,
    cornerRadiusTL: radius
  };
}
