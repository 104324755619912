import * as React from "react";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Header from "./Header";
import EvaluationsGrid from "./EvaluationsGrid";
import { getHomeWrapperLgStyles } from "./utils/styles";

const Home = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Stack sx={getHomeWrapperLgStyles(theme)}>
      <Header />
      <EvaluationsGrid />
    </Stack>
  );
};
export default Home;
