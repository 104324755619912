import * as React from "react";
import Typography from "@mui/material/Typography";
import { ContentProps } from "./types";

const Content = ({ evaluationType }: ContentProps): JSX.Element => {
  return (
    <Typography>
      Are you sure you want to delete your {evaluationType}? All of your data
      will be <b>permanently removed.</b>
    </Typography>
  );
};

export default Content;
