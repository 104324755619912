export enum Collection {
  assessment = "assessments",
  assessmentTemplate = "assessment templates",
  client = "clients",
  evaluation = "evaluations",
  project = "projects",
  response = "responses",
  survey = "surveys",
  surveyTemplate = "survey templates",
  user = "users"
}
