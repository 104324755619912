import create from "./create";
import { CreateRecommendationInterface } from "./types";

class RecommendationService {
  static post(args: CreateRecommendationInterface): void {
    return create(args);
  }
}

export default RecommendationService;
