import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { getReportHeaderStyles } from "./styles";

interface ReportOptionsInterface {
  children: React.ReactNode;
}

/**
 * A flexible Report sub-header component, which should be used to show
 * - options for filtering data, switching charts, etc.
 *
 * This component will be exported as a member of `Report` as `Report.Options`
 */
const ReportOptions = ({ children }: ReportOptionsInterface) => {
  const { palette } = useTheme();
  return (
    <Box
      component="div"
      sx={{
        borderTop: `1px solid ${palette.grey["300"]}`,
        ...getReportHeaderStyles(palette)
      }}
    >
      {children}
    </Box>
  );
};

export default ReportOptions;
