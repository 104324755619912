import _deleteEvaluation from "../evaluations/deleteEvaluation";
import { DeleteEvaluationInterface } from "./types";

/**
 * API Service class for performing actions on evaluations
 */
class EvaluationsService {
  static deleteEvaluation(args: DeleteEvaluationInterface): void {
    return _deleteEvaluation(args);
  }
}
export default EvaluationsService;
