import * as React from "react";
import { useSearchParams } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Input from "src/components/Common/Input";
import useReportsContext from "src/context/Reports";
import { Client } from "src/schemas/client";
import { Nullable } from "src/utils/types";
import { ClientHelperText } from "../HelperTexts";
import OptionLabel from "../OptionLabel";

const ClientDropdown = (): JSX.Element => {
  /* Context */
  const {
    data,
    selectedClient,
    setSelectedClient,
    selectedProject,
    setSelectedProject,
    selectedEvaluation,
    setSelectedEvaluation
  } = useReportsContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [clients, setClients] = React.useState<Nullable<Client[]>>(null);

  // Get initial data to populate dropdown options
  React.useEffect(() => {
    setClients(data.clients);
  }, [data.clients]);

  // Handle manual user value change - change selected values
  function handleValueChange(newValue: Nullable<Client>): void {
    setSelectedClient(newValue);

    if (newValue) {
      searchParams.set("client", newValue.Id);
    } else {
      searchParams.delete("client");
    }

    // Clear project if it is set
    if (selectedProject) {
      setSelectedProject(null);
      searchParams.delete("project");
    }

    // Clear evaluation if it is set
    if (selectedEvaluation) {
      setSelectedEvaluation(null);
      searchParams.delete("evaluation");
    }

    setSearchParams(searchParams);
  }

  return (
    <Stack sx={{ marginRight: "24px" }}>
      <OptionLabel
        id="client"
        title="Client"
        required={false}
        TooltipHelperText={<ClientHelperText />}
      />
      <Autocomplete
        loading={clients === null}
        id="client"
        options={clients ? clients : []}
        renderOption={(props: object, option: Client) => (
          <li {...props} key={option.Id}>
            {option.Name}
          </li>
        )}
        isOptionEqualToValue={(option: Client, value: Client) =>
          option.Name === value.Name
        }
        getOptionLabel={(value: Client) => value.Name as string}
        value={selectedClient}
        onChange={(
          _event: React.SyntheticEvent,
          newSelectedValue: Nullable<Client>
        ) => handleValueChange(newSelectedValue)}
        renderInput={(params: object) => (
          <Input {...params} placeholder="Select a client" />
        )}
      />
    </Stack>
  );
};
export default ClientDropdown;
