import * as React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ToggleCategoryButton from "../../../ToggleButtons/Category";
import { FilterCategory } from "../../../types";

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  pointerEvents: "none", // This prevents the click from going to the accordion
  padding: "16px 12px 0px 6px",
  "& .MuiAccordionSummary-expandIconWrapper": {
    order: -1 // This moves the icon to the left of the text
  }
}));

interface SectionHeaderProps {
  category: FilterCategory;
  toggleExpanded: () => void;
}

const SectionHeader = ({
  category,
  toggleExpanded
}: SectionHeaderProps): JSX.Element => (
  <StyledAccordionSummary
    expandIcon={
      <ExpandMoreIcon sx={{ pointerEvents: "auto" }} onClick={toggleExpanded} />
    }
  >
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Typography
        fontWeight="bold"
        sx={{
          pointerEvents: "auto",
          fontSize: "14px",
          textTransform: "uppercase"
        }}
        onClick={toggleExpanded}
      >
        {category}
      </Typography>
      <ToggleCategoryButton category={category} />
    </Stack>
  </StyledAccordionSummary>
);

export default SectionHeader;
