import { ScoredResponse } from "src/schemas/scoredResponse";
import {
  Classification,
  ClassificationData
} from "src/schemas/reporting/classification";

export default class ClassificationDataHandler {
  /* Helper functions for extracting data from ScoredResponses */
  private static addDomain(item: ScoredResponse, domainSet: Set<string>): void {
    domainSet.add(item.Domain);
  }

  private static addElement(
    item: ScoredResponse,
    elementSet: Set<string>
  ): void {
    elementSet.add(item.Element);
  }

  private static addStage(
    item: ScoredResponse,
    stagesMap: Map<number, string>
  ): void {
    if (!stagesMap.has(item.QuestionStage)) {
      stagesMap.set(item.QuestionStage, item.QuestionStageName);
    }
  }

  static initialize(
    classificationSet: Set<Classification> = new Set(
      Object.values(Classification)
    )
  ): ClassificationData {
    const result: ClassificationData = {};

    if (classificationSet.has(Classification.domains)) {
      result[Classification.domains] = new Set();
    }
    if (classificationSet.has(Classification.elements)) {
      result[Classification.elements] = new Set();
    }
    if (classificationSet.has(Classification.questionStages)) {
      result[Classification.questionStages] = new Map();
    }

    return result;
  }

  static make(
    items: ScoredResponse[],
    classificationFields: Classification[] = Object.values(Classification)
  ): ClassificationData {
    const classificationFieldSet: Set<Classification> = new Set(
      classificationFields
    );
    const result: ClassificationData = ClassificationDataHandler.initialize(
      classificationFieldSet
    );

    items.forEach((item: ScoredResponse) => {
      if (classificationFieldSet.has(Classification.domains)) {
        ClassificationDataHandler.addDomain(
          item,
          result[Classification.domains]
        );
      }
      if (classificationFieldSet.has(Classification.elements)) {
        ClassificationDataHandler.addElement(
          item,
          result[Classification.elements]
        );
      }
      if (classificationFieldSet.has(Classification.questionStages)) {
        ClassificationDataHandler.addStage(
          item,
          result[Classification.questionStages]
        );
      }
    });

    return result;
  }
}
