import * as React from "react";
import { EvaluationListProps } from "./types";
import EvaluationCard from "./EvaluationCard";
import { EvaluationSummary } from "src/schemas/evaluationSummary";

const EvaluationList = ({
  evaluations,
  evaluationType
}: EvaluationListProps): JSX.Element => {
  return (
    <>
      {evaluations.map((evaluation: EvaluationSummary) => {
        return (
          <EvaluationCard
            key={evaluation.Id}
            evaluation={evaluation}
            evaluationType={evaluationType}
          />
        );
      })}
    </>
  );
};
export default EvaluationList;
