import * as React from "react";
import { DetailLinks } from "src/schemas/evaluation";
import { EvaluationExpandOptions } from "src/schemas/expands/evaluation";
import { BoxedTags } from "src/components/Common/Tags";
import Linkable from "./Linkable";
import ClampedContent from "src/components/Common/ClampedContent";
import UserList from "./UserList";
import TitledContent from "./TitledContent";
import ScrollableContainer from "src/components/Common/ScrollableContainer";
import { BoxedNotes } from "src/components/Common/Notes";

const DETAILS_CONTAINER_WIDTH = "250px";
interface DetailsInterface {
  description: string;
  tags: string[];
  notes: string;
  linkables: DetailLinks;
}

const Details = ({ description, tags, notes, linkables }: DetailsInterface) => (
  <ScrollableContainer
    sxParent={{
      width: DETAILS_CONTAINER_WIDTH,
      flexShrink: 0,
      borderRadius: "12px",
      height: "100%"
    }}
    sx={{
      padding: "24px 16px"
    }}
  >
    <TitledContent title="CLIENT">
      <Linkable
        type={EvaluationExpandOptions.enum.Client}
        linkable={linkables.client}
      />
    </TitledContent>
    <TitledContent title="PROJECT">
      <Linkable
        type={EvaluationExpandOptions.enum.Project}
        linkable={linkables.project}
      />
    </TitledContent>
    <TitledContent title="DESCRIPTION">
      <ClampedContent>{description}</ClampedContent>
    </TitledContent>
    <TitledContent title="TAGS">
      <BoxedTags tags={tags} />
    </TitledContent>
    <TitledContent title="NOTES">
      <BoxedNotes notes={notes} />
    </TitledContent>
    <TitledContent title="TEMPLATE">
      <Linkable
        type={EvaluationExpandOptions.enum.Template}
        linkable={linkables.template}
      />
    </TitledContent>
    <TitledContent title="USERS">
      <UserList users={linkables.users} />
    </TitledContent>
  </ScrollableContainer>
);

export default Details;
