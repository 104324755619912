import * as React from "react";
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SlalomLogo from "src/components/Common/svg/SlalomLogo";
import { StyledNavLinkOnDark } from "src/components/Common/StyledNavLink";

const Container = styled("div")(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.bg.darkGrey,
  color: theme.palette.common.white,
  padding: "36px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Footer = (): JSX.Element => {
  const { palette } = useTheme();
  /* Get year dynamically */
  const currentYear = new Date().getFullYear();
  return (
    <Container>
      {/* Left - logo + copyright */}
      <Box sx={{ marginRight: "36px" }}>
        <SlalomLogo
          fill={palette.common.white}
          height="24px"
          sx={{ margin: "0 0 8px 0" }}
        />
        <Typography variant="body1">
          <span className="copyright">&copy;</span> {currentYear} Slalom, LLC
        </Typography>
      </Box>
      {/* Right - links */}
      <Stack direction="row" spacing="36px">
        <StyledNavLinkOnDark to="/contact">Contact</StyledNavLinkOnDark>
      </Stack>
    </Container>
  );
};

export default Footer;
