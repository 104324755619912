import * as React from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TooltipTextBox from "src/components/Common/Tooltip/TooltipTextBox";
import DisabledActionTooltip from "src/components/Common/Tooltip/DisabledActionTooltip";
import useManageEvaluationContext from "src/context/ManageEvaluation";

const ViewReportsTooltip = () => {
  return (
    <TooltipTextBox>
      Reports are only available in the <b>Open</b>, <b>Closed</b>, and{" "}
      <b>Completed</b> phases
    </TooltipTextBox>
  );
};

const ViewReports = ({ disabled }: { disabled: boolean }) => {
  const navigate = useNavigate();
  const { evaluation } = useManageEvaluationContext();

  function navigateToReportsPage(): void {
    const queryString: string = createSearchParams({
      evaluation: evaluation.item.Id
    }).toString();

    navigate({
      pathname: "/reports",
      search: queryString
    });
  }

  return (
    <DisabledActionTooltip title={<ViewReportsTooltip />} disabled={disabled}>
      <Button disabled={disabled} onClick={() => navigateToReportsPage()}>
        View Reports
      </Button>
    </DisabledActionTooltip>
  );
};

export default ViewReports;
