import * as React from "react";
import Stack from "@mui/material/Stack";
import { PHASES, PhaseTypesEnum } from "src/utils/constants/phase";
import { ButtonVariantEnum } from "src/utils/constants/button";
import MoveToPhaseButton from "./MoveToPhaseButton";

/**
 * The ChangePhase component includes the following buttons:
 * - Move to {previousPhase}
 * - Move to {nextPhase}
 *
 * When either Phase button is being submitted, BOTH should be disabled
 */
const ChangePhase = ({ currentPhase }: { currentPhase: PhaseTypesEnum }) => {
  const previousPhase: string = PHASES[PHASES.indexOf(currentPhase) - 1];
  const nextPhase: string = PHASES[PHASES.indexOf(currentPhase) + 1];
  const finalPhase: string = PHASES[PHASES.length - 1];

  // Use `disabled` to enable and disable the `Move to {previousPhase}` and `Move to {nextPhase}` buttons
  const [disabled, setDisabled] = React.useState(false);

  return (
    currentPhase !== finalPhase && (
      <Stack direction="row" spacing="12px" alignItems="flex-end">
        <MoveToPhaseButton
          phase={previousPhase}
          variant={ButtonVariantEnum.text}
          disabled={disabled}
          setDisabled={setDisabled}
        />
        <MoveToPhaseButton
          phase={nextPhase}
          variant={ButtonVariantEnum.contained}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      </Stack>
    )
  );
};

export default ChangePhase;
