import * as React from "react";
import { styled } from "@mui/material/styles";
import { SxProps, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { conditionalObject } from "src/utils/object";

interface SwatchBoxProps {
  color: string;
  height?: string;
  width?: string;
  showBorder?: boolean;
}
const SwatchBox = styled(Box)<SwatchBoxProps>(({ theme, ...props }) => ({
  width: props.width ? props.width : "32px",
  height: props.height ? props.height : "12px",
  backgroundColor: props.color,
  ...conditionalObject(props.showBorder, {
    border: `1px solid ${theme.palette.grey["200"]}`
  })
}));

interface ColorSwatchProps extends SwatchBoxProps {
  label: string | JSX.Element;
  sxLabel?: SxProps;
}
const ColorSwatch = ({
  color,
  width,
  height,
  showBorder = false,
  label,
  sxLabel
}: ColorSwatchProps) => {
  const { palette } = useTheme();
  return (
    <Stack direction="row" spacing="8px" alignItems="center">
      <SwatchBox
        color={color}
        height={height}
        width={width}
        showBorder={showBorder}
      />
      <Typography
        sx={{ fontSize: "12px", color: palette.grey["700"], ...sxLabel }}
      >
        {label}
      </Typography>
    </Stack>
  );
};
export default ColorSwatch;
