import { RowsState, RowStateValue } from "./types";

/* For singular Expand functionality */

/**
 * Expands a singular accordion and updates the state using passed-in update
 * state action
 * @param rowId
 * @param rowsState the state of the row - has an `expanded` field
 * @param updateRowsState rows state updater function
 * @returns void
 */
export function expandRow(
  rowId: string,
  rowsState: RowsState<any>,
  updateRowsState: (newRowsState: RowsState<any>) => any
): void {
  const newRowsState: RowsState<any> = structuredClone(rowsState);
  const row: RowStateValue = Reflect.get(newRowsState, rowId);
  row.expanded = !row.expanded;
  updateRowsState(newRowsState);
}

/* For Expand All functionality */

/**
 * Creates a list of row ids based on the passed-in `rowsState` Map.
 * Filters if a `rowType` (e.g., "Question" or "Domain") is passed in
 * @param rowsState the state of the row - has an `expanded` field
 * @param [rowType] the specific type of the row, used for filtering out row ids by type
 * @returns an array of row ids
 */
export function getRowIds(rowsState: RowsState<any>, rowType = null): string[] {
  return Object.keys(rowsState).filter((id: string) =>
    rowType ? Reflect.get(rowsState, id)?.type == rowType : true
  );
}

/**
 * Checks if all passed-in rows in `rowIds` are expanded, using `rowsState`'
 * @param rowIds an array of row ids
 * @param rowsState the state of the row - has an `expanded` field
 * @returns a boolean indicating if all rows are expanded
 */
export function areAllExpanded(
  rowIds: string[],
  rowsState: RowsState<any>
): boolean {
  return rowIds.every((id: string) => Reflect.get(rowsState, id).expanded);
}
