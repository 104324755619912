import { IndexedQuestion } from "src/schemas/question";
import {
  AnyFilterItem,
  DomainFilterItem,
  FilterItem,
  FilterState
} from "../types";
import SomeEnabled from "./someEnabled";

/**
 * Static class housing helper functions for applying filters to
 * different items (e.g., evaluation questions) based on filter state.
 */
export default class ApplyFilters {
  /**
   * Determines if the passed-in filter item is enabled.
   *
   * **Note:**
   * This handles a special case where filter item has children
   * filter items (e.g., for a domain, which has element filter items).
   *
   * For parent filter items with nested children filter items, we must
   * check the `indeterminate` field - in the `indeterminate` case, the parent
   * filter is still treated as "enabled", but the filtering is left to the
   * children.
   *
   */
  private static filterEnabled(item: AnyFilterItem): boolean {
    return "indeterminate" in item
      ? item.enabled || item.indeterminate
      : item.enabled;
  }

  static questions(
    filterState: FilterState,
    questions: IndexedQuestion[]
  ): IndexedQuestion[] {
    const domainFiltersOn: boolean = SomeEnabled.domains(filterState);
    const elementFiltersOn: boolean = SomeEnabled.elements(filterState);
    const stageFiltersOn: boolean = SomeEnabled.stages(filterState);

    const noFiltersOn: boolean =
      !domainFiltersOn && !elementFiltersOn && !stageFiltersOn;

    if (noFiltersOn) {
      return questions;
    }

    return questions.filter((q: IndexedQuestion) => {
      let enabled = true;

      if (domainFiltersOn) {
        const domain: DomainFilterItem = filterState.domains[`${q.Domain}`];
        enabled &&= ApplyFilters.filterEnabled(domain);
      }

      if (elementFiltersOn) {
        const element: FilterItem =
          filterState.domains[`${q.Domain}`].elements[`${q.Element}`];

        enabled &&= ApplyFilters.filterEnabled(element);
      }

      if ("Stage" in q && stageFiltersOn) {
        const stage: FilterItem = filterState.stages?.[`${q?.Stage}`];
        enabled &&= ApplyFilters.filterEnabled(stage);
      }

      return enabled;
    });
  }
}
