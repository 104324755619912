import * as React from "react";
import QuestionAccordion from "src/components/Common/Accordion/Question";
import { BaseQuestionAccordionSummaryParams } from "src/components/Common/Accordion/types";
import { PhaseTypesEnum } from "src/utils/constants/phase";
import EditingQuestionSummary from "./Editing";
import { QuestionSummaryParams } from "./types";

const QuestionSummary = ({
  phase,
  item,
  handleClick,
  showModal
}: QuestionSummaryParams) => {
  const baseProps: BaseQuestionAccordionSummaryParams = {
    item,
    index: item.index,
    handleClick
  };
  switch (phase) {
    case PhaseTypesEnum.editing:
      return <EditingQuestionSummary {...baseProps} showModal={showModal} />;
    default:
      return <QuestionAccordion.Summary {...baseProps} />;
  }
};

export default QuestionSummary;
