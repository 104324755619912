import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { IXYAxis } from "@amcharts/amcharts5/.internal/charts/xy/series/XYSeries";
import { ChartMaker, ChartInterface } from "../Chart/types";
import { addXYChart, setHeight } from "../utils/chartUtils/xy";
import { AmChartsDataValidatedSeriesEvent } from "../utils/chartUtils/events";
import { DomainRawScore } from "src/schemas/reporting/rawScore";
import AnimatedTheme from "@amcharts/amcharts5/themes/Animated";
import BaseTheme from "../themes/BaseTheme";
import theme from "src/theme";

/**
 * Horizontal bar chart representing the raw scores of Responses, grouped by Domains
 */
export class IndividualDomainRawScores implements ChartMaker {
  static displayName = "Individual Domain Raw Scores";
  private root: am5.Root;
  private data: DomainRawScore[];
  private categoryKey = "Domain";
  private chart: am5xy.XYChart;
  private xAxis: IXYAxis;
  private yAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>;
  private yMinGridDistance = 30;
  private series: am5xy.ColumnSeries;

  constructor({ data, root }: ChartInterface) {
    this.data = data;
    this.root = root;
  }

  private overrideThemes(): void {
    /* Don't want default Responsive theme since it breaks our
     * auto-sizing chart height capability
     */
    this.root.setThemes([
      AnimatedTheme.new(this.root),
      BaseTheme.new(this.root)
    ]);
  }

  private makeXAxis(): IXYAxis {
    const xRenderer: am5xy.AxisRendererX = am5xy.AxisRendererX.new(
      this.root,
      {}
    );

    return this.chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        min: 0,
        max: 100,
        numberFormat: "#'%'",
        strictMinMax: true,
        renderer: xRenderer
      })
    );
  }

  private makeYAxis(): am5xy.CategoryAxis<am5xy.AxisRenderer> {
    const yRenderer: am5xy.AxisRendererY = am5xy.AxisRendererY.new(this.root, {
      minGridDistance: this.yMinGridDistance,
      /* Need to reverse order of data since in amCharts,
       * the first item in the array is the last item in the series (last bar on chart) */
      inversed: true
    });

    // Hide y-axis divider lines between bars
    yRenderer.grid.template.set("forceHidden", true);

    return this.chart.yAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: this.categoryKey,
        tooltip: am5.Tooltip.new(this.root, {}),
        renderer: yRenderer
      })
    );
  }

  private makeTooltip(): am5.Tooltip {
    const tooltip = am5.Tooltip.new(this.root, {
      autoTextColor: false,
      keepTargetHover: true
    });

    tooltip.get("background").setAll({
      fillOpacity: 1
    });

    tooltip.label.setAll({
      fill: am5.color(theme.palette.common.white)
    });

    return tooltip;
  }

  private makeSeries(): am5xy.ColumnSeries {
    const series: am5xy.ColumnSeries = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: "Individual Domain Raw Score Series",
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueXField: "Percent",
        sequencedInterpolation: true,
        categoryYField: this.categoryKey,
        maskBullets: false,
        tooltip: this.makeTooltip()
      })
    );

    series.bullets.push(function (
      root: am5.Root,
      _series: am5xy.ColumnSeries,
      dataItem: any
    ) {
      const dataValue: number = dataItem?.dataContext?.Percent;
      const spriteSettings = {
        centerY: am5.p50,
        text: "{valueX}%",
        fill: am5.color(theme.palette.grey["700"]),
        populateText: true
      };

      // Note: Change threshold if needed
      /* If dataValue > 5, have labels be within bar */
      if (dataValue > 5) {
        spriteSettings.fill = am5.color(theme.palette.common.white);
        spriteSettings["centerX"] = am5.p100;
      }
      return am5.Bullet.new(root, {
        locationX: 1,
        locationY: 0.5,
        sprite: am5.Label.new(root, spriteSettings)
      });
    });

    const columnTemplate: am5.Template<am5.RoundedRectangle> =
      series.columns.template;

    columnTemplate.setAll({
      cursorOverStyle: "pointer",
      tooltipText: "[bold]{Percent}%\n{ScoreValue} / {MaxScoreValue} points",
      cornerRadiusBR: 10,
      cornerRadiusTR: 10
    });

    // Set Fill colors of horizontal bars
    columnTemplate.adapters.add("fill", (_fill, target) => {
      return this.chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    // Set Stroke colors of horizontal bars
    columnTemplate.adapters.add("stroke", (_stroke, target) => {
      return this.chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    return series;
  }

  private dynamicallySetHeight(): void {
    // Dynamically set height of chart based on number of bars
    const cellSize: number = this.yMinGridDistance * 2;
    this.series.events.on(
      "datavalidated",
      function (event: AmChartsDataValidatedSeriesEvent<am5xy.ColumnSeries>) {
        setHeight(event, { cellSize });
      }
    );
  }

  make(container?: am5.Container): am5xy.XYChart {
    this.overrideThemes();
    this.chart = addXYChart(
      this.root,
      {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: this.root.verticalLayout
      },
      container
    );

    this.xAxis = this.makeXAxis();
    this.yAxis = this.makeYAxis();
    this.series = this.makeSeries();

    // Set data
    this.yAxis.data.setAll(this.data);
    this.series.data.setAll(this.data);

    // Set dimensions
    this.dynamicallySetHeight();

    /* Make stuff animate on load
     * https://www.amcharts.com/docs/v5/concepts/animations/
     */
    this.series.appear(1000);
    this.chart.appear(1000, 100);
    return this.chart;
  }
}
