import * as React from "react";
import { TypographyProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import { MATURITY_THRESHOLD_PASSED_TEXT } from "src/components/Reporting/utils/maturity";

const CalculationDefinition = (props: TypographyProps): JSX.Element => {
  return (
    <Typography {...props}>
      A recommendation is provided whenever a question <b>response</b> fails to
      pass a maturity threshold of {MATURITY_THRESHOLD_PASSED_TEXT}.
    </Typography>
  );
};

export default CalculationDefinition;
