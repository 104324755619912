import * as am5 from "@amcharts/amcharts5";
import { MATURITY_THRESHOLD_PASSED_TEXT } from "src/components/Reporting/utils/maturity";
import { LegacyScoredResponse } from "src/schemas/scoredResponse";
import { ChartMaker, ChartInterface } from "../../Chart/types";
import { addTitle } from "../../utils/chartUtils/title";
import { addColumnLabel } from "../../utils/chartUtils/label";
import { addVerticalDivider } from "../../utils/chartUtils/divider";
import { FluencyOfRespondents } from "./FluencyOfRespondents";

export class Text implements ChartMaker {
  static displayName = "Overall Maturity Stage Scores";
  private root: am5.Root;
  private data: LegacyScoredResponse[];
  private chart: am5.Container;
  private draggableLegend: boolean;
  constructor({ data, root, draggableLegend }: ChartInterface) {
    this.data = data;
    this.root = root;
    this.draggableLegend = draggableLegend ?? false;
  }

  make(container?: am5.Container) {
    this.chart = (container ?? this.root.container).children.push(
      am5.Container.new(this.root, {
        layout: this.root.verticalLayout,
        width: am5.p100,
        height: am5.p100
      })
    );

    addTitle(this.root, this.chart, Text.displayName);

    const columns = this.chart.children.push(
      am5.Container.new(this.root, {
        layout: this.root.horizontalLayout,
        width: am5.p100,
        height: am5.percent(90),
        id: "Columns"
      })
    );

    const leftColumn = columns.children.push(
      am5.Container.new(this.root, {
        layout: this.root.verticalLayout,
        id: "LeftColumn"
      })
    );

    addVerticalDivider(this.root, columns);

    const rightColumn = columns.children.push(
      am5.Container.new(this.root, {
        layout: this.root.verticalLayout,
        id: "RightColumn",
        width: am5.p50,
        height: am5.p100
      })
    );

    this.createLeftColumnContents(leftColumn);

    const args: ChartInterface = {
      data: this.data,
      root: this.root,
      draggableLegend: this.draggableLegend
    };
    new FluencyOfRespondents(args).make(rightColumn);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    this.chart.appear(1000, 100);
    return this.chart;
  }

  private createLeftColumnContents(leftColumn: am5.Container) {
    addColumnLabel(this.root, leftColumn, "Your Stage");

    addColumnLabel(this.root, leftColumn, "Stage 2 - Proactive");

    addColumnLabel(
      this.root,
      leftColumn,
      "You've been placed at an overall Maturity Stage of Stage 2 - Proactive across 7 domains."
    );

    addColumnLabel(
      this.root,
      leftColumn,
      "Your Maturity Stage is the highest Stage that has been sufficiently " +
        "met. A Maturity Stage has been sufficiently met when it has a score " +
        "of 100%. A score of 100% indicates that all responses for questions " +
        "linked to that Stage have an answer value of " +
        MATURITY_THRESHOLD_PASSED_TEXT +
        "."
    );
    addColumnLabel(
      this.root,
      leftColumn,
      "For instance, a Maturity Stage score of 70% indicates that 70% of " +
        "responses have an answer value of " +
        MATURITY_THRESHOLD_PASSED_TEXT +
        ". This means that the " +
        "remaining 30% of responses represent areas with insufficient client " +
        "maturity. For those 30% of responses, maturity recommendations will " +
        "be provided to help the client strengthen and enhance those areas."
    );
  }
}
