import { z } from "zod";

/**
 * Source of truth **interface** for all possible expand options (for both
 * API requests and responses).
 *
 * Objects using a subset of these options will be **typed** as
 * `Pick<ExpandOptionsInterface, KeysToPickUnion>`.
 */
export type ExpandOptionsInterface = {
  Client: "client";
  Clients: "clients";
  Project: "project";
  Projects: "projects";
  Questions: "questions";
  Recommendation: "recommendation";
  Template: "template";
  Templates: "templates";
  Users: "users";
};

/**
 * Source of truth **object** for all possible expand options (for both
 * API requests and responses). Used to create zod enum object.
 *
 * Objects using a subset of these options will pick desired fields from this
 * object.
 */
export const ExpandOptionsBase: ExpandOptionsInterface = {
  Client: "client",
  Clients: "clients",
  Project: "project",
  Projects: "projects",
  Questions: "questions",
  Recommendation: "recommendation",
  Template: "template",
  Templates: "templates",
  Users: "users"
};

/**
 * Zod enum object - used in place of native Typescript enum due to our
 * ability to pass in an object that implements or narrows down our base
 * expand options object. Native enums cannot be based on another enum.
 *
 * **Example Usage**
 * - ExpandOptions.enum.Client => "client"
 * - ExpandOptions.enum.Project => "project"
 */
export const ExpandOptions = z.nativeEnum(ExpandOptionsBase);

/**
 * Union of base expand enum **values** - e.g.,
 * "client" | "project" | etc.
 */
export type ExpandOption = z.infer<typeof ExpandOptions>;

/**
 * Union of base expand enum **keys** - e.g,
 * "Client" | "Project" | etc.
 */
export type ExpandOptionKey = keyof ExpandOptionsInterface;
