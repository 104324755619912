import * as React from "react";
import Domains from "src/components/Common/Accordion/Domain/Domains";
import Questions from "src/components/Common/Accordion/Question/Basic/Questions";
import { expandRow } from "src/components/Common/Accordion/utils";
import {
  AppDispatch,
  AppState,
  useAppDispatch,
  useAppSelector
} from "src/store";
import { setPreviewRowsState } from "src/store/slices/createEvaluation";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { PreviewRowsState } from "../types";

interface TemplateProps {
  // Note, the actual typing for items is Question[] | Map<String, Set<String>>, but Typescript ran into issues
  items: any;
  evaluationStagesMap: Record<string, string>;
  isQuestionsMode: boolean;
}

/**
 * Component for when an evaluation template has been selected and loaded from the backend
 */
const Template = ({
  items,
  evaluationStagesMap,
  isQuestionsMode
}: TemplateProps): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  const evaluationType: EvaluationTypes = useAppSelector(
    (state: AppState) => state.createEvaluation.evaluationType
  );
  const previewRowsState: PreviewRowsState = useAppSelector(
    (state: AppState) => state.createEvaluation.previewRowsState
  );

  function updatePreviewRowsState(newRowsState: PreviewRowsState): void {
    dispatch(setPreviewRowsState(newRowsState));
  }

  /* Toggles expansion state for this row. MUI's Accordion does not support
   * expand/collapse all while allowing to also individually management each
   * this works around that limit
   */
  function handleAccordionClick(id: string): void {
    expandRow(id, previewRowsState, updatePreviewRowsState);
  }

  return isQuestionsMode ? (
    <Questions
      items={items}
      evaluationType={evaluationType}
      evaluationStagesMap={evaluationStagesMap}
      rowsState={previewRowsState}
      handleClick={handleAccordionClick}
    />
  ) : (
    <Domains
      items={items}
      rowsState={previewRowsState}
      handleClick={handleAccordionClick}
    />
  );
};

export default Template;
