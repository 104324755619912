import * as React from "react";
import { ChipProps } from "@mui/material/Chip";
import Tag from "./Tags";

interface StageTagProps {
  value: ChipProps["label"];
}

/**
 * A reusable component for rendering an Evaluation Question Maturity Stage tag
 */
const StageTag = ({ value }: StageTagProps): JSX.Element => {
  return (
    <Tag
      label={value}
      size="small"
      sx={{ minWidth: "70px", maxWidth: "300px" }}
    />
  );
};

export default StageTag;
