import * as React from "react";
import * as am5 from "@amcharts/amcharts5";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Tag from "src/components/Common/Tags";
import ConditionalWrapper from "src/components/Common/ConditionalWrapper";
import { SUMMARY_VIEW_KEY } from "./constants";

import { conditionalObject } from "src/utils/object";
import { getDefaultThemes } from "src/components/Reporting/Charts/utils/chartUtils/themes";
import { Nullable } from "src/utils/types";

interface TitleProps {
  domain: string;
  domainIndex: number;
  reportName: string;
  root?: am5.Root;
}

function createColorSet(root: am5.Root): Nullable<am5.ColorSet> {
  return root
    ? am5.ColorSet.new(root, {
        themes: getDefaultThemes(root, { includeProvidedDefault: true })
      })
    : null;
}

const Title = ({
  domain,
  domainIndex,
  reportName,
  root
}: TitleProps): JSX.Element => {
  const { palette } = useTheme();
  const isIndividualDomainView: boolean = domain !== SUMMARY_VIEW_KEY;
  const colorSet: am5.ColorSet = createColorSet(root);

  return (
    <>
      <ConditionalWrapper condition={isIndividualDomainView}>
        <Tag
          size="medium"
          label={domain}
          sx={{
            fontSize: "16px",
            letterSpacing: "0px",
            marginBottom: "12px",
            ...conditionalObject(Boolean(colorSet), {
              color: palette.common.white,
              backgroundColor: colorSet?.getIndex(domainIndex)?.toString()
            })
          }}
        />
      </ConditionalWrapper>
      <Typography variant="h5">
        <b>{reportName}</b>
      </Typography>
    </>
  );
};

export default Title;
