import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InvertedInput from "src/components/Common/Input/Inverted";
import OptionLabel from "../../../Common/OptionLabel";
import { DomainDropdownProps } from "./types";

const DomainDropdown = ({
  domainOptions,
  domain,
  setDomain
}: DomainDropdownProps): JSX.Element => {
  return (
    <>
      <OptionLabel id="domain" title="DOMAIN" isSubOption={true} />
      <Autocomplete
        sx={{ width: "450px", display: "inline-flex" }}
        disableClearable
        id="domain"
        options={domainOptions}
        value={domain}
        onChange={(_event: React.SyntheticEvent, newValue: string) => {
          setDomain(newValue);
        }}
        renderInput={(params: object) => <InvertedInput {...params} />}
      />
    </>
  );
};

export default DomainDropdown;
