import { z } from "zod";

// Classification data - for grouping Domains, Elements, and Stages
export enum Classification {
  domains = "domains",
  elements = "elements",
  questionStages = "questionStages"
}

export const ClassificationDataSchema = z.object({
  [Classification.domains]: z.set(z.string()).optional(),
  [Classification.elements]: z.set(z.string()).optional(),
  [Classification.questionStages]: z.map(z.number(), z.string()).optional()
});

// Types
export type ClassificationData = z.infer<typeof ClassificationDataSchema>;
