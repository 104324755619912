import * as React from "react";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import PhaseActionTable from "./PhaseActionsTable";
import ColorSwatch from "src/components/Common/ColorSwatch";
import { PhaseTypesEnum } from "src/utils/constants/phase";

const Content = ({ currentPhase }: { currentPhase: PhaseTypesEnum }) => {
  const { palette } = useTheme();
  return (
    <Stack>
      <Stack spacing="24px">
        <Typography sx={{ fontSize: "14px" }}>
          You can move an Assessment to one of <b>4</b> different phases
          (Editing, Open, Closed, and Completed). In each phase, you can perform
          a different set of actions on the Assessment.
        </Typography>
        <PhaseActionTable currentPhase={currentPhase} />
      </Stack>
      <Box sx={{ marginTop: "12px" }}>
        <ColorSwatch
          color={palette.bg.lightYellow}
          showBorder={true}
          label="Highlights Assessment's current phase"
          sxLabel={{ fontStyle: "italic" }}
        />
      </Box>
    </Stack>
  );
};
export default Content;
