import * as React from "react";
import BasePrompt from "../../Prompt";
import { PromptProps } from "./types";

const Prompt = ({ sx }: PromptProps): JSX.Element => {
  return (
    <BasePrompt sx={sx}>
      Click on the table below to sort, filter, export, and view
      recommendations.
    </BasePrompt>
  );
};

export default Prompt;
