import { SxProps } from "@mui/material";

/**
 * Data Grid CSS overrides:
 * - Removes border around Data Grid
 * - Removes vertical separator to right of last column in header
 * ("View Details")
 *
 * Note: Have to pass the following object in via the `sx` prop since using the
 * `styled` function from MUI did not work
 */
export const DATA_GRID_STYLES: SxProps = {
  border: "none",
  ".MuiDataGrid-columnHeader:last-of-type": {
    ".MuiDataGrid-columnSeparator": { display: "none" }
  }
};

/**
 * Data Grid Toolbar CSS overrides:
 * - Adds more spacing between all buttons in toolbar
 */
export const TOOLBAR_STYLES: SxProps = {
  ".MuiButton-root": {
    marginRight: "16px"
  }
};
