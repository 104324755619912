import * as React from "react";
import Stack from "@mui/material/Stack";
import { BaseAccordionGroupParams, DomainMap } from "../types";
import DomainAccordion from ".";

interface DomainsParams extends BaseAccordionGroupParams {
  items: DomainMap;
}

/**
 *  ItemAccordion for Domain objects. The parameter items is a Map where
 * - key = Domain
 * - value = Set of Elements
 */
const Domains = ({ items, rowsState, handleClick }: DomainsParams) => {
  return (
    <Stack>
      {[...items.entries()].sort().map((item, index) => (
        <DomainAccordion
          item={item}
          key={index}
          expanded={Reflect.get(rowsState, item[0]).expanded}
          handleClick={handleClick}
        />
      ))}
    </Stack>
  );
};

export default Domains;
