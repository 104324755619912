import { SxProps } from "@mui/material";

/**
 * Style object for vertically aligning multiple text blocks to the text
 * baseline.
 *
 * **Usage**
 * Typically use when you have 2 Typography/text elements of varying font sizes
 * - block 1 = fontSize: 20px
 * - block 2 = fontSize: 14px
 * and want the bottom (baseline) of the text blocks to line up
 */
export const baselineVerticalAlign: SxProps = {
  display: "inline-block",
  verticalAlign: "baseline"
};
