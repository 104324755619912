import * as React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { PhaseTypesEnum } from "src/utils/constants/phase";
import Delete from "./Delete";
import Edit from "./Edit";
import ViewReports from "./ViewReports";

/**
 * The ActionRow component includes the following buttons:
 * - Delete: This button will be enabled in all phases except the Completed phase.
 * - Edit: This button will be enabled in all phases except the Completed phase.
 * - View Reports: This button will be enabled in all phases except the Editing phase.
 */
const ActionRow = ({ phase }: { phase: PhaseTypesEnum }) => {
  const isEditingPhase = phase == PhaseTypesEnum.editing;
  const isCompletedPhase = phase == PhaseTypesEnum.completed;

  return (
    <Stack direction="row" alignItems="center" spacing="16px">
      <Delete phase={phase} />
      <Edit disabled={isCompletedPhase} />
      <Divider orientation="vertical" flexItem />
      <ViewReports disabled={isEditingPhase} />
    </Stack>
  );
};

export default ActionRow;
