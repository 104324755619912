import * as React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import Loading from "src/components/Common/Loading";

/**
 * Component for when an evaluation template is being loaded in
 */
const TemplateLoading = () => {
  const { palette } = useTheme();

  return (
    <Loading height="auto" sx={{ marginTop: "60px" }}>
      <Typography variant="body1" sx={{ color: palette.grey["700"] }}>
        <b>Loading your template...</b>
      </Typography>
    </Loading>
  );
};

export default TemplateLoading;
