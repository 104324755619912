import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ConditionalWrapper from "src/components/Common/ConditionalWrapper";
import { reportBodyStyles } from "./styles";
import { ReportElementProps } from "./types/element";

interface ReportOptionsInterface extends Pick<ReportElementProps, "name"> {
  children: React.ReactNode;
}

/**
 * A flexible Report details (body) component, which displays
 * - a title if the `name` prop is passed in
 * - any custom `children` components (e.g., charts, MUI components, etc.)
 *
 * This component will be exported as a member of `Report` as `Report.Content`
 */
const ReportContent = ({ name, children }: ReportOptionsInterface) => {
  return (
    <Box component="div" sx={reportBodyStyles}>
      <ConditionalWrapper condition={Boolean(name)}>
        <Typography variant="h5">
          <b>{name}</b>
        </Typography>
      </ConditionalWrapper>
      {children}
    </Box>
  );
};

export default ReportContent;
