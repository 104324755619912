import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Modal from "src/components/Common/Modal";
import { ActionsProps } from "../../types";
import SubmitButton from "./SubmitButton";

const Actions = (props: ActionsProps) => {
  const [disabled, setDisabled] = React.useState<boolean>(false);

  return (
    <Modal.Actions sx={{ padding: "16px" }}>
      <Stack spacing="16px" direction="row" justifyContent="flex-end">
        <Button
          variant="text"
          size="large"
          onClick={props.handleClose}
          disabled={disabled}
        >
          Cancel
        </Button>
        <SubmitButton
          {...props}
          setDisabled={setDisabled}
          disabled={disabled}
        />
      </Stack>
    </Modal.Actions>
  );
};

export default Actions;
