import * as React from "react";
import Stack from "@mui/material/Stack";
import { AppDispatch, useAppDispatch } from "src/store";
import { clear, setEvaluationType } from "src/store/slices/createEvaluation";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import ConfigurationOptions from "./ConfigurationOptions";
import Header from "./Header";
import Preview from "./Preview";
import { LEFT_COLUMN_WIDTH } from "./styles";

interface CreateEvaluationFromTemplateProps {
  evaluationType: EvaluationTypes;
}

const CreateEvaluationFromTemplate = ({
  evaluationType
}: CreateEvaluationFromTemplateProps): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(clear()); // Reset all Redux state for Create Evaluation page
    dispatch(setEvaluationType(evaluationType));
  }, []);

  return (
    <Stack direction="row" spacing="48px" sx={{ height: "100%" }}>
      {/* LEFT - Title, Info, Configuration Options */}
      <Stack
        alignItems="space-between"
        spacing="36px"
        sx={{ width: LEFT_COLUMN_WIDTH }}
      >
        <Header />
        <ConfigurationOptions />
      </Stack>
      {/* RIGHT - Preview */}
      <Preview />
    </Stack>
  );
};

export default CreateEvaluationFromTemplate;
