import * as React from "react";
import { capitalize } from "@mui/material";
import { EmptyStateMessage } from "src/components/Common/EmptyStateMessage";
import { AppState, useAppSelector } from "src/store";
import {
  EvaluationTypes,
  EvaluationArticles
} from "src/utils/constants/evaluation";

/**
 * Component for when no evaluation template has been selected yet from configuration options
 */
const NoSelectedTemplate = () => {
  const evaluationType = useAppSelector(
    (state: AppState) => state.createEvaluation.evaluationType
  );
  const evaluationArticle: string =
    EvaluationArticles[evaluationType as EvaluationTypes];
  return (
    <EmptyStateMessage
      header="No template selected"
      body={
        <>
          Please select {evaluationArticle}{" "}
          <b>{capitalize(evaluationType)} Template </b> in{" "}
          <b>Configuration Options</b> to see {evaluationArticle}{" "}
          {evaluationType} preview here.
        </>
      }
    />
  );
};

export default NoSelectedTemplate;
