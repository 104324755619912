import {
  CreateRecommendation,
  CreateRecommendationSchema
} from "src/schemas/recommendation";
import { RecommendationResponseSchema } from "src/schemas/recommendation/response";
import { PostAPICaller, PostAPICallRequest } from "../APICall";
import { CreateRecommendationInterface } from "./types";

/**
 * Creates a new recommendation.
 * - `POST /recommendations`
 */
export default function create({
  recommendation,
  callback,
  errorCallback
}: CreateRecommendationInterface): void {
  const postURL = "/recommendations";
  const body: CreateRecommendation =
    CreateRecommendationSchema.parse(recommendation);

  const request: PostAPICallRequest = {
    path: postURL,
    body,
    callback,
    errorCallback,
    typeValidator: RecommendationResponseSchema,
    withReactHook: false
  };

  return PostAPICaller(request);
}
