import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { PromptProps } from "./types";

/**
 * A reusuable text component to prompt the user to take action
 * (e.g., hover over a chart to see more info)
 */
const Prompt = ({ sx, children }: PromptProps): JSX.Element => {
  const { palette } = useTheme();
  return (
    <Typography
      sx={{
        fontSize: "14px",
        fontStyle: "italic",
        color: palette.grey["700"],
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};

export default Prompt;
