import * as React from "react";
import Button from "@mui/material/Button";
import { DeleteButtonProps } from "../../types";

const DeleteButton = ({
  handleClose,
  setDeleteModalOpen
}: DeleteButtonProps): JSX.Element => {
  // Close the `Edit Question` Modal and show the `Delete Question` Modal
  function onDeleteButtonClick(): void {
    handleClose();
    setDeleteModalOpen(true);
  }

  return (
    <Button color="error" size="large" onClick={onDeleteButtonClick}>
      Delete
    </Button>
  );
};
export default DeleteButton;
