import * as React from "react";
import { SvgIconComponent } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";

interface IconBoxProps {
  icon: SvgIconComponent;
}

function styleIconComponent(IconComponent: SvgIconComponent): JSX.Element {
  return <IconComponent sx={{ fontSize: "32px" }} />;
}

/**
 * A 48px x 48px rounded, filled box component with the passed-in
 * `icon` centered horizontally and vertically within it.
 */
const IconBox = ({ icon }: IconBoxProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        height: "48px",
        width: "48px",
        color: palette.common.white,
        backgroundColor: palette.primary.main,
        borderRadius: "12px",
        margin: "0 auto"
      }}
    >
      {styleIconComponent(icon)}
    </Box>
  );
};

export default IconBox;
