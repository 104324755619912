import * as React from "react";
import ReportOptions from "./Options";
import ReportContent from "./Content";

/**
 * A reusable Report wrapper component to be used whenever we create a new report for our application.
 * The `children` should be comprised of
 * - **Report.Options** - an optional subheader to be used whenever you need to show
 * report-specific options (e.g., for filtering, displaying different charts
 * within the same report)
 * - **Report.Content** - the core Report body, which automatically populates a title if the report name is passed in
 */
const Report = ({ children }) => {
  return <>{children}</>;
};

Report.Options = ReportOptions;
Report.Content = ReportContent;

export default Report;
