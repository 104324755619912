import * as React from "react";
import { useTheme } from "@mui/material";
import { StyledNavLink } from "src/components/Common/StyledNavLink";
import { ContactReason } from "../types";
import { createMailToLink } from "../utils/email";
import IconBox from "./IconBox";
import Text from "./Text";

interface ReasonProps {
  item: ContactReason;
}

/**
 * A styled box component wrapped in a link to mail to dart@slalom.com on
 * user click. This component is used to house a reason why a user may want to
 * contact us.
 */
const Reason = ({ item }: ReasonProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <StyledNavLink
      to={createMailToLink(item)}
      sx={{
        padding: "24px 12px",
        textAlign: "center",
        border: `1px solid ${palette.grey["200"]}`,
        borderRadius: "8px"
      }}
    >
      <IconBox icon={item.icon} />
      <Text>{item.text}</Text>
    </StyledNavLink>
  );
};

export default Reason;
