import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ConditionalWrapper from "./ConditionalWrapper";
import Typography from "@mui/material/Typography";

const Tag = styled(Chip)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "bold",
  backgroundColor: theme.palette.grey["200"],
  color: theme.palette.common.black,
  letterSpacing: "0.5px",
  borderRadius: "8px"
}));

export const BoxedTags = ({
  tags,
  marginLeft = "0px",
  marginRight = "8px",
  marginTop = "0px",
  marginBottom = "8px",
  label = "No Tags"
}: {
  tags: string[];
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  marginBottom?: string;
  label?: string;
}) => {
  const { palette } = useTheme();
  const hasTags = tags.length > 0;
  return (
    <Stack direction="row" flexWrap="wrap">
      <ConditionalWrapper condition={hasTags}>
        <>
          {tags.map((tag: string) => (
            <Tag
              key={tag}
              label={tag}
              size="small"
              sx={{
                fontSize: "11px",
                marginLeft,
                marginRight,
                marginTop,
                marginBottom
              }}
            />
          ))}
        </>
      </ConditionalWrapper>
      <ConditionalWrapper condition={!hasTags}>
        <Typography
          sx={{
            fontSize: "12px",
            fontStyle: "italic",
            marginLeft,
            marginTop,
            color: palette.grey[700]
          }}
        >
          {label}
        </Typography>
      </ConditionalWrapper>
    </Stack>
  );
};

export default Tag;
