import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { ContactReason } from "./types";
import {
  DOUBLE_NEWLINE,
  INDENT,
  NEWLINE,
  createIndentedSection,
  createUnderlinedSection
} from "src/utils/email";

export const CONTACT_REASONS: ContactReason[] = [
  {
    icon: LightbulbIcon,
    text: "Request a feature",
    subject: "New Feature Request",
    body: "As a consultant, I would like to [feature], so that [business case]."
  },
  {
    icon: BugReportRoundedIcon,
    text: "Report a bug",
    subject: "Bug found!",
    body:
      "I discovered a bug on DART." +
      DOUBLE_NEWLINE +
      createUnderlinedSection("Bug Report Template") +
      DOUBLE_NEWLINE +
      "Summary:" +
      DOUBLE_NEWLINE +
      "Platform:" +
      NEWLINE +
      "Operating System:" +
      NEWLINE +
      "Browser:" +
      DOUBLE_NEWLINE +
      "URL:" +
      DOUBLE_NEWLINE +
      createUnderlinedSection("Description") +
      DOUBLE_NEWLINE +
      createUnderlinedSection("Steps to reproduce") +
      DOUBLE_NEWLINE +
      createUnderlinedSection("Expected result") +
      DOUBLE_NEWLINE +
      createUnderlinedSection("Actual result") +
      DOUBLE_NEWLINE +
      createUnderlinedSection("Screenshot(s)") +
      DOUBLE_NEWLINE +
      createUnderlinedSection("Notes")
  },
  {
    icon: AddCircleRoundedIcon,
    text: "Add a client, project, or template",
    subject: "New [Client, Project, Template]",
    body:
      `I'd like to add a new [Client, Project, Template].` +
      DOUBLE_NEWLINE +
      "Client" +
      INDENT +
      createIndentedSection([
        "Name",
        "Description",
        "Salesforce Id",
        "Market"
      ]) +
      DOUBLE_NEWLINE +
      "Project" +
      INDENT +
      createIndentedSection([
        "Name",
        "Description",
        "Salesforce Id",
        "Associated Client",
        "End Date"
      ]) +
      DOUBLE_NEWLINE +
      "Assessment Template" +
      INDENT +
      createIndentedSection(
        [
          "We've provided an example assessment template to help you craft your own template: https://twodegrees1.sharepoint.com/:x:/t/DART780/EWUywX_LqpJGrqjGLyew5cUBilnRabbJJ7wKi6IwAFca0g?e=JecMPi",
          "Create a copy of this Excel document to house your new assessment template",
          "Follow the instructions in the ReadMe sheet",
          "Populate your copy with your desired assessment template configuration",
          "Attach your copy to this email as a direct attachment or a link"
        ],
        "- ",
        ""
      )
  }
];
