import * as React from "react";
import { styled } from "@mui/material/styles";
import { SIDEBAR_WIDTH } from "src/utils/constants/layout";
import Footer from "./Footer";

const Container = styled("div")(() => ({
  height: "100%",
  width: "100%",
  padding: "36px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between", // vertical alignment
  marginLeft: SIDEBAR_WIDTH,

  /* Overflow properties */
  overflowX: "hidden", // Disable horizontal scrolling
  overflowY: "auto"
}));

const Content = ({ children }) => {
  return (
    <Container>
      {children}
      <Footer />
    </Container>
  );
};

export default Content;
