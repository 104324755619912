import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import ClampedContent from "./ClampedContent";
import ConditionalWrapper from "./ConditionalWrapper";

export const BoxedNotes = ({ notes }: { notes: string }) => {
  const { palette } = useTheme();
  const hasNotes = !!notes;
  return (
    <>
      <ConditionalWrapper condition={hasNotes}>
        <>
          <ClampedContent useModal={true} modalTitle="NOTES">
            {notes}
          </ClampedContent>
        </>
      </ConditionalWrapper>
      <ConditionalWrapper condition={!hasNotes}>
        <Typography
          sx={{
            fontSize: "12px",
            fontStyle: "italic",
            color: palette.grey[700]
          }}
        >
          No Notes
        </Typography>
      </ConditionalWrapper>
    </>
  );
};
