/**
 * Style object for taking up remaining vertical space when parent
 * container is set to
 * - display: "flex"
 * - flex-direction: "column"
 *
 * Note: This is used over `flex: 1` in order to support overflow (scrollable)
 * containers.
 *
 * See reference here:
 * https://stackoverflow.com/questions/52487743/prevent-flex-item-from-exceeding-parent-height-and-make-scroll-bar-work/52506205#52506205
 */
export const remainingVerticalSpace: object = {
  flex: "1 1 1px"
};
