import {
  CustomEvaluationResponse,
  UpdateEvaluationForm,
  UpdateEvaluationItem,
  UpdateEvaluationItemSchema
} from "src/schemas/evaluation";

/**
 * Given an expanded Evaluation response's nested `item` field, returns a
 * parsed version of `item`, keeping only the fields necessary for the
 * Edit Modal form
 *
 */
export function shapeEvaluationItem(
  item: CustomEvaluationResponse["item"]
): UpdateEvaluationItem {
  return UpdateEvaluationItemSchema.parse(item);
}

/**
 * Given an expanded Evaluation response `evaluation`, returns a deep copy of
 * `evaluation`, with
 *
 * - a parsed version of the nested `item` field with only the fields
 * necessary for the Edit Modal form
 *
 * - a full version of the nested `_expand` field
 *
 */
export function shapeEvaluation(
  evaluation: CustomEvaluationResponse
): UpdateEvaluationForm {
  return {
    item: structuredClone(shapeEvaluationItem(evaluation.item)),
    _expand: structuredClone(evaluation._expand)
  };
}
