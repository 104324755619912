import * as React from "react";
import Button from "@mui/material/Button";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Modal from "./Modal";

const ErrorModal = ({ children, open, handleClose }) => {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Modal.Header>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing="12px"
        >
          <ErrorOutlinedIcon color="error" />
          <Typography variant="h5" color="error">
            <b>Error</b>
          </Typography>
        </Stack>
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions
        sx={{
          justifyContent: "center",
          padding: "12px"
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={handleClose}
          color="error"
        >
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ErrorModal;
