import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import { OptionsConfig } from "./types";

/* The following are styles that come from MUI by default, but we need to define them here
 * for the `selected` case
 */
const StyledListItemButton = styled(ListItemButton)(() => ({
  "&[aria-selected='true']": {
    backgroundColor: "rgba(12, 98, 251, 0.08)" // theme.palette.primary.main -> 0.08 opacity
  },
  "&[aria-selected='true']:hover": {
    backgroundColor: "rgba(12, 98, 251, 0.12)" // theme.palette.primary.main -> 0.12 opacity
  }
}));

const Item = ({
  index,
  option,
  optionsConfig,
  getOptionProps
}: {
  index: number;
  option: any;
  optionsConfig: OptionsConfig;
  getOptionProps: any;
}) => {
  return (
    <Tooltip
      disableHoverListener={!optionsConfig.tooltip}
      placement="right"
      title={
        <Box sx={{ fontSize: "14px", padding: "8px" }}>
          {option[optionsConfig.tooltip]}
        </Box>
      }
    >
      <StyledListItemButton
        key={option[optionsConfig.id]}
        dense
        role={undefined}
        {...getOptionProps({ option, index })}
      >
        <ListItemText
          primaryTypographyProps={{ variant: "body1" }}
          primary={option[optionsConfig.label]}
        />
      </StyledListItemButton>
    </Tooltip>
  );
};

export default Item;
