import { styled } from "@mui/material/styles";
import { ReactComponent as SlalomLogoSVG } from "src/assets/slalomLogo.svg";
import { CustomSVGProps } from "./types";

const SlalomLogo = styled(SlalomLogoSVG)<CustomSVGProps>(
  ({ theme, ...props }) => ({
    height: props.height || props.width ? "auto" : "56.1px",
    width: props.width || props.height ? "auto" : "216px",
    path: {
      fill: props.fill || theme.palette.common.black
    }
  })
);

export default SlalomLogo;
