import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import useReportsContext from "src/context/Reports";
import { ReportLoading } from "./ReportLoading";
import { Report } from "./Report";
import { NoEvaluationSelected } from "./NoEvaluationSelected";

const ReportBody = (): JSX.Element => {
  const { selectedEvaluation, isReportDataLoading } = useReportsContext();
  const evaluationSelected = Boolean(selectedEvaluation);

  return (
    <Box
      sx={{
        borderColor: useTheme().palette.grey["200"],
        border: `1px solid ${useTheme().palette.grey["200"]}`,
        marginTop: "24px !important",
        borderRadius: "12px",
        flex: 1
      }}
    >
      <Box sx={{ overflow: "hidden", borderRadius: "12px" }}>
        {!evaluationSelected && <NoEvaluationSelected />}
        {evaluationSelected && isReportDataLoading && <ReportLoading />}
        {evaluationSelected && !isReportDataLoading && <Report />}
      </Box>
    </Box>
  );
};

export default ReportBody;
