import * as React from "react";
import { TooltipProps } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import TooltipTextBox from "./TooltipTextBox";

const ComingSoonText = () => {
  return (
    <TooltipTextBox component="div">
      Support for this feature is <b> coming soon</b>
    </TooltipTextBox>
  );
};

const ComingSoonTooltip = (props: Partial<TooltipProps>) => {
  const { children, ...restOfProps } = props;

  // Provide default `title` if not provided in incoming props
  const customTooltipProps: Omit<TooltipProps, "children"> = {
    ...restOfProps,
    title:
      restOfProps.title === undefined ? <ComingSoonText /> : restOfProps.title
  };

  return (
    <Tooltip placement="right-start" {...customTooltipProps}>
      {children}
    </Tooltip>
  );
};

export default ComingSoonTooltip;
