import * as React from "react";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import AutocompleteBase from "src/components/Common/AutocompleteWithHeader/Base";
import {
  OptionsConfig,
  InputConfig
} from "src/components/Common/AutocompleteWithHeader/types";
import {
  CheckboxMap,
  CheckboxMapValue
} from "src/components/Common/CheckboxGroup";
import { sortArrayASC } from "src/utils/sort";
import Header from "./Header";
import Body from "./Body";
import { TagsConfig } from "./types";
import { populateTagsMap, customFilter } from "./utils";

const FilterTagsAutocomplete = ({
  tagsConfig,
  optionsConfig,
  inputConfig,
  // MUI Autocomplete Component Props
  options = [], // required, so we can't just expect it to come in from ...MUIProps
  ...MUIProps
}: {
  tagsConfig: TagsConfig;
  optionsConfig: OptionsConfig;
  inputConfig: InputConfig;
  options: Array<any>;
  [MUIProp: string]: any;
}) => {
  const [showFilterScreen, setShowFilterScreen] =
    React.useState<boolean>(false);
  const [tagsMap, setTagsMap] = React.useState<CheckboxMap>(new Map());
  /* Selected tags (strings) to populate header + to check if all checkboxes are selected */
  const [selectedTags, setSelectedTags] = React.useState<Array<string>>([]);

  // Populate and sort `tagsMap` anytime `options` prop changes
  React.useEffect(() => {
    for (const template of options) {
      populateTagsMap(tagsMap, template, tagsConfig);
    }
    // Sort
    const sortedTags = sortArrayASC([...tagsMap.keys()]);
    setTagsMap(
      new Map(
        sortedTags.map((key) => [key, { selected: tagsMap.get(key).selected }])
      )
    );
  }, [options]);

  // Populate and sort `selectedTags` anytime the `tagsMap` data changes
  React.useEffect(() => {
    const tags = [...tagsMap]
      .filter((tuple) => tuple[1].selected)
      .map((tuple) => tuple[0]);
    setSelectedTags(sortArrayASC(tags));
  }, [tagsMap]);

  // Change `tagsMap` whenever checkbox is toggled
  const handleCheckboxChange = (tag: string) => {
    const newTagMapValue: CheckboxMapValue = {
      selected: !tagsMap.get(tag).selected
    }; // toggle value
    setTagsMap(new Map(tagsMap.set(tag, newTagMapValue)));
  };

  // Change `tagsMap` whenever "Select All" checkbox is toggled
  const handleSelectAllCheckboxChange = (oldCheckedValue: boolean) => {
    setTagsMap(
      new Map(
        [...tagsMap.keys()].map((key) => [key, { selected: !oldCheckedValue }])
      )
    );
  };

  const useAutocompleteProps = useAutocomplete({
    options: options,
    getOptionLabel: (option) => option[optionsConfig.label] ?? "",
    filterOptions: (options, state) =>
      customFilter({
        options,
        state,
        tagsMap,
        selectedTags,
        tagsConfig,
        optionsConfig
      }),
    ...MUIProps
  });

  return (
    <AutocompleteBase
      inputConfig={inputConfig}
      header={
        <Header
          showFilterScreen={showFilterScreen}
          tags={selectedTags}
          onClick={() => setShowFilterScreen(!showFilterScreen)}
        />
      }
      body={
        <Body
          showFilterScreen={showFilterScreen}
          useAutocompleteProps={useAutocompleteProps}
          optionsConfig={optionsConfig}
          tagsMap={tagsMap}
          selectedTags={selectedTags}
          handleSelectAllCheckboxChange={handleSelectAllCheckboxChange}
          handleCheckboxChange={handleCheckboxChange}
        />
      }
      useAutocompleteProps={useAutocompleteProps}
      options={options}
      {...MUIProps}
    />
  );
};

export default FilterTagsAutocomplete;
