import { createSlice } from "@reduxjs/toolkit";
import { createSliceReducer } from "../utils/slice";
import { CreateEvaluationState } from "../types/createEvaluation";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { PreviewMode } from "src/pages/CreateItem/CreateEvaluationFromTemplate/types";

type S = CreateEvaluationState;

const initialState: S = {
  evaluationType: EvaluationTypes.assessment,
  name: "",
  description: "",
  selectedTemplate: null,
  selectedClient: null,
  selectedProject: null,
  previewTemplate: null,
  previewMode: PreviewMode.Questions,
  previewRowsState: {}
};

export const slice = createSlice({
  name: "createEvaluation",
  initialState,
  reducers: {
    clear: (state: S) => {
      Object.keys(initialState).forEach((key: string) => {
        Reflect.set(state, key, Reflect.get(initialState, key));
      });
    },
    setEvaluationType: createSliceReducer<S>("evaluationType"),
    setName: createSliceReducer<S>("name"),
    setDescription: createSliceReducer<S>("description"),
    setSelectedTemplate: createSliceReducer<S>("selectedTemplate"),
    setSelectedClient: createSliceReducer<S>("selectedClient"),
    setSelectedProject: createSliceReducer<S>("selectedProject"),
    setPreviewTemplate: createSliceReducer<S>("previewTemplate"),
    setPreviewMode: createSliceReducer<S>("previewMode"),
    setPreviewRowsState: createSliceReducer<S>("previewRowsState")
  }
});

export const {
  clear,
  setEvaluationType,
  setName,
  setDescription,
  setSelectedTemplate,
  setSelectedClient,
  setSelectedProject,
  setPreviewTemplate,
  setPreviewMode,
  setPreviewRowsState
} = slice.actions;

export default slice.reducer;
