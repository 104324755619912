import * as React from "react";
import Stack from "@mui/material/Stack";
import Input from "src/components/Common/Input";
import ScoreChip from "./ScoreChip";

const AnswerInput = ({
  scoreValue,
  text,
  handleAnswer
}: {
  scoreValue: number;
  text: string;
  handleAnswer: (number, any) => any;
}) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      sx={{ width: "100%", marginBottom: "16px" }}
    >
      <ScoreChip score={scoreValue} />
      <Input
        multiline
        value={text}
        maxRows={5}
        onChange={(event: any) => handleAnswer(scoreValue, event.target.value)}
        sx={{ flex: 1 }}
        placeholder="Enter a new answer"
      />
    </Stack>
  );
};
export default AnswerInput;
