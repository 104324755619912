import pluralize from "pluralize";
import { MaturityStageScore } from "src/schemas/reporting/maturityScore";
import { ScoredResponse } from "src/schemas/scoredResponse";
import { sortObjectArrayASC } from "src/utils/sort";
import { ChartMetadata, ChartType } from "../types";

export function getDomainLabel(
  data: ScoredResponse[],
  metadata: ChartMetadata
): string {
  if (metadata.chartType == ChartType.Domain) {
    return `for the [bold]${metadata.domain}[/] domain`;
  }
  const domainsCount: number = new Set(
    data.map((item: ScoredResponse) => item.Domain)
  ).size;
  return `across [bold]${domainsCount}[/] ${pluralize("domain", domainsCount)}`;
}

/**
 * Gets overall maturity Stage (highest sufficiently-met Stage) from data
 */
export function getOverallStageLabel(data: MaturityStageScore[]): string {
  const sufficientlyMetStages: MaturityStageScore[] = data.filter(
    (item: MaturityStageScore) => item.Percent == 100
  );

  // Sorted sufficiently-met MaturityStageScores on `QuestionStage`
  const sortedSufficientlyMetStages: MaturityStageScore[] = sortObjectArrayASC(
    sufficientlyMetStages,
    (item: MaturityStageScore) => item.QuestionStage
  );

  if (!sortedSufficientlyMetStages.length) {
    return "N/A";
  }

  // Get highest consecutive sufficiently-met Stage
  const overallStage: MaturityStageScore = sortedSufficientlyMetStages.reduce(
    (max: MaturityStageScore, item: MaturityStageScore) => {
      const isFirstOrConsecutiveStage =
        !max || item.QuestionStage == max.QuestionStage + 1;
      return isFirstOrConsecutiveStage ? item : max;
    },
    null
  );

  return overallStage.QuestionStageNameFormatted;
}
