import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { GridColDef } from "@mui/x-data-grid";
import Modal from "src/components/Common/Modal";
import { ContentProps } from "../types";
import Field from "./Field";

const Content = ({ row, columns }: ContentProps): JSX.Element => {
  const shownColumns: GridColDef[] = columns.filter((column: GridColDef) => {
    return column.field !== "ViewDetails";
  });

  return (
    <Modal.Content>
      <Box>
        {shownColumns.map((column: GridColDef, index: number) => {
          return (
            <Box key={column.field}>
              <Field column={column} row={row} />
              {index < shownColumns.length - 1 && <Divider />}
            </Box>
          );
        })}
      </Box>
    </Modal.Content>
  );
};

export default Content;
