import * as React from "react";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import ExpandAllButton from "src/components/Common/Accordion/Expand/ExpandAllButton";
import {
  AppDispatch,
  AppState,
  useAppDispatch,
  useAppSelector
} from "src/store";
import { setPreviewRowsState } from "src/store/slices/createEvaluation";
import { PreviewMode, PreviewRowsState } from "../types";
import CountDisplay from "./CountDisplay";

const PreviewHeaderBottom = ({ dataLoaded, count }) => {
  const dispatch: AppDispatch = useAppDispatch();

  const previewMode: PreviewMode = useAppSelector(
    (state: AppState) => state.createEvaluation.previewMode
  );
  const previewRowsState: PreviewRowsState = useAppSelector(
    (state: AppState) => state.createEvaluation.previewRowsState
  );

  function updatePreviewRowsState(newRowsState: PreviewRowsState): void {
    dispatch(setPreviewRowsState(newRowsState));
  }

  return (
    <>
      {/* Sub-header */}
      {dataLoaded && (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            height: "44px",
            backgroundColor: useTheme().palette.grey["100"]
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            padding="24px"
            spacing="24px"
          >
            <ExpandAllButton
              rowsState={previewRowsState}
              updateRowsState={updatePreviewRowsState}
              rowType={previewMode}
            />
            <CountDisplay>
              {count} {previewMode}
            </CountDisplay>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default PreviewHeaderBottom;
