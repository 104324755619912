import { ActionTenses } from "../types";

export function getActionTenses(isEditing: boolean): ActionTenses {
  return {
    presentSimple: isEditing ? "update" : "create",
    presentContinuous: isEditing ? "updating" : "creating",
    pastSimple: isEditing ? "updated" : "created"
  };
}

export function getDisabledTooltipText(
  isFormInvalid: boolean,
  isFormClean: boolean
) {
  if (isFormInvalid) {
    return "Please fill out all the fields";
  }

  if (isFormClean) {
    return "Please make changes to update this question";
  }
}
