export interface ReducerAction<T extends object> {
  type?: keyof T; // passed-in `type` must be a field of T
  data?: any;
}

export interface ReducerFunction<T extends object> {
  (state: T, action: ReducerAction<T>): T;
}

export type ReducerFunctionParameters<T extends object> = Parameters<
  ReducerFunction<T>
>;

/**
 * Simple React Reducer function that updates an object's fields.
 *
 * **Usage**
 * Pass in as the first argument to the `React.useReducer` hook
 */
export function simpleReducer<T extends object>(
  ...args: ReducerFunctionParameters<T>
): T {
  const [state, action]: ReducerFunctionParameters<T> = args;
  const { type, data }: ReducerFunctionParameters<T>[1] = action;
  return {
    ...state,
    [type]: data
  };
}
