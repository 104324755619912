/**
 * Given `stageNumber` and `stageName`, returns the following formatted string:
 * - `Stage {stageNumber} - {stageName}` if `stageName` is non-falsy
 * - `Stage {stageNumber}` if `stageName` is falsy
 */
export function formatQuestionStageText(
  stageNumber: number,
  stageName: string
): string {
  const showStageName = Boolean(stageName);
  const suffix = showStageName ? ` - ${stageName}` : "";
  return `Stage ${stageNumber}${suffix}`;
}
