import * as React from "react";
import { SxProps } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import { makeToolbarExportProps } from "./utils";

interface ToolbarProps {
  csvFileName: string;
  sx?: SxProps;
}

/**
 * A custom toolbar for DataGrid featuring the following components:
 * - `GridToolbarColumnsButton` - to edit which columns are shown
 * - `GridToolbarFilterButton` - to filter 1 column at a time
 * - `GridToolbarDensitySelector` - to change the height of the rows
 * - `GridToolbarExport` - to export data as a CSV (sorts data and ignores
 * filters)
 *
 * Takes the following **props**:
 * - `csvFileName` - custom csv file name passed to exporting capability
 * - [optional]`sx` - custom styles object for parent toolbar container
 */
const Toolbar = ({ csvFileName, sx = {} }: ToolbarProps): JSX.Element => {
  return (
    <GridToolbarContainer sx={sx}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport {...makeToolbarExportProps(csvFileName)} />
    </GridToolbarContainer>
  );
};

export default Toolbar;
