import {
  FilterItem,
  FilterState
} from "src/pages/ManageEvaluation/QuestionsPanel/Filters/types";
import { ToggleStageActionData } from "../types";

/**
 * Given a new `filterState` and `stageName` + [optional] `enabled` provided
 * in the action `data` payload,
 *
 * - toggles the stage filter on / off OR
 *
 * - if `enabled` is explicitly passed in, sets the stage filter to that value
 */
export default function toggleStage(
  newState: FilterState,
  data: ToggleStageActionData
): FilterState {
  const { stageName, enabled } = data;
  const stage: FilterItem = Reflect.get(newState.stages, stageName);
  stage.enabled = enabled ?? !stage.enabled;
  return newState;
}
