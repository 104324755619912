import * as React from "react";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import AutocompleteInput from "./Input";
import AutocompleteHeader from "./Header";
import { InputConfig, UseAutocompleteHookProps } from "./types";

const Message = ({ text }: { text: string }) => {
  const { palette } = useTheme();
  return (
    <Typography
      variant="body1"
      sx={{
        padding: "14px 16px",
        color: palette.grey["700"]
      }}
    >
      {text}
    </Typography>
  );
};

const Listbox = ({
  header,
  body,
  useAutocompleteProps
}: {
  header: React.ReactNode;
  body: React.ReactNode;
  useAutocompleteProps: UseAutocompleteHookProps<any, false, false, true>;
}) => {
  const { getListboxProps } = useAutocompleteProps;
  return (
    <List
      {...getListboxProps()}
      sx={{
        width: "100%",
        position: "relative",
        overflow: "auto",
        maxHeight: 320,
        "& ul": { padding: 0 }
      }}
      subheader={<AutocompleteHeader>{header}</AutocompleteHeader>}
    >
      {body}
    </List>
  );
};

const renderStates = (
  loading: boolean,
  header: React.ReactNode,
  body: React.ReactNode,
  useAutocompleteProps: UseAutocompleteHookProps<any, false, false, true>
) => {
  const { groupedOptions } = useAutocompleteProps;

  if (loading) {
    return <Message text="Loading..." />;
  }

  return groupedOptions.length > 0 ? (
    <Listbox
      header={header}
      body={body}
      useAutocompleteProps={useAutocompleteProps}
    />
  ) : (
    <Message text="No options" />
  );
};

const AutocompleteBase = ({
  inputConfig,
  header,
  body,
  // Props from the MUI hook
  useAutocompleteProps,
  ...MUIProps
}: {
  inputConfig: InputConfig;
  header: React.ReactNode;
  body: React.ReactNode;
  useAutocompleteProps: UseAutocompleteHookProps<any, false, false, true>;
  [MUIProp: string]: any;
}) => {
  const { disabled, loading } = MUIProps;
  const { popupOpen, anchorEl } = useAutocompleteProps;
  return (
    <Box component="div">
      <AutocompleteInput
        disabled={disabled}
        useAutocompleteProps={useAutocompleteProps}
        inputConfig={inputConfig}
      />
      <Popper open={popupOpen} anchorEl={anchorEl}>
        <Paper
          elevation={3}
          sx={{
            margin: "8px 0px",
            overflow: "hidden",
            width: anchorEl?.offsetWidth ?? "400px"
          }}
        >
          {renderStates(loading, header, body, useAutocompleteProps)}
        </Paper>
      </Popper>
    </Box>
  );
};

export default AutocompleteBase;
