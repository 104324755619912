import { z } from "zod";

export const HealthSchema = z.object({
  status: z.string(),
  dbStatus: z.string(),
  version: z.string(),
  region: z.string(),
  serviceName: z.string(),
  environmentName: z.string(),
  logLevel: z.string(),
  domain: z.string(),
  executionTime: z.number(),
  dbResponseTime: z.number().nullable(),
  errors: z.string().array()
});

// API Response objects
export const HealthResponseSchema = z.object({
  result: HealthSchema
});

// Types
export type Health = z.infer<typeof HealthSchema>;
export type HealthResponse = z.infer<typeof HealthResponseSchema>;
