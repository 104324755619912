import update from "./update";
import { UpdateEvaluationInterface } from "./types";

/**
 * API Service class for performing actions on a single evaluation
 */
class EvaluationService {
  static patch(args: UpdateEvaluationInterface): void {
    return update(args);
  }
}

export default EvaluationService;
