import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// TODO - eventually update based on an official Figma design for this page
const Error = ({ message = "Invalid request." }: { message?: string }) => {
  return (
    <Stack alignItems="center">
      <Typography variant="h3">{message}</Typography>
    </Stack>
  );
};

export default Error;
