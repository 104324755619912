/**
 * Formats epoch millisecond timestamp -> MMMM D, YYYY format (e.g., "March 7, 2022")
 * Use localeDateString() to ensure we take user's timezone settings into account
 */
export const formatLongDate = (epochTimestamp: number) => {
  // Use user's default locale -> set `locales` to []
  return new Date(epochTimestamp).toLocaleDateString([], {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
};

/**
 * Formats epoch millisecond timestamp -> H:MM format (e.g., "10:04 AM")
 * Use localeTimeString() to ensure we take user's timezone settings into account
 */
export const formatShortTime = (epochTimestamp: number) => {
  // Use user's default locale -> set `locales` to []
  return new Date(epochTimestamp).toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit"
  });
};

/**
 * Formats epoch millisecond timestamp -> MMMM D, YYYY {delimiter} H:MM format (e.g., "March 7, 2022 at 10:04 AM")
 * Use localeTimeString() to ensure we take user's timezone settings into account
 */
export const formatLongDateAndShortTime = (
  epochTimestamp: number,
  delimiter = ""
) => {
  return `${formatLongDate(epochTimestamp)} ${delimiter} ${formatShortTime(
    epochTimestamp
  )}`;
};

export default {
  formatLongDate,
  formatShortTime,
  formatLongDateAndShortTime
};
