import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ScrollableContainer from "src/components/Common/ScrollableContainer";
import { HEADER_HEIGHT, SIDEBAR_WIDTH } from "src/utils/constants/layout";
import SidebarItem from "./Item";
import { SandboxPageElementProps } from "../types";

type SidebarProps = SandboxPageElementProps;

const Sidebar = ({ components, componentIds, sx = {} }: SidebarProps) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        position: "fixed",
        top: HEADER_HEIGHT, // offset from global header
        left: SIDEBAR_WIDTH, // offset from global sidebar
        height: "100vh",
        ...sx
      }}
    >
      <ScrollableContainer
        sx={{ padding: "12px 0px 0px" }}
        sxParent={{
          height: "100%",
          padding: "0px",
          borderRadius: "0px",
          border: 0,
          borderRight: `1px solid ${palette.primary.light}`
        }}
      >
        {componentIds.map((id: string) => (
          <SidebarItem
            id={id}
            key={id}
            name={components[`${id}`].displayName}
          />
        ))}
      </ScrollableContainer>
    </Box>
  );
};

export default Sidebar;
