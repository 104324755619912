import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Modal from "src/components/Common/Modal";
import { StyledNavLink } from "src/components/Common/StyledNavLink";

const Content = (): JSX.Element => {
  return (
    <Modal.Content sx={{ padding: "36px" }}>
      <Stack spacing="16px">
        <Typography>
          Don't see an existing Client, Project, or Template that fits your
          needs?
        </Typography>

        <Typography>
          Please follow the instructions on the{" "}
          <StyledNavLink to="/contact" target="_blank">
            <b>Contact</b>
          </StyledNavLink>{" "}
          page to <b>Add a client, project, or template</b>.
        </Typography>
      </Stack>
    </Modal.Content>
  );
};
export default Content;
