import * as React from "react";
// TODO: Re-enable Past MVP
// import { StyledNavLink } from "src/components/Common/StyledNavLink";
import Typography from "@mui/material/Typography";
import { LinkableContent } from "src/schemas/evaluation";
import {
  EvaluationExpandOptions,
  EvaluationExpandOption
} from "src/schemas/expands/evaluation";

const Linkable = ({
  type,
  linkable
}: {
  type: EvaluationExpandOption;
  linkable: LinkableContent;
}) => {
  switch (type) {
    case EvaluationExpandOptions.enum.Project:
    case EvaluationExpandOptions.enum.Client:
    case EvaluationExpandOptions.enum.Template:
      // TODO: once the endpoints are known for each of the types either create a mapper
      // or update the type to line up with the endpoint
      // TODO: Re-enable Past MVP
      // <StyledNavLink to={`/${type.toLowerCase()}s/${linkable.Id}`}>
      //   <b>{linkable.Name}</b>
      // </StyledNavLink>
      return <Typography variant="body2">{linkable.Name}</Typography>;
    default:
      return null;
  }
};

export default Linkable;
