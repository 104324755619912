import { getUserId } from "src/pages/ManageEvaluation/utils";
import {
  CustomEvaluationResponse,
  UpdateEvaluationForm
} from "src/schemas/evaluation";
import { LinkedUsersResponse } from "src/schemas/user";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { IsStateDirty } from "../../types";
import { putEvaluationUsersAndLinks } from "./flatAndLinks/putEvaluationUsersAndLinks";

export interface UpdateUsers {
  evaluationType: EvaluationTypes;
  evaluation: CustomEvaluationResponse;
  localEvaluation: UpdateEvaluationForm;
  isStateDirty: IsStateDirty;
}

/**
 * If `_expand.Users` field is dirty, makes all User update API calls
 * (see `putEvaluationUsersAndLinks`). Otherwise, pulls from unchanged, local
 * state.
 *
 * Returns all User Ids associated with evaluation.
 */
export default async function update({
  evaluationType,
  evaluation,
  localEvaluation,
  isStateDirty
}: UpdateUsers): Promise<LinkedUsersResponse["item"]> {
  if (isStateDirty.users) {
    const response = await putEvaluationUsersAndLinks({
      evaluationType,
      evaluation,
      localEvaluation
    });
    return response.item;
  }
  return localEvaluation._expand.users.map(getUserId);
}
