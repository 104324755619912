import * as React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CalculationDefinition from "./Definition";
import CalculationInfoModal from "./Modal";

interface CalculationInfoProps {
  showTitle?: boolean;
  sx?: SxProps;
}

const CalculationInfo = ({
  showTitle = true,
  sx
}: CalculationInfoProps): JSX.Element => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const title = "How recommendations are provided";

  return (
    <>
      <CalculationInfoModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        HeaderTitle={title}
      />
      <Box sx={sx}>
        {showTitle && (
          <Typography sx={{ marginBottom: "8px" }}>
            <b>{title}</b>
          </Typography>
        )}
        <Box component="span">
          <CalculationDefinition variant="body2" sx={{ display: "inline" }} />
          <IconButton onClick={() => setModalOpen(true)}>
            <InfoOutlinedIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default CalculationInfo;
