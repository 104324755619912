import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material";

export interface CheckboxMapValue {
  selected: boolean;
}

export type CheckboxMap = Map<string, CheckboxMapValue>;

export const CheckboxListItem = ({
  sx = {},
  text,
  children,
  onClickAction
}) => {
  return (
    <ListItem disablePadding sx={sx}>
      <ListItemButton role={undefined} dense onClick={onClickAction}>
        <ListItemIcon sx={{ minWidth: "0px" }}>{children}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

export const SelectAllCheckbox = ({
  itemCount,
  onSelectAllChange,
  selectAllChecked,
  selectedItemsCount
}: {
  itemCount: number;
  onSelectAllChange: (checked: boolean) => any;
  selectAllChecked: boolean;
  selectedItemsCount: number;
}) => {
  const { palette } = useTheme();
  const someItemsSelected =
    selectedItemsCount > 0 && selectedItemsCount < itemCount;

  return (
    <CheckboxListItem
      sx={{ borderBottom: `1px solid ${palette.grey["200"]}` }}
      text="Select All"
      onClickAction={() => onSelectAllChange(selectAllChecked)}
    >
      <Checkbox
        checked={selectAllChecked}
        indeterminate={someItemsSelected}
        edge="start"
        tabIndex={-1}
        disableRipple
      />
    </CheckboxListItem>
  );
};

const CheckboxGroup = ({
  itemMap,
  selectedItemsCount,
  onSelectAllChange,
  onChange
}: {
  itemMap: CheckboxMap;
  selectedItemsCount: number;
  onSelectAllChange: (checked: boolean) => any;
  onChange: (tag: string) => any;
}) => {
  const [selectAllChecked, setSelectAllChecked] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (itemMap.size > 0) {
      setSelectAllChecked(selectedItemsCount === itemMap.size);
    }
  }, [selectedItemsCount]);

  return (
    <>
      <SelectAllCheckbox
        itemCount={itemMap.size}
        onSelectAllChange={onSelectAllChange}
        selectAllChecked={selectAllChecked}
        selectedItemsCount={selectedItemsCount}
      />
      {[...itemMap.keys()].sort().map((tag: string) => (
        <CheckboxListItem
          key={tag}
          text={tag}
          onClickAction={() => onChange(tag)}
        >
          <Checkbox
            edge="start"
            checked={itemMap.get(tag).selected}
            tabIndex={-1}
            disableRipple
          />
        </CheckboxListItem>
      ))}
    </>
  );
};

export default CheckboxGroup;
