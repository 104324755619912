import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { SxProps, Theme } from "@mui/material";

// TODO: Move Modal into its own folder and have types.ts file with this interface
export interface ModalInterface {
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  open: boolean;
  handleClose: any;
}

const ModalHeader = ({ sx = {}, children }) => {
  return (
    <DialogTitle id="scroll-dialog-title" sx={sx}>
      {children}
    </DialogTitle>
  );
};

const ModalContent = ({ sx = {}, children, showDividers = true }) => {
  return (
    <DialogContent sx={sx} dividers={showDividers}>
      <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
        {children}
      </DialogContentText>
    </DialogContent>
  );
};

const ModalActions = ({ sx = {}, children }) => {
  return <DialogActions sx={sx}>{children}</DialogActions>;
};

const Modal = ({ sx = {}, children, open, handleClose }: ModalInterface) => {
  const width: string | number = sx["width"] ?? "600px";
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{ sx: { ...sx, width, maxWidth: width } }}
    >
      {children}
    </Dialog>
  );
};

//Passing Header, content and actions as properties of Modal instead of passing as separate functions
Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Actions = ModalActions;

export default Modal;
