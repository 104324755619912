import * as React from "react";
import Stack from "@mui/material/Stack";
import * as SandboxComponents from "./components";
import Body from "./Body";
import Sidebar from "./Sidebar";
import ToggleSidebarButton from "./ToggleSidebarButton";
import { SANDBOX_SIDEBAR_WIDTH } from "./constants";

const Sandbox = () => {
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(true);
  const sidebarDisplay: string = sidebarOpen ? "block" : "none";
  const bodyMarginLeft: string = sidebarOpen ? SANDBOX_SIDEBAR_WIDTH : "0px";

  /* Sort component **exported** names in case the export doesn't list the components in ascending alphabetical order.
   * Use these ids for tying the Sidebar links to the sandbox Sections in the Body.
   */
  const sortedComponentIds: string[] = Object.keys(SandboxComponents).sort();
  return (
    <Stack direction="row">
      <Sidebar
        components={SandboxComponents}
        componentIds={sortedComponentIds}
        sx={{ width: SANDBOX_SIDEBAR_WIDTH, display: sidebarDisplay }}
      />
      <ToggleSidebarButton
        sidebarOpen={sidebarOpen}
        toggle={() => setSidebarOpen(!sidebarOpen)}
        sidebarWidth={SANDBOX_SIDEBAR_WIDTH}
      />
      <Body
        components={SandboxComponents}
        componentIds={sortedComponentIds}
        sx={{ marginLeft: bodyMarginLeft }}
      />
    </Stack>
  );
};

export default Sandbox;
