import { capitalize } from "@mui/material";
import { ReportGroup } from "src/components/Reporting/Reports/Report/types";
import { MIMEType } from "src/schemas/common";
import { EvaluationTypesParamSchema } from "src/schemas/evaluation";
import { EvaluationSummary } from "src/schemas/evaluationSummary";
import { CSVResponse } from "src/schemas/fileFormats";
import ScoreService from "src/services/score";
import { download } from "src/utils/fileHandling/download";
import { trimWhitespace } from "src/utils/string";

interface Invoke {
  reportName: string;
  group: ReportGroup;
  evaluation: EvaluationSummary;
}

interface Download extends Pick<Invoke, "evaluation" | "reportName"> {
  response: any; // API response data
}

/**
 * Static class for exporting (fetching from API and downloading in browser)
 * various Report groups' data (e.g., ReportGroup.maturity, etc.)
 */
export default class ExportDataHandler {
  /**
   * Fetches data from the API and then proceeds to download the data,
   * automatically determined by the specified Report group.
   *
   * @param args.group the Report group (e.g., ReportGroup.maturity). A Report
   * group is associated with 1 API call.
   * @param args.evaluation evaluation we want to fetch the data for
   */
  static async invoke({
    reportName,
    group,
    evaluation
  }: Invoke): Promise<void> {
    const capitalizedReportGroup: string = capitalize(group);
    const response: any = await ExportDataHandler[
      `get${capitalizedReportGroup}`
    ](evaluation);
    return ExportDataHandler[`download${capitalizedReportGroup}`]({
      evaluation,
      reportName,
      response
    });
  }

  /**
   * Helper method for fetching `ReportGroup.maturity` data (GET /score) as
   * a CSV
   */
  static async getMaturity(
    evaluation: EvaluationSummary
  ): Promise<CSVResponse> {
    return new Promise((resolve, reject) => {
      return ScoreService.get({
        exportCSV: true,
        evaluationIds: evaluation.Id,
        evaluationType: EvaluationTypesParamSchema.enum.assessment,
        callback: (response: CSVResponse) => resolve(response),
        errorCallback: (error: Error) => reject(error)
      });
    });
  }

  /**
   * Helper method for downloading `ReportGroup.maturity` data (GET /score) as
   * a CSV
   */
  static downloadMaturity({
    evaluation,
    response,
    reportName
  }: Download): void {
    download({
      data: response,
      fileName: `${trimWhitespace(evaluation.Name)}-${reportName}AllData.csv`,
      fileType: MIMEType.csv
    });
  }
}
