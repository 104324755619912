import * as am5xy from "@amcharts/amcharts5/xy";
import { ScoredResponse } from "src/schemas/scoredResponse";
import { ChartInterface } from "../../Chart/types";

export enum ChartType {
  Domain = "Domain",
  Overall = "Overall"
}

export interface ChartMetadata {
  domain?: string; // domain should be passed in when chartType is "Domain"
  domainIndex?: number; // domainIndex should be passed in when chartType is "Domain"
  chartType: ChartType;
}

export interface MaturityStageScoresGaugeChartInterface extends ChartInterface {
  data: ScoredResponse[];
  metadata: ChartMetadata;
}

export interface MakeYAxis {
  categoryKey: string;
  rendererOptions?: Partial<am5xy.IAxisRendererYSettings>;
  axisOptions?: Partial<am5xy.ICategoryAxisSettings<am5xy.AxisRenderer>>;
}

export interface LabelsData {
  domains: string;
  overallStage: string;
}
