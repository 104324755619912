import * as React from "react";
import { useTheme } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tag from "src/components/Common/Tags";
import { HEADER_TAGS_LIMIT } from "./utils";

const TagsAndOverflow = ({ tags }: { tags: Array<string> }) => {
  const { palette } = useTheme();
  return (
    <>
      {tags.slice(0, HEADER_TAGS_LIMIT).map((tag: string) => (
        <Tag
          key={tag}
          label={tag}
          size="small"
          sx={{ margin: "3px 6px 3px 0px" }}
        />
      ))}
      {tags.length > HEADER_TAGS_LIMIT && (
        <Typography
          sx={{
            fontSize: "14px",
            color: palette.grey["600"]
          }}
        >
          + {tags.length - HEADER_TAGS_LIMIT} more
        </Typography>
      )}
    </>
  );
};

const Header = ({
  showFilterScreen,
  tags,
  onClick
}: {
  showFilterScreen: boolean;
  tags: Array<string>;
  onClick: React.Dispatch<any>;
}) => {
  const Icon = showFilterScreen ? CloseRoundedIcon : FilterListRoundedIcon;
  return (
    <>
      <Button
        onClick={onClick}
        startIcon={<Icon sx={{ marginRight: "-2px" }} />}
        variant="contained"
        size="small"
        sx={{ margin: "3px 6px 3px 0px" }}
      >
        Filter by Tag
      </Button>
      <TagsAndOverflow tags={tags} />
    </>
  );
};

export default Header;
