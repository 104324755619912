import {
  BaseQuestion,
  BaseQuestionSchema,
  CreateQuestion,
  CreateQuestionSchema
} from "src/schemas/question";
import {
  CreateRecommendation,
  CreateRecommendationSchema,
  EmptyCreateRecommendationSchema,
  Recommendation,
  RecommendationSchema
} from "src/schemas/recommendation";
import { isValid } from "src/utils/validators";

export function isValidRecommendation(recommendation: Recommendation) {
  return isValid(recommendation, RecommendationSchema);
}

/**
 * Checks to see if either cases are true to constitute a valid new
 * recommendation:
 * - Both `Name` and `Description` fields are filled
 * (Recommendation tied to Question)
 * - Both `Name` and `Description` fields are empty
 * (NO Recommendation tied to Question)
 */
export function isValidNewRecommendation(recommendation: CreateRecommendation) {
  return (
    isValid(recommendation, CreateRecommendationSchema) ||
    isValid(recommendation, EmptyCreateRecommendationSchema)
  );
}

export function isValidQuestion(question: BaseQuestion) {
  return isValid(question, BaseQuestionSchema);
}

export function isValidNewQuestion(question: CreateQuestion) {
  return isValid(question, CreateQuestionSchema);
}
