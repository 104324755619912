import * as React from "react";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

interface TextProps {
  children: React.ReactNode;
}

const Text = ({ children }: TextProps): JSX.Element => {
  const { palette } = useTheme();
  return (
    <Typography
      sx={{
        fontSize: "20px",
        fontWeight: "bold",
        color: palette.common.black,
        marginTop: "12px"
      }}
    >
      {children}
    </Typography>
  );
};

export default Text;
