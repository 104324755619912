import * as React from "react";
import Input from "src/components/Common/Input";
import Tooltip from "@mui/material/Tooltip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import { InputConfig, UseAutocompleteHookProps } from "./types";

const AutocompleteInputBase = ({
  disabled,
  inputConfig,
  useAutocompleteProps
}: {
  disabled: boolean;
  inputConfig: InputConfig;
  useAutocompleteProps: UseAutocompleteHookProps<any, false, false, true>;
}) => {
  const { getInputProps, setAnchorEl, popupOpen, getPopupIndicatorProps } =
    useAutocompleteProps;
  return (
    <Input
      sx={{ width: "100%" }}
      disabled={disabled ?? false}
      ref={setAnchorEl}
      inputProps={getInputProps()}
      placeholder={inputConfig.placeholder}
      /* Pop-up indicator to open/close autocomplete dropdown pop-up
       * Notes:
       * - Explicitly disabling click functionality when input is disabled
       * so that pop-up doesn't open in that state.
       * - Matching default MUI Autocomplete pop-up indicator styling 1-1.
       */
      InputProps={{
        endAdornment: (
          <IconButton
            {...getPopupIndicatorProps()}
            color="primary"
            size="small"
            sx={{
              pointerEvents: disabled ? "none" : "auto",
              position: "absolute",
              right: "9px",
              padding: "2px",
              marginRight: "-2px",
              transform: popupOpen ? "rotate(180deg)" : "none"
            }}
          >
            <ArrowDropDownIcon />
          </IconButton>
        )
      }}
    />
  );
};

const AutocompleteInput = ({
  disabled,
  useAutocompleteProps,
  inputConfig
}: {
  disabled: boolean;
  inputConfig: InputConfig;
  useAutocompleteProps: UseAutocompleteHookProps<any, false, false, true>;
}) => {
  const { getRootProps } = useAutocompleteProps;
  return (
    <div {...getRootProps()}>
      {disabled && inputConfig.disabledText ? (
        <Tooltip
          placement="bottom-end"
          disableHoverListener={!disabled}
          title={inputConfig.disabledText}
        >
          <div>
            <AutocompleteInputBase
              disabled={disabled}
              useAutocompleteProps={useAutocompleteProps}
              inputConfig={inputConfig}
            />
          </div>
        </Tooltip>
      ) : (
        <AutocompleteInputBase
          disabled={disabled}
          useAutocompleteProps={useAutocompleteProps}
          inputConfig={inputConfig}
        />
      )}
    </div>
  );
};

export default AutocompleteInput;
