import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Modal from "src/components/Common/Modal";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { CalculationInfoModalProps } from "./types";

const CalculationInfoModal = ({
  open,
  handleClose,
  HeaderTitle,
  HeaderChildren,
  children
}: CalculationInfoModalProps) => {
  const { palette } = useTheme();
  const headerTitle: string = HeaderTitle ?? "How it's calculated";
  return (
    <Modal open={open} handleClose={handleClose}>
      <Modal.Header
        sx={{
          backgroundColor: palette.primary.main,
          color: palette.common.white
        }}
      >
        <Stack direction="row" alignItems="center" spacing="12px">
          {HeaderChildren ? (
            HeaderChildren
          ) : (
            <Typography variant="h6">
              <b>{headerTitle}</b>
            </Typography>
          )}
        </Stack>
      </Modal.Header>
      <Modal.Content sx={{ padding: "24px" }}>
        <Stack spacing="24px" sx={{ marginBottom: "8px" }}>
          {children}
        </Stack>
      </Modal.Content>
      <Modal.Actions
        sx={{
          justifyContent: "center",
          padding: "12px"
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={handleClose}
          color="primary"
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CalculationInfoModal;
