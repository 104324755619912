import { CustomEvaluationResponse } from "src/schemas/evaluation";
import { Metadata } from "src/schemas/metadata";
import { IndexedQuestion } from "src/schemas/question";
import { merge } from "src/utils/object";
import { updateStateItem } from "./utils/state";

/**
 * Given an array of IndexedQuestions `questions` and an `updatedQuestion`,
 * returns new questions array with updatedQuestion
 */
function updateQuestions(
  questions: IndexedQuestion[],
  updatedQuestion: IndexedQuestion
): IndexedQuestion[] {
  return questions.map((question: IndexedQuestion) => {
    return question.Id == updatedQuestion.Id
      ? merge(question, updatedQuestion)
      : question;
  });
}

/**
 * A reducer handler function for editing a question on an evaluation.
 * - Updates the evaluation state `item.Questions` field.
 * - Updates the evaluation state `item.Metadata` object with a new
 * `DateModified` field, used for conflict checking.
 * @param state current reducer state
 * @param updatedQuestion question to be updated in `state.item.Questions` array
 * @param meta updated Evaluation Metadata from database - for conflict checking
 * @returns new reducer state
 */
export default function editQuestion(
  state: CustomEvaluationResponse,
  updatedQuestion: IndexedQuestion,
  meta: Metadata
): CustomEvaluationResponse {
  return updateStateItem(state, {
    Metadata: meta,
    Questions: updateQuestions(state.item.Questions, updatedQuestion)
  });
}
