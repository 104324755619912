import * as React from "react";
import { capitalize } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Collection } from "src/utils/constants/collection";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { remainingVerticalSpace } from "src/utils/styles/flex";
import DataTable from "./DataTable";

interface EvaluationsProps {
  evaluationType: EvaluationTypes;
}

const Evaluations = ({ evaluationType }: EvaluationsProps): JSX.Element => {
  const title: string = capitalize(Reflect.get(Collection, evaluationType));

  return (
    <Stack direction="column" sx={remainingVerticalSpace}>
      <Typography variant="h4">{title}</Typography>
      <DataTable evaluationType={evaluationType} />
    </Stack>
  );
};

export default Evaluations;
