import { MIMEType } from "src/schemas/common";
import { CSVResponseSchema } from "src/schemas/fileFormats";
import { GetAPICallRequest } from "src/services/APICall";

/**
 * Returns partial GetAPICallRequest with
 * - headers set to "text/csv" MIME type
 * - typeValidator set for CSV validation
 */
export function makeCSVRequest(): Partial<GetAPICallRequest> {
  return {
    headers: {
      Accept: MIMEType.csv
    },
    typeValidator: CSVResponseSchema
  };
}
