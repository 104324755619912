import {
  EvaluationPatchResponse,
  UpdateEvaluationForm
} from "src/schemas/evaluation";
import EvaluationService from "src/services/evaluation";
import { EvaluationTypes } from "src/utils/constants/evaluation";

interface PatchEvaluation {
  evaluationType: EvaluationTypes;
  localEvaluation: UpdateEvaluationForm;
}

/**
 * Simple wrapper around `EvaluationService.patch` call so that we
 * return the result of the call or throw an error. This is needed due to
 * how our APICaller methods use callbacks and can't actually return results.
 */
export async function patchEvaluation({
  evaluationType,
  localEvaluation
}: PatchEvaluation): Promise<EvaluationPatchResponse> {
  return new Promise((resolve, reject) => {
    return EvaluationService.patch({
      evaluationId: localEvaluation.item.Id,
      evaluationType,
      updatedEvaluationFields: {
        ...localEvaluation.item,
        Metadata: {
          DateModified: localEvaluation.item.Metadata.DateModified
        }
      },
      callback: (response: EvaluationPatchResponse) => resolve(response),
      errorCallback: (error: Error) => reject(error)
    });
  });
}
