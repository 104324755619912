import * as React from "react";
import * as am5 from "@amcharts/amcharts5";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Report from "../../Report";
import { ReportClass, ReportGroup } from "../../Report/types";
import { MaturityReportElementProps } from "../../Report/types/element";

import { RecommendationRow } from "src/schemas/reporting/recommendations";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import Chart from "./Chart";
import CalculationInfo from "./CalculationInfo";
import PromptContainer from "../../Common/PromptContainer";
import Recommendations from "../../Common/Recommendations";

import { sortArrayASC } from "src/utils/sort";
import { formatQuestionStageText } from "src/utils/format/questionStage";
import { SUMMARY_VIEW_KEY } from "./constants";
import Title from "./Title";
import ReportOptions from "./ReportOptions";

const report = ({ name, data }: MaturityReportElementProps): JSX.Element => {
  const { evaluation, classificationData, scoredData, recommendationsData } =
    data;
  const { domains, questionStages } = classificationData;

  const sortedDomains: string[] = sortArrayASC([...(domains ?? new Set())]);
  const sortedQuestionStages: string[] = sortArrayASC(
    [...(questionStages ?? new Map())].map(
      ([stageNumber, stageName]: [stageNumber: number, stageName: string]) =>
        formatQuestionStageText(stageNumber, stageName)
    )
  );

  const [root, setRoot] = React.useState<am5.Root>();
  const [domain, setDomain] = React.useState<string>(SUMMARY_VIEW_KEY);
  const selectedDomainIndex: number = sortedDomains.indexOf(domain);
  const domainOptions: string[] = [SUMMARY_VIEW_KEY, ...sortedDomains];

  /**
   * Show all recommendations if no Individual Domain selected.
   * Otherwise, filter recommendations by selected Individual Domain
   */
  const recommendations: RecommendationRow[] = (
    recommendationsData ?? []
  ).filter(
    (row: RecommendationRow) =>
      domain === SUMMARY_VIEW_KEY || row.Domain === domain
  );

  return (
    <Report>
      <ReportOptions
        domainOptions={domainOptions}
        domain={domain}
        setDomain={setDomain}
      />
      <Report.Content>
        <Title
          domain={domain}
          domainIndex={selectedDomainIndex}
          root={root}
          reportName={name}
        />
        <Stack spacing="24px" sx={{ margin: "12px 0px 24px" }}>
          <Typography variant="body2">
            This report shows a summarized, cross-section view of how each
            Domain is scored against each Maturity Stage.
          </Typography>
          <CalculationInfo />
          <Divider />
        </Stack>
        <Stack>
          <PromptContainer />
          <Chart
            data={scoredData.items ?? []}
            selectedDomain={domain}
            selectedDomainIndex={selectedDomainIndex}
            domains={sortedDomains}
            questionStages={sortedQuestionStages}
            getRoot={setRoot}
          />
        </Stack>
        <Recommendations
          evaluation={evaluation}
          recommendationsData={recommendations}
        />
      </Report.Content>
    </Report>
  );
};

const DomainSpecificMaturityStageScoresReport: ReportClass = class {
  static displayName = "Domain-Specific Maturity Stage Scores";
  static group: ReportGroup = ReportGroup.maturity;
  static supportedEvaluationTypes = [
    EvaluationTypes.assessment,
    EvaluationTypes.survey
  ];
  static report = report;
};

export default DomainSpecificMaturityStageScoresReport;
