import { plural } from "pluralize";
import {
  UpdateQuestionRequest,
  UpdateQuestionRequestSchema,
  UpdateQuestionResponseSchema
} from "src/schemas/question";
import { PatchAPICaller, PatchAPICallRequest } from "../APICall";
import { UpdateEvaluationQuestionInterface } from "./types";

/**
 * Updates an existing question linked to an evaluation.
 * - `PATCH /assessments/{assessmentId}/questions/{questionId}`
 * - `PATCH /surveys/{surveyId}/questions/{questionId}`
 */
export default function update({
  question,
  evaluationId,
  evaluationDateModified,
  evaluationType,
  callback,
  errorCallback
}: UpdateEvaluationQuestionInterface): void {
  const url = `/${plural(evaluationType)}/${evaluationId}/questions/${
    question.Id
  }`;

  /* Use request schema parse to guard against passing additional fields
   * E.g., strip out `Id` field on question
   */
  const body: UpdateQuestionRequest = UpdateQuestionRequestSchema.parse({
    item: question,
    _meta: {
      DateModified: evaluationDateModified
    }
  });

  const request: PatchAPICallRequest = {
    path: url,
    body,
    callback,
    errorCallback,
    typeValidator: UpdateQuestionResponseSchema,
    withReactHook: false
  };

  return PatchAPICaller(request);
}
