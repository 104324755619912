import create from "./create";
import update from "./update";
import _deleteQuestion from "./deleteQuestion";
import {
  CreateEvaluationQuestionInterface,
  DeleteEvaluationQuestionInterface,
  UpdateEvaluationQuestionInterface
} from "./types";

class EvaluationQuestionService {
  static post(args: CreateEvaluationQuestionInterface): void {
    return create(args);
  }
  static patch(args: UpdateEvaluationQuestionInterface): void {
    return update(args);
  }
  static deleteQuestion(args: DeleteEvaluationQuestionInterface): void {
    return _deleteQuestion(args);
  }
}

export default EvaluationQuestionService;
