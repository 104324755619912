import * as React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

const CountDisplay = ({ children }) => (
  <Typography sx={{ fontSize: "14px", color: useTheme().palette.grey["700"] }}>
    {children}
  </Typography>
);

export default CountDisplay;
