import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const TruncatedText = styled(Typography)(() => ({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
}));

export default TruncatedText;
