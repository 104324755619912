import * as React from "react";
import { Answer } from "src/schemas/question";
import { getPercentInteger } from "src/utils/percent";
import { ANSWER_MAX_VALUE } from "../data/answers";

interface CalculationProps {
  answer: Answer;
}

/**
 * A component which displays what a particular question Response is calculated
 * as, in terms of raw score and percentage.
 */
const Calculation = ({ answer }: CalculationProps): JSX.Element => {
  return (
    <>
      = {`${answer.ScoreValue} / ${ANSWER_MAX_VALUE}`} ={" "}
      <b>{getPercentInteger(answer.ScoreValue, ANSWER_MAX_VALUE)}%</b>
    </>
  );
};

export default Calculation;
