import * as React from "react";
import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid";
import { LabelProps } from "./types";

/**
 * Uses MUI Data Grid column definition to dynamically render column header
 * text, which is the label for our field item (e.g., "Question")
 */
function renderHeader(column: GridColDef): React.ReactNode {
  /* Use `null` to bypass having to pass in MUI Data Grid specific object,
   * which is only needed when linking column to Data Grid instance
   */
  return column.renderHeader(null);
}

const Label = ({ column }: LabelProps): JSX.Element => {
  return (
    <Typography
      sx={{
        display: "inline-block",
        margin: "0px 12px 8px 0px"
      }}
    >
      {renderHeader(column)}
    </Typography>
  );
};

export default Label;
