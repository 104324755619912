import _get from "./get";
import { GetEvaluationRecommendationsInterface } from "./types";

/**
 * API Service class for interacting with evaluation recommendations.
 */
class EvaluationRecommendationsService {
  static get(args: GetEvaluationRecommendationsInterface): void {
    return _get(args);
  }
}
export default EvaluationRecommendationsService;
