import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";

/** A table component with no bottom border
 *
 * **Usage** - whenever you want an MUI Basic Table without a box shadow and bottom border
 */
const FlatTable = styled(Table)(() => ({
  "tr:last-of-type": {
    td: {
      borderBottom: "0px"
    }
  }
}));

export default FlatTable;
