import * as React from "react";
import Typography from "@mui/material/Typography";
import { plural } from "pluralize";
import { TitleProps } from "../types";

const Title = ({ evaluationType }: TitleProps): JSX.Element => {
  return (
    <Typography variant="overline" sx={{ marginRight: "4px" }}>
      <b>ACTIVE {plural(evaluationType)}</b>
    </Typography>
  );
};
export default Title;
