import { Amplify } from "aws-amplify";
import { CognitoUserInterface as CognitoUser } from "@aws-amplify/ui-components";
import makeAmplifyConfig from "../../config/amplify";
import { loadEnvironmentSettings } from "../../context/EnvironmentSettings";
import { EnvironmentSettingsContextType } from "../../context/EnvironmentSettings/types";
import AuthService from "../../services/auth";
import { Nullable } from "../../utils/types";
import { PreloaderGetResponse } from "./types";

/**
 * Class for loading all essential app data before rendering full app
 */
export default class Preloader {
  static async getEnvironmentSettings(): Promise<EnvironmentSettingsContextType> {
    const settings: EnvironmentSettingsContextType =
      await loadEnvironmentSettings();
    Amplify.configure(makeAmplifyConfig(settings));
    return settings;
  }

  static async getUser(): Promise<Nullable<CognitoUser>> {
    return await AuthService.getUser();
  }

  static async get(): Promise<PreloaderGetResponse> {
    try {
      const settings = await Preloader.getEnvironmentSettings();
      /* Has to happen synchronously after environment settings are loaded in
       * and Amplify is configured
       */
      const authenticatedUser = await Preloader.getUser();
      return {
        settings,
        authenticatedUser
      };
    } catch (err) {
      console.error(err);
    }
  }
}
