export function convertToPercentInteger(decimal: number): number {
  return Math.round(100 * decimal);
}

/**
 * Safely converts passed-in numerator and denominator into a decimal.
 * Handles division by 0 case.
 */
export function getPercentInteger(numerator: number, denominator: number) {
  return denominator == 0
    ? 0
    : convertToPercentInteger(numerator / denominator);
}
