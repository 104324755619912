import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Response } from "src/schemas/response";
import { ManageEvaluationState } from "../types/manageEvaluation";
import { createSliceReducer } from "../utils/slice";

type S = ManageEvaluationState;

const initialState: S = {
  responses: {}
};

export const slice = createSlice({
  name: "manageEvaluation",
  initialState,
  reducers: {
    clear: (state: S) => {
      Object.keys(initialState).forEach((key: string) => {
        Reflect.set(state, key, Reflect.get(initialState, key));
      });
    },
    setResponses: createSliceReducer<S>("responses"),
    updateResponse: (state: S, action: PayloadAction<Response>) => {
      state.responses[action.payload.QuestionId] = action.payload;
    }
  }
});

export const { clear, setResponses, updateResponse } = slice.actions;

export default slice.reducer;
