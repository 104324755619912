import * as React from "react";
import { EmptyStateMessage } from "src/components/Common/EmptyStateMessage";
import { EmptyListProps } from "./types";

const EmptyList = ({ evaluationType }: EmptyListProps): JSX.Element => {
  return (
    <EmptyStateMessage
      header={`You have no ${evaluationType}s`}
      body={
        <>
          Click on the <strong>Create {evaluationType}</strong> button above to
          get started.
        </>
      }
    />
  );
};
export default EmptyList;
