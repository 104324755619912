import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";
import CreateEvaluationButton from "./CreateEvaluationButton";
import PreviewHeaderTop from "./HeaderTop";
import PreviewHeaderBottom from "./HeaderBottom";

const PreviewHeader = ({ dataLoaded, count }) => {
  const { palette } = useTheme();

  return (
    <Box
      component="div"
      sx={{
        borderBottom: `1px solid ${palette.primary.light}`
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: "24px",
          height: "80px"
        }}
      >
        <PreviewHeaderTop dataLoaded={dataLoaded} />
        <CreateEvaluationButton />
      </Stack>
      <PreviewHeaderBottom dataLoaded={dataLoaded} count={count} />
    </Box>
  );
};

export default PreviewHeader;
