import * as React from "react";
import FilterTagsAutocomplete from "src/components/Common/FilterTagsAutocomplete";
import Label from "src/components/Common/Input/Label";
import SectionRow from "./layout/SectionRow";
import LabeledBox from "./layout/LabeledBox";
import { top100Films } from "../data/autocomplete";
import { SandboxClass } from "./types";

const Autocompletes = () => {
  return (
    <>
      <SectionRow>
        <LabeledBox label="FilterTagsAutocomplete" sx={{ width: "400px" }}>
          <Label htmlFor="tag">Movie Name</Label>
          <FilterTagsAutocomplete
            id="tag"
            tagsConfig={{
              getField: (item) => item.year
            }}
            optionsConfig={{
              id: "title",
              label: "title",
              tooltip: "year"
            }}
            inputConfig={{
              placeholder: `Select a movie`
            }}
            options={top100Films}
          />
        </LabeledBox>
      </SectionRow>
    </>
  );
};

const AutocompletesSandbox: SandboxClass = class {
  static displayName = "Autocompletes";
  static element = Autocompletes;
};

export default AutocompletesSandbox;
