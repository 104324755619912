import _get from "./get";
import { GetScoreInterface } from "./types";

/**
 * API Service class for interacting with scored responses for evaluation(s).
 * **Usage**
 * - Primarily used for reporting
 */
class ScoreService {
  static get(args: GetScoreInterface): void {
    return _get(args);
  }
}
export default ScoreService;
