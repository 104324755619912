import { convertToPercentInteger } from "src/utils/percent";

/*
 * Note: on the BE, this threshold is called `RECOMMENDATION_THRESHOLD`
 * because it directly relates to how Recommendations are pulled in.
 * However, in the context of the FE, this threshold is used across different
 * maturity reports, not just recommendations, so hence the `MATURITY_THRESHOLD`
 * naming convention.
 */
export const MATURITY_THRESHOLD = 0.5;
export const MATURITY_THRESHOLD_PERCENT_TEXT = `${convertToPercentInteger(
  MATURITY_THRESHOLD
)}%`;
export const MATURITY_THRESHOLD_PASSED_TEXT = `greater than ${MATURITY_THRESHOLD_PERCENT_TEXT}`;

/**
 * Given a score value, returns a boolean indicating whether that score fails
 * to pass our maturity threshold.
 */
export function failsMaturityThreshold(value: number): boolean {
  return value <= MATURITY_THRESHOLD;
}
