import { Theme } from "@mui/material";
import { remainingVerticalSpace } from "src/utils/styles/flex";

/* MUI large breakpoint styles (> 1200px screen size) -
 * when we have 2 columns in the grid
 */

/** Helper for creating MUI large breakpoint (lg) style object */
function makeLgStyles(theme: Theme, styles: object): object {
  return {
    [theme.breakpoints.up("lg")]: styles
  };
}

/** Large breakpoint style object for wrapper around Header + EvaluationsGrid */
export function getHomeWrapperLgStyles(theme: Theme): object {
  return makeLgStyles(theme, remainingVerticalSpace);
}

/** Large breakpoint style object for EvaluationsGrid */
export function getEvaluationsGridLgStyles(theme: Theme): object {
  return makeLgStyles(theme, remainingVerticalSpace);
}

/** Large breakpoint style object for EvaluationsGridItem */
export function getEvaluationsGridItemLgStyles(theme: Theme): object {
  return makeLgStyles(theme, {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  });
}

/** Large breakpoint style object for EvaluationsGridItem Body */
export function getEvaluationsGridItemBodyLgStyles(theme: Theme): object {
  return makeLgStyles(theme, remainingVerticalSpace);
}
