import { CreateRecommendation } from "src/schemas/recommendation";

/**
 * Creates an initial `CreateRecommendation` object used to populate the
 * Recommendation fields in the
 * - **Add** Question modal
 * - **Edit** Question modal if the specific Question doesn't have a Recommendation
 * tied to it. However, at this time, this doesn't matter since the user cannot
 * edit a Recommendation in the Edit Question modal.
 *
 * @returns a new CreateRecommendation object
 */
export function createNewRecommendation(): CreateRecommendation {
  return {
    Name: "",
    Description: ""
  };
}
