import {
  ScoredResponsesResponseSchema,
  ScoredResponsesQueryParams
} from "src/schemas/scoredResponse";
import { conditionalObject, merge } from "src/utils/object";
import { GetAPICaller, GetAPICallRequest } from "../APICall";
import { makeCSVRequest } from "../utils/fileHandling/csv";
import { GetScoreInterface } from "./types";

/**
 * Gets all scored responses for a particular evaluation.
 * - `GET /score`
 */
export default function get({
  exportCSV,
  evaluationIds,
  evaluationType,
  callback,
  errorCallback
}: GetScoreInterface): void {
  const path = "/score";
  const queryStringParameters: ScoredResponsesQueryParams = {
    evaluationIds, // comma-separated string
    type: evaluationType
  };

  /**
   * If `exportCSV` is true, returns partial GetAPICallRequest with
   * - headers set to "text/csv" MIME type
   * - typeValidator set for CSV validation
   *
   * Otherwise, returns empty object.
   */
  const csvRequest: Partial<GetAPICallRequest> = conditionalObject(
    exportCSV,
    makeCSVRequest()
  );

  const request: GetAPICallRequest = merge(
    {
      path,
      queryStringParameters,
      callback,
      errorCallback,
      typeValidator: ScoredResponsesResponseSchema,
      withReactHook: false
    },
    csvRequest
  );

  return GetAPICaller(request);
}
