import * as React from "react";
import Box from "@mui/material/Box";
import { EmptyStateMessage } from "src/components/Common/EmptyStateMessage";
import { reportBodyStyles } from "src/components/Reporting/Reports/Report/styles";
// TODO: Re-enable past MVP when we support a detailed view of reports
// import DetailedViewPrompt from "./DetailedViewPrompt";

const NoSelectedReport = () => {
  return (
    <Box component="div" sx={reportBodyStyles}>
      <EmptyStateMessage
        header="Select a Report"
        body={
          <>
            Please select a <b>Report</b> above to display a specific report
            based on your evaluation results.
            {/* TODO: Re-enable past MVP when we support a detailed view of reports */}
            {/* {" "}<DetailedViewPrompt /> */}
          </>
        }
      />
    </Box>
  );
};

export default NoSelectedReport;
