import * as React from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

interface ViewButtonProps {
  collection: string; // assessments | surveys
  evaluationId: string;
}

/**
 * An icon button component used in the Data Table component.
 * On click, will open up individual evaluation page in new tab.
 */
const ViewButton = ({
  collection,
  evaluationId
}: ViewButtonProps): JSX.Element => {
  return (
    <Tooltip
      placement="bottom-end"
      title={<Box sx={{ fontSize: "14px" }}>View details in new tab</Box>}
    >
      <IconButton
        color="primary"
        size="small"
        aria-label="launch"
        href={`/${collection}/${evaluationId}`}
        target="_blank"
      >
        <LaunchIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ViewButton;
