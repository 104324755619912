import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import theme from "src/theme";
import { SandboxClass } from "./types";
import { TypographyProps } from "@mui/material";

const typographyOverrides: Record<string, any> =
  theme.components.MuiTypography.styleOverrides;
const variants: string[] = Object.keys(typographyOverrides);

const TypographyVariants = () => {
  return (
    <>
      <Stack spacing="32px">
        {variants.map((variant: string) => (
          <Typography
            variant={variant as TypographyProps["variant"]}
            key={variant}
          >
            {variant} - {typographyOverrides[`${variant}`].fontSize}
          </Typography>
        ))}
      </Stack>
    </>
  );
};

const TypographySandbox: SandboxClass = class {
  static displayName = "Typography";
  static element = TypographyVariants;
};

export default TypographySandbox;
