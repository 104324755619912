import * as React from "react";
import { EmptyStateMessage } from "src/components/Common/EmptyStateMessage";

export const NoEvaluationSelected = () => {
  return (
    <EmptyStateMessage
      header="Configure your Reports"
      body={
        <>
          Please fill out the <b>Required</b> options above to fetch tailored
          reports based on your selections.
        </>
      }
    />
  );
};
