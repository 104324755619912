import * as React from "react";
import Search from "src/components/Common/Search";
import SectionRow from "./layout/SectionRow";
import LabeledBox from "./layout/LabeledBox";
import { SandboxClass } from "./types";

const Searchbars = () => {
  return (
    <>
      <SectionRow>
        <LabeledBox label="Generic Search">
          <Search />
        </LabeledBox>
      </SectionRow>
    </>
  );
};

const SearchbarsSandbox: SandboxClass = class {
  static displayName = "Searchbars";
  static element = Searchbars;
};

export default SearchbarsSandbox;
