import * as React from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material";
import Info from "./Info";
import Actions from "./Actions";
import { HeaderProps } from "./types";

const Header = ({ evaluationType }: HeaderProps): JSX.Element => {
  const { palette } = useTheme();
  return (
    <Stack direction="row" alignItems="center" sx={{ marginBottom: "16px" }}>
      <Info evaluationType={evaluationType} />
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          height: "24px",
          color: palette.grey["200"],
          marginLeft: "4px",
          alignSelf: "center"
        }}
      />
      <Actions evaluationType={evaluationType} />
    </Stack>
  );
};
export default Header;
