import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { CheckboxListItem } from "src/components/Common/CheckboxGroup";
import { AnyFilterItems } from "../../types";
import { CHECKBOX_STYLES } from "./styles";

interface ListProps {
  items: AnyFilterItems;
  onClick: (name: string) => any;
}

const List = ({ items, onClick }: ListProps): JSX.Element => {
  const sortedItemNames: string[] = Object.keys(items).sort();

  return (
    <>
      {sortedItemNames.map((name: string) => (
        <CheckboxListItem
          sx={CHECKBOX_STYLES}
          key={name}
          text={items[`${name}`]?.formatted ?? name}
          onClickAction={() => onClick(name)}
        >
          <Checkbox
            size="small"
            edge="start"
            checked={items[`${name}`].enabled}
            indeterminate={items[`${name}`]?.indeterminate}
            tabIndex={-1}
            disableRipple
          />
        </CheckboxListItem>
      ))}
    </>
  );
};

export default List;
