import Button from "@mui/material/Button";
import * as React from "react";
import ErrorModal from "src/components/Common/ErrorModal";
import { generateFakeText } from "src/components/Common/FakeText";
import SectionRow from "./layout/SectionRow";
import LabeledBox from "./layout/LabeledBox";
import { SandboxClass } from "./types";

const Modals = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <SectionRow>
        <LabeledBox label="Error Modal">
          <Button variant="contained" onClick={() => setOpen(true)}>
            Click me
          </Button>
          <ErrorModal open={open} handleClose={() => setOpen(false)}>
            {generateFakeText(6)}
          </ErrorModal>
        </LabeledBox>
      </SectionRow>
    </>
  );
};

const ModalsSandbox: SandboxClass = class {
  static displayName = "Modals";
  static element = Modals;
};

export default ModalsSandbox;
