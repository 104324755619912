import Typography from "@mui/material/Typography";
import * as React from "react";
import ConditionalWrapper from "src/components/Common/ConditionalWrapper";

interface ConclusionProps {
  domain?: string;
}

const Conclusion = ({ domain }: ConclusionProps): JSX.Element => {
  return (
    <Typography variant="body2">
      <ConditionalWrapper condition={Boolean(domain)}>
        <>
          Thus, the example assessment's Domain-Specific Maturity Stage is{" "}
          <b>Stage 1 for the {domain} Domain</b>.
        </>
      </ConditionalWrapper>
      <ConditionalWrapper condition={!domain}>
        <>
          Thus, the{" "}
          <b>example assessment's overall Maturity Stage is Stage 1</b>.
        </>
      </ConditionalWrapper>
    </Typography>
  );
};

export default Conclusion;
