/* Color - Palette Override & Extensions
 * - Defaults: https://mui.com/customization/default-theme/#explore
 */

// Reference: https://mui.com/customization/palette/#adding-new-colors
declare module "@mui/material/styles" {
  interface Palette {
    bg: Palette["primary"];
    inverted: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    bg?: PaletteOptions["primary"];
    inverted?: PaletteOptions["primary"];
  }

  interface PaletteColor {
    lighter?: string;
    lightGrey?: string;
    darkGrey?: string;
    darker?: string;
    lightYellow?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
    lightGrey?: string;
    darkGrey?: string;
    darker?: string;
  }
}

const palette: any = {
  common: {
    white: "#FFFFFF",
    black: "#09091C"
  },
  primary: {
    lighter: "#EDF3FF",
    light: "#C2D8FE",
    main: "#0C62FB",
    dark: "#0051E2", // hover state
    darker: "#003595"
  },
  grey: {
    100: "#F5F5F5",
    200: "#E0E0E0",
    300: "#A8A8A8",
    400: "#8C8C8C",
    500: "#777777",
    600: "#616161",
    700: "#525252"
    // Use defaults for rest
  },
  // Inverted - for items on primary-colored background
  inverted: {
    main: "rgba(255, 255, 255, 0.3)",
    contrastText: "#FFFFFF"
  },
  // Custom Background - for backgrounds outside of main color
  bg: {
    lightGrey: "#F5F5F5",
    darkGrey: "#262626",
    dark: "#09091C",
    lightYellow: "#FEF3C7"
  },
  // Error & Success States
  // - Reference: https://tailwindcss.com/docs/customizing-colors
  error: {
    light: "#EF4444",
    main: "#DC2626",
    dark: "#B91C1C"
  },
  success: {
    light: "#4ADE80",
    main: "#22C55E",
    dark: "#16A34A"
  }
};

export default palette;
