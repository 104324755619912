import * as React from "react";
import Typography from "@mui/material/Typography";
import ModalSection from "src/components/Common/Modal/Section";

const Notes = (): JSX.Element => {
  return (
    <ModalSection name="Notes">
      <Typography variant="body2">
        By default, <b>every assessment sufficiently meets Stage 0</b>, which is
        a baseline stage that has no questions associated with it.
      </Typography>
    </ModalSection>
  );
};

export default Notes;
