import { IndexedQuestion } from "src/schemas/question";
import {
  CreateRecommendation,
  Recommendation
} from "src/schemas/recommendation";
import {
  simpleReducer,
  ReducerFunctionParameters,
  ReducerFunction
} from "src/utils/react/reducers/simple";
import { RecommendationUnion } from "../types";
import { createNewRecommendation } from "../utils/recommendation";

interface SetupRecommendationReducer {
  isEditing: boolean;
  editingQuestion?: IndexedQuestion;
}

/**
 * Simple React Reducer function that updates object fields for object of type
 * Recommendation.
 *
 * **Usage**
 * Pass in as the first argument to the `React.useReducer` hook for
 * - **Edit** Question case with existing recommendation tied to Question
 */
function recommendationReducer(
  ...args: ReducerFunctionParameters<Recommendation>
) {
  return simpleReducer(...args);
}

/**
 * Simple React Reducer function that updates object fields for object of type
 * CreateRecommendation.
 *
 * **Usage**
 * Pass in as the first argument to the `React.useReducer` hook for
 * - **Add** Question case
 * - **Edit** Question case when NO recommendation tied to Question. Note that
 * the user cannot add/change recommendations in the Edit case anyway though.
 */
function createRecommendationReducer(
  ...args: ReducerFunctionParameters<CreateRecommendation>
) {
  return simpleReducer(...args);
}

/**
 * Simple React Reducer that updates object fields for whichever Recommendation
 * type, dynamically based on passed-in `isEditing` flag.
 *
 * @returns a tuple that can passed into `React.useReducer` hook using the
 * spread `...` operator
 */
export function setupRecommendationReducer({
  isEditing,
  editingQuestion
}: SetupRecommendationReducer): [
  ReducerFunction<RecommendationUnion>,
  RecommendationUnion
] {
  const reducer: ReducerFunction<RecommendationUnion> =
    isEditing && editingQuestion?.Recommendation
      ? recommendationReducer
      : createRecommendationReducer;
  const defaultValue: RecommendationUnion =
    editingQuestion?.Recommendation ?? createNewRecommendation();

  return [reducer, defaultValue];
}
