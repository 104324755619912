import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MailtoLink from "src/components/Common/Contact/MailtoLink";
import AuthService from "src/services/auth";

const Login = () => {
  return (
    <Stack
      spacing={6}
      sx={{
        maxWidth: "900px",
        minWidth: "280px",
        margin: "24px auto 36px"
      }}
    >
      <Typography variant="h3">
        Slalom D.A.R.T (Digital Assessment &amp; Recommendation Toolkit) is a
        tool created by and for Slalom consultants to help our clients advance
        in their digital journeys.
      </Typography>
      <div id="login-button">
        <Button
          onClick={AuthService.signIn}
          variant="contained"
          size="xlarge"
          endIcon={<ArrowForwardIcon fontSize="medium" />}
        >
          Login
        </Button>
      </div>
      <Typography variant="body1">
        For more details or feedback, please contact{" "}
        <MailtoLink sx={{ fontWeight: "bold" }} />
      </Typography>
    </Stack>
  );
};

export default Login;
