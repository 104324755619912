import * as React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import ConditionalWrapper from "../../ConditionalWrapper";
import StageTag from "../../StageTag";
import {
  BaseQuestionAccordionParams,
  BaseQuestionAccordionDetailsParams,
  BaseQuestionAccordionSummaryParams
} from "../types";
import ItemAccordion from "..";
import { RecommendationType, Recommendation } from "../../Recommendation";
import { formatQuestionStageText } from "src/utils/format/questionStage";

/**
 * A flexible question accordion summary (header) component.
 * This is shown always, regardless of whether an accordion is expanded. This component will be exported as a member of
 * `QuestionAccordion` as `QuestionAccordion.Summary.`
 * - Default: This will show the question number and text.
 * - Customization: Other components (e.g, buttons) can be passed in as `children`
 * and will appear on the right edge near the expand icon.
 */
const QuestionAccordionSummary = ({
  item,
  index,
  children,
  handleClick
}: BaseQuestionAccordionSummaryParams) => {
  return (
    <ItemAccordion.Summary id={item.Id} handleClick={handleClick}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ width: "100%" }}
      >
        <Stack direction="row" spacing="12px">
          <Typography sx={{ fontSize: "18px" }}>{`${index + 1}.`}</Typography>
          <Typography sx={{ fontSize: "18px" }}>{item.Text}</Typography>
        </Stack>
        <Stack direction="row" spacing="12px" sx={{ marginRight: "12px" }}>
          {/* Children (action buttons, etc. on the right edge near the expand icon) */}
          {children}
        </Stack>
      </Stack>
    </ItemAccordion.Summary>
  );
};

/**
 * A flexible question accordion details (body) component.
 * This is shown whenever an accordion is expanded. This component will be exported as a member of
 * `QuestionAccordion` as `QuestionAccordion.Details.`
 * - Default: This will show the Domain and Element information for the question.
 * If the passed-in `evaluationType` is `"assessment"`, then Stage information will also be shown.
 * - Customization: Other components (list, radio button group, etc.) can be passed in as `children` and will
 * appear below the default information.
 */
const QuestionAccordionDetails = ({
  item,
  index,
  children,
  evaluationType,
  stageName
}: BaseQuestionAccordionDetailsParams) => {
  const isAssessment: boolean = evaluationType == EvaluationTypes.assessment;

  return (
    <ItemAccordion.Details>
      <Stack direction="row" spacing="12px" sx={{ marginTop: "16px" }}>
        {/* Offsets the details by the question number for proper alignment */}
        <Typography sx={{ fontSize: "18px", visibility: "hidden" }}>{`${
          index + 1
        }.`}</Typography>
        <Box component="div">
          <Stack direction="row" alignItems="center" spacing="16px">
            <ConditionalWrapper condition={isAssessment}>
              <StageTag
                value={formatQuestionStageText(item?.Stage, stageName)}
              />
            </ConditionalWrapper>
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
              {item.Domain}
            </Typography>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
              {item.Element}
            </Typography>
          </Stack>
          {/* Children (insert list, radio button group, etc.) */}
          {children}
          {/* TODO: Add logics for included and excluded Recommendation based on the scoring of response */}
          <ConditionalWrapper
            condition={Boolean(item?.Recommendation?.Description)}
          >
            <Recommendation
              type={RecommendationType.base}
              description={item?.Recommendation?.Description}
            />
          </ConditionalWrapper>
        </Box>
      </Stack>
    </ItemAccordion.Details>
  );
};

/**
 * A flexible question accordion component.
 * The `children` should be comprised of
 * - QuestionAccordion.Summary - the question accordion header (shown always)
 * - QuestionAccordion.Details - the question accordion details (shown when accordion is expanded)
 */
const QuestionAccordion = ({
  item,
  expanded,
  children
}: BaseQuestionAccordionParams) => {
  return (
    <ItemAccordion id={item.Id} expanded={expanded}>
      {children}
    </ItemAccordion>
  );
};

QuestionAccordion.Summary = QuestionAccordionSummary;
QuestionAccordion.Details = QuestionAccordionDetails;

export default QuestionAccordion;
