import * as React from "react";
import Box from "@mui/material/Box";
import Section from "./components/layout/Section";
import { SandboxClass } from "./components/types";
import { SandboxPageElementProps } from "./types";

type BodyProps = SandboxPageElementProps;

const Body = ({ components, componentIds, sx = {} }: BodyProps) => {
  return (
    <Box sx={{ flex: 1, ...sx }}>
      {componentIds.map((id: string) => {
        const sandboxClass: SandboxClass = components[`${id}`];
        const SandboxComponent: SandboxClass["element"] =
          components[`${id}`]?.element;
        return (
          <Section id={id} key={id} header={sandboxClass.displayName}>
            <SandboxComponent />
          </Section>
        );
      })}
    </Box>
  );
};

export default Body;
