import * as React from "react";
import Stack from "@mui/material/Stack";
import Label from "./Label";
import SubLabel from "./SubLabel";

interface InputContainerInterface {
  id: string;
  title: string;
  children: React.ReactNode;
  isSubInput: boolean;
}
const InputContainer = ({
  id,
  title,
  children,
  isSubInput
}: InputContainerInterface) => {
  return (
    <Stack>
      {isSubInput ? (
        <SubLabel htmlFor={id}>{title}</SubLabel>
      ) : (
        <Label htmlFor={id}>{title}</Label>
      )}
      {children}
    </Stack>
  );
};
export default InputContainer;
