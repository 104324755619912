import * as React from "react";
import { CreateQuestion, IndexedQuestion } from "src/schemas/question";
import {
  CreateRecommendation,
  Recommendation
} from "src/schemas/recommendation";
import { ReducerAction } from "src/utils/react/reducers/simple";

/**
 * Flag to indicate whether our modal should be in any of the following states:
 * - Add
 * - Edit
 */
export enum QuestionAction {
  add = "add",
  edit = "edit"
}

/**
 * A type that represents a union of either
 * - IndexedQuestion - for `Edit` modal case
 * - CreateQuestion - for `Add` modal case
 */
export type QuestionUnion = IndexedQuestion | CreateQuestion;

/**
 * A type that represents a union of either
 * - Recommendation - for `Edit` modal case
 * - CreateRecommendation - for `Add` modal case
 */
export type RecommendationUnion = Recommendation | CreateRecommendation;

export interface QuestionModalProps {
  open: boolean;
  handleClose: () => void;
  setDeleteModalOpen?: (open: boolean) => void;
  action: QuestionAction;
  editingQuestion?: IndexedQuestion;
}

// Modal Sections
export type SectionsProps = Omit<QuestionModalProps, "open">;

export type HeaderProps = Pick<SectionsProps, "action">;

interface CommonSectionProps {
  isEditing: boolean;
  question: QuestionUnion;
  recommendation: RecommendationUnion;
}

export interface ContentProps extends CommonSectionProps {
  dispatchQuestion: React.Dispatch<ReducerAction<QuestionUnion>>;
  dispatchRecommendation: React.Dispatch<ReducerAction<RecommendationUnion>>;
}

// Modal Actions Section Props
export interface ActionsProps
  extends CommonSectionProps,
    Pick<SectionsProps, "handleClose" | "setDeleteModalOpen"> {
  isFormDirty: boolean;
  isFormValid: boolean;
}

export interface SubmitButtonProps
  extends Omit<ActionsProps, "setDeleteModalOpen"> {
  disabled: boolean;
  setDisabled: (disabled: boolean) => any;
}

export type DeleteButtonProps = Pick<
  ActionsProps,
  "handleClose" | "setDeleteModalOpen"
>;
// Miscellaneous

/**
 * Object containing mapping of different English tenses:
 * - presentSimple: to populate base ActionButton text (e.g., "create")
 * - presentContinuous: to populate loading ActionButton text and
 * error notification (e.g, "creating")
 * - pastSimple: to populate success notification (e.g., "created")
 */
export interface ActionTenses {
  presentSimple: string;
  presentContinuous: string;
  pastSimple: string;
}
