import { z } from "zod";

export const zodUUID = z.string().uuid();

export const zodPositiveInteger = z.number().int().nonnegative(); // 0-inclusive
export const DateSchema = zodPositiveInteger;
export const StrictDateSchema = z.preprocess((arg) => {
  if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
}, z.date());

export const zodPositiveIntegerString = z.string().refine(
  (value) => {
    const numericValue: number = parseInt(value, 10);
    return Number.isInteger(numericValue) && numericValue >= 0;
  },
  {
    message: "String is not a positive integer"
  }
);

const _ActiveStates = ["ACTIVE", "INACTIVE"] as const;
export const ActiveStatesSchema = z.enum(_ActiveStates);
export type ActiveStates = z.infer<typeof ActiveStatesSchema>;

const _Phase = ["editing", "open", "closed", "completed"] as const;
export const PhaseSchema = z.enum(_Phase);
export type Phase = z.infer<typeof PhaseSchema>;
export const PhaseArray = PhaseSchema.options;

const _QuestionTypes = [
  "SingleChoice",
  "MultipleChoice",
  "FreeResponse"
] as const;
export const QuestionTypesSchema = z.enum(_QuestionTypes);
export type QuestionTypes = z.infer<typeof QuestionTypesSchema>;
export const QuestionTypesArray = QuestionTypesSchema.options;

/**
 * Enum of commonly used MIMETypes, like "text/csv", "application/json", etc.
 */
export enum MIMEType {
  csv = "text/csv",
  json = "application/json",
  xlsx = "application/vnd.ms-excel"
}

// may want to support "csv" in the future, "jira" implies interpretation of the data in csv format
export const FormatTypeUnion = ["jira"] as const;
export const FormatTypeSchema = z.enum(FormatTypeUnion);
