import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import ToggleAllCategoriesButton from "./ToggleButtons/AllCategories";

interface HeaderProps {
  height: string;
}

const Header = ({ height }: HeaderProps): JSX.Element => {
  const { palette } = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        height,
        padding: "0px 12px",
        borderBottom: `1px solid ${palette.primary.light}`
      }}
    >
      <Typography fontSize="14px" fontWeight="bold">
        Filters
      </Typography>
      <ToggleAllCategoriesButton />
    </Stack>
  );
};

export default Header;
