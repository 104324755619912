import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const TitledContent = ({
  title,
  children
}: {
  title: string;
  children: string | React.ReactNode;
}) => (
  <Stack direction="column" marginBottom="24px">
    <Typography fontSize="14px" marginBottom="4px">
      <b>{title}</b>
    </Typography>
    <Typography fontSize="14px" component="span">
      {children}
    </Typography>
  </Stack>
);

export default TitledContent;
