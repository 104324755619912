import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { User } from "src/schemas/user";

interface OptionProps {
  props: object;
  option: User;
  selected: boolean;
  disabled: boolean;
}

const Option = ({
  props,
  option,
  selected,
  disabled
}: OptionProps): JSX.Element => {
  if (disabled) {
    selected = true;
  }
  return (
    <li {...props}>
      <Checkbox
        sx={{ marginRight: "4px" }}
        checked={selected}
        disabled={disabled}
      />
      <Typography>{option.Name ?? option.Id}</Typography>
    </li>
  );
};

export default Option;
