import * as React from "react";
import { useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import QuestionAccordion from "src/components/Common/Accordion/Question";
import { EditingQuestionSummaryParams } from "./types";

const EditingQuestionSummary = ({
  item,
  index,
  handleClick,
  showModal
}: EditingQuestionSummaryParams) => {
  const { palette } = useTheme();
  return (
    <QuestionAccordion.Summary
      item={item}
      index={index}
      handleClick={handleClick}
    >
      <IconButton
        aria-label="edit"
        onClick={(e: React.SyntheticEvent) => {
          showModal(e);
        }}
        sx={{ background: palette.primary.lighter, padding: "4px" }}
      >
        <EditIcon
          sx={{
            color: palette.primary.main,
            fontSize: "16px"
          }}
        />
      </IconButton>
    </QuestionAccordion.Summary>
  );
};

export default EditingQuestionSummary;
