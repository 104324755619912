import * as React from "react";
import Box from "@mui/system/Box";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import QuestionAccordion from "src/components/Common/Accordion/Question";
import ErrorMessage from "src/components/Common/ErrorMessage";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError, showSuccess } from "src/store/slices/notification";
import { Answer } from "src/schemas/question";
import {
  ModifyResponse,
  Response,
  ResponseResponse,
  ResponseResponseSchema
} from "src/schemas/response";
import {
  PatchAPICaller,
  PatchAPICallRequest,
  PostAPICaller,
  PostAPICallRequest
} from "src/services/APICall";
import { OpenQuestionDetailsParams } from "../types";
import {
  RADIO_PADDING,
  RADIO_SIZE,
  StyledCircularProgress,
  StyledFormControlLabel,
  StyledRadio
} from "./Styled";

/**
 * A simple loading icon displayed whenever a response is being submitted.
 * Styling currently matches StyledRadio component 1-1 for a seamless user experience,
 * so if styling there changes, please change this as well
 */
const SubmissionLoading = () => {
  return (
    <Box sx={{ padding: RADIO_PADDING, display: "flex", alignItems: "center" }}>
      <StyledCircularProgress size={RADIO_SIZE} />
    </Box>
  );
};

const OpenAssessmentQuestionDetails = ({
  item,
  index,
  evaluationType,
  stageName,
  response,
  setResponse
}: OpenQuestionDetailsParams) => {
  const dispatch: AppDispatch = useAppDispatch();
  const hasPreviousResponse = Boolean(response);
  const { Id: assessmentId } = useManageEvaluationContext().evaluation.item;

  // NOTE: MUI RadioGroup won't accept an `undefined` value
  const [selectedValue, setSelectedValue] = React.useState<number | null>(
    response?.ScoreValue ?? null
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  /* Submit Response */
  function successCallback(value: number, response: Response) {
    // Update response in local state
    setResponse({ ...response, ScoreValue: value });
    setLoading(false);
    dispatch(
      showSuccess({
        message: "Your response was saved successfully"
      })
    );
  }

  function errorCallback(previousValue: number, error: Error) {
    // Reset state of radio button to previous value
    setSelectedValue(previousValue);
    setLoading(false);
    dispatch(
      showError({
        message: (
          <ErrorMessage error={error} action="submitting your response" />
        )
      })
    );
  }

  const handleChange = (event: any, text: string) => {
    // DOM API casts event.target.value as string - need to convert to number
    const value: number = parseInt(
      (event.target as HTMLInputElement).value,
      10
    );
    const previousValue: number = selectedValue; // used to reset state if error
    setSelectedValue(value); // update selected value, so we know which radio button should show loading icon

    setLoading(true);

    const postURL = `/assessments/${assessmentId}/questions/${item.Id}/responses`;
    const patchURL = `${postURL}/${response?.Id}`;

    const body: ModifyResponse = {
      ...(hasPreviousResponse ? { DateModified: response.DateModified } : {}),
      ScoreValue: value,
      Text: text
    };

    const request: PostAPICallRequest | PatchAPICallRequest = {
      path: hasPreviousResponse ? patchURL : postURL,
      body,
      callback: (response: ResponseResponse) =>
        successCallback(value, response.result),
      errorCallback: (error: Error) => errorCallback(previousValue, error),
      typeValidator: ResponseResponseSchema,
      withReactHook: false
    };

    return hasPreviousResponse
      ? PatchAPICaller(request)
      : PostAPICaller(request);
  };

  return (
    <QuestionAccordion.Details
      item={item}
      index={index}
      evaluationType={evaluationType}
      stageName={stageName}
    >
      <FormControl>
        <RadioGroup
          name="open-assessment-questions-radio-group"
          value={selectedValue}
        >
          {item.Answers.map((answer: Answer) => (
            <StyledFormControlLabel
              key={answer.ScoreValue}
              value={answer.ScoreValue}
              control={
                loading && selectedValue == answer.ScoreValue ? (
                  <SubmissionLoading />
                ) : (
                  <StyledRadio />
                )
              }
              label={answer.Text}
              disabled={loading}
              onChange={(event: React.SyntheticEvent) =>
                handleChange(event, answer.Text)
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </QuestionAccordion.Details>
  );
};

export default OpenAssessmentQuestionDetails;
