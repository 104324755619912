import * as React from "react";
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
// TODO: Add these back in when reenabling Client, Projects and Surveys
// import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
// import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
// import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
// import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { StyledNavLink } from "src/components/Common/StyledNavLink";
import {
  HEADER_HEIGHT,
  SIDEBAR_WIDTH,
  NAVBAR_ICON_SIZE,
  NAVBAR_TEXT_SIZE,
  NESTED_NAVBAR_TEXT_SIZE
} from "src/utils/constants/layout";
// Future use case for nested objects
// https://mui.com/components/lists/#nested-list
// import Collapse from "@mui/material/Collapse";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
// TODO: Create option should be re-enabled past MVP
// import AddRoundedIcon from "@mui/icons-material/AddRounded";

const topIcons = [
  {
    name: "Home",
    icon: HomeRoundedIcon,
    route: "/home"
  },
  // {
  //   name: "Clients",
  //   icon: PeopleRoundedIcon,
  //   route: "/clients"
  // },
  // {
  //   name: "Projects",
  //   icon: LayersRoundedIcon,
  //   route: "/projects"
  // },
  // {
  //   name: "Surveys",
  //   icon: AssignmentRoundedIcon,
  //   route: "/surveys"
  // },
  {
    name: "Assessments",
    icon: AssignmentTurnedInRoundedIcon,
    route: "/assessments"
  },
  {
    name: "Reports",
    icon: TimelineRoundedIcon,
    route: "/reports"
  }
  // TODO: Create option should be re-enabled past MVP
  // {
  //   name: "Create",
  //   icon: AddRoundedIcon,
  //   route: "/create"
  // }
  // {
  //   name: "Management",
  //   icon: ManageAccountsRoundedIcon,
  //   route: "/manage",
  //   nestedItems: [
  //     {
  //       name: "Clients",
  //       route: "/manage/clients"
  //     },
  //     {
  //       name: "Projects",
  //       route: "/manage/projects"
  //     },
  //     {
  //       name: "Users",
  //       route: "/manage/users"
  //     }
  //   ]
  // }
];

const bottomIcons = [
  {
    name: "Contact Us",
    icon: SendRoundedIcon,
    route: "/contact"
  }
];

const Container = styled("div")(() => ({
  position: "fixed",
  display: "flex",
  flexDirection: "column", // alignItems = vertical when flex-direction: row
  justifyContent: "space-between", // justifyContent = vertical when flex-direction: column
  alignItems: "center", // horizontal
  padding: "30px 0px",
  height: `calc(100vh - ${HEADER_HEIGHT})`, // need to set explicitly since position:'fixed'
  width: SIDEBAR_WIDTH,
  minWidth: SIDEBAR_WIDTH,
  borderRight: "1px solid #E0E0E0",

  /* Overflow properties */
  overflowX: "hidden", // Disable horizontal scrolling
  overflowY: "auto"
}));

const MakeIcon = (item) => {
  const SidebarIcon = item.icon;
  return <SidebarIcon sx={{ fontSize: NAVBAR_ICON_SIZE }} />;
};

const ListItemButtonStyles = (palette, isActive, isNested) => {
  return {
    ".MuiListItemIcon-root": {
      color: isActive ? palette.primary.main : palette.grey["300"]
    },
    ".MuiListItemText-root": {
      color: isActive ? palette.primary.main : palette.grey["700"]
    },
    ":hover": {
      backgroundColor: palette.bg.lightGrey,
      ".MuiListItemIcon-root": { color: palette.primary.dark },
      ".MuiListItemText-root": { color: palette.primary.dark }
    },
    padding: "0px 12px 0px 30px",
    pl: isNested ? "42px" : "30px",
    height: "40px"
  };
};

const ListEntry = (item, isNested = false) => {
  const { palette } = useTheme();
  return (
    <span key={item.name}>
      <StyledNavLink to={item.route ? item.route : "#"}>
        {({ isActive }) => (
          <ListItemButton
            sx={ListItemButtonStyles(palette, isActive && item.route, isNested)}
            onClick={item.action ? item.action : null}
          >
            <ListItemIcon sx={{ minWidth: `${NAVBAR_ICON_SIZE + 14}px` }}>
              {item.icon && MakeIcon(item)}
            </ListItemIcon>
            <ListItemText
              primary={item.name}
              primaryTypographyProps={{
                fontSize: isNested ? NESTED_NAVBAR_TEXT_SIZE : NAVBAR_TEXT_SIZE
              }}
            />
          </ListItemButton>
        )}
      </StyledNavLink>
      {item.nestedItems && (
        <List component="div" disablePadding>
          {item.nestedItems.map((nestedItem) => ListEntry(nestedItem, true))}
        </List>
      )}
    </span>
  );
};

const IconGroup = ({ icons }) => {
  return (
    <List sx={{ padding: 0, width: "100%" }} component="nav">
      {icons.map((item) => ListEntry(item))}
    </List>
  );
};

const Sidebar = () => {
  return (
    <Container>
      <IconGroup icons={topIcons} />
      <IconGroup icons={bottomIcons} />
    </Container>
  );
};

export default Sidebar;
