import { failsMaturityThreshold } from "src/components/Reporting/utils/maturity";
import { Answer } from "src/schemas/question";
import { AnswerListFilterType } from "../types";

const TEXT_ANSWERS: string[] = [
  "Never / Doesn't Exist",
  "Occasionally / In Design",
  "Frequently / Partially Implemented",
  "Most of the time / Partially Adopted",
  "Always / Fully Adopted"
];

export const ANSWERS: Answer[] = TEXT_ANSWERS.map(
  (answer: string, index: number) => {
    return {
      ScoreValue: index,
      Text: answer
    };
  }
);

export const ANSWER_MAX_VALUE: number = Math.max(
  ...ANSWERS.map((answer: Answer) => answer.ScoreValue)
);

/* Data processing helper methods */

function includeRecommendationFilter(answer: Answer): boolean {
  return failsMaturityThreshold(answer.ScoreValue / ANSWER_MAX_VALUE);
}

function excludeRecommendationFilter(answer: Answer): boolean {
  return !includeRecommendationFilter(answer);
}

export function filterAnswers(
  answers: Answer[],
  filterType: AnswerListFilterType
): Answer[] {
  switch (filterType) {
    case AnswerListFilterType.includeRecommendation: {
      return answers.filter(includeRecommendationFilter);
    }
    case AnswerListFilterType.excludeRecommendation: {
      return answers.filter(excludeRecommendationFilter);
    }
    default: {
      return answers;
    }
  }
}
