export const EMAIL = "dart@slalom.com";

// Reference: https://stackoverflow.com/a/25634014
export const NEWLINE: string = encodeURIComponent("\r\n");
export const DOUBLE_NEWLINE: string = encodeURIComponent("\r\n".repeat(2));
export const INDENT: string = encodeURIComponent("\r" + " ".repeat(4));

/**
 * Given some text, creates an underlined section with the underline being
 * represented by the `underlineSymbol` (default is "=")
 *
 * Example:
 *
 * Notes
 * =====
 */
export function createUnderlinedSection(
  text: string,
  underlineSymbol = "="
): string {
  return `${text}${NEWLINE}${underlineSymbol.repeat(text.length)}`;
}

/**
 * Given an array of text items, creates a nested, indented section. Each text
 * item is indented, optionally prefixed with the provided `startSymbol`,
 * optionally suffixed with the provided `endSymbol`, and takes up 1 line.
 *
 * Example:
 *
 * Input: ["Name", "Description"]
 *
 * Output:
 *
 * [4 space indent]Name:
 *
 * [4 space indent]Description:
 */
export function createIndentedSection(
  items: string[],
  startSymbol = "",
  endSymbol = ":"
): string {
  return items
    .map((text: string) => `${startSymbol}${text}${endSymbol}`)
    .join(INDENT);
}
