import * as React from "react";
import { useNavigate } from "react-router-dom";
import { capitalize, TooltipProps } from "@mui/material";
import Button from "@mui/material/Button";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import ComingSoonTooltip from "src/components/Common/Tooltip/ComingSoonTooltip";
import { CreateEvaluationProps } from "../types";
import { conditionalObject } from "src/utils/object";

const CreateEvaluationButton = ({
  evaluationType
}: CreateEvaluationProps): JSX.Element => {
  const navigate = useNavigate();
  const disabled: boolean = evaluationType == EvaluationTypes.survey;

  // Hide tooltip by setting title to ""
  const tooltipProps: Partial<TooltipProps> = conditionalObject(!disabled, {
    title: ""
  });

  return (
    <ComingSoonTooltip {...tooltipProps}>
      <span>
        <Button
          size="small"
          sx={{ marginLeft: "12px" }}
          variant="contained"
          onClick={() => navigate(`/create/${evaluationType}`)}
          disabled={disabled}
        >
          Create {capitalize(evaluationType)}
        </Button>
      </span>
    </ComingSoonTooltip>
  );
};
export default CreateEvaluationButton;
