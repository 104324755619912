import { UpdateEvaluationForm } from "src/schemas/evaluation";
import { Metadata, ModifyNotesResponse } from "src/schemas/metadata";
import EvaluationNotesService from "src/services/evaluationNotes";
import { EvaluationTypes } from "src/utils/constants/evaluation";

interface PutEvaluationNotes {
  evaluationType: EvaluationTypes;
  localEvaluation: UpdateEvaluationForm;
  dateModified: Metadata["DateModified"];
}

/**
 * Simple wrapper around `EvaluationNotesService.put` call so that we
 * return the result of the call or throw an error. This is needed due to
 * how our APICaller methods use callbacks and can't actually return results.
 */
export async function putEvaluationNotes({
  evaluationType,
  localEvaluation,
  dateModified
}: PutEvaluationNotes): Promise<ModifyNotesResponse> {
  return new Promise((resolve, reject) => {
    EvaluationNotesService.put({
      evaluationId: localEvaluation.item.Id,
      evaluationType,
      body: {
        item: localEvaluation.item.Metadata.Notes[0],
        _meta: {
          DateModified: dateModified
        }
      },
      callback: (response: ModifyNotesResponse) => resolve(response),
      errorCallback: (error: Error) => reject(error)
    });
  });
}
