/** Helper function for sorting strings in ascending order */
export const stringCompareASC = (a: string, b: string): number => {
  const lowerA = a.toLowerCase();
  const lowerB = b.toLowerCase();
  if (lowerA < lowerB) {
    return -1;
  } else if (lowerA > lowerB) {
    return 1;
  } else {
    return 0;
  }
};

/** Helper function for sorting either strings or numbers in ascending order */
export function compareASC<T extends string | number>(a: T, b: T): number {
  if (typeof a == "string" && typeof b == "string") {
    return stringCompareASC(a, b);
  } else if (typeof a == "number" && typeof b == "number") {
    return a - b;
  }
  return 0;
}

/** Sorts an array of strings or numbers in ascending order */
export function sortArrayASC<T extends string | number>(
  items: Array<T>
): Array<T> {
  return items.sort(compareASC);
}

/** Sorts an array of strings or numbers in descending order */
export function sortArrayDESC<T extends string | number>(
  items: Array<T>
): Array<T> {
  return items.sort((a: T, b: T) => compareASC(b, a));
}

/**
 * Sorts an array of objects in ascending order by string or number property returned by `getProperty`
 * - `getProperty` is a function so that we can get nested properties if we ever need to
 */
export function sortObjectArrayASC<T, TProperty extends string | number>(
  items: Array<T>,
  getProperty: (obj: T) => TProperty
): Array<T> {
  return items.sort((a: T, b: T): number =>
    compareASC(getProperty(a), getProperty(b))
  );
}

/**
 * Sorts an array of objects in descending order by string or number property returned by `getProperty`
 * - `getProperty` is a function so that we can get nested properties if we ever need to
 */
export function sortObjectArrayDESC<T, TProperty extends string | number>(
  items: Array<T>,
  getProperty: (obj: T) => TProperty
): Array<T> {
  return items.sort((a: T, b: T): number =>
    compareASC(getProperty(b), getProperty(a))
  );
}
