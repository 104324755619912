import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import * as AWS from "@aws-sdk/client-xray";
import React from "react";
import { getToken } from "src/services/APICall";
import { Segment } from "./types";
import useEnvironmentSettings from "../EnvironmentSettings";
import { EnvironmentSettingsContextType } from "../EnvironmentSettings/types";
// import { Segment } from "@aws-sdk/client-xray";

const CLIENT_NAME = `dart-${window.location.hostname}"`;
export interface XRayContextType {
  endSegment: (segment: Segment) => Promise<Segment>;
  beginSegment: () => Promise<Segment>;
  sendSegment: (segment: Segment) => Promise<Segment>;
  getTraceHeader: (segment: Segment) => string;
}

export const XRayContext = React.createContext<XRayContextType>({
  endSegment: async () => null,
  beginSegment: async () => null,
  sendSegment: async () => null,
  getTraceHeader: () => null
});

function getHexId(length: number): string {
  return Array.from(crypto.getRandomValues(new Uint8Array(length)))
    .map((b: number) => b.toString(16))
    .join("")
    .substring(0, length);
}

function getHexTime(): string {
  return Math.round(new Date().getTime() / 1000).toString(16);
}

function getEpochTime(): number {
  return new Date().getTime() / 1000;
}
async function createXRayClientFromIDP(
  region: string,
  userPoolId: string,
  identityPoolId: string
) {
  const loginKey = [
    ["cognito-idp", region, "amazonaws.com"].join("."),
    userPoolId
  ].join("/");
  return new AWS.XRay({
    region,
    credentials: fromCognitoIdentityPool({
      clientConfig: {
        region
      },
      identityPoolId,
      logins: {
        [loginKey]: await getToken()
      }
    })
  });
}

async function putDocuments(documents: string[]): Promise<void> {
  const envSettings: EnvironmentSettingsContextType = useEnvironmentSettings();
  const client = await createXRayClientFromIDP(
    envSettings.amplify.Auth.region,
    envSettings.amplify.Auth.userPoolId,
    envSettings.amplify.Auth.identityPoolId
  );
  const params: AWS.PutTraceSegmentsCommandInput = {
    TraceSegmentDocuments: documents
  };
  return client
    .putTraceSegments(params)
    .then((data) => {
      // not sure what we should do with the response data
      console.log(data);
    })
    .catch((error) => {
      console.log(error, error.stack);
    });
}

function makeTraceID(hexTime?: string, hexID?: string, version = 1): string {
  hexID ??= getHexId(24);
  hexTime ??= getHexTime();
  return `${version}-${hexTime}-${hexID}`;
}

export const XRayProvider = ({ children }: { children: React.ReactNode }) => {
  function getTraceHeader(segment: Segment): string {
    return `Root=${segment.trace_id};Parent=${segment.id};Sampled=1`;
  }

  async function beginSegment(): Promise<Segment> {
    const segment: Segment = {
      trace_id: makeTraceID(),
      id: getHexId(16),
      start_time: getEpochTime(),
      name: CLIENT_NAME,
      in_progress: true,
      user: sessionStorage["userid"],
      http: {
        request: {
          url: window.location.href
        }
      }
    };

    await putDocuments([JSON.stringify(segment)]);
    return segment;
  }

  async function endSegment(segment: Segment): Promise<Segment> {
    segment.end_time = getEpochTime();
    segment.in_progress = false;
    await putDocuments([JSON.stringify(segment)]);
    return segment;
  }

  async function sendSegment(segment: Segment): Promise<Segment> {
    await putDocuments([JSON.stringify(segment)]);
    return segment;
  }

  const value: XRayContextType = {
    beginSegment,
    sendSegment,
    endSegment,
    getTraceHeader
  };

  return <XRayContext.Provider value={value}>{children}</XRayContext.Provider>;
};

const useXRay = () => React.useContext(XRayContext);
export default useXRay;
