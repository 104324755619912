import * as React from "react";
import Modal from "src/components/Common/Modal";
import Sections from "./Sections";
import { QuestionModalProps } from "./types";

const QuestionModal = ({
  open,
  handleClose,
  setDeleteModalOpen,
  action,
  editingQuestion
}: QuestionModalProps): JSX.Element => {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Sections
        handleClose={handleClose}
        setDeleteModalOpen={setDeleteModalOpen}
        action={action}
        editingQuestion={editingQuestion}
      />
    </Modal>
  );
};

export default QuestionModal;
