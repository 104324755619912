import * as React from "react";
import CreateEvaluationButton from "./CreateEvaluationButton";
import { ActionsProps } from "../types";
// TODO: FilterPopover should be re-enabled past MVP
// import FilterPopover from "./FilterPopover";

const Actions = ({ evaluationType }: ActionsProps): JSX.Element => {
  return (
    <>
      {/* TODO: FilterPopover should be re-enabled past MVP */}
      {/* <FilterPopover /> */}
      <CreateEvaluationButton evaluationType={evaluationType} />
    </>
  );
};
export default Actions;
