import * as React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import Report from "../../Report";
import { ReportClass, ReportGroup } from "../../Report/types";
import { MaturityReportElementProps } from "../../Report/types/element";

import { EvaluationTypes } from "src/utils/constants/evaluation";
import CalculationInfo from "./CalculationInfo";
import Overview from "./Overview";
import Classifications from "./Classifications";

const report = ({ name, data }: MaturityReportElementProps): JSX.Element => {
  const { classificationData, scoredData } = data;
  const { domains, questionStages } = classificationData;

  return (
    <Report>
      <Report.Content name={name}>
        <Stack spacing="24px" sx={{ marginTop: "24px" }}>
          <Overview
            score={scoredData?.score}
            maxScore={scoredData?.maxScore}
            domainsCount={domains?.size}
            questionStagesCount={questionStages?.size}
          />
          <CalculationInfo />
          <Divider />
          <Classifications
            domains={domains ?? new Set()}
            questionStages={questionStages ?? new Map()}
          />
        </Stack>
      </Report.Content>
    </Report>
  );
};

const OverallRawScoreReport: ReportClass = class {
  static displayName = "Overall Raw Score";
  static group: ReportGroup = ReportGroup.maturity;
  static supportedEvaluationTypes = [
    EvaluationTypes.assessment,
    EvaluationTypes.survey
  ];
  static report = report;
};

export default OverallRawScoreReport;
