import * as React from "react";
import { StyledNavLink } from "src/components/Common/StyledNavLink";

interface ToggleFilterButtonProps {
  onClick: () => any;
  allItemsEnabled: boolean;
}

const ToggleFilterButton = ({
  onClick,
  allItemsEnabled
}: ToggleFilterButtonProps): JSX.Element => {
  return (
    <StyledNavLink
      to={"#"}
      onClick={onClick}
      sx={{ fontSize: "14px", pointerEvents: "auto" }}
    >
      {allItemsEnabled && <>Clear</>}
      {!allItemsEnabled && <>All</>}
    </StyledNavLink>
  );
};

export default ToggleFilterButton;
