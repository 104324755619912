import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

const NumberChip = styled(Chip)(({ theme }) => ({
  width: "26px",
  height: "26px",
  flexShrink: 0,
  backgroundColor: theme.palette.grey["200"],
  borderRadius: "50vh",
  fontWeight: "bold",
  color: theme.palette.common.black
}));

export default NumberChip;
