import * as React from "react";
import Box from "@mui/material/Box";
import Header from "./Header";
import Reasons from "./Reasons";

const Contact = (): JSX.Element => {
  return (
    <Box sx={{ maxWidth: "1000px", margin: "0 auto" }}>
      <Header />
      <Reasons />
    </Box>
  );
};

export default Contact;
