import * as React from "react";
import Content from "./Content";
import { QuestionAction, SectionsProps } from "../types";
import useManageEvaluationContext from "src/context/ManageEvaluation";
import { isDirty } from "src/utils/dirtyData";
import Header from "./Header";
import Actions from "./Actions";
import { setupQuestionReducer } from "../reducers/question";
import { setupRecommendationReducer } from "../reducers/recommendation";

import {
  isValidNewQuestion,
  isValidNewRecommendation,
  isValidQuestion
} from "../utils/validation";

const Sections = ({
  handleClose,
  setDeleteModalOpen,
  action,
  editingQuestion
}: SectionsProps): JSX.Element => {
  const { evaluation, evaluationType } = useManageEvaluationContext();

  const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState<boolean>(false);

  const isEditing: boolean =
    action == QuestionAction.edit && Boolean(editingQuestion);

  /* Determine the # of Answers per Question based on the
   * # associated with the 1st evaluation Question.
   * Default to 4 questions if evaluation has no questions.
   */
  const answerCount: number =
    evaluation?.item?.Questions[0]?.Answers?.length ?? 4;

  const [question, dispatchQuestion] = React.useReducer(
    ...setupQuestionReducer({
      isEditing,
      evaluationType,
      editingQuestion,
      answerCount
    })
  );

  const [recommendation, dispatchRecommendation] = React.useReducer(
    ...setupRecommendationReducer({
      isEditing,
      editingQuestion
    })
  );

  /* Watches `question` and `recommendation` for changes to determine if form
   * is dirty or valid
   */
  React.useEffect(() => {
    if (isEditing) {
      // Set both 'dirty' and 'valid' state for Edit
      const isEditingDirty: boolean = isDirty(editingQuestion, question);
      const isQuestionValid: boolean = isValidQuestion(question);
      setIsFormDirty(isEditingDirty);
      setIsFormValid(isQuestionValid);
    } else {
      // Set just 'valid' state for Add
      const isQuestionValid: boolean = isValidNewQuestion(question);
      const isRecommendationValid: boolean =
        isValidNewRecommendation(recommendation);
      setIsFormValid(isQuestionValid && isRecommendationValid);
    }
  }, [question, recommendation]);

  return (
    <>
      <Header action={action} />
      <Content
        isEditing={isEditing}
        question={question}
        dispatchQuestion={dispatchQuestion}
        recommendation={recommendation}
        dispatchRecommendation={dispatchRecommendation}
      />
      <Actions
        isEditing={isEditing}
        handleClose={handleClose}
        setDeleteModalOpen={setDeleteModalOpen}
        question={question}
        recommendation={recommendation}
        isFormDirty={isFormDirty}
        isFormValid={isFormValid}
      />
    </>
  );
};

export default Sections;
