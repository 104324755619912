import * as React from "react";
import { PhaseActionInfo } from "./types";
import { populateData } from "./utils/populateData";

const MoveToPhaseText = ({ previous }: { previous: boolean }): JSX.Element => {
  return (
    <>
      Move Assessment to <b>{previous ? "previous" : "next"}</b> phase
    </>
  );
};

export const ActionsData: PhaseActionInfo[] = [
  populateData({
    name: "Edit Details",
    summary:
      "Modify Assessment Name, Description, Notes, Linked Project, and Linked Users",
    editing: true,
    open: true,
    closed: true
  }),
  populateData({
    name: "Add Questions",
    summary:
      "Select Domain, Stage, and Element + customize Text, Answers, and Recommendation per Question",
    editing: true
  }),
  populateData({
    name: "Edit Questions",
    summary: "Modify Question Text and Answers per Question",
    editing: true
  }),
  populateData({
    name: "Delete Questions",
    editing: true
  }),
  populateData({
    name: "Submit Question Responses",
    open: true
  }),
  populateData({
    name: "View and export Reports",
    open: true,
    closed: true,
    completed: true
  }),
  populateData({
    name: <MoveToPhaseText previous={true} />,
    open: true,
    closed: true
  }),
  populateData({
    name: <MoveToPhaseText previous={false} />,
    editing: true,
    open: true,
    closed: true
  })
];
