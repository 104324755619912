import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ConditionalWrapper from "../ConditionalWrapper";

interface ModalSectionProps {
  name?: string;
  children: React.ReactNode;
}

/** A reusable component to create a text section in a modal */
const ModalSection = ({ name, children }: ModalSectionProps) => {
  return (
    <Stack spacing="4px">
      <ConditionalWrapper condition={Boolean(name)}>
        <Typography>
          <b>{name}</b>
        </Typography>
      </ConditionalWrapper>

      <Stack spacing="16px">{children}</Stack>
    </Stack>
  );
};

export default ModalSection;
