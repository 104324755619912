import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Tag from "src/components/Common/Tags";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import Box from "@mui/material/Box";
import ClampedContent from "src/components/Common/ClampedContent";
import { EvaluationTemplateSummary } from "src/schemas/evaluationTemplateSummary";
import TooltipTextBox from "src/components/Common/Tooltip/TooltipTextBox";

interface TooltipBodyProps {
  evaluationType: EvaluationTypes;
  template: EvaluationTemplateSummary;
}

const TooltipBody = ({
  evaluationType,
  template
}: TooltipBodyProps): JSX.Element => {
  return (
    <TooltipTextBox
      sx={{ maxWidth: "250px" }}
      onClick={(e) => e.stopPropagation()}
    >
      <Stack spacing="12px">
        <Box>
          <Tag
            label={evaluationType}
            size="small"
            sx={{
              textTransform: "uppercase",
              flexShrink: 0
            }}
          />
        </Box>
        <Stack>
          <Typography variant="body2">
            <b>Template</b>
          </Typography>
          <Typography variant="body2">{template.Name}</Typography>
        </Stack>
        <Stack>
          <Typography variant="body2">
            <b>Description</b>
          </Typography>
          <Typography variant="body2">
            <ClampedContent lines={6} allowExpand={false}>
              {template.Description}
            </ClampedContent>
          </Typography>
        </Stack>
      </Stack>
    </TooltipTextBox>
  );
};

export default TooltipBody;
