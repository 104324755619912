import * as React from "react";
import ConditionalWrapper from "src/components/Common/ConditionalWrapper";
import useReportsContext from "src/context/Reports";
import ReportBody from "./ReportBody";
import ConfigurationHeader from "./ConfigurationHeader";
import ReportPageLoading from "./ReportPageLoading";

const Body = (): JSX.Element => {
  const { isPreloading } = useReportsContext();
  return (
    <>
      <ConditionalWrapper condition={!isPreloading}>
        <>
          <ConfigurationHeader />
          <ReportBody />
        </>
      </ConditionalWrapper>
      <ConditionalWrapper condition={isPreloading}>
        <ReportPageLoading />
      </ConditionalWrapper>
    </>
  );
};
export default Body;
