import * as React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { capitalize, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SectionRow from "./layout/SectionRow";
import LabeledBox from "./layout/LabeledBox";
import QuestionAccordion from "src/components/Common/Accordion/Question";
import BasicQuestionDetails from "src/components/Common/Accordion/Question/Basic/Details";
import {
  RowsState,
  RowStateValue
} from "src/components/Common/Accordion/types";
import { expandRow } from "src/components/Common/Accordion/utils";
import {
  sampleAssessmentQuestion,
  sampleSurveyQuestion
} from "../data/accordion";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import Modal from "src/components/Common/Modal";
import { generateFakeText } from "src/components/Common/FakeText";
import { Question } from "src/schemas/question";
import { SandboxClass } from "./types";

const QUESTIONS: Record<string, Question> = {
  [EvaluationTypes.assessment]: sampleAssessmentQuestion,
  [EvaluationTypes.survey]: sampleSurveyQuestion
};

const EditModal = ({ children, open, handleClose }) => {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Modal.Header>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing="12px"
        >
          <Typography variant="h5" color="primary">
            <b>Edit Modal</b>
          </Typography>
        </Stack>
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions
        sx={{
          justifyContent: "center",
          padding: "12px"
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={handleClose}
          color="primary"
        >
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const SampleQuestionAccordion = ({
  question,
  evaluationType,
  expanded,
  handleAccordionClick,
  openModal
}: {
  question: Question;
  evaluationType: EvaluationTypes;
  expanded: boolean;
  handleAccordionClick: (rowId: string) => any;
  openModal: (event: React.SyntheticEvent, open: boolean) => any;
}) => {
  const { palette } = useTheme();
  return (
    <QuestionAccordion item={question} expanded={expanded}>
      <QuestionAccordion.Summary
        item={question}
        index={0}
        handleClick={handleAccordionClick}
      >
        <IconButton
          aria-label="edit"
          onClick={(e: React.SyntheticEvent) => openModal(e, true)}
          sx={{ background: palette.primary.lighter, padding: "4px" }}
        >
          <EditIcon
            sx={{
              color: palette.primary.main,
              fontSize: "16px"
            }}
          />
        </IconButton>
      </QuestionAccordion.Summary>
      <BasicQuestionDetails
        item={question}
        index={0}
        evaluationType={evaluationType}
      />
    </QuestionAccordion>
  );
};

const Accordions = () => {
  const [questions, setQuestions] = React.useState<Record<string, Question>>(
    {}
  );
  const [rowsState, setRowsState] = React.useState<RowsState<RowStateValue>>(
    {}
  );
  const [open, setOpen] = React.useState<boolean>(false);

  /* On mount, set `questions` + `rowMap`
   * Can't simple iterate over the constant QUESTIONS in component below b/c rowMap not mounted at that point
   */
  React.useEffect(() => {
    setQuestions(QUESTIONS);
    const initialRowsState: RowsState<RowStateValue> = {};
    Object.values(QUESTIONS).forEach((question: Question) => {
      Reflect.set(initialRowsState, question.Id, { expanded: false });
    });
    setRowsState(initialRowsState);
  }, []);

  function handleAccordionClick(id: string): void {
    expandRow(id, rowsState, setRowsState);
  }

  function openModal(e: React.SyntheticEvent, open: boolean): void {
    e.stopPropagation();
    setOpen(open);
  }

  return (
    <>
      <EditModal
        open={open}
        handleClose={(e: React.SyntheticEvent) => openModal(e, false)}
      >
        {generateFakeText(2)}
      </EditModal>
      {Object.entries(questions).map(
        ([key, question]: [key: string, question: Question]) => {
          return (
            <SectionRow key={key}>
              <LabeledBox
                label={<b>Question Accordion - {capitalize(key)}</b>}
                sx={{ width: "600px" }}
              >
                <SampleQuestionAccordion
                  question={question}
                  evaluationType={EvaluationTypes[`${key}`]}
                  expanded={Reflect.get(rowsState, question.Id).expanded}
                  handleAccordionClick={handleAccordionClick}
                  openModal={openModal}
                />
              </LabeledBox>
            </SectionRow>
          );
        }
      )}
    </>
  );
};

const AccordionsSandbox: SandboxClass = class {
  static displayName = "Accordions";
  static element = Accordions;
};

export default AccordionsSandbox;
