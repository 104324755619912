import * as React from "react";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import QuestionAccordion from "..";
import ConditionalWrapper from "../../../ConditionalWrapper";
import Tag from "../../../Tags";
import { EvaluationTypes } from "src/utils/constants/evaluation";
import { QuestionAccordionDetailsParams } from "../../types";
import { Answer } from "src/schemas/question";

const ASSESSMENT_STYLES: Record<string, Record<string, any>> = {
  list: {
    listStyleType: "disc",
    listStylePosition: "outside",
    marginLeft: "18px"
  },
  listItem: {
    display: "list-item"
  }
};

/**
 * A simple question accordion details (body) component. If `evaluationType` is
 * - `"assessment"`, a bulleted list of question answers will be shown
 * - `"survey"`, a list of question answers with a Stage tag per answer will be shown
 */
const BasicQuestionDetails = ({
  item,
  index,
  evaluationType,
  stageName
}: QuestionAccordionDetailsParams) => {
  const { palette } = useTheme();
  const isAssessment: boolean = evaluationType == EvaluationTypes.assessment;
  return (
    <QuestionAccordion.Details
      item={item}
      index={index}
      evaluationType={evaluationType}
      stageName={stageName}
    >
      <List sx={isAssessment ? ASSESSMENT_STYLES.list : {}}>
        {item.Answers.map((answer: Answer, index: number) => (
          <ListItem
            key={index}
            sx={{
              color: palette.grey["300"],
              paddingLeft: "0px",
              marginTop: "8px",
              ...(isAssessment ? ASSESSMENT_STYLES.listItem : {})
            }}
          >
            <Stack direction="row" alignItems="flex-start" spacing="12px">
              <ConditionalWrapper condition={!isAssessment}>
                <Tag
                  label={`Stage ${answer.ScoreValue}`}
                  size="small"
                  sx={{ minWidth: "70px" }}
                />
              </ConditionalWrapper>
              <Box
                component="span"
                sx={{ color: palette.grey["700"], lineHeight: "1.5" }}
              >
                {answer.Text}
              </Box>
            </Stack>
          </ListItem>
        ))}
      </List>
    </QuestionAccordion.Details>
  );
};
export default BasicQuestionDetails;
