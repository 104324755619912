import * as React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowRightAltRounded from "@mui/icons-material/ArrowRightAltRounded";

const ArrowRightWrapped = ({ color }: { color: string }) => (
  <IconButton
    disabled={true}
    sx={{
      "&.Mui-disabled": {
        color: color
      },
      margin: "0px 8px"
    }}
  >
    <ArrowRightAltRounded />
  </IconButton>
);

export default ArrowRightWrapped;
