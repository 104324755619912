import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import {
  NotificationOptions,
  Notification,
  NotificationState,
  MakeNotification,
  Severity,
  Variant
} from "../types/notification";

type S = NotificationState;

const initialState: S = {
  notifications: []
};

function makeNotification(args: MakeNotification): Notification {
  return {
    id: uuidv4(),
    timeout: 5000,
    createdAt: Date.now(),
    variant: Variant.filled,
    severity: Severity.info,
    ...args
  };
}

export const slice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    clear: (state: S) => {
      Object.keys(initialState).forEach((key: string) => {
        Reflect.set(state, key, Reflect.get(initialState, key));
      });
    },
    clearNotification: (state: S, action: PayloadAction<number>) => {
      /* When a specific notification is cleared, make sure to
       * clear out any "expired" notifications as well so that they don't
       * reappear
       */
      state.notifications = state.notifications.filter(
        (notification: Notification) => {
          const isSpecifiedNotification = notification.id === action.payload;
          const isExpiredNotification =
            notification.timeout &&
            notification.createdAt + notification.timeout <= Date.now();

          return !(isSpecifiedNotification || isExpiredNotification);
        }
      );
    },
    showInfo: (state: S, action: PayloadAction<NotificationOptions>) => {
      state.notifications.push(makeNotification(action.payload));
    },
    showError: (state: S, action: PayloadAction<NotificationOptions>) => {
      state.notifications.push(
        makeNotification({
          ...action.payload,
          timeout: null,
          severity: Severity.error
        })
      );
    },
    showSuccess: (state: S, action: PayloadAction<NotificationOptions>) => {
      state.notifications.push(
        makeNotification({
          ...action.payload,
          severity: Severity.success
        })
      );
    }
  }
});

export const { clear, clearNotification, showInfo, showError, showSuccess } =
  slice.actions;

export default slice.reducer;
