import * as React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import ActionButton from "src/components/Common/ActionButton";
import ErrorMessage from "src/components/Common/ErrorMessage";
import { ReportGroup } from "src/components/Reporting/Reports/Report/types";
import { AppDispatch, useAppDispatch } from "src/store";
import { showError } from "src/store/slices/notification";
import useReportsContext from "src/context/Reports";
import DisabledExportHelperText from "./DisabledExportHelperText";
import ExportDataHandler from "./ExportDataHandler";

interface ExportDataButtonProps {
  report: string;
  reportGroup: ReportGroup;
}

const ExportDataButton = ({
  report,
  reportGroup
}: ExportDataButtonProps): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();
  const { selectedEvaluation } = useReportsContext();

  const [loading, setLoading] = React.useState<boolean>(false);
  const reportSelected = Boolean(report);

  function errorCallback(error: Error): void {
    dispatch(
      showError({
        message: (
          <ErrorMessage error={error} action="exporting your report data" />
        )
      })
    );
  }

  const handleClick = async (): Promise<void> => {
    setLoading(true);

    ExportDataHandler.invoke({
      reportName: report,
      group: reportGroup,
      evaluation: selectedEvaluation
    })
      .catch(errorCallback)
      .finally(() => setLoading(false));
  };

  return (
    <Tooltip
      placement="bottom-start"
      title={reportSelected ? "" : <DisabledExportHelperText />}
    >
      <Box component="span" sx={{ marginLeft: "20px" }}>
        <ActionButton
          size="medium"
          disabled={!reportSelected}
          loading={loading}
          onClick={handleClick}
        >
          {loading ? "Exporting" : "Export Data"}
        </ActionButton>
      </Box>
    </Tooltip>
  );
};

export default ExportDataButton;
